import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 8,
    cursor: "pointer"
  },
  description: {
    color: '#bebebe'
  }
}));
