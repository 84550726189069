const firebaseConfig = {
    apiKey: "AIzaSyDk-pocbIoREciR-SxYL1K42TjBkFgwD5s",
    authDomain: "legendary-quotes-backoffice.firebaseapp.com",
    databaseURL: "https://legendary-quotes-backoffice.firebaseio.com",
    projectId: "legendary-quotes-backoffice",
    storageBucket: "legendary-quotes-backoffice.appspot.com",
    messagingSenderId: "791160659311",
    appId: "1:791160659311:web:ebb590bd1f74b6f2ae5c9a",
    measurementId: "G-6WNG8D1JDG"
};

export default firebaseConfig;