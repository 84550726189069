import { ListScrapeSourcesResponseDTO } from "./ListScrapeSourcesResponseDTO";
import { GetScrapeContentResponseDTO } from "./GetScrapeContentResponseDTO";
import { FirebaseStorage } from "../../../api/firebase/storage/FirebaseStorage";

export class ScrapeRepositoryV2 {
  constructor(private readonly firebaseStorage: FirebaseStorage) {}

  listScrapeSources = (): Promise<ListScrapeSourcesResponseDTO> => {
    return this.firebaseStorage
      .listFiles("quotesplorer/scraped")
      .then(listResult => ({
        sources: listResult.fileNames.map(fileName => {
          const nameParts = fileName.split("/");
          const nameWithExtension = nameParts.length
            ? nameParts[nameParts.length - 1]
            : nameParts[0];
          const extensionDelimiter = nameWithExtension.indexOf(".");
          const name =
            extensionDelimiter !== -1
              ? nameWithExtension.substring(0, extensionDelimiter)
              : nameWithExtension;
          return {
            id: name,
            name
          };
        })
      }));
  };

  getScrapeContent = async ({
    sourceId
  }: {
    sourceId: string;
  }): Promise<GetScrapeContentResponseDTO> => {
    const fullPath = `quotesplorer/scraped/${sourceId}.json`;

    const downloadUrl = await this.firebaseStorage.getDownloadUrl(fullPath);
    const fetchResponse = await fetch(downloadUrl);
    const scrapedContent = await fetchResponse.json();

    return {
      scrapedContent
    };
  };
}
