export enum FileState {
  NONE = "NONE",
  GENERATING_THUMBNAILS = "GENERATING_THUMBNAILS",
  GENERATING_LINK = "GENERATING_LINK",
  EXPANDING = "EXPANDING"
}

export interface File {
  id: string;
  type: "file" | "directory";
  name: string;
  extension?: string;
  thumbnail_small?: string;
  thumbnail_medium?: string;
  fileType: string;
  fileObjectBacklink?: string;
  publicLinks?: {
    original: string;
    resizedPerPlatform?: Record<string, Record<string, string>>;
  };
  state: FileState;
}
