import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from "react";
import { RecursivePartial } from "../utilityTypes";
import { deepMerge } from "../objects/deepMerge";

export type IncrementalState<T> = [
  T,
  (
    partialUpdatedStateProvider: (currentState: T) => RecursivePartial<T>
  ) => void,
  Dispatch<SetStateAction<T>>
];

export function useIncrementalState<T extends object>(
  initialState: T
): IncrementalState<T> {
  const [state, setState] = useState<T>(initialState);
  const setIncrementalState = useCallback(
    (partialUpdatedStateProvider: (currentState: T) => RecursivePartial<T>) => {
      setState(currentState => {
        return deepMerge(
          currentState,
          partialUpdatedStateProvider(currentState)
        );
      });
    },
    []
  );
  return useMemo(() => [state, setIncrementalState, setState], [
    setIncrementalState,
    state
  ]);
}
