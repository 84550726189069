import EqualSpacedHorizontalGrid from "../../../components/grid/EqualSpacedHorizontalGrid";
import * as React from "react";
import { Avatar } from "@material-ui/core";
import { LocalizedProp } from "../../../types/domainTypes";
import WarningWrapper from "../../../components/warning/WarningWrapper";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import { trimLongText } from "../../../utils/strings/trimLongText";
import {
  isExternalImageLink,
  isLicenseRequiringAttention
} from "./author-validation";

interface AuthorContentProps {
  imageUrl: string | undefined;
  name: LocalizedProp<string>;
  description: LocalizedProp<string>;
  context: LocalizedProp<string>;
  article: string | undefined;
  license: string | undefined;
}

const avatarStyle = { margin: "auto" };

const AuthorContent = (props: AuthorContentProps) => {
  const trimmedLicense = props.license?.trim();
  return (
    <EqualSpacedHorizontalGrid>
      <WarningWrapper
        show={isExternalImageLink(props.imageUrl)}
        message={"External Link"}
      >
        <Avatar src={props.imageUrl} style={avatarStyle} />
      </WarningWrapper>
      <WarningWrapper
        show={!props.name.isLocalized}
        message={"Missing Translation"}
      >
        <Typography>{props.name.value}</Typography>
      </WarningWrapper>
      <WarningWrapper
        show={!props.description.isLocalized}
        message={"Missing Translation"}
      >
        <Typography>
          {trimLongText({ text: props.description.value ?? "" })}
        </Typography>
      </WarningWrapper>
      <WarningWrapper
        show={!props.description.isLocalized}
        message={"Missing Translation"}
      >
        <Typography>
          {trimLongText({ text: props.context.value ?? "" })}
        </Typography>
      </WarningWrapper>
      <Container>
        <Link
          target="_blank"
          href={props.article}
          style={{ overflowWrap: "anywhere" }}
        >
          {props.article}
        </Link>
      </Container>
      <WarningWrapper
        show={isLicenseRequiringAttention(trimmedLicense)}
        message={trimmedLicense ? "Requires Attention" : "Missing License"}
      >
        <Typography>{props.license}</Typography>
      </WarningWrapper>
    </EqualSpacedHorizontalGrid>
  );
};

export default AuthorContent;
