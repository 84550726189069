import { Theme } from "@material-ui/core";

export function overrideTheme(theme: Theme): Theme {
  return {
    ...theme,
    overrides: {
      ...theme.overrides,
      MuiCollapse: {
        ...theme.overrides?.MuiCollapse,
        entered: {
          ...theme.overrides?.MuiCollapse?.entered,
          overflow: "auto"
        }
      },
      MuiExpansionPanel: {
        root: {
          "&.Mui-expanded": {
            margin: 0,
            marginTop: 8,
            "&:first-child": {
              margin: 0
            }
          }
        }
      }
    }
  };
}
