import React, { CSSProperties, useMemo } from "react";
import { List, ListItem, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import useStyles from "../styles";
import { ContextMenuPopover } from "../../../components/context-menu/ContextMenuPopover";
import {
  defaultThumbnailForItem,
  FileManagerDisplayPropsBase,
  getFullName,
  stateIndicatorForItem
} from "./file-manager-display-base";

export interface FileManagerListDisplayProps
  extends FileManagerDisplayPropsBase {}

const thumbnailStyle: CSSProperties = {
  objectFit: "cover",
  width: "2rem",
  height: "2rem"
};

export const FileManagerListDisplay = (props: FileManagerListDisplayProps) => {
  const classes = useStyles();
  const fileListItems = useMemo(() => {
    return props.fileList.map((fileItem, index, arr) => {
      return (
        <ListItem
          button
          onClick={() => props.onFileClick(fileItem)}
          className={
            index % 2 !== 0 ? classes.fileContentsDisplayListItem : undefined
          }
          divider={index < arr.length - 1}
        >
          <ContextMenuPopover render={() => props.renderContextMenu(fileItem)}>
            <Box className={classes.fileContentsDisplayListItemIcon}>
              {defaultThumbnailForItem(fileItem, thumbnailStyle)}
              <Typography style={{ marginLeft: 8 }}>
                {getFullName(fileItem)}
              </Typography>
              <Box marginLeft={4}>{stateIndicatorForItem(fileItem)}</Box>
            </Box>
          </ContextMenuPopover>
        </ListItem>
      );
    });
  }, [
    classes.fileContentsDisplayListItem,
    classes.fileContentsDisplayListItemIcon,
    props
  ]);
  return <List>{fileListItems}</List>;
};
