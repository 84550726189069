import { ExportFileFormat } from "./ExportFileFormat";

export function generateExportedFile(
  selectedFields: string[],
  content: Record<string, string>[],
  fileName: string,
  format: ExportFileFormat
) {
  const element = document.createElement("a");
  const generator =
    format === ExportFileFormat.CSV ? generateCSV : generateJSON;
  const file = new Blob([generator(selectedFields, content)], {
    type: "text/plain"
  });
  element.href = URL.createObjectURL(file);
  element.download = `${fileName}.${format.toLowerCase()}`;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}

function generateCSV(
  selectedFields: string[],
  content: Record<string, string>[]
): string {
  const contentAsColumns = content.map(resource =>
    selectedFields.map(col => resource[col])
  );
  return [selectedFields, ...contentAsColumns]
    .map(row => row.map(str => `"${str}"`).join(","))
    .join("\n");
}

function generateJSON(
  selectedFields: string[],
  content: Record<string, string>[]
): string {
  return JSON.stringify(
    content.map(resource =>
      selectedFields.reduce((acc, field) => {
        acc[field] = resource[field];
        return acc;
      }, {} as Record<string, string>)
    )
  );
}
