import * as firebase from "firebase";
import { QuoteCollection } from "./QuoteCollection";
import Quote from "../../content/Quote";
import { Commit } from "../../commits/Commit";
import User from "../../auth/User";
import Author, { AuthorFields } from "../../content/Author";
import { indexBy } from "../../../utils/arrays/indexBy";
import { Resources } from "../../../data/Resources";
import { combineLatest, Observable } from "rxjs";
import {
  collectionAsObservable,
  documentAsObservable
} from "../../../utils/firebase/asObservable";
import { Locale } from "../../../locale";
import {
  mapDocumentToAuthor,
  mapDocumentToBook,
  mapDocumentToCategory,
  mapDocumentToCommit,
  mapDocumentToConfiguration,
  mapDocumentToMediaPlatform,
  mapDocumentToPodcast,
  mapDocumentToQuote,
  mapDocumentToTask,
  mapDocumentToUser,
  mapDocumentToVideo,
  mapDocumentToWeeklyTip
} from "../../../data/mappers/firebase-data-mappers";
import Category from "../../content/Category";
import Configuration from "../../configuration/Configuration";
import { map, switchMap } from "rxjs/operators";
import Podcast from "../../content/Podcast";
import MediaPlatform from "../../content/MediaPlatform";
import Video from "../../content/Video";
import Book from "../../content/Book";
import { Task } from "../../tasks/Task";
import WeeklyTip from "../../content/WeeklyTip";
import { DiagnosticJob } from "../../../repository/use-case/developer-console/DiagnosticJob";
import { DiagnosticJobReport } from "../../../repository/use-case/developer-console/DiagnosticJobReport";
import { DiagnosticJobType } from "../../../repository/use-case/developer-console/DiagnosticJobType";

export class FirebaseData {
  private quotesCollection?: Promise<QuoteCollection>;

  constructor(
    private readonly firebaseApp: firebase.app.App,
    private readonly defaultLocale: Locale
  ) {
    this.firebaseApp
      .firestore()
      .enablePersistence({ synchronizeTabs: true })
      .catch(e => console.warn(`Caching failed with error=${e}`));
  }

  async updateUserInfo(user: User): Promise<void> {
    return this.getUsersCollection()
      .doc(user.id)
      .set({ ...user }, { merge: true });
  }

  async getUsers(): Promise<Map<string, User>> {
    const usersCollection = await this.getUsersCollection().get();
    const users = usersCollection.docs.map(doc => (doc as unknown) as User);
    return indexBy(users, user => user.id);
  }

  getUsersObservable(): Observable<Map<string, User>> {
    return collectionAsObservable(this.getUsersCollection(), snapshot =>
      snapshot.docs.map(doc => mapDocumentToUser(doc))
    ).pipe(map(users => indexBy(users, user => user.id)));
  }

  private getUsersCollection() {
    return this.firebaseApp.firestore().collection(Resources.USERS);
  }

  getQuotesCollection = async (locale: Locale) => {
    if (!this.quotesCollection) {
      const quotesSnapshot = await this.resourceCollection(
        Resources.QUOTES,
        locale
      ).get();
      this.quotesCollection = Promise.resolve({
        size: quotesSnapshot.size
      } as QuoteCollection);
    }
    return this.quotesCollection;
  };

  getQuotesContent = async (locale: Locale) => {
    const quotesSnapshot = await this.resourceCollection(
      Resources.QUOTES,
      locale
    ).get();
    return quotesSnapshot.docs.map(document => mapDocumentToQuote(document));
  };

  getQuotesObservable(locale: Locale): Observable<Quote[]> {
    return collectionAsObservable(
      this.resourceCollection(Resources.QUOTES, locale),
      snapshot => snapshot.docs.map(doc => mapDocumentToQuote(doc))
    );
  }

  getCategoriesObservable(locale: Locale): Observable<Category[]> {
    return collectionAsObservable(
      this.resourceCollection(Resources.CATEGORIES, locale),
      snapshot => snapshot.docs.map(doc => mapDocumentToCategory(doc))
    );
  }

  getPodcastsObservable(locale: Locale): Observable<Podcast[]> {
    return collectionAsObservable(
      this.resourceCollection(Resources.PODCASTS, locale),
      snapshot => snapshot.docs.map(doc => mapDocumentToPodcast(doc))
    );
  }

  getVideosObservable(locale: Locale): Observable<Video[]> {
    return collectionAsObservable(
      this.resourceCollection(Resources.VIDEOS, locale),
      snapshot => snapshot.docs.map(doc => mapDocumentToVideo(doc))
    );
  }

  getBooksObservable(locale: Locale): Observable<Book[]> {
    return collectionAsObservable(
      this.resourceCollection(Resources.BOOKS, locale),
      snapshot => snapshot.docs.map(doc => mapDocumentToBook(doc))
    );
  }

  getWeeklyTipsObservable(locale: Locale): Observable<WeeklyTip[]> {
    return collectionAsObservable(
      this.resourceCollection(Resources.WEEKLY_TIP, locale),
      snapshot => snapshot.docs.map(doc => mapDocumentToWeeklyTip(doc))
    );
  }

  getMediaPlatformsObservable(locale: Locale): Observable<MediaPlatform[]> {
    return collectionAsObservable(
      this.resourceCollection(Resources.MEDIA_PLATFORM, locale),
      snapshot => snapshot.docs.map(doc => mapDocumentToMediaPlatform(doc))
    );
  }

  getAuthorsContent = async (locale: Locale) => {
    const authorsSnapshot = await this.resourceCollection(
      Resources.AUTHORS,
      locale
    ).get();
    return authorsSnapshot.docs.map(
      document =>
        ({
          id: document.id,
          name: document.get(AuthorFields.NAME),
          description: document.get(AuthorFields.DESCRIPTION),
          [AuthorFields.CONTEXT]: document.get(AuthorFields.CONTEXT),
          imageUrl: document.get(AuthorFields.IMAGE_URL),
          articleUrl: document.get(AuthorFields.ARTICLE_URL)
        } as Author)
    );
  };

  getAuthorsObservable(locale: Locale): Observable<Author[]> {
    return collectionAsObservable(
      this.resourceCollection(Resources.AUTHORS, locale),
      snapshot => snapshot.docs.map(doc => mapDocumentToAuthor(doc))
    );
  }

  getCommitsObservable(
    resource: Resources,
    locale: Locale
  ): Observable<Commit[]> {
    return collectionAsObservable(
      this.commitsCollection(resource, locale),
      snapshot => snapshot.docs.map(value => mapDocumentToCommit(value))
    );
  }

  getConfigurationObservable(): Observable<Configuration> {
    return documentAsObservable(
      this.firebaseApp
        .firestore()
        .collection("system")
        .doc("configuration"),
      snapshot => mapDocumentToConfiguration(snapshot)
    );
  }

  async getCommits(resource: Resources, locale: Locale) {
    const { docs } = await this.commitsCollection(resource, locale).get();
    return docs.map(mapDocumentToCommit);
  }

  getLocalesObservable(): Observable<string[]> {
    return documentAsObservable(
      this.firebaseApp
        .firestore()
        .collection("data")
        .doc("locales"),
      snapshot => (snapshot.get("locales") as string[]) || []
    );
  }

  getTasksObservable(): Observable<Task[]> {
    return collectionAsObservable(
      this.firebaseApp
        .firestore()
        .collection("task_catalog")
        .orderBy("updatedAt", "desc"),
      snapshot => snapshot.docs.map(document => mapDocumentToTask(document))
    );
  }

  getCommit = async (resource: Resources, locale: Locale, commitId: string) => {
    return mapDocumentToCommit(
      await this.commitsCollection(resource, locale)
        .doc(commitId)
        .get()
    );
  };

  getDiagnosticsJobsObservable(): Observable<DiagnosticJob[]> {
    return documentAsObservable(
      this.firebaseApp.firestore().doc("/developer/diagnostics"),
      documentSnapshot => ({
        jobs: documentSnapshot.get("jobs") as string[]
      })
    ).pipe(
      switchMap(diagnosticsDocument => {
        return combineLatest(
          diagnosticsDocument.jobs.map(jobName =>
            documentAsObservable(
              this.firebaseApp
                .firestore()
                .doc(`/developer/diagnostics/${jobName}/status`),
              documentMapper => ({
                id: jobName,
                state: documentMapper.get("state")
              })
            )
          )
        );
      })
    );
  }

  // getDiagnosticsJobReport<T>(jobId: string): Observable<DiagnosticJobReport<T>> {
  //   return documentAsObservable(
  //     this.firebaseApp.firestore().doc(`/developer/diagnostics/${jobId}`),
  //     documentSnapshot => ({
  //       jobs: documentSnapshot.get("jobs") as string[]
  //     })
  //   )
  // }

  private commitsCollection(resource: Resources, locale: Locale) {
    return this.firebaseApp
      .firestore()
      .collection("commits")
      .doc(resource)
      .collection(locale);
  }

  private resourceCollection(resource: Resources, locale: Locale) {
    return this.firebaseApp
      .firestore()
      .collection("data")
      .doc(resource)
      .collection(locale);
  }
}
