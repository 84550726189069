import ManagedQuote, { QuoteContentFields } from "../../data/ManagedQuote";
import { QuoteFields } from "../../api/content/Quote";
import { ManagedAuthor } from "../../data/ManagedAuthor";
import { AuthorFields } from "../../api/content/Author";
import ManagedCategory from "../../data/ManagedCategory";
import { CategoryFields } from "../../api/content/Category";
import {
  ARCHIVE_ACTION,
  UN_ARCHIVE_ACTION
} from "../content/actions/common-actions";

export function createManagedQuote(
  quoteText: string,
  authorText: string,
  categories?: string[]
): ManagedQuote {
  return {
    archived: false,
    tentativeState: {
      isNew: true,
      isDeleted: false,
      isArchived: false,
      isUnarchived: false,
      updatedContent: {}
    },
    actions: {
      update: false,
      restore: false,
      remove: false,
      [ARCHIVE_ACTION]: false,
      [UN_ARCHIVE_ACTION]: false
    },
    content: {
      [QuoteContentFields.AUTHOR]: [authorText.trim()],
      [QuoteContentFields.CATEGORY]: categories,
      [QuoteFields.TEXT]: [quoteText.trim()],
      [QuoteFields.ID]: "",
      [QuoteFields.CATEGORY_ID]: undefined,
      [QuoteFields.AUTHOR_ID]: undefined,
      [QuoteFields.CONTEXT]: undefined,
      untranslatedFields: [],
      hasOwnPresence: false
    }
  };
}

export function createManagedAuthor(authorText: string): ManagedAuthor {
  return {
    archived: false,
    tentativeState: {
      isNew: true,
      isDeleted: false,
      isArchived: false,
      isUnarchived: false,
      updatedContent: {}
    },
    actions: {
      update: false,
      restore: false,
      remove: false,
      [ARCHIVE_ACTION]: false,
      [UN_ARCHIVE_ACTION]: false
    },
    content: {
      [AuthorFields.NAME]: [authorText.trim()],
      [AuthorFields.ID]: "",
      [AuthorFields.DESCRIPTION]: [],
      [AuthorFields.CONTEXT]: [],
      [AuthorFields.IMAGE_URL]: [],
      [AuthorFields.ARTICLE_URL]: [],
      [AuthorFields.LICENSE]: [],
      untranslatedFields: [],
      hasOwnPresence: false
    }
  };
}

export function createManagedCategory(categoryName: string): ManagedCategory {
  return {
    archived: false,
    tentativeState: {
      isNew: true,
      isDeleted: false,
      isArchived: false,
      isUnarchived: false,
      updatedContent: {}
    },
    actions: {
      update: false,
      restore: false,
      remove: false,
      [ARCHIVE_ACTION]: false,
      [UN_ARCHIVE_ACTION]: false
    },
    content: {
      [CategoryFields.NAME]: [categoryName],
      [CategoryFields.ID]: "",
      [CategoryFields.IMAGE_URL]: [],
      untranslatedFields: [],
      hasOwnPresence: false
    }
  };
}
