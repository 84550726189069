import { FabProps, MuiThemeProvider, Theme } from "@material-ui/core";
import * as React from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

const CreateFAB = (props: Omit<FabProps, "children">) => {
  const theme = React.useCallback(
    (theme: Theme) => ({
      ...theme,
      overrides: {
        ...theme.overrides,
        MuiFab: {
          ...theme.overrides?.MuiFab,
          root: {
            ...theme.overrides?.MuiFab?.root,
            color: "#fff",
            backgroundColor: theme.contentManagement?.create?.color,
            "&:hover": {
              backgroundColor: theme.contentManagement?.create?.hoverColor
            }
          }
        }
      }
    }),
    []
  );
  return (
    <MuiThemeProvider theme={theme}>
      <Fab aria-label="add" size="medium" {...props}>
        <AddIcon />
      </Fab>
    </MuiThemeProvider>
  );
};

export default CreateFAB;
