import { CommitsApi } from "../../../api/commits/CommitsApi";
import {
  Commit,
  CommitResource,
  commitResources
} from "../../../api/commits/Commit";
import { Locale } from "../../../locale";
import { Resources } from "../../../data/Resources";
import HotLocalizedRepository from "../../HotLocalizedRepository";
import { combineLatest, Observable } from "rxjs";
import asMap from "../../../utils/arrays/asMap";
import { map } from "rxjs/operators";
import { transformValues } from "../../../utils/maps";
import { Pair } from "../../../utils/tuples/Pair";
import distinct from "../../../utils/arrays/distinct";

const resources: CommitResource[] = commitResources();

export class CommitsRepository extends HotLocalizedRepository<
  Map<CommitResource, Map<string, Commit>>,
  Map<CommitResource, Commit[]>
> {
  constructor(defaultLocale: string, private readonly commitsApi: CommitsApi) {
    super(defaultLocale);
  }

  protected readonly _getDataObservable: (
    locale: Locale
  ) => Observable<Map<CommitResource, Commit[]>> = locale => {
    return combineLatest(
      resources.map(resource => {
        return this.commitsApi
          .getCommitsObservable(resource, locale)
          .pipe(map(commits => ({ resource, commits })));
      })
    ).pipe(
      map(resourceCommits =>
        asMap(
          resourceCommits,
          pair => pair.resource,
          pair => pair.commits
        )
      )
    );
  };

  protected readonly _combiner: (
    data: Map<CommitResource, Commit[]>[]
  ) => Map<CommitResource, Map<string, Commit>> = data => {
    return transformValues(
      data.reduce((resourceToArrayOfCommits, resourceToCommits) => {
        resourceToCommits.forEach((commits, resource) => {
          resourceToArrayOfCommits.set(
            resource,
            (resourceToArrayOfCommits.get(resource) || []).concat([commits])
          );
        });
        return resourceToArrayOfCommits;
      }, new Map<CommitResource, Commit[][]>()),
      arrayOfCommits =>
        arrayOfCommits.reduce((combinedCommits, localizedCommits) => {
          localizedCommits.forEach(commit => {
            const previousCommit =
              combinedCommits.get(commit.commitId) || commit;
            const updatedFields = {
              ...previousCommit.updatedFields,
              ...commit.updatedFields
            };
            for (const field in updatedFields) {
              updatedFields[field] = updatedFields[field].map(
                (updateData: {
                  value: string;
                  ownerId?: string;
                  updateTime: number;
                  locales: Locale[];
                }) => ({
                  ...updateData,
                  locales: distinct(
                    (previousCommit.updatedFields[field]?.locales || []).concat(
                      updateData.locales || []
                    )
                  )
                })
              );
            }
            combinedCommits.set(commit.commitId, {
              ...commit,
              updatedFields,
              linkedResources: {
                ...previousCommit.linkedResources,
                ...commit.linkedResources
              },
              updateTime: commit.updateTime || previousCommit.updateTime
            });
          });
          return combinedCommits;
        }, new Map())
    );
  };

  async updateCommit({
    resource,
    ownerId,
    commitId,
    resourceId,
    updateData
  }: {
    resource: CommitResource;
    ownerId: string;
    updateData: Map<
      Locale,
      {
        updatedFields: Map<string, string[]>;
        updatedLinks: Map<string, Pair<string, string>[]>;
      }
    >;
    commitId?: string;
    resourceId?: string;
  }): Promise<{ commitId: string }> {
    return await this.commitsApi.updateCommit({
      resource,
      commitId,
      ownerId,
      updateData,
      resourceId
    });
  }

  async revertCommit(
    resource: CommitResource,
    locale: string,
    commitId: string
  ) {
    await this.commitsApi.revertCommit(resource, locale, commitId);
  }

  async deleteCommit(
    resource: CommitResource,
    locale: string,
    resourceId: string,
    ownerId?: string
  ) {
    return this.commitsApi.deleteCommit(resource, locale, resourceId, ownerId);
  }

  async archiveCommit({
    resource,
    locale,
    commitId,
    resourceId,
    ownerId
  }: {
    resource: CommitResource;
    locale: string;
    commitId?: string;
    resourceId?: string;
    ownerId?: string;
  }) {
    return this.commitsApi.archiveCommit(
      resource,
      locale,
      resourceId,
      commitId,
      ownerId
    );
  }

  async unarchiveCommit({
    resource,
    locale,
    commitId,
    resourceId,
    ownerId
  }: {
    resource: CommitResource;
    locale: string;
    commitId?: string;
    resourceId?: string;
    ownerId?: string;
  }) {
    return this.commitsApi.unarchiveCommit(
      resource,
      locale,
      resourceId,
      commitId,
      ownerId
    );
  }
}
