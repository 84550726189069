import { ScrappedContent } from "../../pages/quotesplorer/ScrappedContent";
import { useCallback, useState } from "react";
import { pascalCase } from "../../utils/strings/casing";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";

export function useFilters(scrapeSource: () => Promise<ScrappedContent>) {
  const [filters, setFilters] = useState(createDefaultFilters());

  const loadFilters = useCallback(async () => {
    const scrapedContent = await scrapeSource();
    setFilters(currentFilters => {
      const updatedFilters = new Map(currentFilters.entries());
      scrapedContent.quotes.forEach(quote => {
        updatedFilters.get(FILTER_AUTHORS)?.options?.set(quote.author, {
          label: quote.author,
          selected: true
        });

        const filterTagsOptions = updatedFilters.get(FILTER_CATEGORIES)
          ?.options;
        if (filterTagsOptions) {
          quote.metadata?.tags?.forEach(metadataTag => {
            filterTagsOptions.set(metadataTag, {
              label: pascalCase(metadataTag),
              selected: true
            });
          });
        }
      });
      return updatedFilters;
    });
  }, [scrapeSource]);
  useAsyncEffect(loadFilters);

  const onFilterValueChanged = useCallback(
    (filter: string, selectedKeys: string[]) => {
      setFilters(currentFilters => {
        const updatedFilters = new Map(currentFilters.entries());
        const updatedOptionsMap = new Map<string, FilterOption>();
        const currentFilterData = updatedFilters.get(filter);
        if (currentFilterData) {
          const selectedKeyset = new Set(selectedKeys);
          Array.from(currentFilterData.options.entries()).forEach(([key]) => {
            updatedOptionsMap.set(key, {
              ...currentFilterData.options.get(key)!,
              selected: selectedKeyset.has(key)
            });
          });
          const updatedFilterData: FilterData = {
            ...currentFilterData,
            options: updatedOptionsMap
          };
          updatedFilters.set(filter, updatedFilterData);
        }
        return updatedFilters;
      });
    },
    []
  );

  return {
    filters,
    onFilterValueChanged
  };
}

export function createDefaultFilters(): Filters {
  return new Map([
    [FILTER_AUTHORS, { options: new Map() }],
    [FILTER_CATEGORIES, { options: new Map() }],
    [
      FILTER_ATTRIBUTES,
      {
        options: new Map([
          [FILTER_ATTRIBUTE_EXISTING, { label: "Existing Quotes", selected: true }]
        ])
      }
    ]
  ]);
}

export const FILTER_AUTHORS = "Authors";
export const FILTER_CATEGORIES = "Categories";
export const FILTER_ATTRIBUTES = "Attributes";
export const FILTER_ATTRIBUTE_EXISTING = "attribute_existing";

export type Filters = Map<string, FilterData>;

export interface FilterOption {
  label: string;
  selected: boolean;
}

export interface FilterData {
  options: Map<string, FilterOption>;
}
