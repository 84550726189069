import {
  FileManagerFileListItem,
  FileManagerViewModelBase
} from "../FileManagerViewModelBase";
import { LocalViewModelFactory } from "../../../architecture/view-model/LocalViewModelFactory";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React, { useMemo } from "react";
import { DeleteForever, Edit } from "@material-ui/icons";
import { useLocalViewModel } from "../../../architecture/view-model/useViewModel";

export interface FileContextMenuProps {
  viewModelFactory: LocalViewModelFactory<FileManagerViewModelBase>;
  fileItem: FileManagerFileListItem;
}

interface MenuItem {
  label: string;
  icon: React.ComponentType;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const FileContextMenu = (props: FileContextMenuProps) => {
  const [, viewModel] = useLocalViewModel(props.viewModelFactory);
  const actions = useMemo<MenuItem[]>(() => {
    const actions = [
      {
        label: "Delete",
        icon: DeleteForever,
        onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          event.stopPropagation();
          viewModel.onClickDeleteFile(props.fileItem);
        }
      }
    ];
    if (props.fileItem.type !== "directory") {
      actions.push({
        label: "Rename",
        icon: Edit,
        onClick: event => {
          event.stopPropagation();
          viewModel.onClickRenameFile(props.fileItem);
        }
      });
    }
    return actions;
  }, [props.fileItem, viewModel]);
  return (
    <List>
      {actions.map(action => {
        const Icon = action.icon;
        return (
          <ListItem button onClick={action.onClick}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText>{action.label}</ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
};
