import { LCEWrapper } from "../lce/LCEWrapper";
import React, { useCallback, useMemo } from "react";
import {
  ScrapedQuote,
  ScrappedContent
} from "../../pages/quotesplorer/ScrappedContent";
import { useRepositories } from "../../hooks/useRepositories";
import useLCE from "../../hooks/useLCE";
import { CommitResource } from "../../api/commits/Commit";
import { Locale } from "../../locale";
import ManagedResource from "../../data/ManagedResource";
import ScrollableContent from "../content/ScrollableContent";
import { ScrapedContentItem } from "./ScrapedContentItem";
import { createIndexedData, IndexedData } from "./data-indexing";
import { applyFilters } from "./data-filtering";
import { Filters } from "./useFilters";
import { Paginator } from "../paginator/Paginator";

const SCRAPED_ITEM_SIZE = 120;
const PAGE_MAX_ITEMS = 35;

const ScrapedSourceResultsContentComponent = ({
  onAddQuoteClicked,
  onAddAuthorClicked,
  onAddCategoryClicked,
  scrapeSource,
  managedContentByLocale,
  filters
}: {
  scrapeSource: () => Promise<ScrappedContent>;
  onAddQuoteClicked: (
    quoteText: string,
    author: string,
    categories?: string[]
  ) => void;
  onAddAuthorClicked: (authorName: string) => void;
  onAddCategoryClicked: (categoryName: string) => void;
  managedContentByLocale: Map<
    CommitResource,
    Map<Locale, ManagedResource<any>[]>
  >;
  filters: Filters;
}) => {
  const scrapedResultLCE = useLCE(scrapeSource);
  const { locales } = useRepositories();

  const indexedData = useMemo<IndexedData>(() => {
    return createIndexedData({
      locales,
      managedContentByLocale
    });
  }, [locales, managedContentByLocale]);

  const renderContentItem = useCallback(
    (data: ScrapedQuote) => {
      return (
        <ScrapedContentItem
          data={data}
          indexedData={indexedData}
          onAddQuoteClicked={onAddQuoteClicked}
          onAddAuthorClicked={onAddAuthorClicked}
          onAddCategoryClicked={onAddCategoryClicked}
        />
      );
    },
    [indexedData, onAddAuthorClicked, onAddCategoryClicked, onAddQuoteClicked]
  );

  const renderLCEContent = useCallback(
    (scrappedContent: ScrappedContent) => {
      const filteredData = applyFilters({
        scrappedContent,
        filters,
        indexedData
      });
      const pagesCount = Math.ceil(filteredData.length / PAGE_MAX_ITEMS);
      return (
        <Paginator
          pagesCount={pagesCount}
          renderPage={pageNumber => {
            const dataStart = (pageNumber - 1) * PAGE_MAX_ITEMS;
            const dataEnd = Math.min(
              dataStart + PAGE_MAX_ITEMS,
              filteredData.length
            );
            return (
              <ScrollableContent
                data={filteredData.slice(dataStart, dataEnd)}
                itemSize={SCRAPED_ITEM_SIZE}
                renderContentItem={renderContentItem}
                alternatingColors
              />
            );
          }}
        />
      );
    },
    [filters, indexedData, renderContentItem]
  );

  return <LCEWrapper lce={scrapedResultLCE} renderContent={renderLCEContent} />;
};

export const ScrapedSourceResultsContent = React.memo(ScrapedSourceResultsContentComponent);
