import compose from "../../../utils/compose";
import * as React from "react";
import { useMemo } from "react";
import { Resources } from "../../../data/Resources";
import AuthorContent from "./AuthorContent";
import {
  extractFirstContentField,
  extractFirstLocalizedProp
} from "../../../utils/data/resources/managed";
import ManagedContent from "../../../data/ManagedContent";
import { ManagedAuthor } from "../../../data/ManagedAuthor";
import Author, { AuthorFields } from "../../../api/content/Author";
import { SearchFields } from "../../../types/domainTypes";
import BaseContentManagementPage from "../../../components/pages/BaseContentManagementPage";
import {
  isExternalImageLink,
  isLicenseRequiringAttention
} from "./author-validation";
import { useObservableLCE2 } from "../../../hooks/useObservableLCE2";
import { useRepositories } from "../../../hooks/useRepositories";
import { QuoteFields } from "../../../api/content/Quote";

const searchFields: SearchFields = {
  contentFields: [
    AuthorFields.NAME,
    AuthorFields.ARTICLE_URL,
    AuthorFields.DESCRIPTION,
    AuthorFields.CONTEXT,
    AuthorFields.IMAGE_URL
  ]
};

const AuthorsPageWrapper = () => {
  const renderContentItem = React.useCallback(
    (content: ManagedContent<ManagedAuthor>, locale) => (
      <AuthorContent
        imageUrl={extractFirstContentField(
          content.managedResource,
          AuthorFields.IMAGE_URL
        )}
        name={extractFirstLocalizedProp(
          locale,
          content.managedResource,
          AuthorFields.NAME
        )}
        description={extractFirstLocalizedProp(
          locale,
          content.managedResource,
          AuthorFields.DESCRIPTION
        )}
        context={extractFirstLocalizedProp(
          locale,
          content.managedResource,
          AuthorFields.CONTEXT
        )}
        article={extractFirstContentField(
          content.managedResource,
          AuthorFields.ARTICLE_URL
        )}
        license={extractFirstContentField(
          content.managedResource,
          AuthorFields.LICENSE
        )}
      />
    ),
    []
  );

  const { content } = useRepositories();
  const contentLCE = useObservableLCE2(content.getDataObservable());
  const quoteCountByAuthorId = useMemo(() => {
    if (!contentLCE.content) {
      return new Map();
    }
    return Array.from(
      contentLCE.content.defaultData.data.quotes.values()
    ).reduce((countByAuthorId, quote) => {
      const authorId = quote[QuoteFields.AUTHOR_ID]?.[0];
      if (authorId) {
        countByAuthorId.set(authorId, (countByAuthorId.get(authorId) ?? 0) + 1);
      }
      return countByAuthorId;
    }, new Map<string, number>());
  }, [contentLCE]);

  const filterOptions = useMemo(
    () => [
      {
        label: "License requiring attention",
        evaluate: (author: ManagedContent<Author>) => {
          return isLicenseRequiringAttention(
            author.managedResource.content?.[AuthorFields.LICENSE]?.[0]
          );
        }
      },
      {
        label: "External image link",
        evaluate: (author: ManagedContent<Author>) => {
          return isExternalImageLink(
            author.managedResource.content?.[AuthorFields.IMAGE_URL]?.[0]
          );
        }
      },
      {
        label: "Authors with less than 3 quotes",
        evaluate: (author: ManagedContent<Author>) => {
          return (
            (quoteCountByAuthorId?.get(
              author.managedResource.content[AuthorFields.ID]
            ) ?? Number.MAX_VALUE) < 3
          );
        }
      }
    ],
    [quoteCountByAuthorId]
  );
  return (
    <BaseContentManagementPage
      resource={Resources.AUTHORS}
      renderViewModelContent={renderContentItem}
      searchFields={searchFields}
      filterOptions={filterOptions}
    />
  );
};

const AuthorsPage = compose(React.memo)(
  AuthorsPageWrapper
) as React.ComponentType;

export default AuthorsPage;
