import * as React from "react";
import User from "../api/auth/User";
import { useContext } from "react";

const CurrentUserContext = React.createContext<User | null>(null);
export default CurrentUserContext;

export function useCurrentUser(): User | null {
  return useContext(CurrentUserContext);
}
