import { DateCell } from "../DateCell";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useStyles } from "../styles";
import { Box, MuiThemeProvider } from "@material-ui/core";
import { useThemeOverride } from "../../../utils/styles/mergeThemeOverride";
import { Overrides } from "@material-ui/core/styles/overrides";
import { CalendarEventItem } from "../CalendarEventItem";
import { CalendarEvent } from "../../../repository/use-case/calendar/CalendarEvent";
import { Moment } from "moment-timezone";

const parentHeightButtonLabelStyle: Overrides = {
  MuiButton: {
    label: {
      height: "100%"
    }
  }
};

export const MonthDateCell = ({
  dateCell,
  onDateSelected,
  onDragWhileClick,
  onDragRelease,
  onEventSelected,
  momentProvider
}: {
  dateCell: DateCell;
  onDateSelected: () => void;
  onDragWhileClick: () => void;
  onDragRelease: () => void;
  onEventSelected?: (
    element: React.MouseEvent<HTMLButtonElement>,
    event: CalendarEvent
  ) => void;
  momentProvider: () => Moment;
}) => {
  const classes = useStyles();
  const buttonThemeOverride = useThemeOverride(parentHeightButtonLabelStyle);
  return (
    <MuiThemeProvider theme={buttonThemeOverride}>
      <Box
        className={classes.monthDateCellButton}
        onClick={onDateSelected}
        style={{
          ...(!dateCell.isSelected && {
            borderColor: "transparent"
          })
        }}
        onMouseEnter={event => {
          if (event.buttons === 1) {
            onDragWhileClick();
          }
        }}
        onMouseLeave={event => {
          if (event.buttons === 1) {
            onDragWhileClick();
          } else {
            onDragRelease();
          }
        }}
      >
        <Box className={classes.monthDateCellContainer}>
          <Box className={classes.monthDateCellDateContainer}>
            <Box
              className={
                dateCell.isToday
                  ? classes.todayDateCellBackground
                  : classes.dateCellBackground
              }
            >
              <Typography
                align="right"
                color={
                  dateCell.moment.isBefore(momentProvider(), "month")
                    ? "textSecondary"
                    : "textPrimary"
                }
              >
                {dateCell.moment.format("DD")}
              </Typography>
            </Box>
          </Box>
          {dateCell.events.map(event => (
            <Box className={classes.monthDateCellEventWrapper}>
              <CalendarEventItem
                event={event}
                onClick={element => onEventSelected?.(element, event)}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </MuiThemeProvider>
  );
};
