export interface Announcement {
  id: string;
  active: boolean;
  createdAt: number;
  updatedAt?: number;
  expiresAt?: number;
  title: string;
  userEngagement?: {
    type: "email";
  };
  appearance: AnnouncementAppearance;
}

export type AnnouncementAppearance = BannerAppearance | CardAppearance;

export interface BannerAppearance {
  type: "banner";
  text: string;
  colors: {
    [themeId: string]: {
      textColor: ThemeColor;
      backgroundColor: ThemeColor;
    };
  };
}

export interface CardAppearance {
  type: "card";
  imageUrl: string;
}

export type ThemeColor = number | string;

export function isBannerAppearance(
  announcementAppearance: AnnouncementAppearance
): announcementAppearance is BannerAppearance {
  return announcementAppearance.type === "banner";
}

export function isCardAppearance(
  announcementAppearance: AnnouncementAppearance
): announcementAppearance is CardAppearance {
  return announcementAppearance.type === "card";
}
