import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useMemo } from "react";

export interface BasicDialogProps {
  open: boolean;
  title: string;
  onCloseClick: () => void;
  renderContent: () => JSX.Element;
  renderActions?: () => JSX.Element;
}

const closeIconButtonStyle = {
  display: "flex",
  alignItems: "center",
  marginRight: 16
};

export const BasicDialog = ({
  open,
  title,
  onCloseClick,
  renderActions,
  renderContent
}: BasicDialogProps) => {
  const content = useMemo(() => renderContent(), [renderContent]);
  const actions = useMemo(() => (renderActions ? renderActions() : <></>), [
    renderActions
  ]);
  return (
    <Dialog open={open}>
      <Grid container justify={"space-between"}>
        <Grid item>
          <DialogTitle>{title}</DialogTitle>
        </Grid>
        <Grid item style={closeIconButtonStyle}>
          <IconButton onClick={onCloseClick}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>{content}</DialogContent>
      {actions}
    </Dialog>
  );
};
