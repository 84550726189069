import React, { memo, useCallback, useState } from "react";
import { MaterialCalendar } from "../../components/calendar/MaterialCalendar";
import { Paper } from "@material-ui/core";
import { RenderWithPermission } from "../RenderWithPermission";
import { Permission } from "../../api/permissions/Permission";
import { useStyles } from "./styles";
import { CreatableEventType } from "../../components/calendar/CreatableEventType";
import { CreatableEventEntryType } from "../../repository/use-case/calendar/CreatableEventEntryType";
import { CalendarEvent } from "../../repository/use-case/calendar/CalendarEvent";
import { Moment } from "moment";
import withLocale, {
  LocalePropsConsumer
} from "../../components/hoc/withLocale";
import compose from "../../utils/compose";
import { SelectedEventPopover } from "../../components/calendar/event-popover/SelectedEventPopover";
import { EditEventDialog } from "../../components/calendar/edit-event/EditEventDialog";
import { EventFormData } from "../../components/calendar/edit-event/EventFormData";
import { useCalendarActions } from "./useCalendarActions";
import { createEditEventDialogFormData } from "./formDataFactory";
import { DeleteCalendarEventDialog } from "../../components/calendar/delete-event/DeleteCalendarEventDialog";
import Chip from "@material-ui/core/Chip";
import InfoIcon from "@material-ui/icons/Info";
import Box from "@material-ui/core/Box";
import moment from "moment-timezone";
import { CALENDAR_TIMEZONE } from "../../constants/time";

const CREATABLE_EVENT_TYPES: CreatableEventType[] = [
  {
    id: CreatableEventEntryType.WEEKLY_TIP,
    title: "Weekly Tip",
    defaultColor: "#28ae1c"
  }
];

const CalendarPageComponent = ({ locale }: LocalePropsConsumer) => {
  const {
    listCreatableEventEntries,
    deleteEvent,
    allEvents,
    setEvents,
    getEvents,
    createOrUpdateEvent
  } = useCalendarActions(locale, CALENDAR_TIMEZONE);

  const [onEventElementSelected, setOnEventElementSelected] = useState<
    (element: React.MouseEvent<HTMLButtonElement>) => void | undefined
  >();
  const registerEventSelectionCallback = useCallback(callback => {
    // We use callback variant because if we pass the callback directly as the new state
    // it will be invoked!
    setOnEventElementSelected(() => callback);
  }, []);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent>();
  const onEventSelected = useCallback(
    (element: React.MouseEvent<HTMLButtonElement>, event: CalendarEvent) => {
      onEventElementSelected?.(element);
      setSelectedEvent(event);
    },
    [onEventElementSelected]
  );
  const [deleteDialogCallback, setDeleteDialogCallback] = useState<
    (open: boolean) => void
  >();
  const registerDeleteDialogCallback = useCallback(callback => {
    setDeleteDialogCallback(() => callback);
  }, []);
  const onDeleteConfirm = useCallback(async () => {
    if (!selectedEvent) {
      return;
    }
    const eventId = selectedEvent.id;
    await deleteEvent(eventId);
    setEvents(currentEvents =>
      currentEvents.filter(event => event.id !== eventId)
    );
  }, [deleteEvent, selectedEvent, setEvents]);
  const onDeleteEventClicked = useCallback(() => {
    deleteDialogCallback?.(true);
  }, [deleteDialogCallback]);

  const [selectedDates, setSelectedDates] = useState<Moment[]>([]);
  const onDatesSelected = useCallback((selectedDates: Moment[]) => {
    setSelectedDates(selectedDates);
  }, []);
  const [editEventDialogData, setEditEventDialogData] = useState<{
    open?: boolean;
    formData?: EventFormData;
  }>({});
  const openEditEventOnClick = useCallback(() => {
    setEditEventDialogData(dialogData => ({
      ...dialogData,
      formData: createEditEventDialogFormData(
        selectedEvent,
        selectedDates,
        eventTypeId =>
          CREATABLE_EVENT_TYPES.find(
            type => type.id.toString() === eventTypeId
          ),
        CALENDAR_TIMEZONE
      ),
      open: true
    }));
  }, [selectedDates, selectedEvent]);
  const onCloseEditEventDialog = useCallback(() => {
    setSelectedEvent(undefined);
    setEditEventDialogData(dialogData => ({
      ...dialogData,
      formData: undefined,
      open: false
    }));
  }, []);
  const onSaveEvent = useCallback(
    async (formData: EventFormData) => {
      try {
        await createOrUpdateEvent({
          ...formData,
          eventId: selectedEvent?.id
        });
        setEditEventDialogData(dialogData => ({
          ...dialogData,
          open: false
        }));
      } catch (e: any) {
        console.error(e);
      }
    },
    [createOrUpdateEvent, selectedEvent]
  );

  const momentProvider = useCallback(() => moment.tz(CALENDAR_TIMEZONE), []);

  const classes = useStyles();
  const renderCalendar = useCallback(
    () => (
      <Box className={classes.calendarPageWrapper}>
        <Chip
          size="medium"
          icon={<InfoIcon />}
          label={`Keep in mind, all date & times are in ${CALENDAR_TIMEZONE} timezone.`}
          className={classes.calendarTimeZoneInfo}
        />
        <Paper className={classes.calendarPaperWrapper}>
          <MaterialCalendar
            loadEvents={getEvents}
            onEventSelected={onEventSelected}
            currentEvents={allEvents}
            onDatesSelected={onDatesSelected}
            selectedDates={selectedDates}
            onNewEventClicked={openEditEventOnClick}
            momentProvider={momentProvider}
          />
        </Paper>
        <SelectedEventPopover
          registerEventSelectionCallback={registerEventSelectionCallback}
          onDeleteClicked={onDeleteEventClicked}
          onEditClicked={openEditEventOnClick}
        />
        {editEventDialogData.formData && (
          <EditEventDialog
            initialFormData={editEventDialogData.formData}
            open={!!editEventDialogData.open}
            onClose={onCloseEditEventDialog}
            onSave={onSaveEvent}
            creatableEventTypes={CREATABLE_EVENT_TYPES}
            listCreatableEventEntries={listCreatableEventEntries}
            momentProvider={momentProvider}
          />
        )}
        <DeleteCalendarEventDialog
          registerCallback={registerDeleteDialogCallback}
          deleteEvent={onDeleteConfirm}
        />
      </Box>
    ),
    [
      classes.calendarPageWrapper,
      classes.calendarTimeZoneInfo,
      classes.calendarPaperWrapper,
      getEvents,
      onEventSelected,
      allEvents,
      onDatesSelected,
      selectedDates,
      openEditEventOnClick,
      momentProvider,
      registerEventSelectionCallback,
      onDeleteEventClicked,
      editEventDialogData.formData,
      editEventDialogData.open,
      onCloseEditEventDialog,
      onSaveEvent,
      listCreatableEventEntries,
      registerDeleteDialogCallback,
      onDeleteConfirm
    ]
  );
  return (
    <RenderWithPermission
      requiredPermissions={[Permission.PERMISSION_ACCESS_CMS]}
      renderWhenPermitted={renderCalendar}
    />
  );
};

export const CalendarPage = compose(
  memo,
  withLocale
)(CalendarPageComponent) as React.ComponentType;
