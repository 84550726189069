import { FirebaseFunctions } from "../../../api/firebase/functions/FirebaseFunctions";
import { ListScrapeSourcesResponseDTO } from "./ListScrapeSourcesResponseDTO";
import { GetScrapeIndexResponseDTO } from "./GetScrapeIndexResponseDTO";
import { GetScrapeContentResponseDTO } from "./GetScrapeContentResponseDTO";

const FUNCTION_NAME_LIST_SCRAPE_SOURCES = "listScrapeSources";
const FUNCTION_NAME_GET_SCRAPE_INDEX = "getScrapeIndex";
const FUNCTION_NAME_GET_SCRAPED_CONTENT = "getScrapedContent";

export class ScrapeRepository {
  constructor(private readonly firebaseFunctions: FirebaseFunctions) {}

  listScrapeSources = (): Promise<ListScrapeSourcesResponseDTO> => {
    return this.firebaseFunctions.call(FUNCTION_NAME_LIST_SCRAPE_SOURCES);
  };

  getScrapeIndex = (params: {
    sourceId: string;
  }): Promise<GetScrapeIndexResponseDTO> => {
    return this.firebaseFunctions.call(FUNCTION_NAME_GET_SCRAPE_INDEX, params);
  };

  getScrapeContent = (params: {
    sourceId: string;
    url: string;
  }): Promise<GetScrapeContentResponseDTO> => {
    return this.firebaseFunctions.call(
      FUNCTION_NAME_GET_SCRAPED_CONTENT,
      params
    );
  };
}
