import * as React from "react";
import { ContentItemViewModel } from "../ContentItemViewModel";
import ContentActions from "../actions/ContentActions";
import { ContentActionViewModel } from "../actions/ContentActionViewModel";
import ContentOwner from "../owner/ContentOwner";
import { ContentOwnerViewModel } from "../owner/ContentOwnerViewModel";
import Box from "@material-ui/core/Box";
import useStyles from "../styles";
import { PropsWithChildren, useCallback, useMemo } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import moment from "moment";

interface ContentRowProps {
  viewModel: ContentItemViewModel;
  selectable?: boolean;
  isSelected?: boolean;
  onChange?: (isSelected: boolean) => void;
}

const ContentRow = ({
  viewModel,
  selectable,
  isSelected,
  onChange
}: PropsWithChildren<ContentRowProps>) => {
  const contentActions = viewModel.getContentActions();
  const ownerTimeline = viewModel.getOwnerTimeline();

  const classes = useStyles();

  const onChangeEvent = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.checked ?? false);
    },
    [onChange]
  );

  return (
    <>
      <Box className={classes.container}>
        {selectable && (
          <Checkbox
            checked={isSelected}
            onChange={onChangeEvent}
            color="primary"
          />
        )}
        <Box
          className={classes.actions}
          display={"flex"}
          flexDirection={"column"}
        >
          {contentActions.length > 0 && (
            <ContentActions
              viewModel={new ContentActionViewModel(contentActions)}
            />
          )}
        </Box>
        {viewModel.getRenderContent()}
        <Box className={classes.owner}>
          {ownerTimeline?.length ? (
            <ContentOwner
              viewModel={
                new ContentOwnerViewModel(
                  ownerTimeline,
                  viewModel.contentDividerModificationColor
                )
              }
            />
          ) : viewModel.getUpdateTime() ? (
            <Chip label={moment(viewModel.getUpdateTime()).fromNow(true)} />
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ContentRow;
