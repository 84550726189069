import React, { useCallback } from "react";
import { ListItem, Paper, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Add } from "@material-ui/icons";
import { useViewModel } from "../../../viewmodel/useViewModel";
import { ViewModelKeys } from "../../../viewmodel/ViewModelFactoryMap";

export const CreateNewAnnouncement = () => {
  const [, viewModel] = useViewModel(ViewModelKeys.ANNOUNCEMENTS_VIEW_MODEL);
  const onClick = useCallback(() => viewModel.onClickCreateAnnouncement(), [
    viewModel
  ]);
  return (
    <Paper>
      <ListItem button={true} onClick={onClick}>
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Add />
          <Typography>New announcement</Typography>
        </Box>
      </ListItem>
    </Paper>
  );
};
