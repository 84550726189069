import PublishPageData from "./PublishPageData";
import { CommitResource } from "../../../api/commits/Commit";
import { Locale } from "../../../locale";
import ManagedContent from "../../../data/ManagedContent";
import IndexedResource from "../../../data/IndexedResource";

export function emptyPageData(): PublishPageData {
  return {
    commitStatisticsByResource: new Map<
      CommitResource,
      {
        added: number;
        changed: number;
        removed: number;
        archived: number;
        unarchived: number;
        newTranslations: {
          [locale: string]: { countryCode: string; total: number };
        };
      }
    >(),
    resourcesToIndexedManagedContent: new Map<
      CommitResource,
      Map<Locale, ManagedContent<IndexedResource>>[]
    >(),
    aggregatedCommits: {
      added: 0,
      changed: 0,
      removed: 0,
      archived: 0,
      unarchived: 0,
      newTranslations: {}
    }
  };
}
