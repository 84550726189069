import { Divider, Grid } from "@material-ui/core";
import { DescriptiveIconButton } from "../../../components/buttons/DescriptiveIconButton";
import React, { useMemo } from "react";
import { FileManagerViewModelBase } from "../FileManagerViewModelBase";
import { useLocalViewModel } from "../../../architecture/view-model/useViewModel";
import Box from "@material-ui/core/Box";
import useStyles from "../styles";
import { LocalViewModelFactory } from "../../../architecture/view-model/LocalViewModelFactory";

export interface FileBrowserToolbarActionsProps {
  viewModelFactory: LocalViewModelFactory<FileManagerViewModelBase>;
}

export const FileBrowserToolbarActions = (
  props: FileBrowserToolbarActionsProps
) => {
  const [viewState, viewModel] = useLocalViewModel(props.viewModelFactory);
  const actionGroups = useMemo(
    () =>
      viewState.toolbar.actionGroups.map((actionGroup, index, groups) => {
        return (
          <>
            <Grid
              container
              justify={"center"}
              direction={"row"}
              spacing={1}
              wrap={"nowrap"}
            >
              {actionGroup.map(action => {
                const ActionIcon: React.ComponentType = require("@material-ui/icons")[
                  action.icon
                ];
                return (
                  <Grid item>
                    <DescriptiveIconButton
                      onClick={() => viewModel.onActionClick(action)}
                      icon={<ActionIcon />}
                      description={action.description}
                    />
                  </Grid>
                );
              })}
            </Grid>
            {index < groups.length - 1 ? (
              <Divider
                orientation={"vertical"}
                style={{ height: "auto" }}
                variant={"middle"}
              />
            ) : (
              <></>
            )}
          </>
        );
      }),
    [viewModel, viewState.toolbar.actionGroups]
  );
  const classes = useStyles();
  return <Box className={classes.toolbarActionGroups}>{actionGroups}</Box>;
};
