import React from "react";
import { Grid } from "@material-ui/core";
import GoogleButton from "react-google-button";

const LoginOptions = ({
  onLoginSubmitted
}: {
  onLoginSubmitted: () => void;
}) => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={1}
    >
      {<GoogleButton onClick={onLoginSubmitted} />}
    </Grid>
  );
};

export default React.memo(LoginOptions);
