import { ContentItemViewModel } from "../ContentItemViewModel";
import { ContentAction } from "../actions/ContentActions";
import OwnerTimelineEntry from "../../../data/OwnerTimelineEntry";

export default class ContentCardViewModel extends ContentItemViewModel {
  constructor(
    renderContent: JSX.Element,
    private readonly renderHeader: JSX.Element,
    contentActions: ContentAction[] = [],
    modificationType?: "create" | "update" | "remove" | "archive" | "unarchive",
    ownerTimeline?: OwnerTimelineEntry[]
  ) {
    super(renderContent, contentActions, modificationType, ownerTimeline);
  }

  getRenderHeaderComponent(): JSX.Element {
    return this.renderHeader;
  }
}
