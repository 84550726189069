import * as React from "react";
import { useMemo } from "react";
import { MuiThemeProvider, Theme, Tooltip } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { CommitType } from "../../../api/commits/CommitType";
import UpdateIcon from "@material-ui/icons/Update";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useStyles } from "./styles";
import ArchiveIcon from "@material-ui/icons/Archive";
import { Unarchive } from "@material-ui/icons";

interface TimelineActionProps {
  timeline: { type: CommitType; description: string; timestamp: number };
  colorType?: "create" | "update" | "remove" | "archive" | "unarchive";
  marginRight: string;
}

const TimelineAction = (props: TimelineActionProps) => {
  const theme = React.useCallback(
    (theme: Theme) => ({
      ...theme,
      overrides: {
        ...theme.overrides,
        MuiSvgIcon: {
          ...theme.overrides?.MuiSvgIcon,
          root: {
            ...theme.overrides?.MuiSvgIcon?.root,
            color:
              props.colorType &&
              theme.contentManagement?.[props.colorType].color
          }
        }
      },
      palette: {
        ...theme.palette,
        primary: {
          ...theme.palette.primary,
          main:
            (props.colorType &&
              theme.contentManagement?.[props.colorType].color) ||
            ""
        }
      }
    }),
    [props.colorType]
  );
  const style = React.useMemo(
    () => ({
      marginRight: props.marginRight,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }),
    [props.marginRight]
  );
  const classes = useStyles();

  const timelineActionIcon = useMemo(() => {
    switch (props.timeline.type) {
      case CommitType.UPDATE_FIELD:
        return <UpdateIcon className={classes.iconStyle} fontSize="small" />;
      case CommitType.CREATE_ENTRY:
        return <AddIcon className={classes.iconStyle} fontSize="small" />;
      case CommitType.DELETE_ENTRY:
        return (
          <DeleteForeverIcon className={classes.iconStyle} fontSize="small" />
        );
      case CommitType.ARCHIVE_ENTRY:
        return <ArchiveIcon className={classes.iconStyle} fontSize="small" />;
      case CommitType.UNARCHIVE_ENTRY:
        return <Unarchive className={classes.iconStyle} fontSize="small" />;
    }
  }, [classes.iconStyle, props.timeline.type]);

  return (
    <MuiThemeProvider theme={theme}>
      <Tooltip title={props.timeline.description}>
        <Box borderRight={2} color="primary.main" style={style}>
          {timelineActionIcon}
        </Box>
      </Tooltip>
    </MuiThemeProvider>
  );
};

export default TimelineAction;
