import { Observable } from "rxjs";
import { UserTask } from "../../../domain/global-user-tasks/entities/UserTask";
import Box from "@material-ui/core/Box";
import React from "react";
import { Typography } from "@material-ui/core";
import { useObservableLCE2 } from "../../../hooks/useObservableLCE2";
import { LCEWrapper } from "../../lce/LCEWrapper";
import LinearProgress from "@material-ui/core/LinearProgress";

export interface ActivityTaskItemProps {
  taskObservable: Observable<UserTask>;
}

export const ActivityTaskItem = (props: ActivityTaskItemProps) => {
  const taskLCE = useObservableLCE2(props.taskObservable);
  return (
    <LCEWrapper
      lce={taskLCE}
      renderContent={task => (
        <Box style={{ width: "100%" }}>
          <Typography>{task.label}</Typography>
          <LinearProgress variant={"determinate"} value={task.progress} />
        </Box>
      )}
    />
  );
};
