import makeStyles from "@material-ui/core/styles/makeStyles";
import pageContainer from "../../styles/pageContainer";

const useStyles = makeStyles(theme => ({
  container: {
    ...pageContainer(theme),
    display: "flex",
    flexDirection: "column",
    overflowY: "auto"
  },
  toolBar: {
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  toolbarNavigationControls: {},
  toolbarDivider: {
    height: "auto"
  },
  toolbarBreadcrumbs: {
    flexGrow: 1,
    marginLeft: 8,
    marginRight: 8,
    display: "flex",
    alignItems: "center"
  },
  toolbarActions: {},
  toolbarActionGroups: {
    display: "flex",
    flexDirection: "row"
  },
  fileContentsDisplayGridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  fileContentsDisplayListItemIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%"
  },
  fileContentsDisplayListItem: {
    backgroundColor: theme.components?.list?.alternatingColor
  }
}));

export default useStyles;
