import React, { useCallback, useMemo } from "react";
import { LCEWrapper } from "../../../../components/lce/LCEWrapper";
import { DiagnosticJobStatus } from "../../../../repository/use-case/developer-console/DiagnosticJobStatus";
import { DiagnosticJobType } from "../../../../repository/use-case/developer-console/DiagnosticJobType";
import { useRepositories } from "../../../../hooks/useRepositories";
import { useObservableLCE2 } from "../../../../hooks/useObservableLCE2";

export interface DiagnosticJobLatestRunProps {
  jobType: DiagnosticJobType;
  renderContent: (jobStatus: DiagnosticJobStatus) => JSX.Element;
}

export const DiagnosticJobLatestRun = (props: DiagnosticJobLatestRunProps) => {
  const { developerConsole } = useRepositories();

  const diagnosticsJobsStatuses = useObservableLCE2(
    developerConsole.diagnosticsJobsStatuses
  );

  const diagnosticsJobLCE = useMemo(
    () => ({
      ...diagnosticsJobsStatuses,
      content: diagnosticsJobsStatuses.content?.find(
        jobStatus => jobStatus.id === jobTypeToId(props.jobType)
      )
    }),
    [diagnosticsJobsStatuses, props.jobType]
  );
  const renderLoader = useCallback(() => <></>, []);
  return (
    <LCEWrapper
      lce={diagnosticsJobLCE}
      renderContent={props.renderContent}
      renderLoader={renderLoader}
    />
  );
};

function jobTypeToId(jobType: DiagnosticJobType) {
  switch (jobType) {
    case DiagnosticJobType.JOB_TYPE_CONTENT_DISCREPANCIES:
      return "jobs_content_discrepancies";
  }
}
