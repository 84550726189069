import toManagedResource from "./managed-resource-mapper";
import calculateTimeline from "../owner-timeline-mapper";
import { Commit, CommitResource } from "../../../api/commits/Commit";
import User from "../../../api/auth/User";
import { extractValues } from "../../../utils/maps";
import { Resources } from "../../Resources";
import Category from "../../../api/content/Category";
import ManagedCategory from "../../ManagedCategory";
import { Locale } from "../../../locale";

export default function toManagedCategories({
  commits,
  users,
  categories,
  locale
}: {
  commits: Map<CommitResource, Map<string, Commit>>;
  users: Map<string, User>;
  categories: Map<string, Category>;
  locale: Locale;
}): ManagedCategory[] {
  return toManagedResource({
    calculateTimeline: calculateTimeline("category"),
    commits: commits.get(Resources.CATEGORIES) || new Map(),
    users,
    contentCollection: extractValues(categories, category => ({
      ...category
    })),
    locale
  });
}
