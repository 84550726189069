import Destination from "./Destination";
import { CMSRoutes } from "../../constants/routes/cms-routes";
import { Dashboard, Folder, FormatQuote } from "@material-ui/icons";
import FaceIcon from "@material-ui/icons/Face";
import CategoryIcon from "@material-ui/icons/Category";
import ExtensionIcon from "@material-ui/icons/Extension";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import MicIcon from "@material-ui/icons/Mic";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PublishIcon from "@material-ui/icons/Publish";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ExploreIcon from "@material-ui/icons/Explore";
import * as React from "react";

export const cmsDestinations = (): Array<Destination> => [
  {
    name: "Dashboard",
    relativePath: CMSRoutes.DASHBOARD,
    icon: <Dashboard />
  },
  {
    name: "Calendar",
    relativePath: CMSRoutes.CALENDAR,
    icon: <DateRangeIcon />
  },
  {
    name: "Quotesplorer",
    relativePath: CMSRoutes.QUOTESPLORER,
    icon: <ExploreIcon />
  },
  {
    name: "Content",
    children: [
      {
        name: "Quotes",
        relativePath: CMSRoutes.QUOTES,
        icon: <FormatQuote />
      },
      {
        name: "Authors",
        relativePath: CMSRoutes.AUTHORS,
        icon: <FaceIcon />
      },
      {
        name: "Categories",
        relativePath: CMSRoutes.CATEGORIES,
        icon: <CategoryIcon />
      },
      {
        name: "Platforms",
        relativePath: CMSRoutes.MEDIA_PLATFORMS,
        icon: <ExtensionIcon />
      },
      {
        name: "Weekly Tips",
        relativePath: CMSRoutes.WEEKLY_TIPS,
        icon: <ViewCarouselIcon />
      }
    ]
  },
  {
    name: "Feed",
    children: [
      {
        name: "Podcasts",
        relativePath: CMSRoutes.PODCASTS,
        icon: <MicIcon />
      },
      {
        name: "Videos",
        relativePath: CMSRoutes.VIDEOS,
        icon: <VideoLibraryIcon />
      },
      {
        name: "Books",
        relativePath: CMSRoutes.BOOKS,
        icon: <LibraryBooksIcon />
      }
    ]
  },
  {
    name: "File Manager",
    relativePath: CMSRoutes.FILE_MANAGER,
    icon: <Folder />
  },
  {
    name: "Publish",
    relativePath: CMSRoutes.PUBLISH,
    icon: <PublishIcon />
  },
  {
    name: "Tasks",
    relativePath: CMSRoutes.TASKS,
    icon: <AssignmentIcon />
  }
];
