import DestructiveAlertDialog from "../../../../components/dialogs/DestructiveAlertDialog";
import React from "react";
import { useViewModel } from "../../../viewmodel/useViewModel";
import { ViewModelKeys } from "../../../viewmodel/ViewModelFactoryMap";

export const DeleteAnnouncementAlertDialog = () => {
  const [viewState, viewModel] = useViewModel(
    ViewModelKeys.ANNOUNCEMENTS_VIEW_MODEL
  );
  return (
    <DestructiveAlertDialog
      isOpen={viewState.pendingAction?.actionConfirmation?.type === "delete"}
      title={"Delete announcement"}
      message={
        "Are you sure you want to delete this announcement? This action cannot be undone."
      }
      destructiveButtonText={"Delete"}
      handleClose={viewModel.onCancelAction}
      handleConfirm={viewModel.onConfirmAction}
      isLoading={!!viewState.pendingAction?.isLoading}
    />
  );
};
