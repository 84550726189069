import ConfigurationApi from "../../../api/configuration/ConfigurationApi";
import Configuration from "../../../api/configuration/Configuration";
import { Observable } from "rxjs";
import HotObservableRepository from "../../HotObservableRepository";

export default class ConfigurationRepository extends HotObservableRepository<
  Configuration
> {
  constructor(private readonly configurationApi: ConfigurationApi) {
    super();
  }

  protected readonly _loadDataObservable: () => Observable<
    Configuration
  > = () => this.configurationApi.getConfigurationObservable();

  setContentLock({ locked }: { locked: boolean }) {
    return this.configurationApi.setContentLock({ locked });
  }

  performPublish({ ownerId }: { ownerId?: string }) {
    return this.configurationApi.performPublish({ ownerId });
  }

  listBackups({ ownerId }: { ownerId?: string }) {
    return this.configurationApi.listBackups({ ownerId });
  }

  selectBackup({ ownerId, backupId }: { ownerId?: string; backupId: string }) {
    return this.configurationApi.selectBackup({ ownerId, backupId });
  }

  listStagedData() {
    return this.configurationApi.listStagedData();
  }

  selectStagedData(id: string) {
    return this.configurationApi.selectStagedData(id);
  }
}
