import { FirebaseFunctions } from "../../../api/firebase/functions/FirebaseFunctions";

export class WeeklyTipsRepository {
  constructor(private readonly firebaseFunctions: FirebaseFunctions) {}

  revertWeeklyTipCommit = (commitId: string, locale: string): Promise<void> => {
    return this.firebaseFunctions.call("revertWeeklyTipCommit", {
      commitId,
      locale
    });
  };
}
