import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Observable, Subscription } from "rxjs";
import { ViewModelFactoryContext } from "./ViewModelFactoryContext";
import { ViewModel } from "./ViewModel";
import { usedObjectPathsProxy } from "../../utils/objects/used-object-paths-proxy";
import { enumerateDeep } from "../../utils/objects/enumerate-deep";
import { forwardProxy } from "../../utils/objects/forward-proxy";
import { ViewModelFactoryMap } from "./ViewModelFactory";
import { LocalViewModelFactory } from "./LocalViewModelFactory";

type ObservableType<T> = T extends Observable<infer U> ? U : never;
export type ViewModelState<T> = T extends ViewModel<infer S, any> ? S : never;
export type ViewModelEffects<T> = T extends ViewModel<any, infer E> ? E : never;

export type EffectCallback<
  T extends ViewModelFactoryMap<any>,
  K extends keyof T
> = (effect: ReturnType<T[K]>["effects"]) => void;

export function useViewModel<
  T extends ViewModelFactoryMap<any>,
  K extends keyof T
>(
  viewModelType: K,
  onEffect?: (effect: ReturnType<T[K]>["effects"]) => void
): [ViewModelState<ReturnType<T[K]>>, ReturnType<T[K]>] {
  type State = ViewModelState<ReturnType<T[K]>>;

  const viewModelFactory = useContext(ViewModelFactoryContext);
  if (!viewModelFactory) {
    throw new Error(
      "ViewModelFactoryContext value is not defined, please make sure a context exist with initialized value in the component tree."
    );
  }

  const viewModel = useMemo(() => viewModelFactory.get(viewModelType), [
    viewModelFactory,
    viewModelType
  ]);

  const registerReference = useCallback(() => {
    viewModelFactory.registerReference(viewModel);
  }, [viewModel, viewModelFactory]);

  const unregisterReference = useCallback(() => {
    viewModelFactory.unregisterReference(viewModel);
  }, [viewModel, viewModelFactory]);

  return useViewModelInternal(
    viewModel,
    registerReference,
    unregisterReference,
    onEffect
  ) as [ViewModelState<ReturnType<T[K]>>, ReturnType<T[K]>];
}

export function useLocalViewModel<T extends ViewModel<any, any>>(
  viewModelFactory: LocalViewModelFactory<T>,
  onEffect?: (effect: ObservableType<T["effects"]>) => void
): [ViewModelState<T>, T] {
  const viewModel = useMemo(() => viewModelFactory.getLocalViewModel(), [
    viewModelFactory
  ]);
  const registerReference = useCallback(() => {
    viewModelFactory.registerReference(viewModel);
  }, [viewModelFactory, viewModel]);
  const unregisterReference = useCallback(() => {
    viewModelFactory.unregisterReference(viewModel);
  }, [viewModelFactory, viewModel]);

  return useViewModelInternal(
    viewModel,
    registerReference,
    unregisterReference,
    onEffect
  ) as [ViewModelState<T>, T];
}

function useViewModelInternal<T extends ViewModel<any, any>>(
  viewModel: T,
  registerReference: () => void,
  unregisterReference: () => void,
  onEffect?: (effect: ViewModelEffects<T>) => void
) {
  const observedPathsRef = useRef<Set<string>>(new Set());
  const viewStateProxy = useMemo(() => {
    const currentViewStateProxy = forwardProxy(() => viewModel.currentState());
    return usedObjectPathsProxy(
      currentViewStateProxy,
      path => {
        observedPathsRef.current.add(path);
      },
      true
    );
  }, [viewModel]);

  const [, setVersion] = useState(0);
  useEffect(() => {
    registerReference();
    const partialUpdatesSubscription = viewModel.partialStateUpdates.subscribe(
      (partialStateUpdate: Partial<ViewModelState<T>>) => {
        let anyObservedPaths = false;
        enumerateDeep(partialStateUpdate, (_, fullPath) => {
          anyObservedPaths = observedPathsRef.current.has(fullPath);
          return !anyObservedPaths;
        });
        if (anyObservedPaths) {
          setVersion(currentVersion => currentVersion + 1);
        }
      }
    );

    // Trigger a single render to make sure viewState is synced to the latest value.
    // This is because registering a reference could trigger an update to the state.
    setVersion(currentVersion => currentVersion + 1);

    return () => {
      partialUpdatesSubscription.unsubscribe();
      unregisterReference();
    };
  }, [
    registerReference,
    unregisterReference,
    viewModel,
    viewModel.partialStateUpdates
  ]);

  useEffect(() => {
    let effectsSubscription: Subscription | undefined;
    if (onEffect) {
      effectsSubscription = viewModel.effects.subscribe(onEffect);
    }
    return () => {
      effectsSubscription?.unsubscribe();
    };
  }, [onEffect, viewModel.effects]);

  return [viewStateProxy, viewModel];
}
