import React, { useCallback } from "react";
import { Moment } from "moment-timezone";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

export const TimePickerRow = ({
  selectedDate,
  handleDateChange,
  title,
  momentProvider
}: {
  selectedDate: Moment;
  handleDateChange: (newDate: Moment) => void;
  title: string;
  momentProvider: () => Moment;
}) => {
  const onPickerDateChange = useCallback(
    (date: Moment | null) => {
      handleDateChange(date ?? momentProvider());
    },
    [handleDateChange]
  );
  return (
    <Grid container spacing={1} alignItems="center" style={{ width: "100%" }}>
      <Grid item xs={2}>
        <Typography>{`${title}:`}</Typography>
      </Grid>
      <Grid item>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DateTimePicker
            ampm={false}
            value={selectedDate}
            onChange={onPickerDateChange}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};
