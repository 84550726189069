import React from "react";

const RotationAnimatedComponent = ({
  render,
  toDegrees,
  transition = "150ms"
}: {
  render: () => JSX.Element;
  toDegrees: number;
  transition?: string;
}) => {
  return (
    <div
      style={{
        transform: `rotate(${toDegrees}deg)`,
        transformOrigin: "50% 50%",
        transition
      }}
    >
      {render()}
    </div>
  );
};

export default RotationAnimatedComponent;
