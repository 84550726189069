import { Commit } from "../../../api/commits/Commit";
import User from "../../../api/auth/User";
import { CommitType } from "../../../api/commits/CommitType";
import { indexBy } from "../../../utils/arrays/indexBy";
import ManagedResource from "../../ManagedResource";
import OwnerTimelineEntry from "../../OwnerTimelineEntry";
import IndexedResource from "../../IndexedResource";
import { Locale } from "../../../locale";
import LocalizedResource from "../../LocalizedResource";
import {
  ARCHIVE_ACTION,
  UN_ARCHIVE_ACTION
} from "../../../components/content/actions/common-actions";

const isNew = (commit: Commit | undefined) => {
  if (commit?.resourceId) {
    return false;
  }
  return (
    commit?.type === CommitType.CREATE_ENTRY ||
    commit?.type === CommitType.ARCHIVE_ENTRY
  );
};

export default function toManagedResource<
  T extends IndexedResource & LocalizedResource
>({
  calculateTimeline,
  commits,
  users,
  contentCollection,
  resolveLinksToFields,
  locale,
  customActions = {}
}: {
  calculateTimeline: (
    commit: Commit,
    owners: Map<string, User>
  ) => { [p: string]: OwnerTimelineEntry };
  commits: Map<string, Commit>;
  users: Map<string, User>;
  contentCollection: T[];
  resolveLinksToFields?: (linkedResources: {
    [p: string]: { resourceId?: string; commitId?: string }[];
  }) => { [p: string]: { value: string | undefined; locales: Locale[] }[] };
  locale: Locale;
  customActions?: { [actionName: string]: boolean };
}): ManagedResource<T>[] {
  const createCommits: Commit[] = [];
  const otherCommits: Commit[] = [];
  commits.forEach(commit => {
    (isNew(commit) ? createCommits : otherCommits).push(commit);
  });

  const managedCreatedResources = createCommits.map(commit => {
    const isArchived = commit.type === CommitType.ARCHIVE_ENTRY;
    return {
      content: {},
      tentativeState: {
        commitLocale: commit.locale,
        commitId: commit.commitId,
        updatedContent: {
          ...extractUpdatedContent(commit.updatedFields),
          ...resolveLinksToFields?.(commit.linkedResources)
        },
        isDeleted: false,
        isNew: true,
        isArchived,
        timeLine: calculateTimeline(commit, users)
      },
      actions: {
        remove: false,
        restore: commit.locale === locale,
        update: true,
        [ARCHIVE_ACTION]: !isArchived,
        [UN_ARCHIVE_ACTION]: isArchived,
        ...customActions
      }
    } as ManagedResource<T>;
  });
  const indexedChangeCommits = indexBy(
    otherCommits,
    commit => commit.resourceId
  );
  const managedChangedResources = contentCollection.reduce(
    (changedResources, resource) => {
      const relatedCommit = indexedChangeCommits.get(resource.id);
      const isDeleted =
        (relatedCommit && relatedCommit.type === CommitType.DELETE_ENTRY) ||
        false;
      const isTentativeUnarchived =
        (relatedCommit && relatedCommit.type === CommitType.UNARCHIVE_ENTRY) ||
        false;
      const isTentativeArchived =
        (relatedCommit && relatedCommit.type === CommitType.ARCHIVE_ENTRY) ||
        false;
      changedResources.push({
        archived: !!resource.archived && !isTentativeUnarchived,
        content: resource,
        tentativeState: {
          commitLocale: relatedCommit?.locale,
          commitId: relatedCommit?.commitId,
          updatedContent: {
            ...(relatedCommit && extractUpdatedFields(relatedCommit)),
            ...(relatedCommit &&
              resolveLinksToFields?.(relatedCommit.linkedResources))
          },
          isNew: isNew(relatedCommit),
          isArchived: isTentativeArchived,
          isUnarchived: isTentativeUnarchived,
          isDeleted,
          timeLine: relatedCommit && calculateTimeline(relatedCommit, users)
        },
        actions: {
          remove: !isDeleted && resource.hasOwnPresence,
          restore: !!relatedCommit && relatedCommit.locale === locale,
          update: !isDeleted,
          [ARCHIVE_ACTION]: isCreateOrUpdateArchiveEnabled(
            resource,
            relatedCommit
          ),
          [UN_ARCHIVE_ACTION]: isCreateOrUpdateUnarchiveEnabled(
            resource,
            relatedCommit
          ),
          ...customActions
        }
      });
      return changedResources;
    },
    [] as ManagedResource<T>[]
  );
  return managedCreatedResources.concat(managedChangedResources);
}

function isCreateOrUpdateArchiveEnabled(
  resource: IndexedResource & LocalizedResource,
  relatedCommit: Commit | undefined
) {
  return (
    !resource.archived &&
    (!relatedCommit || relatedCommit.type !== CommitType.ARCHIVE_ENTRY)
  );
}

function isCreateOrUpdateUnarchiveEnabled(
  resource: IndexedResource & LocalizedResource,
  relatedCommit: Commit | undefined
) {
  if (resource.archived) {
    return (
      !relatedCommit ||
      (relatedCommit.type !== CommitType.UNARCHIVE_ENTRY &&
        relatedCommit.type !== CommitType.DELETE_ENTRY)
    );
  }
  return !!relatedCommit && relatedCommit.type === CommitType.ARCHIVE_ENTRY;
}

function extractUpdatedFields(commit: Commit) {
  return Object.keys(commit.updatedFields).reduce(
    (updatedFields, field) => ({
      ...updatedFields,
      [field]: (commit.updatedFields[field] || []).map(
        ({ value, locales }) => ({
          value,
          locales
        })
      )
    }),
    {}
  );
}

function extractUpdatedContent(updatedFields: {
  [fieldName: string]: {
    value: string;
    ownerId?: string;
    updateTime: number;
    locales: Locale[];
  }[];
}): {
  [updatedField: string]: {
    value: string;
    locales: Locale[];
  }[];
} {
  return Object.keys(updatedFields)
    .map(key => ({
      key,
      values: updatedFields[key]
    }))
    .reduce((acc, keyValuesPair) => {
      acc[keyValuesPair.key] = keyValuesPair.values;
      return acc;
    }, {} as { [updatedField: string]: { value: string; locales: Locale[] }[] });
}
