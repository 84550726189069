import { ViewModel } from "./ViewModel";

export type ViewModelFactoryMap<
  T extends Record<string, () => ViewModel<any, any>>
> = {
  [viewModelType in keyof T]: T[viewModelType];
};

export class ViewModelFactory<
  T extends Record<string, () => ViewModel<any, any>>
> {
  // private readonly viewModelRefCount = new Map<string, number>();
  private readonly viewModelRefCount = new Map<ViewModel<any, any>, number>();

  constructor(private readonly map: ViewModelFactoryMap<T>) {}

  get = (viewModelType: keyof T) => {
    return this.map[viewModelType]();
  };

  // get = (viewModelType: keyof T) => {
  //   const self = this;
  //   const viewModel = this.map[viewModelType]();
  //   return new Proxy(viewModel, {
  //     get(target: any, viewModelProperty: PropertyKey): any {
  //       const value = target[viewModelProperty as keyof typeof target];
  //
  //       if (
  //         viewModelProperty !== ViewModelProperty.PARTIAL_STATE_UPDATES &&
  //         viewModelProperty !== ViewModelProperty.STATE
  //       ) {
  //         return value;
  //       }
  //
  //       const descriptor = Object.getOwnPropertyDescriptor(target, viewModelProperty);
  //       if (descriptor?.writable === false) {
  //         return value;
  //       }
  //
  //       return new Proxy(value, {
  //         get(partialStateUpdatesTarget: any, valueProperty: PropertyKey): any {
  //           const observableProperty =
  //             partialStateUpdatesTarget[
  //               valueProperty as keyof typeof partialStateUpdatesTarget
  //             ];
  //           if (valueProperty === "subscribe") {
  //             return new Proxy(observableProperty, {
  //               apply(target: any, thisArg: any, argArray?: any): any {
  //                 self.updateRefCount(viewModelType.toString(), true);
  //                 const subscription = target.apply(thisArg, argArray);
  //                 return new Proxy(subscription, {
  //                   get(target: any, subscriptionProperty: PropertyKey): any {
  //                     const subscriptionValue =
  //                       target[subscriptionProperty as keyof typeof target];
  //                     if (subscriptionProperty !== "unsubscribe") {
  //                       return subscriptionValue;
  //                     }
  //
  //                     return new Proxy(subscriptionValue, {
  //                       apply(target: any, thisArg: any, argArray?: any): any {
  //                         const updatedCount = self.updateRefCount(
  //                           viewModelType.toString(),
  //                           false
  //                         );
  //                         const result = target.apply(thisArg, argArray);
  //
  //                         if (updatedCount === 0) {
  //                           viewModel.clear?.();
  //                         }
  //
  //                         return result;
  //                       }
  //                     });
  //                   }
  //                 });
  //               }
  //             });
  //           }
  //
  //           return observableProperty;
  //         }
  //       });
  //     }
  //   });
  // };

  registerReference = (viewModel: ViewModel<any, any>) => {
    const updatedCount = this.updateRefCount(viewModel, true);
    if (updatedCount === 1) {
      // First subscriber.
      viewModel.init?.();
    }
  };

  unregisterReference = (viewModel: ViewModel<any, any>) => {
    const updatedCount = this.updateRefCount(viewModel, false);
    if (updatedCount === 0) {
      // Last subscriber is now unsubscribed.
      viewModel.clear?.();
    }
  };

  private updateRefCount(
    /*viewModelType: string,*/ viewModel: ViewModel<any, any>,
    increment: boolean
  ): number {
    let previousValue = this.viewModelRefCount.get(/*viewModelType*/ viewModel);
    if (previousValue === undefined) {
      previousValue = 0;
    }

    let additionalValue = 1;
    if (!increment) {
      if (previousValue === 0) {
        return 0;
      }

      additionalValue = -1;
    }

    const updatedCount = previousValue + additionalValue;
    this.viewModelRefCount.set(
      /*viewModelType.toString()*/ viewModel,
      updatedCount
    );

    if (updatedCount === 0) {
      this.viewModelRefCount.delete(/*viewModelType*/ viewModel);
    }

    return updatedCount;
  }
}
