import UserApi from "../../../api/users/UserApi";
import User from "../../../api/auth/User";
import HotObservableRepository from "../../HotObservableRepository";
import { Observable } from "rxjs";

export default class UserRepository extends HotObservableRepository<
  Map<string, User>
> {
  constructor(private readonly userApi: UserApi) {
    super();
  }

  updateUserInfo: (user: User) => Promise<void> = async user => {
    await this.userApi.updateUserInfo(user);
  };

  protected readonly _loadDataObservable: () => Observable<
    Map<string, User>
  > = () => this.userApi.getUsersObservable();
}
