import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

const Banner = ({
  message,
  backgroundColor
}: {
  message: string;
  backgroundColor?: string;
}) => {
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        padding: "8px",
        backgroundColor
      }}
    >
      <Typography>{message}</Typography>
    </Box>
  );
};

export default Banner;
