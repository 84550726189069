import Repositories, { RepositoriesContext } from "../context/repository";
import * as React from "react";
import { useContext } from "react";
import LocaleContext from "../context/locale";

export function withRepositories(
  repositoriesSelector: (
    repositories: Repositories
  ) => { [repositoryName: string]: any }
) {
  return (WrappedComponent: React.ComponentType) => (props: any) => {
    const repositories = useContext(RepositoriesContext)!;
    const locale = useContext(LocaleContext)?.localeObservable?.value;
    if (locale) {
      repositories.locale = locale;
    }
    return (
      <WrappedComponent {...props} {...repositoriesSelector(repositories)} />
    );
  };
}
