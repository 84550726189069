import Destination from "./Destination";
import { Dashboard, Message } from "@material-ui/icons";
import PollIcon from "@material-ui/icons/Poll";
import FeedbackIcon from "@material-ui/icons/Feedback";
import SendIcon from "@material-ui/icons/Send";
import * as React from "react";
import { CommunityRoutes } from "../../constants/routes/community-routes";

export const communityDestinations = (): Array<Destination> => [
  {
    name: "Dashboard",
    relativePath: CommunityRoutes.DASHBOARD,
    icon: <Dashboard />
  },
  {
    name: "Users Voice",
    relativePath: CommunityRoutes.USERS_VOICE,
    icon: <Message />
  },
  {
    name: "Polls",
    relativePath: CommunityRoutes.POLLS,
    icon: <PollIcon />
  },
  {
    name: "Feedback",
    relativePath: CommunityRoutes.FEEDBACK,
    icon: <FeedbackIcon />
  },
  {
    name: "Announcements",
    relativePath: CommunityRoutes.ANNOUNCEMENTS,
    icon: <SendIcon />
  }
];
