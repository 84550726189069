import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { MainRoutes } from "../../constants/routes/main-routes";

const useStyles = makeStyles(theme => ({
  progress: {
    position: "absolute",
    top: "50vh",
    left: "50vw",
    translate: "translate(-50%, -50%)"
  }
}));

interface PrivateRouteProps {
  isAuthorized: boolean | undefined;
}

const PrivateRoute = (props: PrivateRouteProps | any) => {
  const { children, ...rest } = props;

  const styles = useStyles();

  return (
    <Route
      {...rest}
      render={({ location }: { location: string }) =>
        props.isAuthorized === true ? (
          children
        ) : props.isAuthorized === false ? (
          <Redirect
            to={{
              pathname: MainRoutes.LOGIN,
              state: { from: location }
            }}
          />
        ) : (
          <CircularProgress className={styles.progress} />
        )
      }
    />
  );
};

export default PrivateRoute;
