import { Announcement } from "../../../../domain/announcements/entities/announcement";
import { ListItem, Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import { AnnouncementState } from "./AnnouncementState";
import Box from "@material-ui/core/Box";
import { useViewModel } from "../../../viewmodel/useViewModel";
import { ViewModelKeys } from "../../../viewmodel/ViewModelFactoryMap";

export interface AnnouncementListItemProps {
  announcement: Announcement;
}

export const AnnouncementListItem = ({
  announcement
}: AnnouncementListItemProps) => {
  const [, viewModel] = useViewModel(ViewModelKeys.ANNOUNCEMENTS_VIEW_MODEL);
  const onClick = useCallback(() => {
    viewModel.onClickAnnouncement(announcement);
  }, [announcement, viewModel]);
  return (
    <ListItem button={true} onClick={onClick}>
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <Typography style={{ fontWeight: "bold" }}>
          {announcement.title}
        </Typography>
        <AnnouncementState active={announcement.active} />
      </Box>
    </ListItem>
  );
};
