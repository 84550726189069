import * as React from "react";
import { useCallback, useRef } from "react";
import AddIcon from "@material-ui/icons/Add";
import { WeeklyTipSlideContainer } from "./WeeklyTipSlideContainer";
import { useStyles } from "../styles";
import Button from "@material-ui/core/Button";

export const NewWeeklyTipSlide = ({
  onFilesSelected
}: {
  onFilesSelected: (file: File[]) => void;
}) => {
  const classes = useStyles();
  const uploadRef = useRef<any>();
  const onUploadClicked = useCallback(() => {
    uploadRef.current?.click();
  }, []);
  const onSlideFileChange = useCallback(
    event => {
      onFilesSelected(event.target.files);
    },
    [onFilesSelected]
  );

  return (
    <WeeklyTipSlideContainer dashed>
      <input
        accept="image/*"
        style={{ display: "none" }}
        type="file"
        ref={ref => (uploadRef.current = ref)}
        onChange={onSlideFileChange}
        multiple
      />
      <Button onClick={onUploadClicked} className={classes.newSlideButton}>
        <AddIcon fontSize={"large"} />
      </Button>
    </WeeklyTipSlideContainer>
  );
};
