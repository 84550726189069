import { useViewModel } from "../../../viewmodel/useViewModel";
import { ViewModelKeys } from "../../../viewmodel/ViewModelFactoryMap";
import DestructiveAlertDialog from "../../../../components/dialogs/DestructiveAlertDialog";
import React from "react";

export const CancelAnnouncementEditConfirmationDialog = () => {
  const [viewState, viewModel] = useViewModel(
    ViewModelKeys.ANNOUNCEMENTS_VIEW_MODEL
  );
  return (
    <DestructiveAlertDialog
      isOpen={
        viewState.pendingAction?.actionConfirmation?.type === "cancelEdit"
      }
      title={"Discard changes"}
      message={
        "Are you sure you want to discard the changes you've made? This action cannot be undone."
      }
      destructiveButtonText={"Discard"}
      handleClose={viewModel.onCancelAction}
      handleConfirm={viewModel.onConfirmAction}
      isLoading={!!viewState.pendingAction?.isLoading}
    />
  );
};
