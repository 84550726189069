import React from "react";
import { AnnouncementsList } from "./list/AnnouncementsList";
import useStyles from "./styles";
import { ViewModelKeys } from "../../viewmodel/ViewModelFactoryMap";
import { useViewModelInPageNavigation } from "../../viewmodel/navigation/useViewModelInPageNavigation";
import { AnnouncementDetails } from "./details/AnnouncementDetails";
import { Grid } from "@material-ui/core";
import { DeleteAnnouncementAlertDialog } from "./dialogs/DeleteAnnouncementAlertDialog";
import { SaveAnnouncementChangesConfirmationDialog } from "./dialogs/SaveAnnouncementChangesConfirmationDialog";
import { CancelAnnouncementEditConfirmationDialog } from "./dialogs/CancelAnnouncementEditConfirmationDialog";
import { useViewModel } from "../../viewmodel/useViewModel";

interface AnnouncementPageParams {
  announcementId?: string;
}

export const AnnouncementsPageContent = () => {
  const [viewState] = useViewModel(ViewModelKeys.ANNOUNCEMENTS_VIEW_MODEL);
  useViewModelInPageNavigation(ViewModelKeys.ANNOUNCEMENTS_VIEW_MODEL);

  const classes = useStyles();
  return (
    <Grid className={classes.container} container spacing={2}>
      <Grid item>
        <AnnouncementsList />
      </Grid>
      {viewState.selectedAnnouncement?.announcement?.id ? (
        <Grid item xs={6}>
          <AnnouncementDetails
            announcementId={viewState.selectedAnnouncement.announcement.id}
          />
        </Grid>
      ) : (
        <></>
      )}
      <DeleteAnnouncementAlertDialog />
      <CancelAnnouncementEditConfirmationDialog />
      <SaveAnnouncementChangesConfirmationDialog />
    </Grid>
  );
};
