import React, { useEffect } from "react";
import { DiagnosticState } from "../DiagnosticState";
import { RecursivePartial } from "../../../../../utils/utilityTypes";
import { ContentDiscrepanciesMenu } from "./ContentDiscrepanciesMenu";
import { ContentDiscrepanciesDiagnosticJobReport } from "./ContentDiscrepanciesDiagnosticJobReport";
import { useDiagnosticsViewModel } from "../useDiagnosticsViewModel";

export const ContentDiscrepanciesDiagnostic = ({
  diagnosticStateUpdater
}: {
  diagnosticStateUpdater: (
    mergeDiagnosticState: (
      currentState: DiagnosticState
    ) => RecursivePartial<DiagnosticState>
  ) => void;
}) => {
  const [viewState, viewModel] = useDiagnosticsViewModel();

  useEffect(() => {
    diagnosticStateUpdater(currentState => ({
      actions: {
        ...(viewState.reportDisplayed
          ? {
              run: undefined,
              commit: {
                ...DIAGNOSTIC_BUTTON_STYLE,
                label: "Commit",
                onClick: viewModel.onClickCommit,
                enabled: !viewState.reportLCE.isLoading
              }
            }
          : {
              run: {
                ...DIAGNOSTIC_BUTTON_STYLE,
                label: "Run",
                onClick: viewModel.onClickRun
              },
              commit: undefined
            })
      }
    }));
  }, [
    diagnosticStateUpdater,
    viewState.reportDisplayed,
    viewModel.onClickRun,
    viewState.reportLCE.isLoading,
    viewModel.onClickCommit
  ]);

  if (viewState.reportDisplayed) {
    return <ContentDiscrepanciesDiagnosticJobReport />;
  }

  return <ContentDiscrepanciesMenu />;
};

const DIAGNOSTIC_BUTTON_STYLE = {
  style: "primary" as const,
  variant: "contained" as const
};
