import compose from "../../../utils/compose";
import * as React from "react";
import { Resources } from "../../../data/Resources";
import Grid from "@material-ui/core/Grid";
import CategoryContent from "./CategoryContent";
import ManagedContent from "../../../data/ManagedContent";
import ManagedCategory from "../../../data/ManagedCategory";
import BaseContentManagementPage from "../../../components/pages/BaseContentManagementPage";
import withLocale, {
  LocalePropsConsumer
} from "../../../components/hoc/withLocale";
import { SearchFields } from "../../../types/domainTypes";
import { CategoryFields } from "../../../api/content/Category";
import GroupedScrollableContent from "../../../components/content/GroupedScrollableContent";
import withResourceManagement, {
  ManageResourcesPageProps
} from "../../../components/hoc/withResourceManagement";
import managedResourceMappers from "../../../data/mappers/resources/content-mappers";
import { extractId } from "../../../utils/data/resources/managed";
import IndexedResource from "../../../data/IndexedResource";
import { QuoteFields } from "../../../api/content/Quote";

const searchFields: SearchFields = {
  contentFields: [CategoryFields.NAME]
};

const CategoriesPageWrapper = (
  props: LocalePropsConsumer & ManageResourcesPageProps
) => {
  const renderGroup = React.useCallback(
    (data: ManagedContent<ManagedCategory>[]) => (
      <Grid container justify="flex-start" alignItems="center" spacing={3}>
        {data.map(item => {
          const categoryId = extractId(
            (item.managedResource as unknown) as IndexedResource &
              ManagedCategory
          );
          return (
            <CategoryContent
              managedContent={item}
              locale={props.locale}
              quotesCount={
                props.managedContentByLocale
                  ?.get(Resources.QUOTES)
                  ?.get(props.locale)
                  ?.filter(managedQuote =>
                    managedQuote.content[QuoteFields.CATEGORY_ID]?.includes(
                      categoryId
                    )
                  )?.length ?? 0
              }
            />
          );
        })}
      </Grid>
    ),
    [props.locale, props.managedContentByLocale]
  );
  const renderContent = React.useCallback(
    content => (
      <GroupedScrollableContent
        data={content}
        groupSize={4}
        withDividers={false}
        wrapWithPaper={false}
        itemSize={500}
        renderGroup={renderGroup}
      />
    ),
    [renderGroup]
  );

  return (
    <BaseContentManagementPage
      resource={Resources.CATEGORIES}
      renderContent={renderContent}
      searchFields={searchFields}
      showTableHeaders={false}
      searchDebounce={0}
    />
  );
};

const CategoriesPage = compose(
  React.memo,
  withLocale,
  withResourceManagement(managedResourceMappers)
)(CategoriesPageWrapper) as React.ComponentType;

export default CategoriesPage;
