import LocalizedResource from "../../data/LocalizedResource";

export enum MediaPlatformFields {
  ID = "id",
  NAME = "name",
  ICON = "icon"
}

export default interface MediaPlatform extends LocalizedResource {
  [MediaPlatformFields.ID]: string;
  [MediaPlatformFields.NAME]: string[];
  [MediaPlatformFields.ICON]: string[];
}
