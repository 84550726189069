import FormField from "../FormField";
import { Locale } from "../../../../../locale";
import { Pair } from "../../../../../utils/tuples/Pair";
import { OptionalProperties } from "../../../../../utils/generics/OptionalProperties";

export const wrapFormFieldsFactoryWithValidator = <T>(
  defaultLocale: Locale,
  factory: (...args: T[]) => OptionalProperties<FormField, "validate">[]
): ((...args: T[]) => FormField[]) => (...args: T[]): FormField[] =>
  factory(...args).map(fieldWithOptionalValidate => ({
    ...fieldWithOptionalValidate,
    validate:
      fieldWithOptionalValidate.validate ||
      ((
        locale: Locale,
        updatedFields: string[] | undefined,
        updatedLinks: Pair<string, string>[] | undefined
      ) => {
        // Other locales might not have translations and that's ok.
        if (defaultLocale !== locale) {
          return { isValid: true };
        }

        // Field values are valid if they are provided and at least one of them is a non-empty string.
        const areFieldValuesValid =
          !updatedFields || (updatedFields || []).some(value => value);
        // Links are valid if they are provided and at least one of the link values is a non-empty string.
        const areLinksValid =
          !updatedLinks || (updatedLinks || []).some(pair => pair.value);

        const isValid = areFieldValuesValid && areLinksValid;

        return {
          isValid,
          ...(!isValid && {
            message: areLinksValid
              ? "Please provide a value."
              : "Please select a valid link."
          })
        };
      })
  }));
