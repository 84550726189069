import ScrollableContent from "../../../../components/content/ScrollableContent";
import React, { useCallback, useState } from "react";
import { ConversationItem } from "./ConversationItem";
import { ConversationEntry } from "../../../../repository/use-case/users-voice/dto/ListConversationsResponse";

const CONVERSATION_ITEM_SIZE = 80;

export const ConversationsList = ({
  conversations,
  onConversationSelect
}: {
  conversations: ConversationEntry[];
  onConversationSelect: (conversation: ConversationEntry) => void;
}) => {
  const [selectedConversationItem, setSelectedConversationItem] = useState<
    | {
        conversation: ConversationEntry;
        index: number;
      }
    | undefined
  >();

  const onClickItem = useCallback(
    (conversation: ConversationEntry, index: number) => {
      setSelectedConversationItem({
        conversation,
        index
      });
      onConversationSelect(conversation);
    },
    [onConversationSelect]
  );

  return (
    <ScrollableContent
      itemSize={CONVERSATION_ITEM_SIZE}
      data={conversations}
      renderContentItem={ConversationItem}
      selectable
      selectedIndex={selectedConversationItem?.index}
      onClickItem={onClickItem}
    />
  );
};
