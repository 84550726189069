import { LCEWrapper } from "../../../lce/LCEWrapper";
import DialogActions from "@material-ui/core/DialogActions";
import React, { useCallback } from "react";
import { useContentObservableLCE } from "./useContentObservableLCE";
import { CommitResource } from "../../../../api/commits/Commit";
import Button from "@material-ui/core/Button";
import { generateExportedFile } from "./generate-exported-file";
import { ExportFileFormat } from "./ExportFileFormat";

export interface ExportDialogActionsProps {
  resource: CommitResource;
  selectedContentFields: string[];
  fileName: string;
  selectedFormat: ExportFileFormat;
}

export const ExportDialogActions = ({
  resource,
  selectedContentFields,
  fileName,
  selectedFormat
}: ExportDialogActionsProps) => {
  const contentLCE = useContentObservableLCE(resource);
  const renderLCEContent = useCallback(
    content => {
      return (
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => {
            generateExportedFile(
              selectedContentFields,
              (Array.from(
                contentLCE.content?.values() ?? []
              ) as unknown) as Record<string, string>[],
              fileName,
              selectedFormat
            );
          }}
        >
          Export
        </Button>
      );
    },
    [contentLCE.content, fileName, selectedContentFields, selectedFormat]
  );

  return (
    <DialogActions>
      <LCEWrapper lce={contentLCE} renderContent={renderLCEContent} />
    </DialogActions>
  );
};
