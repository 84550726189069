import { useRepositories } from "../../hooks/useRepositories";
import { useCallback, useMemo, useState } from "react";
import { CreatableEventType } from "../../components/calendar/CreatableEventType";
import Cache from "../../utils/cache/Cache";
import { CalendarEvent } from "../../repository/use-case/calendar/CalendarEvent";
import distinct from "../../utils/arrays/distinct";
import moment, { Moment } from "moment-timezone";
import { EventFormData } from "../../components/calendar/edit-event/EventFormData";
import { Locale } from "../../locale";

export function useCalendarActions(locale: Locale, timezone: string) {
  const { calendar } = useRepositories();

  const listCreatableEventEntries = useCallback(
    (eventType: CreatableEventType) => {
      return calendar.listCreatableEventEntries({ eventType: eventType.id });
    },
    [calendar]
  );

  const eventsCache = useMemo(() => {
    return new Cache<[string, string], Promise<CalendarEvent[]>>(
      ([startTime, endTime]) =>
        calendar.listCalendarEvents({
          startTime,
          endTime,
          locale
        })
    );
  }, [calendar, locale]);

  const [allEvents, setEvents] = useState<CalendarEvent[]>([]);
  const updateEvents = useCallback(
    (events: CalendarEvent[]) => {
      const updatedEvents = distinct(
        events.concat(allEvents),
        event => event.id
      ).sort((ev1, ev2) =>
        moment(ev1.startTime)
          .tz(timezone)
          .diff(moment(ev2.startTime).tz(timezone))
      );
      setEvents(updatedEvents);
    },
    [allEvents]
  );
  const getEvents = useCallback(
    async (startTime: Moment, endTime: Moment) => {
      const eventsForGivenRange = await eventsCache.get([
        startTime.toISOString(),
        endTime.toISOString()
      ]);
      updateEvents(eventsForGivenRange);
    },
    [eventsCache, updateEvents]
  );

  const createOrUpdateEvent = useCallback(
    async ({
      eventId,
      selectedDates,
      eventCandidate: { eventType, eventEntry } = {},
      title,
      color
    }: EventFormData) => {
      if (!eventEntry || !eventType) {
        throw new Error("Missing event details");
      }
      if (!title) {
        throw new Error("Missing title");
      }
      if (!color) {
        throw new Error("Missing color");
      }
      const eventData = {
        startTime: selectedDates[0].toISOString(),
        endTime: selectedDates[selectedDates.length - 1].toISOString(),
        eventType: eventType.id,
        entryId: eventEntry.id,
        title,
        locale,
        color
      };
      const newOrUpdatedEvent = await (eventId
        ? calendar.editEvent(eventId, eventData)
        : calendar.createNewEvent(eventData));
      updateEvents([newOrUpdatedEvent]);
    },
    [calendar, locale, updateEvents]
  );

  const deleteEvent = useCallback(
    (eventId: string) => calendar.deleteEvent({ eventId }),
    [calendar]
  );

  return {
    listCreatableEventEntries,
    getEvents,
    createOrUpdateEvent,
    deleteEvent,
    allEvents,
    setEvents
  };
}
