import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./components/logo/Logo.css";
import "./components/login/Login.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Navigation, { NavigationContext } from "./components/navigation";
import { AuthContext } from "./components/context/auth";
import FirebaseAuthApi from "./api/auth/impl/FirebaseAuthApi";
import FirebaseApi from "./api/firebase/FirebaseApi";
import { StatisticsContext } from "./components/context/statistics";
import { FirebaseStatisticsApi } from "./api/statistics/impl/FirebaseStatisticsApi";
import { FirebaseContentApi } from "./api/content/impl/FirebaseContentApi";
import { FirebaseCommitApi } from "./api/commits/impl/FirebaseCommitApi";
import Repositories, {
  RepositoriesContext
} from "./components/context/repository";
import { CommitsRepository } from "./repository/use-case/commits/CommitsRepository";
import ContentRepository from "./repository/use-case/content/ContentRepository";
import UserRepository from "./repository/use-case/user/UserRepository";
import FirebaseUserApi from "./api/users/impl/FirebaseUserApi";
import Defaults from "./defaults";
import LocaleContext from "./components/context/locale";
import { BehaviorSubject } from "rxjs";
import LocalesRepository from "./repository/use-case/locales/LocalesRepository";
import FirebaseLocalesApi from "./api/locales/impl/FirebaseLocalesApi";
import ConfigurationRepository from "./repository/use-case/configuration/ConfigurationRepository";
import FirebaseConfigurationApi from "./api/configuration/impl/FirebaseConfigurationApi";
import elasticlunr from "elasticlunr";
import { TasksRepository } from "./repository/use-case/tasks/TasksRepository";
import { FirebaseTaskApi } from "./api/tasks/impl/FirebaseTaskApi";
import { PermissionsRepository } from "./repository/use-case/permissions/PermissionsRepository";
import { FirebasePermissionApi } from "./api/permissions/impl/FirebasePermissionApi";
import FirebaseApiContext from "./components/context/firebase/FirebaseApiContext";
import { WeeklyTipsRepository } from "./repository/use-case/weekly-tips/WeeklyTipsRepository";
import { CalendarRepository } from "./repository/use-case/calendar/CalendarRepository";
import { ScrapeRepository } from "./repository/use-case/scrape/ScrapeRepository";
import { UsersVoiceRepository } from "./repository/use-case/users-voice/UsersVoiceRepository";
import { DeveloperConsoleRepository } from "./repository/use-case/developer-console/DeveloperConsoleRepository";
import { ScrapeRepositoryV2 } from "./repository/use-case/scrape2/ScrapeRepositoryV2";
import { FirebaseAnnouncementsRepository } from "./data/implementation/firebase/FirebaseAnnouncementsRepository";
import { FirestoreRealtimeAPI } from "./data/implementation/firebase/FirestoreRealtimeAPI";
import { FirebaseFileManagementRepository } from "./data/implementation/firebase/FirebaseFileManagementRepository";
import { GlobalUserTasksRepositoryImpl } from "./data/implementation/GlobalUserTasksRepositoryImpl";

// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

elasticlunr.clearStopWords();

const firebaseApi = new FirebaseApi(Defaults.locale);
const firestoreRealtimeAPI = new FirestoreRealtimeAPI(firebaseApi.firestore);
const firebaseAuthApi = new FirebaseAuthApi(firebaseApi.authentication);
ReactDOM.render(
  <AuthContext.Provider value={firebaseAuthApi}>
    <LocaleContext.Provider
      value={{
        localeObservable: new BehaviorSubject(Defaults.locale),
        defaultLocale: Defaults.locale
      }}
    >
      <FirebaseApiContext.Provider value={firebaseApi}>
        <RepositoriesContext.Provider
          value={
            new Repositories(
              new CommitsRepository(
                Defaults.locale,
                new FirebaseCommitApi(firebaseApi.data, firebaseApi.functions)
              ),
              new ContentRepository(
                Defaults.locale,
                new FirebaseContentApi(firebaseApi.data, firebaseApi.storage)
              ),
              new UserRepository(new FirebaseUserApi(firebaseApi.data)),
              new LocalesRepository(
                new FirebaseLocalesApi(firebaseApi.data),
                Defaults.locale
              ),
              new ConfigurationRepository(
                new FirebaseConfigurationApi(
                  firebaseApi.data,
                  firebaseApi.functions
                )
              ),
              new TasksRepository(
                new FirebaseTaskApi(firebaseApi.data, firebaseApi.functions)
              ),
              new PermissionsRepository(
                new FirebasePermissionApi(firebaseApi.functions)
              ),
              new WeeklyTipsRepository(firebaseApi.functions),
              new CalendarRepository(firebaseApi.functions),
              new ScrapeRepository(firebaseApi.functions),
              new ScrapeRepositoryV2(firebaseApi.storage),
              new UsersVoiceRepository(firebaseApi.functions),
              new DeveloperConsoleRepository(
                firebaseApi.functions,
                firebaseApi.data
              ),
              new FirebaseAnnouncementsRepository(
                firebaseApi.firestore,
                firestoreRealtimeAPI
              ),
              new FirebaseFileManagementRepository(
                firebaseApi.firestore,
                firebaseApi.storage,
                firestoreRealtimeAPI,
                firebaseAuthApi,
                firebaseApi.functions
              ),
              new GlobalUserTasksRepositoryImpl(firebaseApi.storage)
            )
          }
        >
          <StatisticsContext.Provider
            value={new FirebaseStatisticsApi(firebaseApi.data)}
          >
            <NavigationContext.Provider value={new Navigation()}>
              <App />
            </NavigationContext.Provider>
          </StatisticsContext.Provider>
        </RepositoriesContext.Provider>
      </FirebaseApiContext.Provider>
    </LocaleContext.Provider>
  </AuthContext.Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
