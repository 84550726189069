import LocalizedResource from "../../data/LocalizedResource";

export enum AuthorFields {
  ID = "id",
  NAME = "name",
  DESCRIPTION = "description",
  CONTEXT = "context",
  IMAGE_URL = "imageUrl",
  ARTICLE_URL = "articleUrl",
  LICENSE = "license"
}

export default interface Author extends LocalizedResource {
  [AuthorFields.ID]: string;
  [AuthorFields.NAME]: string[];
  [AuthorFields.DESCRIPTION]: string[];
  [AuthorFields.CONTEXT]: string[];
  [AuthorFields.IMAGE_URL]: string[];
  [AuthorFields.ARTICLE_URL]: string[];
  [AuthorFields.LICENSE]: string[];
}
