import compose from "../../../utils/compose";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Resources } from "../../../data/Resources";
import Grid from "@material-ui/core/Grid";
import ManagedContent from "../../../data/ManagedContent";
import BaseContentManagementPage from "../../../components/pages/BaseContentManagementPage";
import withLocale, {
  LocalePropsConsumer
} from "../../../components/hoc/withLocale";
import { SearchFields } from "../../../types/domainTypes";
import GroupedScrollableContent from "../../../components/content/GroupedScrollableContent";
import WeeklyTipContent from "../../../components/weekly-tip/WeeklyTipContent";
import { Locale } from "../../../locale";
import { ManagedWeeklyTip } from "../../../data/ManagedWeeklyTip";
import { EditWeeklyTip } from "../../../components/weekly-tip/EditWeeklyTip";
import FormDialog from "../../../components/dialogs/FormDialog/FormDialog";
import { Pair } from "../../../utils/tuples/Pair";
import FormField from "../../../components/dialogs/FormDialog/fields/FormField";
import { FormFieldType } from "../../../components/dialogs/FormDialog/fields/FormFieldType";
import { useRepositories } from "../../../hooks/useRepositories";
import { useHistory, useLocation } from "react-router-dom";
import { withAuth } from "../../../components/hoc/withAuth";
import User from "../../../api/auth/User";
import queryString from "query-string";
import { WeeklyTipFields } from "../../../api/content/WeeklyTip";
import ManagedResource from "../../../data/ManagedResource";

const searchFields: SearchFields = {
  contentFields: [WeeklyTipFields.TOPIC]
};

const WeeklyTipsPageWrapper = (
  props: LocalePropsConsumer & { user: User | undefined }
) => {
  const { commits, weeklyTips } = useRepositories();
  const history = useHistory();
  const location = useLocation();

  const [editParams, setEditParams] = useState<{
    commitId?: string;
    resourceId?: string;
  }>({});
  useEffect(() => {
    if (location.search.trim().length) {
      setEditParams(queryString.parse(location.search));
    } else {
      setEditParams({});
    }
  }, [location]);

  const onCreateWeeklyTipClicked = React.useCallback(() => {
    setCreateDialogOpen(true);
  }, []);
  const onCustomActionClicked = React.useCallback(
    (actionType: string, managedResource: ManagedWeeklyTip) => {
      if (actionType === "update") {
        const pathParams: { [param: string]: string } = {
          ...(managedResource.tentativeState.commitId && {
            commitId: managedResource.tentativeState.commitId
          }),
          ...(managedResource.content[WeeklyTipFields.ID] && {
            resourceId: managedResource.content[WeeklyTipFields.ID]
          })
        };
        const query = Object.keys(pathParams).reduce((queryAcc, param) => {
          if (!queryAcc.length) {
            return `${queryAcc}edit?${param}=${pathParams[param]}`;
          }
          return `${queryAcc}&${param}=${pathParams[param]}`;
        }, "");
        history.push(`${location.pathname}${query}`);
        return true;
      }
      return false;
    },
    [history, location.pathname]
  );

  const onBackClicked = React.useCallback(() => history.goBack(), [history]);

  const [isCreateRequestLoading, setCreateRequestLoading] = useState(false);
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
  const onDialogClosed = useCallback(() => setCreateDialogOpen(false), []);
  const onDialogSaved = useCallback(
    async (
      saveData: Map<
        Locale,
        {
          updatedFields: Map<string, string[]>;
          updatedLinks: Map<string, Pair<string, string>[]>;
        }
      >
    ) => {
      setCreateRequestLoading(true);
      const { commitId } = await commits.updateCommit({
        resource: Resources.WEEKLY_TIP,
        updateData: saveData,
        ownerId: props.user?.id ?? ""
      });
      setCreateRequestLoading(false);
      setCreateDialogOpen(false);
      history.push(`${location.pathname}edit?commitId=${commitId}`);
    },
    [commits, history, location.pathname, props.user]
  );

  const managementActionsHook = useCallback(
    (
      actionType: string,
      payload: { resourceType: Resources; resource: ManagedResource<any> }
    ) => {
      if (actionType === "revert") {
        if (payload.resource.tentativeState.commitId) {
          weeklyTips.revertWeeklyTipCommit(
            payload.resource.tentativeState.commitId,
            props.locale
          );
        }
      }
    },
    [props.locale, weeklyTips]
  );

  const localeToFields = useMemo(() => {
    return [props.defaultLocale, props.locale].reduce(
      (localeToFieldsAcc, locale) => {
        localeToFieldsAcc.set(locale, [
          {
            id: "topic",
            label: "Topic",
            type: FormFieldType.TEXT,
            validate(
              locale: Locale,
              updatedFields: string[] | undefined
            ): { isValid: boolean; message?: string } {
              return {
                isValid: !!updatedFields?.[0]?.length
              };
            }
          }
        ]);
        return localeToFieldsAcc;
      },
      new Map<string, FormField[]>()
    );
  }, [props.defaultLocale, props.locale]);

  const renderGroup = React.useCallback(
    (data: ManagedContent<ManagedWeeklyTip>[]) => (
      <Grid container justify="flex-start" alignItems="center" spacing={3}>
        {data.map(item => (
          <WeeklyTipContent managedContent={item} locale={props.locale} />
        ))}
      </Grid>
    ),
    [props.locale]
  );
  const renderContent = React.useCallback(
    content => (
      <GroupedScrollableContent
        data={content}
        groupSize={4}
        withDividers={false}
        wrapWithPaper={false}
        itemSize={500}
        renderGroup={renderGroup}
      />
    ),
    [renderGroup]
  );

  const pageContent = React.useMemo(() => {
    return Object.values(editParams).filter(p => p).length ? (
      <EditWeeklyTip {...editParams} onBackClicked={onBackClicked} />
    ) : (
      <BaseContentManagementPage
        resource={Resources.WEEKLY_TIP}
        renderContent={renderContent}
        searchFields={searchFields}
        showTableHeaders={false}
        searchDebounce={0}
        onCustomActionClicked={onCustomActionClicked}
        onCreateClicked={onCreateWeeklyTipClicked}
        managementActionsHook={managementActionsHook}
      />
    );
  }, [
    editParams,
    managementActionsHook,
    onBackClicked,
    onCreateWeeklyTipClicked,
    onCustomActionClicked,
    renderContent
  ]);

  return (
    <>
      {pageContent}
      <FormDialog
        isOpen={isCreateDialogOpen}
        handleClose={onDialogClosed}
        handleFormSave={onDialogSaved}
        isLoading={isCreateRequestLoading}
        title={"Create New Weekly Tip"}
        description={
          "Please provide a topic for the Weekly Tip. You will be automatically redirected to a dedicated page to manage slides."
        }
        actionButtonText={"Create"}
        localeToFields={localeToFields}
        defaultLocale={props.defaultLocale}
        resourceType={Resources.WEEKLY_TIP}
      />
    </>
  );
};

export const WeeklyTipsPage = compose(
  React.memo,
  withLocale,
  withAuth
)(WeeklyTipsPageWrapper) as React.ComponentType;
