import * as React from "react";
import { Attributes, useContext, useEffect, useState } from "react";
import { Locale } from "../../locale";
import LocaleContext from "../context/locale";

export interface LocalePropsConsumer {
  locale: Locale;
  defaultLocale: Locale;
}

const withLocale = function<P extends Attributes & LocalePropsConsumer>(
  WrappedComponent: React.ComponentType<P>
) {
  return (props: P) => {
    const { localeObservable, defaultLocale } = useContext(LocaleContext)!;
    const [locale, setLocale] = useState(localeObservable.value);
    useEffect(() => {
      const subscription = localeObservable.subscribe({
        next: (data: Locale) => {
          setLocale(data);
        }
      });
      return () => subscription.unsubscribe();
    }, [props.locale]);

    return (
      <WrappedComponent
        {...props}
        locale={locale}
        defaultLocale={defaultLocale}
      />
    );
  };
};

export default withLocale;
