import { useEffect } from "react";
import { isImplementsNavigationCallback } from "./UpdateNavigationCallback";
import { ViewModel } from "../../../architecture/view-model/ViewModel";
import { useLocalViewModel } from "../../../architecture/view-model/useViewModel";
import { LocalViewModelFactory } from "../../../architecture/view-model/LocalViewModelFactory";
import { useNavigationEffectCallback } from "./useNavigationEffectCallback";

export function useLocalViewModelInPageNavigation<
  PageParams extends object,
  VM extends ViewModel<any, any>
>(viewModelFactory: LocalViewModelFactory<VM>) {
  const [pathname, pageParams, viewModelEffects] = useNavigationEffectCallback<
    PageParams
  >();
  const [, viewModel] = useLocalViewModel(viewModelFactory, viewModelEffects);
  useEffect(() => {
    if (isImplementsNavigationCallback<PageParams>(viewModel)) {
      viewModel.onNavigationLocationUpdated(pageParams, pathname);
    }
  }, [pageParams, pathname, viewModel]);
}
