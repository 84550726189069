import Typography from "@material-ui/core/Typography";
import { Checkbox, ListItemText, MenuItem, Select } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import { FilterData, FilterOption } from "./useFilters";

export const ScrapeSourceHeaderFilter = ({
  filterName,
  filterData,
  onFilterValueChanged
}: {
  filterName: string;
  filterData: FilterData | undefined;
  onFilterValueChanged: (filter: string, selectedKeys: string[]) => void;
}) => {
  const options = filterData?.options ?? new Map<string, FilterOption>();
  const entriesArray = Array.from(options.entries());
  const values = useMemo(() => {
    return entriesArray
      .filter(([, option]) => option.selected)
      .map(([key]) => key);
  }, [entriesArray]);
  const renderValue = useCallback(() => {
    let text: string;
    if (!values.length) {
      text = "None";
    } else if (entriesArray.length === values.length) {
      text = "All";
    } else {
      text = `${values.length} selected`;
    }
    return <Typography>{text}</Typography>;
  }, [entriesArray.length, values.length]);

  const menuItems = useMemo(
    () =>
      entriesArray.map(([key]) => {
        const filterOption = options.get(key);
        return (
          <MenuItem key={key} value={key}>
            <Checkbox checked={filterOption?.selected ?? false} />
            <ListItemText primary={filterOption?.label ?? key} />
          </MenuItem>
        );
      }),
    [entriesArray, options]
  );

  return (
    <Select
      value={values}
      multiple
      label={filterName}
      renderValue={renderValue}
      onChange={({ target: { value } }) => {
        onFilterValueChanged(filterName, value as string[]);
      }}
    >
      {menuItems}
    </Select>
  );
};
