import Grid from "@material-ui/core/Grid";
import {
  FormControl,
  IconButton,
  InputLabel,
  Tooltip,
  useTheme
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import React, { useMemo } from "react";
import { useStyles } from "./styles";
import { ScrapeSourceHeaderFilter } from "./ScrapeSourceHeaderFilter";
import { Filters } from "./useFilters";

const ScrapedSourceResultsHeaderComponent = ({
  onBackClicked,
  title,
  filters,
  onFilterValueChanged
}: {
  title: string;
  onBackClicked: () => void;
  filters: Filters;
  onFilterValueChanged: (filter: string, selectedKeys: string[]) => void;
}) => {
  const classes = useStyles();
  const filterSelectables = useMemo(() => {
    return Array.from(filters.keys()).map(filterName => {
      return (
        <Grid item>
          <FormControl>
            <InputLabel>{filterName}</InputLabel>
            <ScrapeSourceHeaderFilter
              filterName={filterName}
              filterData={filters.get(filterName)}
              onFilterValueChanged={onFilterValueChanged}
            />
          </FormControl>
        </Grid>
      );
    });
  }, [filters, onFilterValueChanged]);

  const theme = useTheme();
  const headerStyle = useMemo(
    () => ({ backgroundColor: theme.palette.primary.main }),
    [theme.palette.primary.main]
  );

  return (
    <Box className={classes.scrappedContentGridHeader}>
      <Grid container direction="row" alignItems="center" spacing={4}>
        <Grid item>
          <Tooltip title="Back to Source Selection">
            <IconButton onClick={onBackClicked}>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        {filterSelectables}
      </Grid>
      <Grid
        container
        direction={"row"}
        className={classes.scrapedSourceResultsHeaderTable}
        spacing={1}
      >
        <Grid item style={headerStyle} xs={6}>
          <Typography variant={"h5"} align={"center"}>
            Quote
          </Typography>
        </Grid>
        <Grid item style={headerStyle} xs={2}>
          <Typography variant={"h5"} align={"center"}>
            Author
          </Typography>
        </Grid>
        <Grid item style={headerStyle} xs={4}>
          <Typography variant={"h5"} align={"center"}>
            Categories
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export const ScrapedSourceResultsHeader = React.memo(
  ScrapedSourceResultsHeaderComponent
);
