import { StatisticsApi } from "../StatisticsApi";
import { QuoteStatistics } from "../QuoteStatistics";
import { FirebaseData } from "../../firebase/data/FirebaseData";

export class FirebaseStatisticsApi implements StatisticsApi {
  constructor(private firebaseData: FirebaseData) {}

  getQuotesStatistics: (locale: string) => Promise<QuoteStatistics> = async (locale) => {
    const quotesCollection = await this.firebaseData.getQuotesCollection(locale);
    return {
      count: quotesCollection.size
    };
  };
}
