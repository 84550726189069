export interface UpdateNavigationCallback<T extends object> {
  onNavigationLocationUpdated(
    navigationParams: Partial<T>,
    pathname: string
  ): void;
}

export function isImplementsNavigationCallback<T extends object>(
  candidate: any
): candidate is UpdateNavigationCallback<T> {
  return candidate && candidate.onNavigationLocationUpdated;
}
