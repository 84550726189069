import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import {
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import React from "react";
import { Task } from "../../api/tasks/Task";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import ReplayIcon from "@material-ui/icons/Replay";
import CancelIcon from "@material-ui/icons/Cancel";
import { TaskActionButton } from "./TaskActionButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import moment from "moment";

function isActionable(task: Task) {
  return task.cancelable || task.retryable || task.deletable;
}

function isUpdated(task: Task) {
  return task.updatedAt && task.updatedAt !== task.createdAt;
}

function formatTaskDate(date: string): string {
  return moment(date).format("ddd, MMM YYYY HH:mm");
}

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 16
  }
}))(TableCell);

export const TasksPanel = ({
  title,
  tasks,
  executingTaskIds,
  onTaskRetryClicked,
  onTaskCancelClicked,
  onTaskDeleteClicked,
  isExpanded,
  onExpandClicked
}: {
  title: string;
  tasks: Task[];
  executingTaskIds: string[];
  onTaskRetryClicked(taskId: string): void;
  onTaskCancelClicked(taskId: string): void;
  onTaskDeleteClicked(taskId: string): void;
  isExpanded: boolean;
  onExpandClicked: (isExpanded: boolean) => void;
}) => {
  const anyActionable = tasks.some(task => isActionable(task));
  const anyUpdated = tasks.some(task => isUpdated(task));
  return (
    <ExpansionPanel
      expanded={isExpanded}
      onChange={(_: any, expanded: boolean) => {
        onExpandClicked(expanded);
      }}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{title}</Typography>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {anyActionable && (
                  <StyledTableCell align="center">Actions</StyledTableCell>
                )}
                <StyledTableCell align="center">Type</StyledTableCell>
                <StyledTableCell align="center">Created At</StyledTableCell>
                {anyUpdated && (
                  <StyledTableCell align="center">Updated At</StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map(task => (
                <TableRow key={task.id}>
                  {isActionable(task) && (
                    <TableCell align="center">
                      {executingTaskIds.includes(task.id) ? (
                        <CircularProgress size={20} />
                      ) : (
                        <>
                          {task.cancelable && (
                            <TaskActionButton
                              taskActionType="cancel"
                              description="Cancel"
                              icon={<CancelIcon />}
                              onClick={() => onTaskCancelClicked(task.id)}
                            />
                          )}
                          {task.retryable && (
                            <TaskActionButton
                              taskActionType="retry"
                              description="Retry"
                              icon={<ReplayIcon />}
                              onClick={() => onTaskRetryClicked(task.id)}
                            />
                          )}
                          {task.deletable && (
                            <TaskActionButton
                              taskActionType="delete"
                              description="Delete"
                              icon={<DeleteForeverIcon />}
                              onClick={() => onTaskDeleteClicked(task.id)}
                            />
                          )}
                        </>
                      )}
                    </TableCell>
                  )}
                  <TableCell align="center">{task.type}</TableCell>
                  <TableCell align="center">
                    {formatTaskDate(task.createdAt)}
                  </TableCell>
                  {isUpdated(task) && task.updatedAt && (
                    <TableCell align="center">
                      {formatTaskDate(task.updatedAt)}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
