import {
  extractFirstContentField,
  extractFirstLocalizedProp,
  extractLocalizedProps
} from "../../../../../utils/data/resources/managed";
import { Resources } from "../../../../../data/Resources";
import { FormFieldType } from "../FormFieldType";
import LinkFormFieldType, {
  LinkFormFieldOption
} from "../link/LinkFormFieldType";
import AvatarListItem from "../../../../list/item/AvatarListItem";
import * as React from "react";
import { Locale } from "../../../../../locale";
import { CommitResource } from "../../../../../api/commits/Commit";
import ManagedResource from "../../../../../data/ManagedResource";
import { CategoryFields } from "../../../../../api/content/Category";
import ManagedCategory from "../../../../../data/ManagedCategory";
import { OptionalProperties } from "../../../../../utils/generics/OptionalProperties";

export const categoryLinkOption = ({
  locale,
  currentLocale,
  managedContent,
  resource,
  linkedFieldName
}: {
  locale: Locale;
  currentLocale: Locale;
  managedContent: Map<CommitResource, Map<Locale, ManagedResource<any>[]>>;
  resource?: ManagedResource<any>;
  linkedFieldName: string;
}) =>
  ({
    defaultValues:
      resource &&
      extractLocalizedProps(currentLocale, resource, linkedFieldName).map(
        localizedProp => ({
          value: resource,
          label: localizedProp.value
        })
      ),
    id: Resources.CATEGORIES,
    label: "Select Category",
    type: FormFieldType.LINK,
    options: (managedContent?.get(Resources.CATEGORIES)?.get(locale) || []).map(
      category => ({
        label:
          extractFirstLocalizedProp(
            currentLocale,
            category,
            CategoryFields.NAME
          ).value || "",
        value: category
      })
    ),
    multiChoice: true,
    renderOption: (option: LinkFormFieldOption<ManagedCategory>) => (
      <AvatarListItem
        src={extractFirstContentField(option.value, "imageUrl")}
        text={
          extractFirstLocalizedProp(
            currentLocale,
            option.value,
            CategoryFields.NAME
          ).value || ""
        }
      />
    )
  } as OptionalProperties<LinkFormFieldType<ManagedCategory>, "validate">);
