import { Locale } from "../../locale";

export function formatFormFieldLabel({
  label,
  locale
}: {
  label: string;
  locale?: Locale;
}) {
  return locale ? `${label} (${locale})` : `${label}`;
}
