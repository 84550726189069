import React, { useCallback, useEffect, useRef, useState } from "react";
import moment, { Moment } from "moment";
import { Grid } from "@material-ui/core";
import { DateCell } from "../DateCell";
import Divider from "@material-ui/core/Divider";
import { MonthDateCell } from "./MonthDateCell";
import Box from "@material-ui/core/Box";
import { useStyles } from "../styles";
import { usePrevious } from "../../../hooks/usePrevious";
import { CalendarEvent } from "../../../repository/use-case/calendar/CalendarEvent";

const DAYS_IN_WEEK = 7;
const WEEK_ROWS_TO_SHOW = 6;

function getDateRangeStart(dateCells2d: DateCell[][]): Moment {
  return dateCells2d[0][0].moment;
}

function getDateRangeEnd(dateCells2d: DateCell[][]): Moment {
  return dateCells2d[dateCells2d.length - 1][
    dateCells2d[dateCells2d.length - 1].length - 1
  ].moment;
}

export const MonthDates = ({
  momentToShow,
  selectedDates,
  onDatesSelected,
  onDateRangeDisplayed,
  events,
  onEventSelected,
  momentProvider
}: {
  momentToShow?: Moment;
  selectedDates: Moment[];
  onDatesSelected: (dates: Moment[]) => void;
  onDateRangeDisplayed: (dates: Moment[]) => void;
  events: CalendarEvent[];
  onEventSelected?: (
    element: React.MouseEvent<HTMLButtonElement>,
    event: CalendarEvent
  ) => void;
  momentProvider: () => Moment;
}) => {
  const classes = useStyles();

  const allDateCells = React.useMemo(() => {
    const dateCells: DateCell[][] = [];
    const today = momentProvider();
    const earliestDateToShow =
      momentToShow?.clone()?.startOf("week") ??
      today
        .clone()
        .startOf("week")
        .subtract(4, "week");
    for (let i = 0; i < WEEK_ROWS_TO_SHOW; i++) {
      dateCells[i] = [];
      for (let j = 0; j < DAYS_IN_WEEK; j++) {
        const cellIndex = i * DAYS_IN_WEEK + j;
        const dateMoment = earliestDateToShow.clone().add(cellIndex, "days");
        dateCells[i][j] = {
          moment: dateMoment,
          isToday: dateMoment.isSame(today, "date"),
          isSelected:
            selectedDates[0]?.isSameOrBefore(dateMoment, "date") &&
            selectedDates[selectedDates.length - 1]?.isSameOrAfter(
              dateMoment,
              "date"
            ),
          cellIndex,
          events: events.filter(calendarEvent => {
            return (
              dateMoment.isSameOrAfter(moment(calendarEvent.startTime)) &&
              dateMoment.isSameOrBefore(calendarEvent.endTime)
            );
          })
        };
      }
    }
    return dateCells;
  }, [events, momentProvider, momentToShow, selectedDates]);

  const previousDateCells = usePrevious(allDateCells);
  useEffect(() => {
    if (!previousDateCells) {
      onDateRangeDisplayed([
        getDateRangeStart(allDateCells),
        getDateRangeEnd(allDateCells)
      ]);
      return;
    }

    if (
      !getDateRangeStart(allDateCells).isSame(
        getDateRangeStart(previousDateCells)
      ) ||
      !getDateRangeEnd(allDateCells).isSame(getDateRangeEnd(previousDateCells))
    ) {
      onDateRangeDisplayed([
        getDateRangeStart(allDateCells),
        getDateRangeEnd(allDateCells)
      ]);
    }
  }, [allDateCells, onDateRangeDisplayed, previousDateCells]);

  const [activeIndexRange, setActiveIndexRange] = useState<number[]>(() => []);
  const previousActiveIndexRange = usePrevious(activeIndexRange);
  useEffect(() => {
    if (previousActiveIndexRange !== activeIndexRange) {
      if (activeIndexRange.length !== 2) {
        onDatesSelected([]);
      } else {
        const activeDates = activeIndexRange.map(normalizedIndex => {
          const row = Math.floor(normalizedIndex / DAYS_IN_WEEK);
          const col = normalizedIndex % DAYS_IN_WEEK;
          return allDateCells[row][col].moment;
        });
        onDatesSelected(activeDates);
      }
    }
  }, [
    activeIndexRange,
    allDateCells,
    onDatesSelected,
    previousActiveIndexRange
  ]);

  const isDragReset = useRef(false);
  const onDragRelease = useCallback(() => {
    isDragReset.current = true;
  }, []);

  return (
    <ul className={classes.monthGridContainer}>
      {allDateCells.map(row => (
        <li>
          <div className={classes.matchParentHeight}>
            <Divider />
            <Grid
              container
              justify="space-between"
              alignItems="flex-end"
              direction="row"
              className={classes.matchParentHeight}
            >
              {row.map((dateCell, index) => (
                <Grid item xs className={classes.monthRowContainer}>
                  <Box className={classes.monthCellWithDividerContainer}>
                    <MonthDateCell
                      dateCell={dateCell}
                      onDateSelected={() => {
                        isDragReset.current = true;
                        setActiveIndexRange([
                          dateCell.cellIndex,
                          dateCell.cellIndex
                        ]);
                      }}
                      onDragWhileClick={() => {
                        setActiveIndexRange(currentRange => [
                          isDragReset.current
                            ? dateCell.cellIndex
                            : currentRange[0] ?? dateCell.cellIndex,
                          dateCell.cellIndex
                        ]);
                        isDragReset.current = false;
                      }}
                      onDragRelease={onDragRelease}
                      onEventSelected={onEventSelected}
                      momentProvider={momentProvider}
                    />
                    {index < row.length - 1 ? (
                      <Divider
                        orientation="vertical"
                        className={classes.flexDivider}
                      />
                    ) : null}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </div>
        </li>
      ))}
    </ul>
  );
};
