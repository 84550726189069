import toManagedResource from "./managed-resource-mapper";
import calculateTimeline from "../owner-timeline-mapper";
import { Commit, CommitResource } from "../../../api/commits/Commit";
import User from "../../../api/auth/User";
import { extractValues } from "../../../utils/maps";
import { Resources } from "../../Resources";
import { Locale } from "../../../locale";
import WeeklyTip from "../../../api/content/WeeklyTip";
import { ManagedWeeklyTip } from "../../ManagedWeeklyTip";

export default function toManagedWeeklyTip({
  commits,
  users,
  weeklyTips,
  locale
}: {
  commits: Map<CommitResource, Map<string, Commit>>;
  users: Map<string, User>;
  weeklyTips: Map<string, WeeklyTip>;
  locale: Locale;
}): ManagedWeeklyTip[] {
  return toManagedResource({
    calculateTimeline: calculateTimeline("Weekly Tip"),
    commits: commits.get(Resources.WEEKLY_TIP) || new Map(),
    users,
    contentCollection: extractValues(weeklyTips, weeklyTips => ({
      ...weeklyTips
    })),
    locale
  });
}
