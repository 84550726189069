import { Snackbar, SnackbarProps } from "@material-ui/core";
import { useCallback, useState } from "react";
import React from "react";

export type OneOfSnackbarProps = Omit<SnackbarProps, "open"> &
  Required<Pick<SnackbarProps, "autoHideDuration">>;

export const OneOfSnackbar = (props: OneOfSnackbarProps) => {
  const [open, setOpen] = useState(true);
  const handleClose = useCallback(() => setOpen(false), []);
  return <Snackbar open={open} onClose={handleClose} {...props} />;
};
