import Destination from "./Destination";
import * as React from "react";
import { cmsDestinations } from "./CMSNavigationDestinations";
import { communityDestinations } from "./CommunityNavigationDestinations";
import { developerConsoleDestinations } from "./developerConsoleDestinations";

export enum NAVIGATION_ROOT {
  CMS = "cms",
  COMMUNITY = "community",
  DEVELOPER_CONSOLE = "developer-console"
}

class Navigation {
  destinations(root: string | undefined): Array<Destination> {
    let destinations: Destination[];
    switch (root) {
      case NAVIGATION_ROOT.CMS:
        destinations = cmsDestinations();
        break;
      case NAVIGATION_ROOT.COMMUNITY:
        destinations = communityDestinations();
        break;
      case NAVIGATION_ROOT.DEVELOPER_CONSOLE:
        destinations = developerConsoleDestinations();
        break;
      default:
        destinations = [];
        break;
    }
    return destinations.map(destination =>
      addRootPrefix(root ?? "", destination)
    );
  }
}

function addRootPrefix(root: string, destination: Destination): Destination {
  const children = [];
  for (let childDestination of destination.children ?? []) {
    children.push(addRootPrefix(root, childDestination));
  }

  return {
    children: children.length ? children : undefined,
    icon: destination.icon,
    name: destination.name,
    relativePath: `${root}/${destination.relativePath}`
  };
}

export default Navigation;
