import ManagedResource from "../ManagedResource";
import ManagedContent from "../ManagedContent";
import { ContentActionType } from "../../components/content/actions/ContentActions";

const mapResourcesToManagedContent: (
  managedResources: ManagedResource<any>[],
  supportedActions: {
    normal: Map<string, { icon: any; description: string }>;
    locked?: { icon: any; description: string };
  },
  onClicked: (
    managedResource: ManagedResource<any>,
    actionType: string
  ) => void,
  isContentLock: boolean
) => ManagedContent<any>[] = (
  managedResources,
  supportedActions,
  onClicked,
  isContentLock
) => {
  return managedResources.map(managedResource => ({
    managedResource,
    contentActions: isContentLock
      ? [
          {
            ...supportedActions.locked,
            enabled: false
          }
        ]
      : Object.keys(managedResource.actions)
          .filter(
            actionType =>
              managedResource.actions[actionType] &&
              supportedActions.normal.has(actionType)
          )
          .map(actionType => ({
            actionType: actionType as ContentActionType,
            description: supportedActions.normal.get(actionType)!.description,
            icon: supportedActions.normal.get(actionType)!.icon,
            onClicked: () => {
              onClicked(managedResource, actionType);
            },
            enabled: true
          })),
    modificationType: resolveModificationType(managedResource),
    ownerTimeline:
      (managedResource.tentativeState.timeLine &&
        Object.values(managedResource.tentativeState.timeLine)) ||
      [],
    isContentLock,
    updatedAt: managedResource.content.updatedAt
  }));
};

function resolveModificationType(
  managedResource: ManagedResource<any>
): ManagedContent<any>["modificationType"] {
  const tentativeState = managedResource.tentativeState;
  if (tentativeState.isNew) {
    return "create";
  }

  if (tentativeState.isDeleted) {
    return "remove";
  }

  if (tentativeState.isArchived) {
    return "archive";
  }

  if (tentativeState.isUnarchived) {
    return "unarchive";
  }

  if (Object.keys(tentativeState.updatedContent).length) {
    return "update";
  }

  return undefined;
}

export default mapResourcesToManagedContent;
