import React, { useCallback, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import LinearProgress from "@material-ui/core/LinearProgress";
import { DialogTitle } from "@material-ui/core";
import { ConversationStatus } from "../../../../../repository/use-case/users-voice/dto/ListConversationsResponse";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import { useRepositories } from "../../../../../hooks/useRepositories";

export const ConfirmStatusChangeDialog = ({
  isOpen,
  handleClose,
  conversationId,
  newStatus
}: {
  isOpen: boolean;
  handleClose: ({ didChange }?: { didChange?: boolean }) => void;
  conversationId: string;
  newStatus: ConversationStatus;
}) => {
  const { usersVoice } = useRepositories();
  const [isSaving, setSaving] = useState(false);
  const onConfirm = useCallback(async () => {
    setSaving(true);
    await usersVoice.changeConversationStatus({
      conversationId,
      newStatus
    });
    setSaving(false);
    handleClose({ didChange: true });
  }, [conversationId, newStatus, usersVoice]);
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      {isSaving && <LinearProgress variant="query" />}
      <DialogTitle>{`Change status to ${getStatusText(
        newStatus
      )}?`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to change the status? The user will receive a
          notification about this action.
        </DialogContentText>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button onClick={onConfirm} autoFocus>
            Update
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

function getStatusText(conversationStatus: ConversationStatus) {
  switch (conversationStatus) {
    case ConversationStatus.NONE:
      return "none";
    case ConversationStatus.FEEDBACK_PENDING:
      return "pending";
    case ConversationStatus.FEEDBACK_RESOLVED:
      return "resolved";
  }
}
