import { useCallback, useEffect, useState } from "react";
import { LCE } from "../architecture/lce/lce";

const useLCE = function<T, E extends Error>(contentLoading: () => Promise<T>) {
  const [lce, setLCE] = useState<LCE<T, E>>({ isLoading: true });

  const contentLoaderCallback = useCallback(contentLoading, []);

  useEffect(() => {
    (async () => {
      try {
        setLCE({ isLoading: true });
        const content = await contentLoaderCallback();
        setLCE({ isLoading: false, content });
      } catch (e: any) {
        setLCE({ isLoading: false, error: e });
      }
    })();
  }, [contentLoaderCallback]);

  return lce;
};

export default useLCE;
