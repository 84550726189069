import toManagedResource from "./managed-resource-mapper";
import calculateTimeline from "../owner-timeline-mapper";
import { Commit, CommitResource } from "../../../api/commits/Commit";
import User from "../../../api/auth/User";
import { extractValues } from "../../../utils/maps";
import { Resources } from "../../Resources";
import { Locale } from "../../../locale";
import MediaPlatform from "../../../api/content/MediaPlatform";
import { ManagedMediaPlatform } from "../../ManagedMediaPlatform";

export default function toManagedMediaPlatform({
  commits,
  users,
  mediaPlatforms,
  locale
}: {
  commits: Map<CommitResource, Map<string, Commit>>;
  users: Map<string, User>;
  mediaPlatforms: Map<string, MediaPlatform>;
  locale: Locale;
}): ManagedMediaPlatform[] {
  return toManagedResource({
    calculateTimeline: calculateTimeline("media platform"),
    commits: commits.get(Resources.MEDIA_PLATFORM) || new Map(),
    users,
    contentCollection: extractValues(mediaPlatforms, mediaPlatform => ({
      ...mediaPlatform
    })),
    locale
  });
}
