import { ContentAction } from "./ContentActions";
import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import { MuiThemeProvider, Theme, Tooltip } from "@material-ui/core";

const ContentActionButton = ({
  contentAction
}: {
  contentAction: ContentAction;
}) => {
  const actionButton =
    (contentAction.enabled && (
      <IconButton onClick={contentAction.onClicked} size="small">
        {contentAction.icon}
      </IconButton>
    )) ||
    contentAction.icon;
  const theme = React.useCallback(
    (theme: Theme) => ({
      ...theme,
      overrides: {
        ...theme.overrides,
        MuiIconButton: {
          ...theme.overrides?.MuiIconButton,
          label: {
            color:
              contentAction.actionType &&
              theme.contentManagement &&
              theme.contentManagement[contentAction.actionType]?.color
          }
        },
        MuiSvgIcon: {
          ...theme.overrides?.MuiSvgIcon,
          root: {
            ...theme.overrides?.MuiSvgIcon?.root,
            ...(!contentAction.enabled && {
              fill: theme.contentManagement?.locked.color
            })
          }
        }
      }
    }),
    [contentAction.actionType, contentAction.enabled]
  );
  return contentAction.description ? (
    <MuiThemeProvider theme={theme}>
      <Tooltip title={contentAction.description}>{actionButton}</Tooltip>
    </MuiThemeProvider>
  ) : (
    actionButton ?? null
  );
};

export default ContentActionButton;
