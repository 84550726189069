import React, { useCallback, useState } from "react";
import { Box, Grid, Paper } from "@material-ui/core";
import useStyles from "../developer-console/diagnostics/view/styles";
import { UsersList } from "../users-list/UsersList";
import { PermissionsList } from "../permissions-list/PermissionsList";
import Button from "@material-ui/core/Button";
import useActionLCE from "../../hooks/useActionLCE";
import LinearProgress from "@material-ui/core/LinearProgress";
import { LCEWrapper } from "../../components/lce/LCEWrapper";
import User from "../../api/auth/User";
import { useRepositories } from "../../hooks/useRepositories";

interface UserInfo {
  name: string;
  email: string;
}

interface PermissionInfo {
  id: string;
  description?: string;
}

export const PermissionManagementScreen = () => {
  const [selectedUser, setSelectedUser] = useState<User | undefined>();
  const [permissionSelections, setPermissionSelections] = useState<string[]>();
  const { permissions } = useRepositories();
  const applyChangesCallback = useCallback(
    async (selectedPermissions: string[] | undefined) => {
      if (!selectedUser?.email || !selectedPermissions) {
        return;
      }

      await permissions.setUserPermissions({
        user: {
          email: selectedUser.email
        },
        enabledPermissions: {
          ids: selectedPermissions
        }
      });
    },
    [permissions, selectedUser]
  );
  const [applyChangesLCE, applyChanges] = useActionLCE(applyChangesCallback);

  const onApplyChangesClick = useCallback(() => {
    applyChanges(permissionSelections);
  }, [applyChanges, permissionSelections]);

  const classes = useStyles();
  return (
    <>
      <Box className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Paper>
              <UsersList
                disabled={applyChangesLCE.isLoading}
                onUserClick={userInfo => setSelectedUser(userInfo)}
                disabledUser={userInfo => ({
                  disabled: !userInfo.email,
                  disabledRationale: "Email not available."
                })}
              />
            </Paper>
          </Grid>
          {selectedUser && (
            <Grid item xs={3}>
              <Paper>
                <LCEWrapper
                  lce={applyChangesLCE}
                  renderContent={() => <></>}
                  renderLoader={() => <LinearProgress variant="query" />}
                />
                <Button
                  color={"primary"}
                  variant={"outlined"}
                  style={{ margin: 8 }}
                  onClick={onApplyChangesClick}
                >
                  Apply changes
                </Button>
                <PermissionsList
                  disabled={applyChangesLCE.isLoading}
                  forUserEmail={selectedUser.email!!}
                  onPermissionSelectionChange={setPermissionSelections}
                />
              </Paper>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};
