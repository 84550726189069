export function toObject<K, V>(map: Map<K, V>): { [key: string]: V } {
  const obj: any = {};
  map.forEach((value, key) => {
    obj[key] = value;
  });
  return obj;
}

export function toMap<V>(obj: any): Map<string, any> {
  const asMap = new Map();
  if (obj) {
    Object.keys(obj).forEach(key => {
      asMap.set(key, obj[key]);
    });
  }
  return asMap;
}

export function toArray<K, V, R>(
  map: Map<K, V>,
  transformer: (value: V, key: K, index: number) => R
) {
  const transformed: R[] = [];
  let index = 0;
  map.forEach((v, k) => {
    transformed.push(transformer(v, k, index++));
  });
  return transformed;
}

export function transformValues<K, V, R>(
  map: Map<K, V>,
  transform: (value: V, key: K) => R
) {
  const transformed = new Map<K, R>();
  map.forEach((value: V, key: K) => {
    transformed.set(key, transform(value, key));
  });
  return transformed;
}

export function transform<K1, V1, K2, V2>(
  map: Map<K1, V1>,
  transform: (key: K1, value: V1) => { key: K2; value: V2 }
) {
  const transformed = new Map<K2, V2>();
  map.forEach((value: V1, key: K1) => {
    const transformedEntry = transform(key, value);
    transformed.set(transformedEntry.key, transformedEntry.value);
  });
  return transformed;
}

export function extractValues<K, V, R = V>(
  map: Map<K, V>,
  transform?: (value: V, key?: K) => R
) {
  const extracted: (V | R)[] = [];
  map.forEach((value, key) => {
    extracted.push(transform?.(value, key) || value);
  });
  return extracted;
}

export function filterValues<K, V>(
  map: Map<K, V>,
  predicate: (value: V, key: K) => boolean
) {
  const filteredMap = new Map<K, V>();
  map.forEach((value, key) => {
    if (predicate(value, key)) {
      filteredMap.set(key, value);
    }
  });
  return filteredMap;
}

export function filterKeys<K, V>(
  map: Map<K, V>,
  predicate: (key: K) => boolean
) {
  const filteredMap = new Map<K, V>();
  map.forEach((value, key) => {
    if (predicate(key)) {
      filteredMap.set(key, value);
    }
  });
  return filteredMap;
}

export function merge<K, V>(
  map: Map<K, V>,
  otherMap: Map<K, V>,
  mergeValues: (v1: V | undefined, v2: V) => V = (v1, v2) => v2
) {
  const mergedMap = new Map<K, V>();
  map.forEach((value, key) => mergedMap.set(key, value));
  otherMap.forEach((value, key) =>
    mergedMap.set(key, mergeValues(mergedMap.get(key), value))
  );
  return mergedMap;
}

export function mergeEntry<K, V>(map: Map<K, V>, key: K, value: V) {
  const mergedMap = new Map<K, V>();
  map.forEach((value, key) => mergedMap.set(key, value));
  mergedMap.set(key, value);
  return mergedMap;
}

export function mergeDeepEntry(
  map: Map<any, any>,
  path: any[],
  value: any
): Map<any, any> {
  if (path) {
    if (path.length === 1) {
      return mergeEntry(map, path[0], value);
    }
    const k = path.shift();
    return mergeEntry(map, k, mergeDeepEntry(map.get(k), path, value));
  }
  return map;
}

export function removeDeepEntries(
  map: Map<any, any>,
  data: {
    path: any[];
    keys: any[];
  }
): Map<any, any> {
  if (data.path) {
    if (data.path.length === 1) {
      return filterKeys(map, key => !data.keys.includes(key));
    }
    const k = data.path.shift();
    return mergeEntry(
      map,
      k,
      removeDeepEntries(map, { path: data.path, keys: data.keys })
    );
  }
  return map;
}

export function copyOf<K, V>(map: Map<K, V> | undefined | null) {
  const copy = new Map();
  if (map) {
    map.forEach((value, key) => {
      copy.set(key, value);
    });
  }
  return copy;
}

export function reduce<K, V, R>(
  map: Map<K, V>,
  reducer: (accumulated: R, value: V, key: K) => R,
  initial: R
) {
  let reduced = initial;
  map.forEach((value, key) => {
    reduced = reducer(reduced, value, key);
  });
  return reduced;
}

export function mapOf<K, V>(k1: K, v1: V): Map<K, V>;
export function mapOf<K, V>(k1: K, v1: V, k2: K, v2: V): Map<K, V>;
export function mapOf<K, V>(
  k1: K,
  v1: V,
  k2: K,
  v2: V,
  k3: K,
  v3: V
): Map<K, V>;
export function mapOf<K, V>(
  k1: K,
  v1: V,
  k2: K,
  v2: V,
  k3: K,
  v3: V,
  k4: K,
  v4: V
): Map<K, V>;
export function mapOf<K, V>(
  k1: K,
  v1: V,
  k2: K,
  v2: V,
  k3: K,
  v3: V,
  k4: K,
  v4: V,
  k5: K,
  v5: V
): Map<K, V>;
export function mapOf<K, V>(
  k1: K,
  v1: V,
  k2: K,
  v2: V,
  k3: K,
  v3: V,
  k4: K,
  v4: V,
  k5: K,
  v5: V,
  k6: K,
  v6: V
): Map<K, V>;
export function mapOf<K, V>(
  k1: K,
  v1: V,
  k2: K,
  v2: V,
  k3: K,
  v3: V,
  k4: K,
  v4: V,
  k5: K,
  v5: V,
  k6: K,
  v6: V,
  k7: K,
  v7: V
): Map<K, V>;
export function mapOf<K, V>(
  k1: K,
  v1: V,
  k2: K,
  v2: V,
  k3: K,
  v3: V,
  k4: K,
  v4: V,
  k5: K,
  v5: V,
  k6: K,
  v6: V,
  k7: K,
  v7: V,
  k8: K,
  v8: V
): Map<K, V>;
export function mapOf<K, V>(...keyValuePairs: [any]): Map<K, V> {
  const newMap = new Map();

  for (let i = 0; i < keyValuePairs.length; i += 2) {
    newMap.set(keyValuePairs[i], keyValuePairs[i + 1]);
  }

  return newMap;
}
