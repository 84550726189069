import { ContentData } from "../../../repository/use-case/content/ContentData";
import { Commit, CommitResource } from "../../../api/commits/Commit";
import User from "../../../api/auth/User";
import { mapOf } from "../../../utils/maps";
import { Resources } from "../../Resources";
import ManagedResource from "../../ManagedResource";
import toManagedQuotes from "./managed-quote-mapper";
import toManagedAuthors from "./managed-author-mapper";
import toManagedCategories from "./managed-category-mapper";
import { LocaleData } from "../../../repository/LocaleData";
import toManagedMediaPlatform from "./managed-platform-mapper";
import toManagedMediaFields from "./managed-media-feed-mapper";
import toManagedWeeklyTip from "./managed-weekly-tip-mapper";

const managedResourceMappers = ({
  contentData,
  commits,
  users
}: {
  contentData: LocaleData<ContentData>;
  commits: Map<CommitResource, Map<string, Commit>>;
  users: Map<string, User>;
}): Map<CommitResource, ManagedResource<any>[]> => {
  return mapOf<CommitResource, ManagedResource<any>[]>(
    Resources.QUOTES,
    toManagedQuotes({ commits, users, contentData }),
    Resources.AUTHORS,
    toManagedAuthors({
      commits,
      users,
      authors: contentData.data.authors,
      locale: contentData.locale
    }),
    Resources.CATEGORIES,
    toManagedCategories({
      commits,
      users,
      categories: contentData.data.categories,
      locale: contentData.locale
    }),
    Resources.MEDIA_PLATFORM,
    toManagedMediaPlatform({
      commits,
      users,
      mediaPlatforms: contentData.data.mediaPlatforms,
      locale: contentData.locale
    }),
    Resources.PODCASTS,
    toManagedMediaFields({
      commits,
      users,
      contentData,
      resource: Resources.PODCASTS,
      resourceName: "podcast",
      indexedMediaFeed: contentData.data.podcasts
    }),
    Resources.VIDEOS,
    toManagedMediaFields({
      commits,
      users,
      contentData,
      resource: Resources.VIDEOS,
      resourceName: "video",
      indexedMediaFeed: contentData.data.videos
    }),
    Resources.BOOKS,
    toManagedMediaFields({
      commits,
      users,
      contentData,
      resource: Resources.BOOKS,
      resourceName: "book",
      indexedMediaFeed: contentData.data.books
    }),
    Resources.WEEKLY_TIP,
    toManagedWeeklyTip({
      commits,
      users,
      weeklyTips: contentData.data.weeklyTips,
      locale: contentData.locale
    })
  );
};

export default managedResourceMappers;
