import React, { ChangeEvent, useCallback, useState } from "react";
import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField
} from "@material-ui/core";
import { DeleteForever } from "@material-ui/icons";
import {
  ConversationStatus,
  ConversationType
} from "../../../../repository/use-case/users-voice/dto/ListConversationsResponse";
import SendIcon from "@material-ui/icons/Send";

export const ConversationActions = ({
  status,
  onConversationActionSelection,
  type,
  isReplyLoading
}: {
  status: ConversationStatus;
  onConversationActionSelection: (
    conversationActionSelection: ConversationActionSelection
  ) => void;
  type: ConversationType;
  isReplyLoading: boolean;
}) => {
  const [messageContent, setMessageContent] = useState("");
  const onSendClicked = useCallback(() => {
    onConversationActionSelection({
      type: "send",
      messageContent
    });
  }, [onConversationActionSelection, messageContent]);
  const onDeleteClick = useCallback(() => {
    onConversationActionSelection({
      type: "delete"
    });
  }, [onConversationActionSelection]);
  const onSelectStatus = useCallback(
    event => {
      const newStatus = event.target.value as ConversationStatus;
      if (newStatus !== status) {
        onConversationActionSelection({
          type: "status",
          newStatus
        });
      }
    },
    [onConversationActionSelection, status]
  );
  const onMessageContentChanged = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setMessageContent(event.target.value);
    },
    [setMessageContent]
  );
  return (
    <Paper style={{ padding: "12px" }}>
      <Grid container direction={"column"} spacing={2}>
        <Grid item container spacing={2} alignItems={"center"}>
          <Grid item>
            <Button
              variant={"outlined"}
              startIcon={<SendIcon />}
              onClick={onSendClicked}
              disabled={
                !messageContent ||
                isReplyLoading ||
                type === ConversationType.BROADCAST
              }
            >
              Reply
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={"outlined"}
              startIcon={<DeleteForever />}
              onClick={onDeleteClick}
            >
              Delete Conversation
            </Button>
          </Grid>
          <Grid item>
            <Select
              label={"Status"}
              value={status}
              variant={"outlined"}
              onChange={onSelectStatus}
            >
              <MenuItem value={ConversationStatus.NONE}>None</MenuItem>
              <MenuItem value={ConversationStatus.FEEDBACK_PENDING}>
                Pending
              </MenuItem>
              <MenuItem value={ConversationStatus.FEEDBACK_RESOLVED}>
                Resolved
              </MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            placeholder="New Message"
            multiline
            rows={4}
            disabled={isReplyLoading || type === ConversationType.BROADCAST}
            variant={"outlined"}
            style={{ width: "100%" }}
            onChange={onMessageContentChanged}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export interface ConversationActionDelete {
  type: "delete";
}

export interface ConversationActionSend {
  type: "send";
  messageContent: string;
}

export interface ConversationActionChangeStatus {
  type: "status";
  newStatus: ConversationStatus;
}

export type ConversationActionSelection =
  | ConversationActionDelete
  | ConversationActionSend
  | ConversationActionChangeStatus;
