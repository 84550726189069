import { toMap } from "../../../utils/objects/toMap";
import { Pair } from "../../../utils/tuples/Pair";
import { filterValues, transformValues } from "../../../utils/maps";
import { Locale } from "../../../locale";
import FormField from "./fields/FormField";
import CurrentFormInfo from "./CurrentFormInfo";
import { pipe } from "rxjs";
import LinkFormFieldType from "./fields/link/LinkFormFieldType";
import _ from "lodash";
import { CommitResource } from "../../../api/commits/Commit";
import { getResourceLinkId } from "../../../data/mappers/resources/resource-link-mappers";
import { Resources } from "../../../data/Resources";

const transformFormInfoToUpdatedData = (currentFormInfo: CurrentFormInfo) =>
  toMap(currentFormInfo, locale => {
    const res = currentFormInfo[locale]
      ? {
        updatedFields: toMap(
          currentFormInfo[locale].updatedFields || {},
          field => currentFormInfo[locale].updatedFields?.[field] || []
        ),
        updatedLinks: toMap(
          currentFormInfo[locale].updatedLinks || {},
          resource => currentFormInfo[locale].updatedLinks?.[resource] || []
        )
      }
      : {
        updatedFields: new Map<string, string[]>(),
        updatedLinks: new Map<string, Pair<string, string>[]>()
      };
      return res;
    }
  );

const transformDistinctUpdatedData = (
  localeToFields: Map<Locale, FormField[]>,
  resourceType: CommitResource
) => (
  localeToUpdates: Map<
    Locale,
    {
      updatedFields: Map<string, string[]>;
      updatedLinks: Map<string, Pair<string, string>[]>;
    }
  >
) => {
  const res = transformValues(localeToUpdates, (value, locale) => ({
    updatedFields: filterValues(
      value.updatedFields,
      (updatedValues, fieldName) => {
        const relevantField = (localeToFields.get(locale) || []).find(
          field => field.id === fieldName
        );
        return !(
          relevantField?.defaultValue?.value &&
          updatedValues.includes(relevantField.defaultValue.value)
        );
      }
    ),
    updatedLinks: filterValues(
      value.updatedLinks,
      (updatedLinks, fieldName) => {
        const relevantLink = (localeToFields.get(locale) || []).find(
          field => field.id === fieldName
        );
        const defaultIds =
          (relevantLink as
            | LinkFormFieldType<any>
            | undefined)?.defaultValues?.map(
            ({ value: resource }) =>
              (resource.content?.[
                getResourceLinkId(resourceType)(fieldName as Resources) ||
                ""
                  ] ||
                resource.tentativeState.updatedContent?.[fieldName] || [])[0]
          ) || [];
        return !_.isEqual(
          defaultIds,
          updatedLinks.map(({ value }) => value)
        );
      }
    )
  }));
  return res;
};

const filterUpdatedFormData = (
  updatedFormData: Map<
    Locale,
    {
      updatedFields: Map<string, string[]>;
      updatedLinks: Map<string, Pair<string, string>[]>;
    }
  >
) => {
  const res = filterValues(
    updatedFormData,
    updateData =>
      updateData.updatedLinks.size > 0 || updateData.updatedFields.size > 0
  );
  return res;
};

export const updateClickHandler = ({
  currentFormInfo,
  localeToFields,
  handleFormSave,
  handleClose,
  setInvalidFields,
  resourceType
}: {
  currentFormInfo: CurrentFormInfo;
  localeToFields: Map<Locale, FormField[]>;
  handleFormSave: (
    saveData: Map<
      Locale,
      {
        updatedFields: Map<string, string[]>;
        updatedLinks: Map<string, Pair<string, string>[]>;
      }
    >
  ) => void;
  handleClose: () => void;
  setInvalidFields: (
    invalidFields: Map<Locale, Map<string, string | undefined>>
  ) => void;
  resourceType: CommitResource;
}) => () => {
  const updatedFormData = pipe(
    transformFormInfoToUpdatedData,
    // transformDistinctUpdatedData(localeToFields, resourceType),
    filterUpdatedFormData
  )(currentFormInfo);

  if (updatedFormData.size) {
    const validationResults = transformValues(
      updatedFormData,
      (formData, locale) =>
        (localeToFields.get(locale) || []).reduce(
          (invalidFieldResults, formField) => {
            const result = formField.validate(
              locale,
              formData.updatedFields.get(formField.id),
              formData.updatedLinks.get(formField.id)
            );
            if (!result.isValid) {
              invalidFieldResults.set(formField.id, result.message);
            }
            return invalidFieldResults;
          },
          new Map<string, string | undefined>()
        )
    );
    const isFormValid =
      Array.from(validationResults.values()).reduce(
        (allResults, localeValidationResults) => [
          ...allResults,
          ...Array.from(localeValidationResults.values())
        ],
        [] as any[]
      ).length === 0;
    if (isFormValid) {
      handleFormSave(updatedFormData);
    } else {
      setInvalidFields(validationResults);
    }
  } else {
    handleClose();
  }
};
