import { spring } from "react-motion";

export const SLIDE_SIZE = 300;
export const UPDATE_BUTTON_DEFAULT_DISABLED_STYLE = { opacity: 0 };
export const UPDATE_BUTTON_ANIMATED_DISABLED_STYLE = {
  opacity: spring(1)
};
export const UPDATE_BUTTON_DEFAULT_ENABLED_STYLE = { opacity: 1 };
export const UPDATE_BUTTON_ANIMATED_ENABLED_STYLE = {
  opacity: spring(0)
};
export const SLIDE_BORDER_RADIUS = 8;