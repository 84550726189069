import React from "react";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const DESCRIPTION_MARGIN_FROM_ICON = "0.2em";

const ContentStatisticsSummary = ({
  resourceName,
  totals
}: {
  resourceName: string;
  totals: {
    description?: string;
    renderIcon?: () => JSX.Element;
    className: string;
    show: boolean;
  }[];
}) => (
  <Grid container justify="flex-start" alignItems="center" spacing={1}>
    <Grid item>
      <Typography>{`${resourceName
        .charAt(0)
        .toUpperCase()}${resourceName.substring(1)}`}</Typography>
    </Grid>
    {totals &&
      totals
        .filter(({ show }) => show)
        .map(({ description, renderIcon, className }) => {
          const marginLeft =
            renderIcon && description ? DESCRIPTION_MARGIN_FROM_ICON : "0";
          return (
            <Grid item>
              <Box className={className as string}>
                <Grid container justify="center" alignItems="center">
                  {renderIcon && <Grid item>{renderIcon()}</Grid>}
                  {description && (
                    <Grid item style={{ marginLeft }}>
                      <Typography>{description}</Typography>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
          );
        })}
  </Grid>
);

export default React.memo(ContentStatisticsSummary);
