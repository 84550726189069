import { LocalViewModelFactory } from "../../../architecture/view-model/LocalViewModelFactory";
import { FileManagerViewModelBase } from "../FileManagerViewModelBase";
import { useLocalViewModel } from "../../../architecture/view-model/useViewModel";
import React, { useCallback, useState } from "react";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Typography
} from "@material-ui/core";
import { DescriptiveIconButton } from "../../../components/buttons/DescriptiveIconButton";
import { FileCopy } from "@material-ui/icons";
import RotationAnimatedComponent from "../../../components/animated/RotationAnimatedComponent";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Box from "@material-ui/core/Box";
import { copyToClipboard } from "../../../utils/copyToClipboard";
import { Alert } from "@material-ui/lab";

export interface FileManagerPublicLinksProps {
  viewModelFactory: LocalViewModelFactory<FileManagerViewModelBase>;
}

export const FileManagerPublicLinks = (props: FileManagerPublicLinksProps) => {
  const [viewState] = useLocalViewModel(props.viewModelFactory);
  const currentFileItem = viewState.fileDetailedView?.fileItem;

  const [showSuccessfulCopyMessage, setShowSuccessfulCopyMessage] = useState(
    false
  );
  const onCopyLinkClick = useCallback((linkName: string, linkValue: string) => {
    copyToClipboard(linkValue);
    setShowSuccessfulCopyMessage(true);
  }, []);

  const [collapseStates, setCollapseStates] = useState<Record<string, boolean>>(
    {}
  );
  const toggleCollapseState = useCallback((linkName: string) => {
    setCollapseStates(currentCollapseStates => ({
      ...currentCollapseStates,
      [linkName]: !currentCollapseStates[linkName]
    }));
  }, []);

  if (currentFileItem?.type !== "file" || !currentFileItem.publicLinks) {
    return <></>;
  }

  return (
    <>
      <Typography variant={"h6"} component={"h2"}>
        Public Links
      </Typography>
      <Box height={"100%"} overflow={"auto"}>
        <List dense disablePadding>
          {convertLinksObjectToDisplay(
            currentFileItem.publicLinks,
            onCopyLinkClick,
            collapseStates,
            toggleCollapseState
          )}
        </List>
      </Box>
      <Snackbar
        open={showSuccessfulCopyMessage}
        autoHideDuration={3000}
        onClose={() => setShowSuccessfulCopyMessage(false)}
      >
        <Alert severity={"success"}>Link copied to clipboard.</Alert>
      </Snackbar>
    </>
  );
};

function convertLinksObjectToDisplay(
  links:
    | Record<
        string,
        string | Record<string, Record<string, string>> | undefined
      >
    | Record<string, Record<string, string>>,
  onCopyLinkClick: (linkName: string, linkValue: string) => void,
  collapseStates: Record<string, boolean>,
  toggleCollapse: (linkName: string) => void,
  depth: number = 0,
  linkNamePrefix?: string
): JSX.Element[] {
  return Object.keys(links).reduce((linkDisplayElements, linkName) => {
    const linkValue = links?.[linkName];
    if (!linkValue) {
      return linkDisplayElements;
    }
    const prefix = linkNamePrefix ? `${linkNamePrefix}-${linkName}` : linkName;
    if (typeof linkValue === "object") {
      linkDisplayElements.push(
        <Box marginLeft={`${4 * depth}px`}>
          <ListItem button onClick={() => toggleCollapse(prefix)}>
            <ListItemIcon>
              <RotationAnimatedComponent
                render={() => <ArrowRightIcon />}
                toDegrees={collapseStates[prefix] ? 90 : 0}
              />
            </ListItemIcon>
            <ListItemText>{linkName}</ListItemText>
          </ListItem>
        </Box>
      );
      linkDisplayElements.push(
        <Collapse component={"li"} in={collapseStates[prefix]}>
          <List dense disablePadding>
            {convertLinksObjectToDisplay(
              linkValue,
              onCopyLinkClick,
              collapseStates,
              toggleCollapse,
              depth + 1,
              prefix
            )}
          </List>
        </Collapse>
      );
    } else {
      linkDisplayElements.push(
        <ListItem
          button
          onClick={() => onCopyLinkClick(linkName, linkValue)}
          style={{ marginLeft: `${4 * (depth + 1)}px` }}
        >
          <ListItemIcon>
            <DescriptiveIconButton
              onClick={() => onCopyLinkClick(linkName, linkValue)}
              icon={<FileCopy />}
              description={"Copy to clipboard"}
            />
          </ListItemIcon>
          <ListItemText primary={linkName} secondary={linkValue} />
        </ListItem>
      );
    }
    return linkDisplayElements;
  }, [] as JSX.Element[]);
}
