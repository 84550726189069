const VALID_LICENSES = new Set(["fair use", "public domain", "pd", "PD"]);

const APP_CLOUD_STORAGE_LINK_BASE =
  "https://storage.googleapis.com/legendary-quotes-bd0b2.appspot.com";

export function isLicenseRequiringAttention(
  license: string | undefined
): boolean {
  return !license || !VALID_LICENSES.has(license);
}

export function isExternalImageLink(imageURL: string | undefined): boolean {
  return !!imageURL && !imageURL.startsWith(APP_CLOUD_STORAGE_LINK_BASE);
}
