import {
  ExpansionPanelSummary,
  MuiThemeProvider,
  Theme
} from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ManagedContent from "../../../data/ManagedContent";
import React, { PropsWithChildren, useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import { Locale } from "../../../locale";
import mapIndexedContentToIndexedDiffData from "../diff/mapIndexedContentToIndexedDiffData";
import { useStyles } from "./styles";
import ContentStatisticsSummary from "./ContentStatisticsSummary";
import ContentStatisticsDetails from "./ContentStatisticsDetails";
import { overrideTheme } from "./overrideTheme";
import Grid from "@material-ui/core/Grid";
import ContentHeader from "../../content/row/ContentHeader";
import Flag from "react-world-flags";

interface ContentStatisticsPanelProps {
  resourceName: string;
  headers: {
    [fieldKey: string]: {
      title: string;
      isImage?: boolean;
      isUrl?: boolean;
    };
  };
  content: Map<Locale, ManagedContent<any>>[];
  totalAdded: number;
  totalChanged: number;
  totalRemoved: number;
  totalArchived: number;
  totalUnarchived: number;
  totalNewLocales: {
    [locale: string]: { countryCode: string; total: number };
  };
  defaultLocale: Locale | undefined;
  onExpandChange: (isExpanded: boolean) => void;
  withMargins: boolean;
  commitSelection: {
    enabled: boolean;
    selectedCommits: Map<string, { resourceName: string; locale: string }>;
    onChange: (
      resourceName: string,
      locale: string,
      commitId: string,
      isSelected: boolean
    ) => void;
  };
}

const ContentStatisticsPanel = ({
  totalAdded,
  totalChanged,
  totalRemoved,
  totalArchived,
  totalUnarchived,
  totalNewLocales,
  content,
  resourceName,
  headers,
  defaultLocale,
  withMargins,
  commitSelection
}: ContentStatisticsPanelProps) => {
  const classes = useStyles();

  const [totals, setTotals] = useState<
    {
      description: string;
      renderIcon?: () => JSX.Element;
      show: boolean;
      className: string;
    }[]
  >([]);
  useEffect(() => {
    setTotals([
      {
        description: `+${totalAdded}`,
        show: totalAdded > 0,
        className: classes.added
      },
      {
        description: `${totalChanged}`,
        show: totalChanged > 0,
        className: classes.changed
      },
      {
        description: `-${totalRemoved}`,
        show: totalRemoved > 0,
        className: classes.removed
      },
      {
        description: `-${totalArchived}`,
        show: totalArchived > 0,
        className: classes.archived
      },
      {
        description: `+${totalUnarchived}`,
        show: totalUnarchived > 0,
        className: classes.unarchived
      },
      ...Object.values(totalNewLocales)
        .filter(({ countryCode }) => countryCode)
        .map(({ countryCode, total }) => ({
          description: `${total}`,
          show: total > 0,
          renderIcon: () => (
            <Flag code={countryCode} style={{ width: "1em", height: "1em" }} />
          ),
          className: classes.translated
        }))
    ]);
  }, [
    classes.added,
    classes.changed,
    classes.removed,
    classes.translated,
    totalAdded,
    totalChanged,
    totalNewLocales,
    totalRemoved
  ]);

  const contentDiffData = React.useMemo(
    () => mapIndexedContentToIndexedDiffData(content),
    [content]
  );

  const [expanded, setExpanded] = useState(false);
  const onPanelExpandedChange = React.useCallback(
    (_: any, expanded: boolean) => {
      setExpanded(expanded);
    },
    []
  );

  const contentHeaders = React.useMemo(
    () => ["Locale", ...Object.values(headers).map(header => header.title)],
    [headers]
  );
  const theme = React.useCallback((theme: Theme) => overrideTheme(theme), []);
  return (
    <MuiThemeProvider theme={theme}>
      <ExpansionPanel
        className={
          withMargins
            ? classes.expansionPanelWithMargins
            : classes.expansionPanel
        }
        onChange={onPanelExpandedChange}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container direction="column">
            <Grid item>
              <ContentStatisticsSummary
                resourceName={resourceName}
                totals={totals}
              />
            </Grid>
            {expanded && <ContentHeader headers={contentHeaders} />}
          </Grid>
        </ExpansionPanelSummary>
        <Divider />
        <ExpansionPanelDetails>
          <ContentStatisticsDetails
            headers={headers}
            contentDiffData={contentDiffData}
            defaultLocale={defaultLocale}
            commitSelection={commitSelection}
            resourceName={resourceName}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </MuiThemeProvider>
  );
};

export default React.memo(ContentStatisticsPanel) as React.ComponentType<
  ContentStatisticsPanelProps & PropsWithChildren<any>
>;
