import { DiagnosticJobLatestRun } from "../DiagnosticJobLatestRun";
import { DiagnosticJobType } from "../../../../../repository/use-case/developer-console/DiagnosticJobType";
import { ContentDiscrepanciesLatestJob } from "./ContentDiscrepanciesLatestJob";
import { InformationBanner } from "../../../../info/InformationBanner";
import {
  Checkbox,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@material-ui/core";
import React, { useCallback } from "react";
import { useDiagnosticsViewModel } from "../useDiagnosticsViewModel";
import { DiagnosticJobStatus } from "../../../../../repository/use-case/developer-console/DiagnosticJobStatus";

export const ContentDiscrepanciesMenu = () => {
  const [viewState, viewModel] = useDiagnosticsViewModel();
  const renderLatestJobRun = useCallback(
    (jobStatus: DiagnosticJobStatus) => (
      <ContentDiscrepanciesLatestJob
        jobStatus={jobStatus}
        createdAtMillis={
          viewState.reportLCE.content?.[0]?.creationInfo?.createdAtMillis
        }
        onClickViewReport={viewModel.onClickViewReport}
      />
    ),
    [viewModel.onClickViewReport, viewState.reportLCE.content]
  );
  return (
    <>
      <DiagnosticJobLatestRun
        jobType={DiagnosticJobType.JOB_TYPE_CONTENT_DISCREPANCIES}
        renderContent={renderLatestJobRun}
      />
      <InformationBanner
        message={
          "Keep in mind, diagnostic are expensive since they read the entire\n" +
          "            collection of the content so use it sparingly."
        }
        style={{
          marginTop: 8
        }}
      />
      <Typography style={{ marginTop: 16 }}>
        Select all content types you wish to diagnose.
      </Typography>
      <List
        style={{
          marginTop: 8
        }}
      >
        {Object.keys(viewState.contentItemsSelections).map(contentKey => (
          <ListItem>
            <ListItemText>
              {viewState.contentItemsSelections[contentKey].label}
            </ListItemText>
            <ListItemSecondaryAction>
              <Checkbox
                color="primary"
                checked={viewState.contentItemsSelections[contentKey].selected}
                onClick={() => {
                  viewModel.onToggleContentDiagnosticItemSelection(contentKey);
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </>
  );
};
