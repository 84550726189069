import { isObject, isUndefined } from "lodash";

export function usedObjectPathsProxy<T extends object>(
  object: T,
  callback: (accessedObjectPath: string) => void,
  allowPropertiesNotInTarget: boolean = false
) {
  return usedObjectPathsProxyRecursive(
    new Map(),
    "",
    object,
    callback,
    allowPropertiesNotInTarget
  );
}

function usedObjectPathsProxyRecursive<T extends object>(
  proxyCache: Map<string, object>,
  parentPath: string,
  object: T,
  callback: (accessedObjectPath: string) => void,
  allowPropertiesNotInTarget: boolean
) {
  return new Proxy(object, {
    get(target: T, p: PropertyKey, receiver: any): any {
      if (!allowPropertiesNotInTarget && !(p in target)) {
        return;
      }

      const propertyValue = target[p as keyof typeof target];

      const descriptor = Object.getOwnPropertyDescriptor(target, p);
      if (descriptor?.writable === false) {
        return propertyValue;
      }

      const fullPath = parentPath + p.toString();

      if (
        !isUndefined(propertyValue) &&
        isObject(propertyValue) &&
        !Array.isArray(propertyValue)
      ) {
        if (!proxyCache.get(fullPath)) {
          proxyCache.set(
            fullPath,
            usedObjectPathsProxyRecursive(
              proxyCache,
              fullPath + ".",
              propertyValue,
              callback,
              allowPropertiesNotInTarget
            )
          );
        }

        callback(fullPath);

        return proxyCache.get(fullPath);
      }

      callback(fullPath);

      return propertyValue;
    },

    set(target: T, p: PropertyKey, value: any): boolean {
      if (!allowPropertiesNotInTarget && !(p in target)) {
        return false;
      }

      const fullPath = parentPath + p.toString();
      if (proxyCache.has(fullPath)) {
        proxyCache.delete(fullPath);
        if (!isUndefined(value) && isObject(value)) {
          proxyCache.set(
            fullPath,
            usedObjectPathsProxyRecursive(
              proxyCache,
              fullPath + ".",
              value,
              callback,
              allowPropertiesNotInTarget
            )
          );
        }
      }

      target[p as keyof typeof target] = value;

      return true;
    },

    deleteProperty(target: T, p: PropertyKey): boolean {
      if (!allowPropertiesNotInTarget && !(p in target)) {
        return false;
      }

      proxyCache.delete(parentPath + p.toString());
      delete target[p as keyof typeof target];

      return true;
    },

    ownKeys(target) {
      return Object.keys(object);
    },

    getOwnPropertyDescriptor(k) {
      return {
        enumerable: true,
        configurable: true,
        writable: true
      };
    }
  });
}
