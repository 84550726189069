import firebase from "firebase";

export enum RealtimeSystems {
  ANNOUNCEMENTS = "announcements",
  FILE_MANAGEMENT = "file_management"
}

export interface RealtimeIntentPayload {
  name: string;
  [key: string]: any;
}

export class FirestoreRealtimeAPI {
  constructor(private readonly firestore: firebase.firestore.Firestore) {}

  async postCommand(
    system: RealtimeSystems,
    identifier: string,
    payload: RealtimeIntentPayload
  ): Promise<void> {
    await this.firestore
      .collection(`realtime/systems/${system}/${identifier}/commands`)
      .add(payload);
  }
}
