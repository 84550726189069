import React from "react";
import ContentDiff from "../diff/ContentDiff";
import { Locale } from "../../../locale";
import ContentDiffData from "../diff/ContentDiffData";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import { ListItem } from "@material-ui/core";

const ContentStatisticsDetails = ({
  headers,
  contentDiffData,
  defaultLocale,
  commitSelection,
  resourceName
}: {
  headers: {
    [fieldKey: string]: {
      title: string;
      isImage?: boolean;
      isUrl?: boolean;
    };
  };
  contentDiffData: Map<Locale, ContentDiffData>[];
  defaultLocale: Locale | undefined;
  commitSelection: {
    enabled: boolean;
    selectedCommits: Map<string, { resourceName: string; locale: string }>;
    onChange: (
      resourceName: string,
      locale: string,
      commitId: string,
      isSelected: boolean
    ) => void;
  };
  resourceName: string;
}) => {
  const renderItem = React.useCallback(
    ({ index, style }) => (
      <ListItem
        divider={index < contentDiffData.length - 1}
        style={{ ...style, overflowY: "auto" }}
      >
        <ContentDiff
          localeToDiffData={contentDiffData[index]}
          headers={Object.keys(headers).map(key => ({
            key,
            isImage: headers[key].isImage,
            isUrl: headers[key].isUrl
          }))}
          defaultLocale={defaultLocale}
          commitSelection={commitSelection}
          resourceName={resourceName}
        />
      </ListItem>
    ),
    [commitSelection, contentDiffData, defaultLocale, headers]
  );
  const renderAutoSizeList = React.useCallback(
    ({ height, width }) => (
      <List
        height={height}
        width={width}
        itemCount={contentDiffData.length}
        itemSize={150}
      >
        {renderItem}
      </List>
    ),
    [contentDiffData.length, renderItem]
  );
  const autoSizerStyle = React.useMemo(() => ({ height: "1000px" }), []);
  return <AutoSizer style={autoSizerStyle}>{renderAutoSizeList}</AutoSizer>;
};

export default React.memo(ContentStatisticsDetails);
