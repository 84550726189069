import { Locale } from "../../../../../locale";
import FormField from "../FormField";
import { extractFirstLocalizedProp } from "../../../../../utils/data/resources/managed";
import { FormFieldType } from "../FormFieldType";
import { OptionalProperties } from "../../../../../utils/generics/OptionalProperties";
import { ManagedMediaPlatform } from "../../../../../data/ManagedMediaPlatform";
import { MediaPlatformFields } from "../../../../../api/content/MediaPlatform";

const createMediaPlatformFormFields: (
  currentLocale: Locale
) => ({
  resource
}: {
  resource?: ManagedMediaPlatform | undefined;
}) => OptionalProperties<FormField, "validate">[] = currentLocale => ({
  resource
} = {}) => [
  {
    id: MediaPlatformFields.NAME,
    label: "Name",
    defaultValue:
      resource &&
      extractFirstLocalizedProp(
        currentLocale,
        resource,
        MediaPlatformFields.NAME
      ),
    type: FormFieldType.TEXT
  },
  {
    id: MediaPlatformFields.ICON,
    label: "Icon (link)",
    defaultValue:
      resource &&
      extractFirstLocalizedProp(
        currentLocale,
        resource,
        MediaPlatformFields.ICON
      ),
    type: FormFieldType.TEXT
  }
];

export default createMediaPlatformFormFields;
