import { Moment } from "moment-timezone";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { NewRangeEventForm } from "./NewRangeEventForm";
import { Grid, TextField } from "@material-ui/core";
import { CreatableEventType } from "../CreatableEventType";
import Chip from "@material-ui/core/Chip";
import { Autocomplete } from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import { CreateEventEntry } from "../CreatableEventEntry";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FORM_PROGRESS_SIZE_PX, useStyles } from "../styles";
import { Chance } from "chance";
import { ColorPicker } from "material-ui-color";
import { EventFormData } from "./EventFormData";
import { usePrevious } from "../../../hooks/usePrevious";

export const EditEventForm = ({
  currentForm,
  onDatesUpdated,
  creatableEventTypes,
  listCreatableEventEntries,
  onSelectedCreatableEvent,
  onEventTitleChanged,
  onColorChanged,
  momentProvider
}: {
  currentForm: EventFormData;
  onDatesUpdated: (newDates: Moment[]) => void;
  creatableEventTypes: CreatableEventType[];
  listCreatableEventEntries: (
    eventType: CreatableEventType
  ) => Promise<CreateEventEntry[]>;
  onSelectedCreatableEvent: (
    eventType: CreatableEventType | undefined,
    eventEntry: CreateEventEntry | null
  ) => void;
  onEventTitleChanged: (newTitle: string) => void;
  onColorChanged: (color: string) => void;
  momentProvider: () => Moment;
}) => {
  const [selectedEventType, selectEventType] = useState<
    CreatableEventType | undefined
  >(creatableEventTypes.length ? creatableEventTypes[0] : undefined);
  const [selectedEntry, setSelectedEntry] = useState<
    CreateEventEntry | undefined | null
  >({ id: "" });

  const [isLoadingEventEntries, setLoadingEventEntries] = useState(false);
  const [creatableEventEntries, setCreatableEventEntries] = useState<
    CreateEventEntry[]
  >([]);
  const previousSelectedEventType = usePrevious<CreatableEventType | undefined>(
    selectedEventType
  );
  useEffect(() => {
    (async () => {
      try {
        if (
          selectedEventType &&
          selectedEventType?.id !== previousSelectedEventType?.id
        ) {
          setLoadingEventEntries(true);
          const createEventEntries = await listCreatableEventEntries(
            selectedEventType
          );
          setCreatableEventEntries(createEventEntries);
          if (currentForm.eventCandidate?.eventEntry?.id) {
            const matchingEntry = createEventEntries.find(
              eventEntry =>
                eventEntry.id === currentForm.eventCandidate?.eventEntry?.id
            );
            setSelectedEntry(matchingEntry);
          }
          setLoadingEventEntries(false);
        }
      } catch (e: any) {
        console.error(e);
      }
    })();
  }, [
    currentForm.eventCandidate,
    listCreatableEventEntries,
    selectedEventType
  ]);

  const onSelectedEntryChange = useCallback(
    (_: any, value: CreateEventEntry | null) => {
      onSelectedCreatableEvent(selectedEventType, value);
      setSelectedEntry(value);
    },
    [onSelectedCreatableEvent, selectedEventType]
  );

  const [suggestedTitle, setSuggestedTitle] = useState<string | undefined>();
  const [title, setTitle] = useState<string | undefined>();
  const onTitleChanged = useCallback(
    (element: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTitle(element.target.value);
    },
    []
  );

  useEffect(() => {
    const preferredTitle = title ?? currentForm.title ?? suggestedTitle;
    if (preferredTitle !== undefined) {
      onEventTitleChanged(preferredTitle);
    }
  }, [currentForm.title, onEventTitleChanged, suggestedTitle, title]);
  useEffect(() => {
    if (selectedEventType?.title && selectedEntry?.title) {
      setSuggestedTitle(`${selectedEventType.title} - ${selectedEntry.title}`);
    }
  }, [selectedEntry, selectedEventType]);

  useEffect(() => {
    if (!currentForm.color) {
      onColorChanged(
        selectedEventType?.defaultColor ??
          Chance().color({ format: "hex", casing: "upper" })
      );
    }
  }, [currentForm.color, onColorChanged, selectedEventType]);
  const onColorSelected = useCallback(
    color => onColorChanged(`#${color.hex}`),
    [onColorChanged]
  );

  const classes = useStyles();
  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <NewRangeEventForm
          selectedDates={
            currentForm.selectedDates.length
              ? currentForm.selectedDates
              : [momentProvider().startOf("day"), momentProvider().endOf("day")]
          }
          handleDatesChange={onDatesUpdated}
          momentProvider={momentProvider}
        />
      </MuiPickersUtilsProvider>
      <Grid container direction="row" spacing={1} alignItems="center">
        <Grid item>
          <Typography variant="h6" className={classes.formSectionSpacing}>
            Event Details
          </Typography>
        </Grid>
        {isLoadingEventEntries ? (
          <Grid item>
            <CircularProgress size={FORM_PROGRESS_SIZE_PX} />
          </Grid>
        ) : null}
      </Grid>
      <Grid container spacing={1} className={classes.eventItemSpacing}>
        {creatableEventTypes.map(eventType => (
          <Grid item>
            <Chip
              clickable
              label={eventType.title}
              color={selectedEventType === eventType ? "primary" : "default"}
              onClick={() => {
                selectEventType(eventType);
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Autocomplete
        className={classes.eventItemSpacing}
        options={creatableEventEntries}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label={isLoadingEventEntries ? "Loading" : "Select Event"}
            variant="outlined"
            disabled={isLoadingEventEntries}
            fullWidth
            inputProps={{
              ...params.inputProps,
              autoComplete: "disabled"
            }}
          />
        )}
        value={selectedEntry}
        getOptionSelected={(a, b) => a.id === b.id}
        onChange={onSelectedEntryChange}
        getOptionLabel={eventEntry => eventEntry.title ?? ""}
        renderOption={({ title }: CreateEventEntry) => (
          <Typography>{title}</Typography>
        )}
      />
      <TextField
        variant={"outlined"}
        value={currentForm.title ?? suggestedTitle ?? ""}
        onChange={onTitleChanged}
        label="Title"
        fullWidth
        className={classes.eventItemSpacing}
      />
      <Grid
        container
        direction="row"
        spacing={1}
        className={classes.eventItemSpacing}
        alignItems="center"
      >
        <Grid item>
          <Typography>Color:</Typography>
        </Grid>
        <Grid item>
          <ColorPicker
            disableTextfield
            value={currentForm.color}
            onChange={onColorSelected}
          />
        </Grid>
      </Grid>
      <Typography color="textSecondary">
        Title and color are used for the calendar and not presented to users.
      </Typography>
    </>
  );
};
