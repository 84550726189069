import {
  Announcement,
  isCardAppearance
} from "../../../../domain/announcements/entities/announcement";
import { AnnouncementsViewModel } from "../AnnouncementsViewModel";
import { AnnouncementState } from "../list/AnnouncementState";
import { Button, ButtonGroup, TextField } from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Moment } from "moment-timezone";
import React from "react";
import moment from "moment";
import { BrokenImage } from "@material-ui/icons";

export const announcementRenderByProperty: {
  [K in keyof Announcement]?: {
    label: string;
    renderStatic?: (value: Announcement[K]) => JSX.Element | string;
    renderDynamic?: (
      value: Announcement[K],
      viewModel: AnnouncementsViewModel
    ) => JSX.Element;
    info?: string;
    renderDetails?: boolean;
  };
} = {
  active: {
    label: "Status",
    renderStatic: active => <AnnouncementState active={active} />,
    renderDynamic: (active, viewModel) => (
      <ButtonGroup variant={"outlined"} size={"small"} color={"primary"}>
        <Button
          variant={active ? "contained" : "outlined"}
          onClick={() => viewModel.onEditAnnouncementProperty("active", true)}
        >
          Active
        </Button>
        <Button
          variant={active ? "outlined" : "contained"}
          onClick={() => viewModel.onEditAnnouncementProperty("active", false)}
        >
          Inactive
        </Button>
      </ButtonGroup>
    )
  },
  createdAt: {
    label: "Created",
    renderStatic: renderDate
  },
  updatedAt: {
    label: "Updated",
    renderStatic: renderDate
  },
  expiresAt: {
    label: "Expires",
    renderStatic: renderDate,
    renderDynamic: (expiresAt, viewModel) => {
      return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DateTimePicker
            ampm={false}
            value={expiresAt}
            onChange={(date: MaterialUiPickersDate) => {
              console.log("change:", date);
              viewModel.onEditAnnouncementProperty(
                "expiresAt",
                (date as Moment).utc().valueOf()
              );
            }}
          />
        </MuiPickersUtilsProvider>
      );
    }
  },
  appearance: {
    label: "Appearance",
    renderStatic: appearance => {
      if (isCardAppearance(appearance)) {
        if (!appearance.imageUrl.trim()) {
          return <BrokenImage fontSize={"large"} />;
        }
        return (
          <img
            src={appearance.imageUrl}
            width={128}
            height={128}
            style={{
              objectFit: "cover"
            }}
          />
        );
      }
      return <></>;
    },
    renderDynamic: (appearance, viewModel) => {
      if (isCardAppearance(appearance)) {
        return (
          <TextField
            defaultValue={appearance.imageUrl}
            onBlur={event => {
              viewModel.onEditAnnouncementProperty("appearance", {
                ...appearance,
                imageUrl: event.target.value
              });
            }}
            style={{ width: "100%" }}
            variant={"outlined"}
          />
        );
      }
      return <></>;
    }
  }
};

function renderDate(timeInMillis?: number) {
  return moment(timeInMillis).format("DD/MM/YYYY HH:mm UTC");
}
