import FormField from "../FormField";
import {
  extractFirstLocalizedProp,
  extractId,
  extractLocalizedProps
} from "../../../../../utils/data/resources/managed";
import { FormFieldType } from "../FormFieldType";
import { Locale } from "../../../../../locale";
import { OptionalProperties } from "../../../../../utils/generics/OptionalProperties";
import { CommitResource } from "../../../../../api/commits/Commit";
import ManagedResource from "../../../../../data/ManagedResource";
import { authorLinkOption } from "./authorLinkOption";
import { platformLinkOption } from "./platformLinkOption";
import React from "react";
import { Avatar } from "@material-ui/core";
import { Resources } from "../../../../../data/Resources";
import { LocalizedProp } from "../../../../../types/domainTypes";
import { indexBy } from "../../../../../utils/arrays/indexBy";
import MediaPlatform, {
  MediaPlatformFields
} from "../../../../../api/content/MediaPlatform";
import { MediaFeedFields } from "../../../../../api/content/MedaFeedContent";
import ManagedMediaFeedContent, {
  MediaFeedContentFields
} from "../../../../../data/ManagedMediaFeedContent";
import { categoryLinkOption } from "./categoriesLinkOption";

const createMediaFeedFormFields: (
  currentLocale: Locale,
  managedContent: Map<CommitResource, Map<Locale, ManagedResource<any>[]>>
) => ({
  resource,
  locale
}: {
  locale: Locale;
  resource?: ManagedMediaFeedContent | undefined;
}) => OptionalProperties<FormField, "validate">[] = (
  currentLocale,
  managedContent
) => ({ resource, locale }) => {
  const platforms = (managedContent
    .get(Resources.MEDIA_PLATFORM)
    ?.get(currentLocale) || []) as ManagedResource<MediaPlatform>[];
  const indexedPlatformsById = indexBy(platforms, managedResource =>
    extractId(managedResource)
  );
  const indexedPlatformsByName = indexBy(
    platforms,
    managedResource =>
      extractFirstLocalizedProp(
        currentLocale,
        managedResource,
        MediaPlatformFields.NAME
      ).value
  );
  const linkByMediaFeedPlatformId = new Map<string, LocalizedProp<string>>();
  if (resource) {
    extractLocalizedProps(
      currentLocale,
      resource,
      MediaFeedContentFields.PLATFORM
    ).forEach(({ value: platformName }, index) => {
      const platform = indexedPlatformsByName.get(platformName ?? "");
      if (platform) {
        linkByMediaFeedPlatformId.set(
          extractId(platform),
          extractLocalizedProps(
            currentLocale,
            resource,
            MediaFeedFields.PLATFORM_LINK
          )?.[index]
        );
      }
    });
  }
  return [
    {
      id: MediaFeedFields.NAME,
      label: "Name",
      defaultValue:
        resource &&
        extractFirstLocalizedProp(
          currentLocale,
          resource,
          MediaFeedFields.NAME
        ),
      type: FormFieldType.TEXT
    },
    {
      id: MediaFeedFields.IMAGE,
      label: "Image (link)",
      defaultValue:
        resource &&
        extractFirstLocalizedProp(
          currentLocale,
          resource,
          MediaFeedFields.IMAGE
        ),
      type: FormFieldType.TEXT
    },
    {
      id: MediaFeedFields.DESCRIPTION,
      label: "Description",
      defaultValue:
        resource &&
        extractFirstLocalizedProp(
          currentLocale,
          resource,
          MediaFeedFields.DESCRIPTION
        ),
      type: FormFieldType.TEXT
    },
    platformLinkOption({
      locale,
      currentLocale,
      resource,
      linkedFieldName: MediaFeedContentFields.PLATFORM,
      managedContent
    }),
    {
      id: MediaFeedFields.PLATFORM_LINK,
      label: "Links",
      type: FormFieldType.TEXT_MAP,
      formFieldOptions: {
        dependencies: [Resources.MEDIA_PLATFORM],
        dataFactory: (dependencies: { [id: string]: string[] }) => {
          return (dependencies[Resources.MEDIA_PLATFORM] ?? [])
            .filter(platformId => indexedPlatformsById.has(platformId))
            .map(platformId => {
              const platform = indexedPlatformsById.get(platformId);
              return {
                key: platformId,
                value: {
                  value: linkByMediaFeedPlatformId.get(platformId),
                  label:
                    platform &&
                    extractFirstLocalizedProp(
                      currentLocale,
                      platform,
                      MediaPlatformFields.NAME
                    ).value
                }
              };
            });
        },
        renderKey: (id: string) => {
          const platform = indexedPlatformsById.get(id);
          return (
            <Avatar
              src={
                platform &&
                extractFirstLocalizedProp(
                  currentLocale,
                  platform,
                  MediaPlatformFields.ICON
                ).value
              }
            />
          );
        }
      }
    },
    authorLinkOption({
      locale,
      currentLocale,
      managedContent,
      resource,
      linkedFieldName: MediaFeedContentFields.AUTHOR
    }),
    categoryLinkOption({
      managedContent,
      resource,
      locale,
      currentLocale,
      linkedFieldName: MediaFeedContentFields.CATEGORY
    })
  ];
};
export default createMediaFeedFormFields;
