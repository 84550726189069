import { Typography } from "@material-ui/core";
import React from "react";
import Box from "@material-ui/core/Box";

export const EmptyFolderContents = () => {
  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography>No files here, upload files or create new folder.</Typography>
    </Box>
  );
};
