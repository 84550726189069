import React from "react";
import { Message } from "../../../../repository/use-case/users-voice/dto/ListMessagesResponse";
import { MessageItem } from "./MessageItem";
import { List, ListItem, Paper } from "@material-ui/core";

export const MessagesList = ({ messages }: { messages: Message[] }) => {
  return (
    <Paper>
      <List>
        {messages.map(message => (
          <ListItem>
            <MessageItem message={message} />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};
