import { PropsWithChildren, useCallback, useMemo } from "react";
import React from "react";
import { ManipulateChildren } from "../manipulate-children/ManipulateChildren";
import { Popover } from "@material-ui/core";
import { PopoverProps } from "@material-ui/core/Popover/Popover";

export interface PopoverWrapperProps {
  popoverProps?: Partial<
    Omit<PopoverProps, "open" | "anchorEl" | "anchorPosition">
  >;
  render: () => JSX.Element;
}

export const PopoverWrapper = (
  props: PropsWithChildren<PopoverWrapperProps>
) => {
  const [
    popoverElement,
    setPopoverElement
  ] = React.useState<HTMLElement | null>(null);
  const onClosePopover = useCallback(() => {
    setPopoverElement(null);
  }, []);
  const popoverContent = useMemo(() => props.render(), [props]);
  return (
    <>
      <ManipulateChildren
        children={props.children}
        injectProps={() => {
          return {
            onClick: (element: React.MouseEvent<HTMLElement, MouseEvent>) => {
              setPopoverElement(element.currentTarget);
            }
          };
        }}
      />
      <Popover
        open={!!popoverElement}
        anchorEl={popoverElement}
        onClose={onClosePopover}
        anchorOrigin={
          props.popoverProps?.anchorOrigin ?? {
            vertical: "bottom",
            horizontal: "left"
          }
        }
        transformOrigin={
          props.popoverProps?.transformOrigin ?? {
            vertical: "top",
            horizontal: "right"
          }
        }
      >
        {popoverContent}
      </Popover>
    </>
  );
};
