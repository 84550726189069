import compose from "../../utils/compose";
import withResourceManagement, {
  ManageResourcesPageProps
} from "../hoc/withResourceManagement";
import managedResourceMappers from "../../data/mappers/resources/content-mappers";
import * as React from "react";
import { useCallback, useState } from "react";
import ContentManagementPage from "./content-management/ContentManagementPage";
import commonActions, {
  ARCHIVE_ACTION,
  UN_ARCHIVE_ACTION
} from "../content/actions/common-actions";
import ContentManagementPageProps from "./content-management/ContentManagementPageProps";
import { CommitResource } from "../../api/commits/Commit";
import CreateFAB from "../buttons/CreateFAB";
import Cache from "../../utils/cache/Cache";
import ManagedContent from "../../data/ManagedContent";
import ContentRow from "../content/row/ContentRow";
import { extractId } from "../../utils/data/resources/managed";
import ScrollableContent from "../content/ScrollableContent";
import { ContentItemViewModel } from "../content/ContentItemViewModel";
import { merge, transformValues } from "../../utils/maps";
import { Locale } from "../../locale";
import ManagedResource from "../../data/ManagedResource";
import LazyArray from "../../utils/arrays/lazyArray";
import { Resources } from "../../data/Resources";
import { ContentManagementPageHeader } from "./ContentManagementPageHeader";
import { ExportContentManagementDialog } from "./content-management/export/ExportContentManagementDialog";
import { useContentManagementSort } from "./useContentManagementSort";
import { ContentFilterOption } from "../../types/domainTypes";

export type GridContentManagementPageProps = Partial<
  Exclude<ContentManagementPageProps, "managedResourcesByLocale">
> & {
  resource: CommitResource;
  resources?: CommitResource[];
  renderViewModelContent?: (
    managedContent: ManagedContent<any>,
    locale: string,
    allContent: Map<CommitResource, ManagedResource<any>[]>
  ) => JSX.Element;
  renderContentItem?: (
    contentItemViewModel: ContentItemViewModel
  ) => JSX.Element;
  renderContentWithViewModels?: (
    viewModels: LazyArray<ContentItemViewModel>
  ) => void;
  formResourceName?: string;
  formLinkInfo?: Map<CommitResource, string>;
  showTableHeaders?: boolean;
  searchDebounce?: number;
  onCreateClicked?: () => void;
  managementActionsHook?(
    action: "revert" | "remove" | "update" | "archive" | "unarchive",
    payload: { resourceType: Resources; resource: ManagedResource<any> }
  ): void;
};

const PageWrapper = (
  props: ManageResourcesPageProps & GridContentManagementPageProps
) => {
  const [createCallback, setCreateCallback] = useState<{
    callback?: () => any;
  }>({});
  const addResourceCallback = useCallback(callback => {
    setCreateCallback({ callback });
  }, []);
  const fieldsUpdater = useCallback(
    async (resourceType, resource, updateData) => {
      props.updateFields(resourceType, resource, updateData);
      if (props.managementActionsHook) {
        props.managementActionsHook("update", { resourceType, resource });
      }
    },
    [props]
  );
  const changesReverter = useCallback(
    (resourceType, resource) => {
      props.revert(resourceType, resource);
      if (props.managementActionsHook) {
        props.managementActionsHook("revert", { resourceType, resource });
      }
    },
    [props]
  );
  const entryRemove = useCallback(
    (resourceType, resource) => {
      props.remove(resourceType, resource);
      if (props.managementActionsHook) {
        props.managementActionsHook("remove", { resourceType, resource });
      }
    },
    [props]
  );
  const entryArchive = useCallback(
    (resourceType, resource) => {
      props.archive(resourceType, resource);
      if (props.managementActionsHook) {
        props.managementActionsHook(ARCHIVE_ACTION, { resourceType, resource });
      }
    },
    [props]
  );
  const entryUnarchive = useCallback(
    (resourceType, resource) => {
      props.unarchive(resourceType, resource);
      if (props.managementActionsHook) {
        props.managementActionsHook(UN_ARCHIVE_ACTION, {
          resourceType,
          resource
        });
      }
    },
    [props]
  );

  const [filters, setFilters] = useState<ContentManagementPageProps["filters"]>(
    {}
  );
  const onSearchTermChanged = useCallback(searchTerm => {
    setFilters(currentFilters => ({
      ...currentFilters,
      searchTerm
    }));
  }, []);
  const onFilterToggle = useCallback(
    (contentFilterOption: ContentFilterOption) => {
      setFilters(currentFilters => {
        let activeOptions = currentFilters?.activeOptions ?? [];
        const isFilterActive = currentFilters?.activeOptions?.some(
          option => option.label === contentFilterOption.label
        );
        if (isFilterActive) {
          activeOptions = activeOptions.filter(
            option => option.label !== contentFilterOption.label
          );
        } else {
          activeOptions = [...activeOptions, contentFilterOption];
        }
        return {
          ...currentFilters,
          activeOptions
        };
      });
    },
    []
  );
  const onArchivedContentClicked = useCallback(() => {
    setFilters(currentFilters => ({
      ...currentFilters,
      showArchivedContent: !currentFilters?.showArchivedContent
    }));
  }, []);

  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const onExportClick = useCallback(() => {
    setExportDialogOpen(true);
  }, []);
  const onCloseExportDialogClick = useCallback(() => {
    setExportDialogOpen(false);
  }, []);

  const sortInfo = useContentManagementSort(props.resource);
  const renderHeader = useCallback(
    () => (
      <ContentManagementPageHeader
        filter={
          props.filterOptions && {
            options: props.filterOptions,
            activeOptions: filters?.activeOptions ?? []
          }
        }
        onFilterToggle={onFilterToggle}
        searchFields={props.searchFields}
        searchDebounce={props.searchDebounce}
        onSearchTermChanged={onSearchTermChanged}
        resource={props.resource}
        managedContent={props.managedContent}
        showTableHeaders={props.showTableHeaders}
        onArchivedContentClicked={onArchivedContentClicked}
        archiveEnabled={!!filters?.showArchivedContent}
        onExportClick={onExportClick}
        sortInfo={sortInfo}
      />
    ),
    [
      props.filterOptions,
      props.searchFields,
      props.searchDebounce,
      props.resource,
      props.managedContent,
      props.showTableHeaders,
      filters,
      onFilterToggle,
      onSearchTermChanged,
      onArchivedContentClicked,
      onExportClick,
      sortInfo
    ]
  );
  const onCreateClick = useCallback(() => {
    if (props.onCreateClicked) {
      props.onCreateClicked();
    } else {
      createCallback.callback?.();
    }
  }, [createCallback.callback, props]);
  const renderFooter = useCallback(
    () => !props.isContentLock && <CreateFAB onClick={onCreateClick} />,
    [onCreateClick, props.isContentLock]
  );
  const contentCache = React.useMemo(
    () =>
      new Cache(
        (content: ManagedContent<any>) =>
          new ContentItemViewModel(
            props.renderViewModelContent?.(
              content,
              props.locale,
              props.managedContent
            ) ?? <></>,
            content.contentActions,
            content.modificationType,
            content.ownerTimeline,
            content.updatedAt
          )
      ),
    [props.locale, props.managedContent, props.renderViewModelContent]
  );
  const renderContentItem = useCallback(
    content => {
      const viewModel = contentCache.get(content);
      return props.renderContentItem ? (
        props.renderContentItem(viewModel)
      ) : (
        <ContentRow viewModel={viewModel} />
      );
    },
    [contentCache, props]
  );
  const keyResolver = useCallback(
    (index, item: ManagedContent<any>) =>
      (item?.managedResource && extractId(item.managedResource)) ?? index,
    []
  );
  const renderContent = useCallback(
    managedContent => {
      return props.renderContentWithViewModels ? (
        props.renderContentWithViewModels(
          new LazyArray<ContentItemViewModel>(managedContent.length, () =>
            contentCache.get(managedContent)
          )
        )
      ) : (
        <ScrollableContent
          data={managedContent}
          renderContentItem={renderContentItem}
          keyResolver={keyResolver}
        />
      );
    },
    [contentCache, keyResolver, props, renderContentItem]
  );
  const formFields = props.formFieldFactory();
  const editResourceFields = useCallback(
    (resourceType: Resources) => (
      locale: Locale,
      resource?: ManagedResource<any>
    ) =>
      formFields.get(resourceType ?? props.resource)?.({
        locale,
        resource
      }) || [],
    [formFields, props.resource]
  );
  const addResourceFields = useCallback(
    (resourceType: Resources) => (
      locale: string,
      resource?: ManagedResource<any>
    ) =>
      formFields.get(resourceType ?? props.resource)?.({ locale, resource }) ||
      [],
    [formFields, props.resource]
  );
  const formInfo = React.useMemo(() => {
    return merge(
      new Map(
        (props.resources ?? [props.resource]).map(resourceType => [
          resourceType,
          {
            name: props.formResourceName ?? resourceType,
            editFields: editResourceFields(resourceType),
            addFields: addResourceFields(resourceType)
          }
        ])
      ),
      transformValues(
        props.formLinkInfo ?? new Map<CommitResource, string>(),
        (name, resource) => ({
          name,
          editFields: (locale, managedResource) =>
            formFields.get(resource)?.({
              locale,
              resource: managedResource
            }) || [],
          addFields: (locale, managedResource) =>
            formFields.get(resource)?.({
              locale,
              resource: managedResource
            }) || []
        })
      )
    );
  }, [
    addResourceFields,
    editResourceFields,
    formFields,
    props.formLinkInfo,
    props.formResourceName,
    props.resource,
    props.resources
  ]);
  return (
    <>
      <ContentManagementPage
        isUpdating={props.isUpdating}
        updateSuccess={props.updateSuccess}
        supportedActions={props.supportedActions ?? commonActions}
        addResourceCallback={props.addResourceCallback ?? addResourceCallback}
        managedResourcesByLocale={
          props.managedContentByLocale.get(props.resource) ?? new Map()
        }
        managedContentByLocale={props.managedContentByLocale}
        fieldsUpdater={fieldsUpdater}
        changesReverter={changesReverter}
        entryRemove={entryRemove}
        entryArchive={entryArchive}
        entryUnarchive={entryUnarchive}
        renderHeader={props.renderHeader ?? renderHeader}
        renderContent={props.renderContent ?? renderContent}
        renderFooter={props.renderFooter ?? renderFooter}
        mainResource={props.resource}
        formInfo={props.formInfo ?? formInfo}
        isContentLock={props.isContentLock}
        isPublishing={props.isPublishing}
        locale={props.locale}
        defaultLocale={props.defaultLocale}
        searchFields={props.searchFields}
        filters={filters}
        onCustomActionClicked={props.onCustomActionClicked}
        sortInfo={sortInfo}
      />
      <ExportContentManagementDialog
        open={exportDialogOpen}
        resource={props.resource}
        onCloseClick={onCloseExportDialogClick}
      />
    </>
  );
};

const BaseContentManagementPage = compose(
  withResourceManagement(managedResourceMappers),
  React.memo
)(PageWrapper) as React.ComponentType<GridContentManagementPageProps>;

export default BaseContentManagementPage;
