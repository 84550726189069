import { isFunction, isObject, isUndefined } from "lodash";

export function forwardProxy<T extends object>(currentValue: () => T): T {
  return new Proxy({} as T, {
    get(target: T, p: PropertyKey): any {
      const value = currentValue()[p as keyof T];

      const descriptor = Object.getOwnPropertyDescriptor(target, p);
      if (descriptor?.writable === false) {
        return value;
      }

      if (
        !isUndefined(value) &&
        !Array.isArray(value) &&
        !isFunction(value) &&
        isObject(value)
      ) {
        return forwardProxy(() => currentValue()[p as keyof T] as any);
      }
      return value;
    },

    ownKeys(target) {
      return Object.keys(currentValue());
    },
    getOwnPropertyDescriptor(k) {
      return {
        enumerable: true,
        configurable: true,
        writable: true
      };
    }
  });
}
