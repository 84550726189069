import { Theme } from "@material-ui/core";

const pageContainer = (theme: Theme) => ({
  margin: "2vh 2vw",
  display: "flex",
  height: "calc(100% - 4vh)",
  width: "calc(100% - 4vw)"
});

export default pageContainer;
