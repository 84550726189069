const ELLIPSE_LENGTH = 3;

export const trimLongText = ({
  text,
  maxCharacters = 120,
  ellipseLength = ELLIPSE_LENGTH
}: {
  text: string;
  maxCharacters?: number;
  ellipseLength?: number;
}) => {
  if (text.length > maxCharacters) {
    return `${text.substr(0, Math.max(0, maxCharacters - ellipseLength))}...`;
  }
  return text;
};
