import makeStyles from "@material-ui/core/styles/makeStyles";
import { contentCircles } from "../../../styles/summaryCircles";

const expansionPanelStyle = {
  flexGrow: 1,
  display: "flex",
  overflow: "auto",
  margin: 0,
  flexFlow: "column"
};

export const useStyles = makeStyles(theme => ({
  statisticsItemContainer: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
    width: "100%"
  },
  ...contentCircles(theme),
  headersSpacer: {
    height: "0.5vh"
  },
  contentDiffDivider: {
    width: "100%"
  },
  expansionPanel: {
    ...expansionPanelStyle
  },
  expansionPanelWithMargins: {
    ...expansionPanelStyle,
    marginTop: 8
  },
  contentDetailsRowItem: {
    width: "100%",
  }
}));
