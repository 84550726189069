import makeStyles from "@material-ui/core/styles/makeStyles";
import pageContainer from "../../../styles/pageContainer";

const useStyles = makeStyles(theme => ({
  container: {
    ...pageContainer(theme)
  },
  column: { overflow: "auto", height: "100%" }
}));

export default useStyles;
