import { Chip, Grid, ListItem, Tooltip } from "@material-ui/core";
import SearchInput from "../search/SearchInput";
import Paper from "@material-ui/core/Paper";
import ContentHeader from "../content/row/ContentHeader";
import { getContentHeadersForResource } from "../../utils/data/resources/getContentHeadersForResource";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { ContentFilterOption, SearchFields } from "../../types/domainTypes";
import { CommitResource } from "../../api/commits/Commit";
import ManagedResource from "../../data/ManagedResource";
import { Archive, FilterList, GetApp, Sort } from "@material-ui/icons";
import { SortPopover } from "./SortPopover";
import { ContentManagementSortTypes } from "./content-management-sort-types";
import { Resources } from "../../data/Resources";
import { QuoteFields } from "../../api/content/Quote";
import { AuthorFields } from "../../api/content/Author";
import { CategoryFields } from "../../api/content/Category";
import { MediaPlatformFields } from "../../api/content/MediaPlatform";
import { WeeklyTipFields } from "../../api/content/WeeklyTip";
import { MediaFeedFields } from "../../api/content/MedaFeedContent";
import { PopoverWrapper } from "../popover/PopoverWrapper";
import { FilterPopover } from "./FilterPopover";

export const ContentManagementPageHeader = (props: {
  searchFields?: SearchFields;
  filter?: {
    options: ContentFilterOption[];
    activeOptions: ContentFilterOption[];
  };
  onFilterToggle?: (filer: ContentFilterOption) => void;
  searchDebounce?: number;
  onSearchTermChanged: (searchTerm: string) => void;
  onArchivedContentClicked: () => void;
  onExportClick: () => void;
  resource: CommitResource;
  managedContent: Map<CommitResource, ManagedResource<any>[]>;
  showTableHeaders?: boolean;
  archiveEnabled: boolean;
  sortInfo: {
    type?: ContentManagementSortTypes;
    field?: string;
    property?: "createdAt" | "updatedAt";
  };
}) => {
  const archivedCount = useMemo(() => {
    return (
      props.managedContent
        .get(props.resource)
        ?.filter(resource => resource.archived)?.length ?? 0
    );
  }, [props.managedContent, props.resource]);
  const contentCount = useMemo(() => {
    return props.managedContent.get(props.resource)?.length ?? 0;
  }, [props.managedContent, props.resource]);
  const [
    sortPopoverElement,
    setSortPopoverElement
  ] = React.useState<HTMLDivElement | null>(null);
  const onSortClick = useCallback(
    (element: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setSortPopoverElement(element.currentTarget);
    },
    []
  );
  const onCloseSortPopover = useCallback(() => {
    setSortPopoverElement(null);
  }, []);
  const sortableFields = useMemo(() => {
    return sortFieldsByResource(props.resource);
  }, [props.resource]);
  return (
    <>
      <Grid container alignItems="center" spacing={1}>
        {props.searchFields && (
          <Grid item>
            <SearchInput
              onSearchChanged={props.onSearchTermChanged}
              margin={"0px 0px 8px 0px"}
              debounce={props.searchDebounce}
            />
          </Grid>
        )}
        {props.filter?.options?.length && (
          <Grid item>
            <PopoverWrapper
              popoverProps={{
                anchorOrigin: {
                  vertical: "center",
                  horizontal: "right"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                }
              }}
              render={() => (
                <FilterPopover
                  filter={props.filter}
                  onFilterToggle={props.onFilterToggle}
                />
              )}
            >
              <Chip
                icon={<FilterList />}
                label={
                  props.filter.activeOptions.length
                    ? `${props.filter.activeOptions.length} filters`
                    : "Filter"
                }
                color={
                  props.filter.activeOptions.length ? "primary" : "default"
                }
                onClick={props.onArchivedContentClicked}
              />
            </PopoverWrapper>
          </Grid>
        )}
        <Grid item>
          <Chip label={`${contentCount} ${props.resource}`} />
        </Grid>
        {archivedCount || props.archiveEnabled ? (
          <Grid item>
            <Chip
              icon={<Archive />}
              label={`${archivedCount} archived`}
              onClick={props.onArchivedContentClicked}
              color={props.archiveEnabled ? "primary" : "default"}
            />
          </Grid>
        ) : (
          <></>
        )}
        {sortableFields.list.length ? (
          <Grid item>
            <Tooltip title={"Sort"}>
              <Chip
                icon={<Sort />}
                label={`Sort: ${props.sortInfo.field}${
                  props.sortInfo.property ? `, ${props.sortInfo.property}` : ""
                } - ${sortTypeToString(
                  props.sortInfo?.type ?? ContentManagementSortTypes.NONE
                )}`}
                onClick={onSortClick}
              />
            </Tooltip>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item>
          <Tooltip title={"Export to a CSV or JSON file."}>
            <Chip
              icon={<GetApp />}
              label={"Export"}
              onClick={props.onExportClick}
            />
          </Tooltip>
        </Grid>
      </Grid>
      {(props.showTableHeaders ?? true) && (
        <Paper>
          <ListItem>
            <ContentHeader
              headers={Object.values(
                getContentHeadersForResource(props.resource)
              ).map(header => header.title)}
            />
          </ListItem>
        </Paper>
      )}
      <SortPopover
        resource={props.resource}
        anchorElement={sortPopoverElement}
        onClose={onCloseSortPopover}
        defaultSortField={sortableFields.defaultField}
        sortableFields={sortableFields.list}
      />
    </>
  );
};

function sortTypeToString(sortType: ContentManagementSortTypes) {
  switch (sortType) {
    case ContentManagementSortTypes.NONE:
      return "none";
    case ContentManagementSortTypes.INCREASING:
      return "increasing (A-Z or 0-9)";
    case ContentManagementSortTypes.DECREASING:
      return "decreasing (Z-A or 9-0)";
  }
}

function sortFieldsByResource(resource: CommitResource) {
  switch (resource) {
    case Resources.QUOTES:
      return {
        defaultField: QuoteFields.TEXT,
        list: Object.values(QuoteFields)
      };
    case Resources.AUTHORS:
      return {
        defaultField: AuthorFields.NAME,
        list: Object.values(AuthorFields)
      };
    case Resources.CATEGORIES:
      return {
        defaultField: CategoryFields.NAME,
        list: Object.values(CategoryFields)
      };
    case Resources.PODCASTS:
    // FALLTHROUGH
    case Resources.VIDEOS:
    // FALLTHROUGH
    case Resources.BOOKS:
      return {
        defaultField: MediaFeedFields.NAME,
        list: Object.values(MediaFeedFields)
      };
    case Resources.MEDIA_PLATFORM:
      return {
        defaultField: MediaPlatformFields.NAME,
        list: Object.values(MediaPlatformFields)
      };
    case Resources.WEEKLY_TIP:
      return {
        defaultField: WeeklyTipFields.TOPIC,
        list: Object.values(WeeklyTipFields)
      };
    default:
      return {
        defaultField: "",
        list: []
      };
  }
}
