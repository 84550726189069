import React, {
  Key,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { GeometryReporting } from "./GeometryReporting";

export interface ContextMenuProps {
  render: (x: number, y: number) => JSX.Element;
}

export const ContextMenu = (
  props: React.PropsWithChildren<ContextMenuProps>
) => {
  const [menuRenderInfo, setMenuRenderInfo] = useState({
    render: false,
    x: 0,
    y: 0
  });
  const childRectGetters = useRef(new Map<Key, () => DOMRect | undefined>());
  useEffect(() => {
    document.addEventListener("click", event => {
      setMenuRenderInfo(renderInfo => ({
        ...renderInfo,
        render: false
      }));
    });
    document.addEventListener("contextmenu", event => {
      const isContextEventWithinAnyChild = Array.from(
        childRectGetters.current.values()
      ).some(domRectGetter => {
        const domRect = domRectGetter();
        if (!domRect) {
          return false;
        }
        const { x, y, width, height } = domRect;
        return (
          event.pageX >= x &&
          event.pageX <= x + width &&
          event.pageY >= y &&
          event.pageY <= y + height
        );
      });

      if (!isContextEventWithinAnyChild) {
        // The context menu isn't related to any of the children being wrapped.
        setMenuRenderInfo(renderInfo => ({
          ...renderInfo,
          render: false
        }));
        return;
      }

      event.preventDefault();

      setMenuRenderInfo({
        render: true,
        x: event.pageX,
        y: event.pageY
      });
    });
  }, []);

  const wrapChildWithGeometryReporting = useCallback(
    (child: ReactNode, index: number = 0) => {
      if (isJSXElement(child)) {
        const key = child.key ?? index;
        return (
          <GeometryReporting
            element={child}
            onMount={getter => {
              childRectGetters.current.set(key, getter);
            }}
            onUnmount={() => {
              childRectGetters.current.delete(key);
            }}
          />
        );
      }
      return child;
    },
    []
  );

  return (
    <>
      {Array.isArray(props.children)
        ? props.children.map((child, index) =>
            wrapChildWithGeometryReporting(child, index)
          )
        : wrapChildWithGeometryReporting(props.children)}
      {menuRenderInfo.render ? (
        props.render(menuRenderInfo.x, menuRenderInfo.y)
      ) : (
        <></>
      )}
    </>
  );
};

function isJSXElement(child: ReactNode): child is JSX.Element {
  return child && (child as JSX.Element).props;
}
