import { ViewModelFactory } from "./ViewModelFactory";
import { ViewModel } from "./ViewModel";

const LOCAL_VIEW_MODEL_KEY = "LOCAL_VIEW_MODEL" as const;

export class LocalViewModelFactory<
  VM extends ViewModel<any, any>
> extends ViewModelFactory<any> {
  constructor(factory: () => VM) {
    super({ [LOCAL_VIEW_MODEL_KEY]: factory });
  }

  getLocalViewModel = () => this.get(LOCAL_VIEW_MODEL_KEY);
}
