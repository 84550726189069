import CircularProgress from "@material-ui/core/CircularProgress";
import * as React from "react";
import { useStyles } from "./styles";
import { Box } from "@material-ui/core";

export const LCEDefaultLoader = () => {
  const classes = useStyles();
  return (
    <Box className={classes.viewPortCenter}>
      <CircularProgress />
    </Box>
  );
};
