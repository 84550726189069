import { Box } from "@material-ui/core";
import { Moment } from "moment-timezone";
import React, { useCallback } from "react";
import { TimePickerRow } from "./TimePickerRow";
import WarningChip from "../../chips/WarningChip";
import { useStyles } from "../styles";

export const NewRangeEventForm = ({
  selectedDates,
  handleDatesChange,
  momentProvider
}: {
  selectedDates: Moment[];
  handleDatesChange: (newDate: Moment[]) => void;
  momentProvider: () => Moment;
}) => {
  const onStartTimeChange = useCallback(
    (newStartTime: Moment) => {
      const rangeEnd = selectedDates[selectedDates.length - 1];
      const rangeStart = selectedDates[0];
      if (
        rangeStart &&
        rangeEnd &&
        !rangeStart.isSame(rangeEnd) &&
        newStartTime.isAfter(rangeEnd)
      ) {
        const originalDeltaMillis = rangeEnd.diff(rangeStart, "millisecond");
        handleDatesChange([
          newStartTime,
          ...selectedDates.slice(1, selectedDates.length - 1),
          newStartTime.clone().add(originalDeltaMillis, "milliseconds")
        ]);
      } else {
        handleDatesChange([newStartTime, ...selectedDates.slice(1)]);
      }
    },
    [handleDatesChange, selectedDates]
  );
  const onEndTimeChange = useCallback(
    (newEndTime: Moment) => {
      const rangeEnd = selectedDates[selectedDates.length - 1];
      const rangeStart = selectedDates[0];
      if (
        rangeStart &&
        rangeEnd &&
        !rangeStart.isSame(rangeEnd) &&
        newEndTime.isBefore(rangeEnd)
      ) {
        const originalDeltaMillis = rangeEnd.diff(rangeStart, "millisecond");
        handleDatesChange([
          newEndTime.clone().subtract(originalDeltaMillis, "milliseconds"),
          ...selectedDates.slice(1, selectedDates.length - 1),
          newEndTime
        ]);
      } else {
        handleDatesChange([...selectedDates.slice(-1), newEndTime]);
      }
    },
    [handleDatesChange, selectedDates]
  );

  const classes = useStyles();
  return (
    <Box>
      {selectedDates[selectedDates.length - 1]?.isBefore(momentProvider()) && (
        <Box className={classes.formItemSpacing}>
          <WarningChip text="You are creating an event in the past." />
        </Box>
      )}
      {selectedDates[0]?.isBefore(momentProvider()) &&
        selectedDates[selectedDates.length - 1]?.isSameOrAfter(
          momentProvider()
        ) && (
          <Box className={classes.formItemSpacing}>
            <WarningChip text="Your event starts in the past." />
          </Box>
        )}
      <TimePickerRow
        title="Start"
        handleDateChange={onStartTimeChange}
        selectedDate={selectedDates[0]}
        momentProvider={momentProvider}
      />
      <TimePickerRow
        title="End"
        handleDateChange={onEndTimeChange}
        selectedDate={selectedDates[selectedDates.length - 1]}
        momentProvider={momentProvider}
      />
    </Box>
  );
};
