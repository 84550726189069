import makeStyles from "@material-ui/core/styles/makeStyles";
import pageContainer from "../../../styles/pageContainer";

const useStyles = makeStyles(theme => ({
  containerWithBanner: {
    height: "100%",
    width: "100%",
    flexFlow: "column"
  },
  container: {
    ...pageContainer(theme)
  },
  grid: {
    maxHeight: "100%",
    height: "100%"
  },
  fixedGridItem: {
    width: "100%"
  },
  limitedGridItem: {
    overflow: "auto",
    width: "100%"
  }
}));

export default useStyles;
