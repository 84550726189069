import useTheme from "@material-ui/core/styles/useTheme";
import Banner from "./Banner";
import * as React from "react";
import { SystemConfigurationConsumerProps } from "../hoc/withSystemConfiguration";

const SystemBanner = ({
  isContentLock,
  isPublishing
}: SystemConfigurationConsumerProps) => {
  const message = isPublishing
    ? "Data is being processed for publish."
    : isContentLock
    ? "Content management is currently locked by an administrator."
    : "";
  const theme = useTheme();
  return message ? (
    <Banner
      message={message}
      backgroundColor={
        theme.messages?.system?.configuration?.contentLock?.backgroundColor
      }
    />
  ) : (
    <div />
  );
};

export default SystemBanner;
