import { createTheme } from "@material-ui/core/styles";

const signOutButtonTheme = createTheme({
  overrides: {
    MuiButton: {
      outlinedPrimary: {
        color: "#fff",
        border: "1px solid rgba(255, 255, 255, 0.5);",
        "&:hover": {
          border: "1px solid rgba(255, 255, 255, 0.5);"
        }
      }
    }
  }
});

const brightnessToggleButtonTheme = createTheme({
  overrides: {
    MuiIconButton: {
      root: {
        color: "#fff"
      }
    }
  }
});

const themes = {
  brightnessToggleButtonTheme,
  signOutButtonTheme
};

export default themes;
