import React from "react";
import { ViewModelFactory } from "../../viewmodel/ViewModelFactory";
import { AnnouncementsPageContent } from "./AnnouncementsPageContent";

export const AnnouncementsPage = () => {
  return (
    <ViewModelFactory>
      <AnnouncementsPageContent />
    </ViewModelFactory>
  );
};
