import ManagedResource from "../../../../data/ManagedResource";
import { LocalizedProp } from "../../../../types/domainTypes";
import { Locale } from "../../../../locale";
import IndexedResource from "../../../../data/IndexedResource";

export function extractContentField(
  resource: ManagedResource<any>,
  field: string
): string[] {
  return (
    resource.tentativeState.updatedContent[field]?.map(
      content => content.value
    ) ||
    resource.content[field] ||
    []
  );
}

export function extractFirstContentField(
  resource: ManagedResource<any>,
  field: string
): string | undefined {
  return extractContentField(resource, field)?.[0];
}

export function extractLocalizedProps(
  currentLocale: Locale,
  resource: ManagedResource<any>,
  field: string
): LocalizedProp<string>[] {
  return extractContentField(resource, field).map((value, index) => {
    const localized =
      resource.content.untranslatedFields?.includes(field) === false ||
      resource.tentativeState.updatedContent[field]?.[index]?.locales.includes(
        currentLocale
      );
    return {
      isLocalized: localized,
      value,
      missing: currentLocale
    };
  });
}

export function extractFirstLocalizedProp(
  currentLocale: Locale,
  resource: ManagedResource<any>,
  field: string
): LocalizedProp<string> {
  return (
    extractLocalizedProps(currentLocale, resource, field)[0] || {
      isLocalized: false,
      missing: currentLocale,
      value: undefined
    }
  );
}

export function extractId<T extends IndexedResource>(
  resource: ManagedResource<T>
) {
  const id = resource.content.id || resource.tentativeState.commitId;
  if (!id) {
    throw new Error("Invalid resource");
  }
  return id;
}
