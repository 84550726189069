import { ListItem } from "@material-ui/core";
import * as React from "react";
import Paper from "@material-ui/core/Paper";
import useStyles from "./styles";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";

const FIXED_ITEM_SIZE = 120;

export interface ScrollableContentProps {
  data: any[];
  renderContentItem: (data: any, index: number) => JSX.Element;
  keyResolver?: (index: number, item: any) => number | string;
  itemSize?: number;
  withDividers?: boolean;
  wrapWithPaper?: boolean;
  selectable?: boolean;
  selectedIndex?: number;
  onClickItem?: (item: any, inex: number) => void;
  alternatingColors?: boolean;
  overrideHeight?: string;
}

const ScrollableContent = ({
  data,
  renderContentItem,
  keyResolver = (_, index) => index,
  itemSize = FIXED_ITEM_SIZE,
  withDividers = true,
  wrapWithPaper = true,
  selectable = false,
  selectedIndex = -1,
  onClickItem,
  alternatingColors,
  overrideHeight
}: ScrollableContentProps) => {
  const classes = useStyles();
  const renderListItem = React.useCallback(
    ({ index, style }) => {
      const additionalStyle: React.CSSProperties = {};
      if (alternatingColors && index % 2 !== 0) {
        additionalStyle.backgroundColor = "#69696970";
      }

      return (
        <ListItem
          button={selectable as any}
          selected={index === selectedIndex}
          divider={withDividers && index < data.length - 1}
          style={{
            ...style,
            ...additionalStyle
          }}
          onClick={() => onClickItem?.(data[index], index)}
        >
          {renderContentItem(data[index], index)}
        </ListItem>
      );
    },
    [
      alternatingColors,
      data,
      onClickItem,
      renderContentItem,
      selectable,
      selectedIndex,
      withDividers
    ]
  );
  const renderAutoSizeList = React.useCallback(
    ({ height, width }) => (
      <List
        height={height}
        width={width}
        itemCount={data.length}
        itemSize={itemSize}
        itemKey={keyResolver}
      >
        {renderListItem}
      </List>
    ),
    [data.length, itemSize, keyResolver, renderListItem]
  );
  const autoSizerStyle = React.useMemo(
    () => ({ height: overrideHeight ?? "2000px" }),
    [overrideHeight]
  );
  return (
    <>
      {wrapWithPaper ? (
        <Paper className={classes.contentContainer}>
          <AutoSizer style={autoSizerStyle}>{renderAutoSizeList}</AutoSizer>
        </Paper>
      ) : (
        <div className={classes.contentContainer}>
          <AutoSizer style={autoSizerStyle}>{renderAutoSizeList}</AutoSizer>
        </div>
      )}
    </>
  );
};

export default React.memo(ScrollableContent);
