import React, { useCallback, useState } from "react";
import { useRepositories } from "../../../../../hooks/useRepositories";
import DestructiveAlertDialog from "../../../../../components/dialogs/DestructiveAlertDialog";

export const DeleteConversationDialog = ({
  conversationId,
  isOpen,
  handleClose
}: {
  conversationId: string;
  isOpen: boolean;
  handleClose: ({ didDelete }?: { didDelete?: boolean }) => void;
}) => {
  const { usersVoice } = useRepositories();
  const [isDeleting, setDeleting] = useState(false);
  const onDeleteConversationConfirmed = useCallback(async () => {
    setDeleting(true);
    await usersVoice.deleteConversation({ conversationId });
    handleClose({didDelete: true})
    setDeleting(false);
  }, [conversationId, usersVoice]);
  return (
    <DestructiveAlertDialog
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirm={onDeleteConversationConfirmed}
      isLoading={isDeleting}
      title={"Delete Conversation"}
      message={
        "Are you sure you want to delete this conversation? this cannot be undone."
      }
      destructiveButtonText={"Delete"}
    />
  );
};
