import React, { memo, useCallback, useMemo } from "react";
import { CircularProgress, Grid, Tooltip } from "@material-ui/core";
import { useStyles } from "./styles";
import { WeeklyTipEditFields } from "./WeeklyTipEditFields";
import withResourceManagement, {
  ManageResourcesPageProps
} from "../hoc/withResourceManagement";
import managedResourceMappers from "../../data/mappers/resources/content-mappers";
import compose from "../../utils/compose";
import withLocale, { LocalePropsConsumer } from "../hoc/withLocale";
import { Resources } from "../../data/Resources";
import withResourceManagementActions, {
  ResourceManagementProps
} from "../hoc/withResourceManagementActions";
import Typography from "@material-ui/core/Typography";
import { WeeklyTipFields } from "../../api/content/WeeklyTip";
import { mapOf } from "../../utils/maps";
import { toMap } from "../../utils/objects/toMap";
import { useRepositories } from "../../hooks/useRepositories";
import { extractId } from "../../utils/data/resources/managed";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import { Motion, spring } from "react-motion";

export interface EditWeeklyTipOwnProps {
  commitId?: string;
  resourceId?: string;
  onBackClicked: () => void;
}

const EditWeeklyTipWrapper = ({
  commitId,
  resourceId,
  onBackClicked,
  locale,
  managedContentByLocale,
  updateFields,
  isUpdating
}: EditWeeklyTipOwnProps &
  ManageResourcesPageProps &
  ResourceManagementProps &
  LocalePropsConsumer) => {
  const { content } = useRepositories();
  const weeklyTip = useMemo(() => {
    const weeklyTips = managedContentByLocale
      .get(Resources.WEEKLY_TIP)
      ?.get(locale);
    return weeklyTips?.find(managedWeeklyTip => {
      return (
        (managedWeeklyTip.tentativeState.commitId &&
          managedWeeklyTip.tentativeState.commitId === commitId) ||
        (managedWeeklyTip.content.id &&
          managedWeeklyTip.content.id === resourceId)
      );
    });
  }, [commitId, locale, managedContentByLocale, resourceId]);
  const classes = useStyles();

  const onWeeklyTipFormChanged = useCallback(
    (updatedFields: {
      [WeeklyTipFields.TOPIC]: string[];
      [WeeklyTipFields.SLIDES]: string[];
    }) => {
      updateFields(
        Resources.WEEKLY_TIP,
        weeklyTip,
        mapOf(locale, {
          updatedFields: toMap(updatedFields),
          updatedLinks: new Map()
        })
      );
    },
    [locale, updateFields, weeklyTip]
  );

  const onWeeklyTipFileUpload = useCallback(
    (file: File) => {
      return weeklyTip
        ? content.uploadFile({
            resource: Resources.WEEKLY_TIP,
            locale,
            resourceOrCommitId: extractId(weeklyTip),
            file
            // metadata: {}
          })
        : Promise.reject();
    },
    [content, locale, weeklyTip]
  );

  if (!weeklyTip) {
    return <div />;
  }

  const isUpdatingAnyField = isUpdating(Resources.WEEKLY_TIP);
  return (
    <Grid
      container
      direction="column"
      spacing={2}
      className={classes.pageContainer}
    >
      <Grid alignItems="center" item container spacing={2}>
        <Grid item>
          <Tooltip title="Back to Weekly Tips">
            <IconButton onClick={onBackClicked}>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Typography variant="h4">Edit Weekly Tip</Typography>
        </Grid>
        <Motion
          defaultStyle={{ opacity: isUpdatingAnyField ? 0 : 1 }}
          style={{ opacity: spring(isUpdatingAnyField ? 1 : 0) }}
        >
          {interpolatedStyle => (
            <Grid item>
              <CircularProgress size={24} style={interpolatedStyle} />
            </Grid>
          )}
        </Motion>
      </Grid>
      <Grid item>
        <WeeklyTipEditFields
          weeklyTip={weeklyTip}
          locale={locale}
          onChange={onWeeklyTipFormChanged}
          uploadFile={onWeeklyTipFileUpload}
        />
      </Grid>
    </Grid>
  );
};

export const EditWeeklyTip = compose(
  withResourceManagementActions,
  withResourceManagement(managedResourceMappers),
  withLocale,
  memo
)(EditWeeklyTipWrapper) as React.ComponentType<EditWeeklyTipOwnProps>;
