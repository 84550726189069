const bannedSymbolsRegex = /[,.'’"]/;

export function tokenizeWithoutSymbols(input: string): WordTokenizedString {
  const sanitizedInput = input.replace(bannedSymbolsRegex, "").trim();
  const words = sanitizedInput.split(/\s+/);
  return {
    // words: new Set(words)
    sentence: words.join(" ")
  };
}

// export function areTokenizedStringsEqual(first: WordTokenizedString, second: WordTokenizedString): boolean {
//   if (first.words.size !== second.words.size) {
//     return false;
//   }
//
//   for (let word of Array.from(first.words)) {
//     if (!second.words.has(word)) {
//       return false;
//     }
//   }
//
//   return true;
// }

export interface WordTokenizedString {
  // words: Set<string>;
  sentence: string;
}
