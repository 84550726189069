import { useMemo } from "react";
import { map } from "rxjs/operators";
import { CommitResource } from "../../../../api/commits/Commit";
import { useRepositories } from "../../../../hooks/useRepositories";
import { useObservableLCE2 } from "../../../../hooks/useObservableLCE2";

export function useContentObservableLCE(resource: CommitResource) {
  const { content } = useRepositories();
  const contentObservable = useMemo(
    () => {
      return content
        .getDataObservable()
        .pipe(
          map(data => Array.from(data.defaultData.data[resource].values()))
        );
    },
    [content, resource]
  );
  return useObservableLCE2(contentObservable);
}
