import LocalizedResource from "../../data/LocalizedResource";

export enum CategoryFields {
  ID = "id",
  NAME = "name",
  IMAGE_URL = "imageUrl"
}

export default interface Category extends LocalizedResource {
  [CategoryFields.ID]: string;
  [CategoryFields.NAME]: string[];
  [CategoryFields.IMAGE_URL]: string[];
}
