import toManagedResource from "./managed-resource-mapper";
import calculateTimeline from "../owner-timeline-mapper";
import { Commit, CommitResource } from "../../../api/commits/Commit";
import User from "../../../api/auth/User";
import { extractValues } from "../../../utils/maps";
import { Resources } from "../../Resources";
import { filterNills } from "../../../utils/arrays/filterNills";
import { AuthorFields } from "../../../api/content/Author";
import { LocaleData } from "../../../repository/LocaleData";
import { ContentData } from "../../../repository/use-case/content/ContentData";
import { extractLinkedResourceField } from "../../../utils/data/mappers/extractLinkedResourceField";
import { MediaPlatformFields } from "../../../api/content/MediaPlatform";
import ManagedMediaFeedContent, {
  MediaFeedContentFields
} from "../../ManagedMediaFeedContent";
import { MediaFeedFields } from "../../../api/content/MedaFeedContent";
import Video from "../../../api/content/Video";
import Podcast from "../../../api/content/Podcast";
import Book from "../../../api/content/Book";
import { CategoryFields } from "../../../api/content/Category";

export default function toManagedMediaFields({
  commits,
  users,
  contentData,
  resource,
  resourceName,
  indexedMediaFeed
}: {
  commits: Map<CommitResource, Map<string, Commit>>;
  users: Map<string, User>;
  contentData: LocaleData<ContentData>;
  resource: Resources.PODCASTS | Resources.VIDEOS | Resources.BOOKS;
  resourceName: string;
  indexedMediaFeed:
    | Map<string, Podcast>
    | Map<string, Video>
    | Map<string, Book>;
}): ManagedMediaFeedContent[] {
  return toManagedResource({
    calculateTimeline: calculateTimeline(resourceName),
    commits: commits.get(resource) || new Map(),
    users,
    contentCollection: extractValues(indexedMediaFeed, media => ({
      ...media,
      [MediaFeedContentFields.AUTHOR]: filterNills(
        media[MediaFeedFields.AUTHOR_ID]?.map(
          id => contentData.data.authors.get(id)?.[AuthorFields.NAME]?.[0]
        ) ?? []
      ),
      [MediaFeedContentFields.PLATFORM]: filterNills(
        media[MediaFeedFields.PLATFORM_ID]?.map(
          id =>
            contentData.data.mediaPlatforms.get(id)?.[
              MediaPlatformFields.NAME
            ]?.[0]
        ) ?? []
      ),
      [MediaFeedContentFields.CATEGORY]: filterNills(
        media[MediaFeedFields.CATEGORY_ID]?.map(
          id =>
            contentData.data.categories.get(id)?.[
              CategoryFields.NAME
            ]?.[0]
        ) ?? []
      )
    })),
    locale: contentData.locale,
    resolveLinksToFields: linkedResources => {
      return {
        ...extractLinkedResourceField(
          linkedResources,
          commits,
          contentData.data.authors,
          [contentData.locale],
          Resources.AUTHORS,
          MediaFeedContentFields.AUTHOR,
          AuthorFields.NAME
        ),
        ...extractLinkedResourceField(
          linkedResources,
          commits,
          contentData.data.mediaPlatforms,
          [contentData.locale],
          Resources.MEDIA_PLATFORM,
          MediaFeedContentFields.PLATFORM,
          MediaPlatformFields.NAME
        ),
        ...extractLinkedResourceField(
          linkedResources,
          commits,
          contentData.data.categories,
          [contentData.locale],
          Resources.CATEGORIES,
          MediaFeedContentFields.CATEGORY,
          CategoryFields.NAME
        )
      };
    }
  });
}
