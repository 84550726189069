import asMap from "../arrays/asMap";

export function toMap<V>(
  obj: any,
  valueSelector: (property: string) => V = property => obj[property]
) {
  return asMap(
    Object.keys(obj),
    property => property,
    property => valueSelector(property)
  );
}
