import { MuiThemeProvider, Theme } from "@material-ui/core";
import * as React from "react";
import { PropsWithChildren } from "react";
import Chip from "@material-ui/core/Chip";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "@material-ui/core/Tooltip";

const WarningChip = (
  props: PropsWithChildren<any> & {
    description: string;
    size?: "small" | "medium";
    text: string;
  }
) => {
  const chip = (
    <Chip
      {...props}
      size={props.size || "small"}
      icon={<WarningIcon />}
      label={props.text}
    />
  );
  const theme = React.useCallback(
    (theme: Theme) => ({
      ...theme,
      overrides: {
        ...theme.overrides,
        MuiChip: {
          ...theme.overrides?.MuiChip,
          label: {
            ...theme.overrides?.MuiChip?.label,
            color: theme.warning?.color
          },
          icon: {
            ...theme.overrides?.MuiChip?.icon,
            color: theme.warning?.color
          }
        }
      }
    }),
    []
  );
  return (
    <MuiThemeProvider theme={theme}>
      {props.description ? (
        <Tooltip title={props.description}>{chip}</Tooltip>
      ) : (
        chip
      )}
    </MuiThemeProvider>
  );
};

export default WarningChip;
