import { Supplier } from "./Supplier";

export class LazySupplier<T> implements Supplier<T> {
  private readonly dataHolder: {
    initialized: boolean;
    data: T | undefined;
  };
  constructor(private readonly loader: () => T) {
    this.dataHolder = {
      initialized: false,
      data: undefined
    };
  }

  get(): T {
    if (!this.dataHolder.initialized) {
      this.dataHolder.data = this.loader();
      this.dataHolder.initialized = true;
    }
    return this.dataHolder.data!!;
  }
}
