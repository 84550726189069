import useCookies from "react-cookie/lib/useCookies";
import { Cookies } from "../../cookies";
import { useMemo } from "react";
import { ContentManagementSortTypes } from "./content-management-sort-types";
import { CommitResource } from "../../api/commits/Commit";

export function useContentManagementSort(resource: CommitResource) {
  const [cookies] = useCookies([Cookies.CONTENT_SORT]);
  return useMemo<{
    type?: ContentManagementSortTypes;
    field?: string;
    property?: "createdAt" | "updatedAt";
  }>(() => {
    return (
      cookies[Cookies.CONTENT_SORT]?.[resource] ?? {
        type: ContentManagementSortTypes.NONE,
        field: ""
      }
    );
  }, [cookies, resource]);
}
