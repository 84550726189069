import {
  ListPermissionsResponse,
  ListUserPermissionsRequest,
  ListUserPermissionsResponse,
  PermissionResponse,
  PermissionsApi,
  SetUserPermissionsRequest,
  SetUserPermissionsResponse
} from "../PermissionsApi";
import { FirebaseFunctions } from "../../firebase/functions/FirebaseFunctions";

export class FirebasePermissionApi implements PermissionsApi {
  constructor(private readonly firebaseFunctions: FirebaseFunctions) {}

  getScreenPermissions = (): Promise<PermissionResponse> =>
    this.firebaseFunctions.call("getScreenPermissions");

  listPermissions = (): Promise<ListPermissionsResponse> =>
    this.firebaseFunctions.call("listPermissions");

  listUserPermissions = (
    request: ListUserPermissionsRequest
  ): Promise<ListUserPermissionsResponse> =>
    this.firebaseFunctions.call("listUserPermissions", request);

  setUserPermissions = (
    request: SetUserPermissionsRequest
  ): Promise<SetUserPermissionsResponse> =>
    this.firebaseFunctions.call("setUserPermissions", request);
}
