import { ContentAction } from "./actions/ContentActions";
import OwnerTimelineEntry from "../../data/OwnerTimelineEntry";

export class ContentItemViewModel {
  constructor(
    private readonly renderContent: any,
    private readonly contentActions: ContentAction[] = [],
    private readonly modificationType?:
      | "create"
      | "update"
      | "remove"
      | "archive"
      | "unarchive",
    private readonly ownerTimeline?: OwnerTimelineEntry[],
    private readonly updateTime?: number
  ) {}

  getRenderContent(): any {
    return this.renderContent;
  }

  getContentActions(): ContentAction[] {
    return this.contentActions;
  }

  getOwnerTimeline(): OwnerTimelineEntry[] | undefined {
    return this.ownerTimeline;
  }

  getUpdateTime(): number | undefined {
    return this.updateTime;
  }

  get contentDividerModificationColor() {
    return this.modificationType;
  }
}
