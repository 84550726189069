import * as React from "react";
import { Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LCE } from "../../architecture/lce/lce";

export interface LCEContentConsumer<T> {
  content: T | undefined;
}

const useStyles = makeStyles(theme => ({
  viewPortCenter: {
    position: "absolute",
    top: "50vh",
    left: "50vw"
  }
}));

export default function withLCE<P, T extends object, E extends Error>(
  lceProvider: (
    props: P & React.Attributes & { content: T | undefined }
  ) => LCE<T, Error>,
  options: { showLoadingScreen?: boolean; displayError?: boolean } = {
    showLoadingScreen: true,
    displayError: true
  }
) {
  return (WrappedComponent: React.ComponentType<any>) => {
    return (props: P & React.Attributes & LCEContentConsumer<T>) => {
      const lce = lceProvider(props);

      const classes = useStyles();
      if (options.showLoadingScreen && lce.isLoading) {
        return <CircularProgress className={classes.viewPortCenter} />;
      }
      if (options.displayError && lce.error) {
        return (
          <Typography color="textPrimary" className={classes.viewPortCenter}>
            {lce.error?.message}
          </Typography>
        );
      }

      return (
        <WrappedComponent
          {...props}
          content={{ ...props.content, ...lce.content }}
        />
      );
    };
  };
}
