import { LocalViewModelFactory } from "../../../architecture/view-model/LocalViewModelFactory";
import { FileManagerViewModelBase } from "../FileManagerViewModelBase";
import {
  GenericFormDialog,
  GenericFormDialogCheckboxField,
  GenericFormDialogChoiceField,
  GenericFormDialogField,
  GenericFormDialogSection,
  GenericFormDialogTextInputField
} from "../../../components/dialogs/GenericFormDialog";
import React, { useCallback, useMemo } from "react";
import { useLocalViewModel } from "../../../architecture/view-model/useViewModel";
import { Cookies } from "../../../cookies";
import { useTypedCookies } from "../../../hooks/useTypedCookies";

export interface GeneratePublicLinksDialogProps {
  viewModelFactory: LocalViewModelFactory<FileManagerViewModelBase>;
}

interface LastOptionsCookie {
  lastThumbnailVariants?: string[];
  lastResizeForAndroid?: boolean;
  lastResizeForIOS?: boolean;
  lastCapResolution?: boolean;
}

export const GeneratePublicLinksDialog = (
  props: GeneratePublicLinksDialogProps
) => {
  const [viewState, viewModel] = useLocalViewModel(props.viewModelFactory);
  const onClose = useCallback(() => viewModel.onClickCancelPendingAction(), [
    viewModel
  ]);
  const fileItem = viewState.fileDetailedView?.fileItem;
  const [cookies, setCookie] = useTypedCookies<LastOptionsCookie>(
    Cookies.FILE_MANAGER_GENERATE_PUBLIC_LINK_OPTIONS
  );
  const destinationName = useMemo<GenericFormDialogTextInputField>(() => {
    let name = fileItem?.name;
    if (fileItem?.type === "file" && fileItem.extension) {
      name += `.${fileItem.extension}`;
    }
    return {
      type: "textInput",
      label: "Name",
      description:
        "The name of the file as it will be created in the application backend storage. This will be visible in the public URL.",
      defaultValue: name
    };
  }, [fileItem]);
  const destinationPath = useMemo<GenericFormDialogTextInputField>(() => {
    let fullPath = viewState.pathStack[viewState.pathIndex].fullPath;
    if (fullPath.startsWith("/")) {
      fullPath = fullPath.substring(1);
    }
    if (fullPath.endsWith("/")) {
      fullPath = fullPath.substring(0, fullPath.length);
    }
    return {
      type: "textInput",
      label: "Path",
      description:
        "The folder in application backend storage which the file(s) will be created. This will be visible in the public URL.",
      defaultValue: fullPath
    };
  }, [viewState.pathIndex, viewState.pathStack]);
  const variants = useMemo<GenericFormDialogChoiceField>(
    () => ({
      type: "choice",
      label: "Thumbnail",
      defaultValue: cookies?.lastThumbnailVariants ?? [],
      multiple: true,
      description:
        "Generate smaller variants that will be displayed as thumbnails (e.g. author image next to quote).",
      hint: "Select",
      choices: [
        { label: "Small", description: "Up to 25% of image size." },
        { label: "Medium", description: "Up to 33% of image size." },
        { label: "Large", description: "Up to 50% of image size." }
      ]
    }),
    [cookies]
  );
  const resizeForAndroid = useMemo<GenericFormDialogCheckboxField>(
    () => ({
      type: "checkbox",
      label: "Android",
      description:
        "Generate reduced file size for every variant at every pixel density (e.g. hdpi, xhdpi).",
      defaultValue: cookies?.lastResizeForAndroid ?? true
    }),
    [cookies]
  );
  const resizeForIOS = useMemo<GenericFormDialogCheckboxField>(
    () => ({
      type: "checkbox",
      label: "iOS",
      description:
        "Generate reduced file size for every variant at every asset scale (e.g. @2, @3).",
      defaultValue: cookies?.lastResizeForIOS ?? true
    }),
    [cookies]
  );
  const resizeCapResolution = useMemo<GenericFormDialogCheckboxField>(
    () => ({
      type: "checkbox",
      label: "Cap resolution",
      description:
        "Resizes the original image (maintains aspect ratio) to match the maximum mobile device resolution based on the highest end devices on the market. Not applicable if the image is smaller.",
      defaultValue: cookies?.lastCapResolution ?? true
    }),
    [cookies]
  );
  const fields = useMemo(() => {
    const sections: GenericFormDialogSection[] = [
      {
        title: "Uploaded File Info",
        fields: [destinationName, destinationPath]
      }
    ];
    if (fileItem?.type === "file" && fileItem.fileType.startsWith("image/")) {
      sections.push({
        title: "Variants",
        fields: [variants]
      });
      sections.push({
        title: "Resize Options",
        fields: [resizeCapResolution, resizeForAndroid, resizeForIOS]
      });
    }
    return sections;
  }, [
    destinationName,
    destinationPath,
    fileItem,
    resizeCapResolution,
    resizeForAndroid,
    resizeForIOS,
    variants
  ]);
  const actions = useMemo(
    () => [
      {
        type: "cancel" as const,
        label: "Cancel"
      },
      {
        type: "save" as const,
        label: "Generate",
        onSave: (currentValues: Map<GenericFormDialogField, any>) => {
          if (!fileItem) {
            return;
          }
          const selectedVariants = currentValues
            .get(variants)
            ?.map((variant: string) => `thumbnail_${variant.toLowerCase()}`);
          const capResolution = currentValues.get(resizeCapResolution);
          const selectedResizeForIOS = currentValues.get(resizeForIOS);
          const selectedResizeForAndroid = currentValues.get(resizeForAndroid);
          setCookie({
            lastCapResolution: capResolution,
            lastThumbnailVariants: selectedVariants,
            lastResizeForAndroid: selectedResizeForAndroid,
            lastResizeForIOS: selectedResizeForIOS
          });
          viewModel.onConfirmGeneratePublicLinks(fileItem, {
            path: currentValues.get(destinationPath),
            name: currentValues.get(destinationName),
            variants: selectedVariants,
            resize: {
              capResolution,
              ios: selectedResizeForIOS,
              android: selectedResizeForAndroid
            }
          });
        }
      }
    ],
    [
      destinationName,
      destinationPath,
      fileItem,
      resizeCapResolution,
      resizeForAndroid,
      resizeForIOS,
      setCookie,
      variants,
      viewModel
    ]
  );
  return (
    <GenericFormDialog
      isLoading={viewState.pendingAction?.isLoading}
      open={viewState.pendingAction?.type === "generate_links_form"}
      onClose={onClose}
      title={"Generate Public Links"}
      actions={actions}
      fieldSections={fields}
    />
  );
};
