import { useEffect, useState } from "react";
import { LCE } from "../architecture/lce/lce";

const useLCE2 = function<T, E extends Error = Error>(contentLoading: () => Promise<T>) {
  const [lce, setLCE] = useState<LCE<T, E>>({ isLoading: true });

  useEffect(() => {
    (async () => {
      try {
        setLCE({ isLoading: true });
        const content = await contentLoading();
        setLCE({ isLoading: false, content });
      } catch (error: any) {
        setLCE({ isLoading: false, error });
      }
    })();
  }, [contentLoading]);

  return lce;
};

export default useLCE2;
