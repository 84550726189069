import HotObservableRepository from "../../HotObservableRepository";
import { Task } from "../../../api/tasks/Task";
import { Observable } from "rxjs";
import { TaskApi } from "../../../api/tasks/TaskApi";

export class TasksRepository extends HotObservableRepository<Task[]> {
  constructor(private readonly taskApi: TaskApi) {
    super();
  }

  protected readonly _loadDataObservable: () => Observable<Task[]> = () =>
    this.taskApi.getTasksObservable();

  retryTask = (taskId: string): Promise<void> => {
    return this.taskApi.retryTask(taskId);
  };

  cancelTask = (taskId: string): Promise<void> => {
    return this.taskApi.cancelTask(taskId);
  };

  deleteTask = (taskId: string): Promise<void> => {
    return this.taskApi.deleteTask(taskId);
  };

  createPublishTask = ({
    individualCommits
  }: {
    individualCommits?: {
      resourceName: string;
      commitId: string;
      locale: string;
    }[];
  } = {}): Promise<void> => {
    return this.taskApi.createPublishTask({
      individualCommits
    });
  };
}
