import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Close, InfoRounded } from "@material-ui/icons";
import { isNil } from "lodash";
import { flatten } from "../../utils/arrays/flatten";
import Box from "@material-ui/core/Box";
import useTheme from "@material-ui/core/styles/useTheme";

export type GenericFormDialogAction =
  | GenericFormDialogActionCancel
  | GenericFormDialogActionSave;

export interface GenericFormDialogActionBase {
  label: string;
  disabled?: boolean;
}

export interface GenericFormDialogActionCancel
  extends GenericFormDialogActionBase {
  type: "cancel";
}

export interface GenericFormDialogActionSave
  extends GenericFormDialogActionBase {
  type: "save";
  onSave: (currentValues: Map<GenericFormDialogField, any>) => void;
}

export type GenericFormDialogField =
  | GenericFormDialogTextInputField
  | GenericFormDialogChoiceField
  | GenericFormDialogCheckboxField;

interface GenericFormDialogFieldBase<T> {
  validate?(value: T | undefined): string | null;

  label?: string;
  description?: string;
  defaultValue?: T;
}

export interface GenericFormDialogTextInputField
  extends GenericFormDialogFieldBase<string> {
  type: "textInput";
  hint?: string;
}

export interface GenericFormDialogCheckboxField
  extends GenericFormDialogFieldBase<boolean> {
  type: "checkbox";
}

export interface GenericFormDialogChoiceField
  extends GenericFormDialogFieldBase<string[]> {
  type: "choice";
  multiple?: boolean;
  hint?: string;
  choices: {
    label: string;
    description?: string;
  }[];
}

export interface GenericFormDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  isLoading?: boolean;
  actions: GenericFormDialogAction[];
  fields?: GenericFormDialogField[];
  fieldSections?: GenericFormDialogSection[];
}

export interface GenericFormDialogSection {
  title: string;
  fields: GenericFormDialogField[];
}

const closeIconButtonStyle = {
  display: "flex",
  alignItems: "center",
  marginRight: 16
};

export const GenericFormDialog = (props: GenericFormDialogProps) => {
  const [formValues, setFormValues] = useState(
    new Map<GenericFormDialogField, any>()
  );
  useEffect(() => {
    setFormValues(
      (props.fieldSections
        ? flatten(props.fieldSections.map(section => section.fields))
        : props.fields ?? []
      ).reduce((initialValues, field) => {
        initialValues.set(field, field.defaultValue);
        return initialValues;
      }, new Map<GenericFormDialogField, any>())
    );
  }, [props.fieldSections, props.fields]);
  const [validationErrors, setValidationErrors] = useState(
    new Map<GenericFormDialogField, string>()
  );
  const renderFormField = useCallback(
    (formField: GenericFormDialogField) => {
      let fieldElement: JSX.Element = <></>;
      if (isTextInputField(formField)) {
        const validationError = validationErrors.get(formField);
        fieldElement = (
          <TextField
            onBlur={event => {
              const value = event.target?.value;
              setFormValues(currentValues => {
                const updatedValues = new Map(currentValues);
                updatedValues.set(formField, value);
                return updatedValues;
              });
            }}
            onChange={
              formField.validate
                ? event => {
                    const value = event.target?.value;
                    setValidationErrors(currentValidationErrors => {
                      const updatedValidationErrors = new Map(
                        currentValidationErrors
                      );
                      const validationResult = formField.validate?.(value);
                      if (isNil(validationResult)) {
                        updatedValidationErrors.delete(formField);
                      } else {
                        updatedValidationErrors.set(
                          formField,
                          validationResult
                        );
                      }
                      return updatedValidationErrors;
                    });
                  }
                : undefined
            }
            error={!isNil(validationError)}
            helperText={
              isNil(validationError) ? formField.hint : validationError
            }
            defaultValue={formField.defaultValue}
            style={{ width: "100%" }}
            variant={"outlined"}
          />
        );
      } else if (isCheckboxField(formField)) {
        fieldElement = (
          <Checkbox
            color={"primary"}
            checked={formValues.get(formField) ?? formField.defaultValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFormValues(currentValues => {
                const updatedValues = new Map(currentValues);
                updatedValues.set(formField, event.target.checked ?? false);
                return updatedValues;
              });
            }}
          />
        );
      } else if (isChoiceField(formField)) {
        fieldElement = (
          <Select
            fullWidth
            multiple={!!formField.multiple}
            label={formField.hint}
            variant={"outlined"}
            renderValue={selected => {
              return (selected as string[]).join(", ");
            }}
            value={Array.from(
              formValues.get(formField) ?? formField.defaultValue
            )}
            onChange={event => {
              const eventValue = event.target.value as string[] | string;
              setFormValues(currentValues => {
                const updatedValues = new Map(currentValues);
                updatedValues.set(
                  formField,
                  Array.isArray(eventValue) ? eventValue : [eventValue]
                );
                return updatedValues;
              });
            }}
          >
            {formField.choices.map(choice => {
              const label = <ListItemText primary={choice.label} />;
              const checked = formValues.get(formField)?.includes(choice.label);
              return (
                <MenuItem value={choice.label}>
                  {formField.multiple ? (
                    <Checkbox checked={checked} />
                  ) : (
                    <Radio checked={checked} />
                  )}
                  {choice.description ? (
                    <Tooltip title={choice.description}>{label}</Tooltip>
                  ) : (
                    label
                  )}
                </MenuItem>
              );
            })}
          </Select>
        );
      }
      return (
        <Grid item container direction={"row"} alignItems={"center"}>
          <Grid item xs={4}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              width={"100%"}
              alignItems={"center"}
            >
              <Typography>{formField.label}</Typography>
              {formField.description ? (
                <Tooltip
                  title={formField.description}
                  style={{ marginLeft: "8px" }}
                >
                  <InfoRounded fontSize={"small"} />
                </Tooltip>
              ) : (
                <></>
              )}
            </Box>
          </Grid>
          <Grid item xs={8}>
            {fieldElement}
          </Grid>
        </Grid>
      );
    },
    [formValues, validationErrors]
  );

  const theme = useTheme();

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={"sm"} fullWidth>
      {props.isLoading && <LinearProgress variant="query" />}
      <Grid container justify={"space-between"} spacing={1}>
        <Grid item>
          <DialogTitle>{props.title}</DialogTitle>
        </Grid>
        <Grid item style={closeIconButtonStyle}>
          <IconButton onClick={props.onClose}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid container direction={"column"} spacing={1}>
          {props.fieldSections?.map(section => {
            return (
              <Box
                margin={"8px"}
                padding={"16px"}
                bgcolor={theme.components?.list?.alternatingColor}
                borderRadius={"16px"}
              >
                <Typography variant={"h6"} component={"h1"}>
                  {section.title}
                </Typography>
                <Box
                  marginTop={"8px"}
                  marginBottom={"16px"}
                  marginLeft={"4px"}
                  marginRight={"4px"}
                >
                  <Divider />
                </Box>
                {section.fields.map(renderFormField)}
              </Box>
            );
          }) ??
            props.fields?.map(renderFormField) ?? <></>}
        </Grid>
      </DialogContent>
      <DialogActions>
        {props.actions.map(action => (
          <Button
            disabled={
              action.type !== "cancel" &&
              (action.disabled ||
                props.isLoading ||
                (action.type === "save" && validationErrors.size !== 0))
            }
            onClick={() => {
              if (action.type === "cancel") {
                // TODO: Confirmation?
                props.onClose();
              } else if (action.type === "save") {
                action.onSave(formValues);
                // const results = props.fields
                //   .map(
                //     field =>
                //       [field, field.validate?.(formValues.get(field))] as const
                //   )
                //   .filter((fieldToResultPair): fieldToResultPair is [
                //     GenericFormDialogField,
                //     string
                //   ] => {
                //     return (
                //       fieldToResultPair[1] !== null &&
                //       fieldToResultPair[1] !== undefined
                //     );
                //   });
                // if (results.length === 0) {
                //   action.onSave(formValues);
                //   props.onClose();
                // } else {
                //   setValidationErrors(new Map(results));
                // }
              }
            }}
          >
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

function isTextInputField(
  formField: GenericFormDialogField
): formField is GenericFormDialogTextInputField {
  return formField.type === "textInput";
}

function isCheckboxField(
  formField: GenericFormDialogField
): formField is GenericFormDialogCheckboxField {
  return formField.type === "checkbox";
}

function isChoiceField(
  formField: GenericFormDialogField
): formField is GenericFormDialogChoiceField {
  return formField.type === "choice";
}
