import { LocalViewModelFactory } from "../../../architecture/view-model/LocalViewModelFactory";
import {
  FileDetails,
  FileManagerViewModelBase
} from "../FileManagerViewModelBase";
import { useLocalViewModel } from "../../../architecture/view-model/useViewModel";
import { LCEWrapper } from "../../../components/lce/LCEWrapper";
import React, { useCallback } from "react";
import { Box, CircularProgress, Fab, Tooltip } from "@material-ui/core";
import {
  CloudDownload,
  ControlPointDuplicate,
  PriorityHigh
} from "@material-ui/icons";
import { PredefinedFileActions } from "../../../domain/file-management/entities/PredefinedFileActions";
import { Alert } from "@material-ui/lab";
import { OneOfSnackbar } from "../../../components/one-of-snackbar/OneOfSnackbar";

export interface FileManagerFileActionsProps {
  viewModelFactory: LocalViewModelFactory<FileManagerViewModelBase>;
}

const renderActionIconMap: Record<PredefinedFileActions, () => JSX.Element> = {
  [PredefinedFileActions.FILE_ACTION_DOWNLOAD]: () => <CloudDownload />,
  [PredefinedFileActions.FILE_ACTION_GENERATE_APP_RESOURCE]: () => (
    <ControlPointDuplicate />
  )
};

const FAB_SMALL_SIZE_PX = 40;
const FAB_LOADER_SIZE_PX = 48;

export const FileManagerFileActions = (props: FileManagerFileActionsProps) => {
  const [viewState, viewModel] = useLocalViewModel(props.viewModelFactory);

  const renderFileDetails = useCallback(
    (fileDetails: FileDetails) => {
      if (!fileDetails?.availableActions.length) {
        return <></>;
      }

      return (
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          width={"100%"}
          height={"100%"}
          gridColumnGap={"8px"}
        >
          {fileDetails.availableActions.map((action, index) => (
            <Box
              width={`${FAB_LOADER_SIZE_PX}px`}
              height={`${FAB_LOADER_SIZE_PX}px`}
              position={"relative"}
            >
              <Box
                position={"absolute"}
                left={`${(FAB_LOADER_SIZE_PX - FAB_SMALL_SIZE_PX) / 2}px`}
                top={`${(FAB_LOADER_SIZE_PX - FAB_SMALL_SIZE_PX) / 2}px`}
              >
                <Tooltip title={action.description}>
                  <Fab
                    color={"primary"}
                    size={"small"}
                    onClick={() => {
                      viewModel.onClickAction(action.id);
                    }}
                  >
                    {renderActionIconMap[action.id]?.() ?? <PriorityHigh />}
                  </Fab>
                </Tooltip>
              </Box>
              {viewState.fileDetailedView?.actionState?.[action.id] ? (
                <Box position={"absolute"} left={0} top={0}>
                  <LCEWrapper
                    lce={viewState.fileDetailedView.actionState[action.id]}
                    renderLoader={() => (
                      <CircularProgress
                        color={"secondary"}
                        size={FAB_LOADER_SIZE_PX}
                        variant="indeterminate"
                      />
                    )}
                    renderError={error => (
                      <OneOfSnackbar autoHideDuration={5000}>
                        <Alert severity={"error"}>{error.message}</Alert>
                      </OneOfSnackbar>
                    )}
                    renderContent={() => <></>}
                  />
                </Box>
              ) : (
                <></>
              )}
            </Box>
          ))}
        </Box>
      );
    },
    [viewModel, viewState.fileDetailedView]
  );

  if (!viewState.fileDetailedView?.detailsLCE) {
    return <></>;
  }

  return (
    <LCEWrapper
      lce={viewState.fileDetailedView.detailsLCE}
      renderLoader={null}
      renderContent={renderFileDetails}
    />
  );
};
