import * as firebase from "firebase";

export default class FirebaseAuth {
  private readonly firebaseAuth: firebase.auth.Auth;

  constructor(private firebaseApp: firebase.app.App) {
    this.firebaseAuth = this.firebaseApp.auth();
  }

  get auth(): firebase.auth.Auth {
    return this.firebaseAuth;
  }

  googleProvider = () => new firebase.auth.GoogleAuthProvider();
}
