import compose from "../../../utils/compose";
import * as React from "react";
import { Resources } from "../../../data/Resources";
import BaseContentManagementPage from "../../../components/pages/BaseContentManagementPage";
import { SearchFields } from "../../../types/domainTypes";
import { MediaPlatformFields } from "../../../api/content/MediaPlatform";
import ManagedContent from "../../../data/ManagedContent";
import { ManagedMediaPlatform } from "../../../data/ManagedMediaPlatform";
import withLocale, {
  LocalePropsConsumer
} from "../../../components/hoc/withLocale";
import MediaPlatformContent from "./MediaPlatformContent";

const searchFields: SearchFields = {
  contentFields: [MediaPlatformFields.NAME]
};

const MediaPlatformWrapper = (props: LocalePropsConsumer) => {
  const renderViewModelContent = React.useCallback(
    (content: ManagedContent<ManagedMediaPlatform>) => (
      <MediaPlatformContent locale={props.locale} content={content} />
    ),
    [props.locale]
  );
  return (
    <BaseContentManagementPage
      resource={Resources.MEDIA_PLATFORM}
      searchFields={searchFields}
      renderViewModelContent={renderViewModelContent}
      searchDebounce={0}
    />
  );
};

const MediaPlatformPage = compose(
  React.memo,
  withLocale
)(MediaPlatformWrapper) as React.ComponentType;

export default MediaPlatformPage;
