import HotObservableRepository from "../../HotObservableRepository";
import { Observable } from "rxjs";
import LocalesApi from "../../../api/locales/LocalesApi";
import { Locale } from "../../../locale";

export default class LocalesRepository extends HotObservableRepository<
  string[]
> {
  constructor(
    private readonly localesApi: LocalesApi,
    readonly defaultLocale: Locale
  ) {
    super();
  }

  protected readonly _loadDataObservable: () => Observable<string[]> = () =>
    this.localesApi.getLocalesObservable();
}
