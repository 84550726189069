import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid } from "@material-ui/core";
import { ConversationsList } from "./conversations-list/ConversationsList";
import {
  ConversationEntry,
  ListConversationsResponse
} from "../../../repository/use-case/users-voice/dto/ListConversationsResponse";
import { ConversationWindow } from "./conversation-window/ConversationWindow";
import { useSendConversationReply } from "./useSendConversationReply";
import { ConversationAlert } from "./conversation-alerts/ConversationAlert";
import useStyles from "./styles";
import { useRepositories } from "../../../hooks/useRepositories";
import { useObservableLCE2 } from "../../../hooks/useObservableLCE2";
import { LCEWrapper } from "../../../components/lce/LCEWrapper";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

export const UsersVoicePage = () => {
  const { usersVoice } = useRepositories();

  const [loadConversationId, setLoadConversationId] = useState<
    string | undefined
  >();
  const conversationsObservable = useMemo(() => {
    const requiredConversationIds = [];
    if (loadConversationId) {
      requiredConversationIds.push(loadConversationId);
    }
    return usersVoice.listConversations({
      requiredConversationIds
    });
  }, [usersVoice, loadConversationId]);
  const conversationsLCE = useObservableLCE2(conversationsObservable);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.search.trim().length) {
      const { conversationId } = queryString.parse(location.search) ?? {};
      if (
        typeof conversationId === "string" &&
        !conversationsLCE.content?.conversationEntries?.some(
          conversation => conversation.id === conversationId
        )
      ) {
        setLoadConversationId(conversationId);
      }
    } else {
      setLoadConversationId(undefined);
    }
  }, [conversationsLCE.content, location]);

  const [selectedConversation, setSelectedConversation] = useState<
    ConversationEntry | undefined
  >();

  useEffect(() => {
    if (loadConversationId && conversationsLCE.content) {
      setSelectedConversation(
        conversationsLCE.content.conversationEntries.find(
          conversation => conversation.id === loadConversationId
        )
      );
    }
  }, [conversationsLCE.content, loadConversationId]);

  const onConversationSelect = useCallback(
    (conversation: ConversationEntry) => {
      history.push(`${location.pathname}?conversationId=${conversation.id}`);
      setSelectedConversation(conversation);
    },
    [history, location.pathname]
  );

  const onConversationDeleted = useCallback(() => {
    setSelectedConversation(undefined);
  }, []);

  const { sendReplyLCE, onConversationReply } = useSendConversationReply(
    selectedConversation
  );

  const classes = useStyles();

  const renderConversations = useCallback(
    ({ conversationEntries }: ListConversationsResponse) => (
      <ConversationsList
        conversations={conversationEntries}
        onConversationSelect={onConversationSelect}
      />
    ),
    [onConversationSelect]
  );

  return (
    <>
      <Grid container spacing={4} className={classes.container}>
        <Grid item xs={3} className={classes.column}>
          <LCEWrapper
            lce={conversationsLCE}
            renderContent={renderConversations}
          />
        </Grid>
        {selectedConversation && (
          <Grid item xs={4}>
            <ConversationWindow
              sendReplyLCE={sendReplyLCE}
              conversation={selectedConversation}
              onConversationDeleted={onConversationDeleted}
              onReply={onConversationReply}
            />
          </Grid>
        )}
      </Grid>
      <ConversationAlert sendReplyLCE={sendReplyLCE} />
    </>
  );
};
