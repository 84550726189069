import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { LocaleInfo } from "./HeaderViewModel";
import CountryFlag from "../localization/CountryFlag";
import { getCountryCodeForLocale } from "../../locale";

const LocaleAutoComplete = ({
  selectedLocale,
  localeInfo,
  onLocaleSelected
}: {
  localeInfo: LocaleInfo[];
  selectedLocale: string;
  onLocaleSelected: (locale: string) => void;
}) => {
  return (
    <Autocomplete
      options={localeInfo}
      defaultValue={{
        locale: selectedLocale,
        countryCode: getCountryCodeForLocale(selectedLocale)
      }}
      groupBy={({ hasTranslations }) =>
        hasTranslations ? "Translated" : "No Translations"
      }
      onChange={(_: any, value: any) =>
        onLocaleSelected((value as LocaleInfo).locale)
      }
      renderOption={({
        locale,
        countryCode
      }: {
        locale: string;
        countryCode: string;
      }) => <CountryFlag locale={locale} countryCode={countryCode} />}
      getOptionLabel={({ locale }: { locale: string }) => locale}
      renderInput={params => (
        <TextField
          {...params}
          label="Language"
          variant="outlined"
          fullWidth
          inputProps={{
            ...params.inputProps,
            autoComplete: "disabled"
          }}
        />
      )}
    />
  );
};

export default React.memo(LocaleAutoComplete);
