import React, { useCallback, useRef } from "react";

export interface FileUploadProps {
  accept: ("image/*" | ".zip")[];
  type?: string;
  multiple?: boolean;
  onFilesSelected: (fileList: FileList | null) => void;
  render: (initiateUpload: Function) => JSX.Element | void;
}

export const FileUpload = (props: FileUploadProps) => {
  const uploadRef = useRef<any>();
  const initiateUpload = useCallback(() => {
    uploadRef.current?.click();
  }, []);

  const onFilesSelected = useCallback(
    event => {
      props.onFilesSelected(event.target.files);
    },
    [props]
  );

  return (
    <>
      <input
        accept={props.accept.join(",")}
        style={{ display: "none" }}
        type={props.type ?? "file"}
        ref={ref => (uploadRef.current = ref)}
        onChange={onFilesSelected}
        multiple={props.multiple}
      />
      {props.render(initiateUpload) ?? <></>}
    </>
  );
};
