import React from "react";
import { useThemeOverride } from "../../../../../utils/styles/mergeThemeOverride";
import { map, toPairs } from "lodash";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MuiThemeProvider
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CheckboxRow } from "../../../../row/checkbox-row/CheckboxRow";
import ScrollableContent from "../../../../../components/content/ScrollableContent";
import { useDiagnosticsViewModel } from "../useDiagnosticsViewModel";

const ITEM_SIZE = 40;

export const ContentDiscrepanciesDiagnosticJobReport = () => {
  const [viewState, viewModel] = useDiagnosticsViewModel();

  const overriddenTheme = useThemeOverride(() => ({
    MuiCollapse: {
      entered: {
        overflow: "auto"
      }
    }
  }));

  return (
    <>
      {map(viewState.commitResourcesSelections, (value, resourceName) => {
        const selectionCount = value?.selectionCount ?? 0;
        const totalResourcesCount = Object.keys(value.resourceMap).length;
        const allResourcesSelected = selectionCount === totalResourcesCount;
        return (
          <MuiThemeProvider theme={overriddenTheme}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <CheckboxRow
                  checked={allResourcesSelected}
                  onClick={event => {
                    event.stopPropagation();
                  }}
                  onChange={() => {
                    viewModel.onToggleAllCommitResourcesSelection(resourceName);
                  }}
                >
                  {`${resourceName} (${selectionCount}/${totalResourcesCount})`}
                </CheckboxRow>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ width: "100%" }}>
                  <ScrollableContent
                    data={toPairs(value.resourceMap)}
                    itemSize={ITEM_SIZE}
                    wrapWithPaper={false}
                    overrideHeight={"80vh"}
                    alternatingColors
                    renderContentItem={([
                      resourceId,
                      { isSelected, label, disabled }
                    ]) => {
                      return (
                        <CheckboxRow
                          disabled={disabled}
                          checked={isSelected}
                          onChange={() => {
                            viewModel.onToggleCommitResourceSelection(
                              resourceName,
                              resourceId
                            );
                          }}
                        >
                          {label}
                        </CheckboxRow>
                      );
                    }}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </MuiThemeProvider>
        );
      })}
    </>
  );
};
