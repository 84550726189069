import LocalesApi from "../LocalesApi";
import { Observable } from "rxjs";
import { FirebaseData } from "../../firebase/data/FirebaseData";

export default class FirebaseLocalesApi implements LocalesApi {
  constructor(private readonly firebaseData: FirebaseData) {}

  getLocalesObservable(): Observable<string[]> {
    return this.firebaseData.getLocalesObservable();
  }
}
