import * as React from "react";
import BaseContentManagementPage from "../../../components/pages/BaseContentManagementPage";
import { Resources } from "../../../data/Resources";
import ManagedContent from "../../../data/ManagedContent";
import {
  extractFirstContentField,
  extractFirstLocalizedProp,
  extractLocalizedProps
} from "../../../utils/data/resources/managed";
import { mapOf } from "../../../utils/maps";
import { CommitResource } from "../../../api/commits/Commit";
import { SearchFields } from "../../../types/domainTypes";
import ManagedResource from "../../../data/ManagedResource";
import { ManagedMediaPlatform } from "../../../data/ManagedMediaPlatform";
import { MediaPlatformFields } from "../../../api/content/MediaPlatform";
import { MediaFeedFields } from "../../../api/content/MedaFeedContent";
import ManagedMediaFeedContent, {
  MediaFeedContentFields
} from "../../../data/ManagedMediaFeedContent";
import { MediaFeedContent } from "./MediaFeedContent";

const searchFields: SearchFields = {
  contentFields: [
    MediaFeedFields.NAME,
    MediaFeedFields.DESCRIPTION,
    MediaFeedContentFields.AUTHOR,
    MediaFeedContentFields.PLATFORM
  ]
};

const MediaFeedContentPage = ({
  resource
}: {
  resource: Resources.VIDEOS | Resources.PODCASTS | Resources.BOOKS;
}) => {
  const renderContentItem = React.useCallback(
    (
      managedContent: ManagedContent<ManagedMediaFeedContent>,
      locale: string,
      allContent: Map<CommitResource, ManagedResource<any>[]>
    ) => (
      <MediaFeedContent
        name={extractFirstLocalizedProp(
          locale,
          managedContent.managedResource,
          MediaFeedFields.NAME
        )}
        imageUrl={extractFirstContentField(
          managedContent.managedResource,
          MediaFeedFields.IMAGE
        )}
        description={extractFirstLocalizedProp(
          locale,
          managedContent.managedResource,
          MediaFeedFields.DESCRIPTION
        )}
        authors={extractLocalizedProps(
          locale,
          managedContent.managedResource,
          MediaFeedContentFields.AUTHOR
        )}
        platforms={extractLocalizedProps(
          locale,
          managedContent.managedResource,
          MediaFeedContentFields.PLATFORM
        ).map(({ value: name = "" }, index) => {
          const platformWithName = ((allContent.get(Resources.MEDIA_PLATFORM) ??
            []) as ManagedMediaPlatform[]).find(mediaPlatform => {
            return (
              extractFirstLocalizedProp(
                locale,
                mediaPlatform,
                MediaPlatformFields.NAME
              ).value === name
            );
          });
          return {
            name,
            icon:
              platformWithName &&
              extractFirstLocalizedProp(
                locale,
                platformWithName,
                MediaPlatformFields.ICON
              ).value,
            url:
              extractLocalizedProps(
                locale,
                managedContent.managedResource,
                MediaFeedFields.PLATFORM_LINK
              )?.[index]?.value ?? ""
          };
        })}
        categories={extractLocalizedProps(
          locale,
          managedContent.managedResource,
          MediaFeedContentFields.CATEGORY
        )}
      />
    ),
    []
  );
  const formLinkInfo = React.useMemo(
    () =>
      mapOf<CommitResource, string>(
        Resources.AUTHORS,
        MediaFeedContentFields.AUTHOR,
        Resources.MEDIA_PLATFORM,
        MediaFeedContentFields.PLATFORM
      ),
    []
  );
  return (
    <BaseContentManagementPage
      resource={resource}
      renderViewModelContent={renderContentItem}
      formLinkInfo={formLinkInfo}
      searchFields={searchFields}
      searchDebounce={0}
    />
  );
};

export default MediaFeedContentPage;
