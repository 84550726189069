import makeStyles from "@material-ui/core/styles/makeStyles";
import pageContainer from "../../styles/pageContainer";

export const useStyles = makeStyles(theme => ({
  viewPortCenter: {
    position: "relative",
    top: "50vh",
    left: "50vw"
  },
  container: {
    ...pageContainer(theme)
  }
}));
