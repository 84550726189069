import { Typography } from "@material-ui/core";
import * as React from "react";
import ForwardIcon from "@material-ui/icons/Forward";
import Grid from "@material-ui/core/Grid";
import WarningWrapper from "../../warning/WarningWrapper";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import distinct from "../../../utils/arrays/distinct";

const renderValue = (
  value: string | undefined,
  isImage: boolean,
  isUrl: boolean
) =>
  value &&
  (isImage ? (
    <Avatar src={value} />
  ) : isUrl ? (
    <Link target="_blank" href={value}>
      {value}
    </Link>
  ) : (
    <Typography>{value}</Typography>
  ));

const getWarningWrapperProps = ({
  previous,
  current,
  isMandatoryValue,
  isLocalizationRequired
}: {
  previous: string | undefined;
  current: string | undefined;
  isMandatoryValue: boolean;
  isLocalizationRequired: boolean;
}) => ({
  show:
    (isMandatoryValue && !previous && !current) ||
    (isLocalizationRequired && (!previous || !current)),
  message: isLocalizationRequired ? "Missing Translation" : "Missing Value",
  tooltip: isLocalizationRequired
    ? "This field is missing translation"
    : "No value provided for this field"
});

const ContentFieldDiff = ({
  previous,
  current,
  isImage,
  isUrl,
  isMandatoryValue,
  isLocalizationRequired
}: {
  previous: string | undefined;
  current: string | undefined;
  isImage: boolean | undefined;
  isUrl: boolean | undefined;
  isMandatoryValue: boolean;
  isLocalizationRequired: boolean;
}) => {
  const [distinctPrev, distinctCurrent] = distinct([previous, current]);
  return (
    <WarningWrapper
      {...getWarningWrapperProps({
        previous: distinctPrev,
        current: distinctCurrent,
        isLocalizationRequired,
        isMandatoryValue
      })}
    >
      <Grid container justify="center" alignItems="center">
        <Grid item>{renderValue(distinctPrev, !!isImage, !!isUrl)}</Grid>
        {distinctPrev && distinctCurrent && (
          <Grid item>
            <ForwardIcon />
          </Grid>
        )}
        <Grid item>{renderValue(distinctCurrent, !!isImage, !!isUrl)}</Grid>
      </Grid>
    </WarningWrapper>
  );
};

export default ContentFieldDiff;
