import {
  extractContentField,
  extractFirstLocalizedProp
} from "../../../../../utils/data/resources/managed";
import { FormFieldType } from "../FormFieldType";
import LinkFormFieldType, {
  LinkFormFieldOption
} from "../link/LinkFormFieldType";
import AvatarListItem from "../../../../list/item/AvatarListItem";
import * as React from "react";
import ManagedPodcast from "../../../../../data/ManagedPodcast";
import { Resources } from "../../../../../data/Resources";
import { CommitResource } from "../../../../../api/commits/Commit";
import { Locale } from "../../../../../locale";
import ManagedResource from "../../../../../data/ManagedResource";
import MediaPlatform, {
  MediaPlatformFields
} from "../../../../../api/content/MediaPlatform";
import ManagedVideo from "../../../../../data/ManagedVideo";

export const platformLinkOption = ({
  locale,
  currentLocale,
  resource,
  linkedFieldName,
  managedContent
}: {
  locale: Locale;
  currentLocale: Locale;
  resource?: ManagedPodcast | ManagedVideo;
  linkedFieldName: string;
  managedContent: Map<CommitResource, Map<Locale, ManagedResource<any>[]>>;
}) =>
  ({
    defaultValues:
      resource &&
      extractContentField(resource, linkedFieldName).map(name => ({
        label: name,
        value: resource
      })),
    multiChoice: true,
    id: Resources.MEDIA_PLATFORM,
    label: "Select Platform",
    type: FormFieldType.LINK,
    options: (
      managedContent.get(Resources.MEDIA_PLATFORM)?.get(locale) ?? []
    ).map(platform => ({
      label:
        extractFirstLocalizedProp(
          currentLocale,
          platform,
          MediaPlatformFields.NAME
        )?.value ?? "",
      value: platform
    })),
    renderOption: (option: LinkFormFieldOption<MediaPlatform>) => (
      <AvatarListItem
        src={
          extractFirstLocalizedProp(
            currentLocale,
            option.value,
            MediaPlatformFields.ICON
          ).value
        }
        text={
          extractFirstLocalizedProp(
            currentLocale,
            option.value,
            MediaPlatformFields.NAME
          ).value ?? ""
        }
      />
    )
  } as LinkFormFieldType<MediaPlatform>);
