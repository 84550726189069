import { Header } from "./Header";
import * as React from "react";
import { HeaderViewModel } from "./HeaderViewModel";
import User from "../../api/auth/User";
import AuthApi from "../../api/auth/AuthApi";
import compose from "../../utils/compose";
import { withRepositories } from "../hoc/withRepositories";
import withLCE from "../hoc/withLCE";
import { useObservableRepositoryLCE } from "../../hooks/useObservableRepositoryLCE";
import LocalesRepository from "../../repository/use-case/locales/LocalesRepository";
import { identity } from "../../utils/functional";

interface HeaderWrapperProps {
  onDrawerToggle: () => void;
  onBrightnessToggle: () => void;
  currentUser: User | null;
  isAuthorized: boolean | undefined;
  authApi: AuthApi;
  isDarkTheme: boolean;
  onLocaleSelected: (code: string) => void;
  locale: string;
}

const HeaderWrapper = (
  props: HeaderWrapperProps & { content: { locales?: string[] } | undefined }
) => (
  <Header
    viewModel={
      new HeaderViewModel(
        props.authApi,
        props.currentUser,
        !!props.isAuthorized,
        props.isDarkTheme,
        props.locale,
        props.content?.locales || []
      )
    }
    onDrawerToggle={props.onDrawerToggle}
    onBrightnessToggle={props.onBrightnessToggle}
    onLocaleSelected={props.onLocaleSelected}
  />
);

const HeaderHOC = compose(
  withRepositories(repositories => ({
    localesRepository: repositories.locales
  })),
  withLCE(
    (props: { localesRepository: LocalesRepository }) =>
      useObservableRepositoryLCE(
        ([locales]) => Promise.resolve({ locales }),
        [],
        props.localesRepository
      ),
    {
      showLoadingScreen: false,
      displayError: false
    }
  ),
  React.memo
)(HeaderWrapper) as React.ComponentType<HeaderWrapperProps>;

export default HeaderHOC;
