import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Pair } from "../../../../../utils/tuples/Pair";
import TextFormField from "../text/TextFormField";
import { Locale } from "../../../../../locale";
import FormFieldBaseProps from "../FormFieldBaseProps";
import FormField from "../FormField";
import { LocalizedProp } from "../../../../../types/domainTypes";
import { usePrevious } from "../../../../../hooks/usePrevious";
import _ from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";

type MapData = Pair<
  any,
  {
    value: LocalizedProp<string> | undefined;
    label: string;
  }
>;

interface MapFormFieldProps {
  dataFactory: (currentDependencies: { [id: string]: string[] }) => MapData[];
  renderKey: (key: any) => JSX.Element;
  currentFields: {
    [id: string]: string[];
  };
  currentLinksById?: {
    [fieldId: string]: Pair<string, string>[];
  };
  field: FormField;
  onValuesChanged: (locale: string, fieldId: string, values: string[]) => void;
  locale: Locale;
}

const textFieldStyle = { flex: 1 };
const unusedFun = () => {};

const MapFormField = ({
  locale,
  dataFactory,
  renderKey,
  currentFields,
  currentLinksById,
  field,
  onCurrentValueResolved,
  onValuesChanged
}: MapFormFieldProps & Omit<FormFieldBaseProps<string[]>, "locale">) => {
  const [currentValues, setValues] = useState<{
    [key: number]: string | undefined;
  }>({});
  const onTextChanged = React.useCallback(
    (index, value) => {
      setValues({ ...currentValues, [index]: value });
    },
    [currentValues]
  );
  const data = React.useMemo(() => {
    const linkDependencies = Object.keys(currentLinksById ?? {})
      .filter(id => field.formFieldOptions.dependencies?.includes(id))
      .map(fieldId => {
        const linkPairs = currentLinksById?.[fieldId] ?? [];
        return {
          [fieldId]: linkPairs.map(pair => pair.value)
        };
      })
      .reduce(
        (allDependencies, dependency) => ({
          ...allDependencies,
          ...dependency
        }),
        {}
      );
    const valueDependencies = Object.keys(currentFields)
      .filter(id => field.formFieldOptions.dependencies?.includes(id))
      .reduce((relevantFields, id) => {
        relevantFields[id] = currentFields[id];
        return relevantFields;
      }, {} as { [id: string]: string[] });
    const mergedDependencies = {
      ...linkDependencies,
      ...valueDependencies
    };
    return dataFactory(mergedDependencies) ?? [];
  }, [
    currentFields,
    currentLinksById,
    dataFactory,
    field.formFieldOptions.dependencies
  ]);
  // useEffect(() => {
  // }, [data]);
  useEffect(() => {
    const allValuesLocalized = data.reduce(
      (isLocalized, keyValuePair) =>
        isLocalized && (keyValuePair.value?.value?.isLocalized ?? true),
      true
    );
    if (allValuesLocalized) {
      onCurrentValueResolved(
        data.map(
          ({ value: elementValue }, index) =>
            elementValue?.value?.value ?? currentFields[field.id][index] ?? ""
        )
      );
    }
  }, []);
  const previousData = usePrevious(data);
  useDeepCompareEffect(() => {
    const remappedValues = data.reduce((remappedValues, currentPair, index) => {
      const previousIndex = (previousData ?? data).findIndex(previousPair =>
        _.isEqual(previousPair, currentPair)
      );
      remappedValues[index] =
        currentValues[previousIndex] ?? currentPair.value?.value?.value ?? "";
      return remappedValues;
    }, {} as { [index: number]: string });
    onValuesChanged(locale, field.id, Object.values(remappedValues));
    if (!_.isEqual(remappedValues, currentValues)) {
      setValues(remappedValues);
    }
  }, [currentValues, data, onValuesChanged, previousData]);
  const getField = React.useCallback(
    index =>
      ({
        id: index,
        label: data[index].value.label,
        defaultValue: data[index].value.value
      } as FormField),
    [data]
  );
  const getElementCurrentFields = React.useCallback(
    index => ({
      [index]: [currentFields[field.id]?.[index]] ?? ""
    }),
    [currentFields, field.id]
  );
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography>{field.label}</Typography>
      </Grid>
      {data.map(({ key, value }, index) => (
        <React.Fragment key={key}>
          <Grid container item direction="row" alignItems="center" spacing={1}>
            <Grid item>{renderKey(key)}</Grid>
            <Grid item style={textFieldStyle}>
              <TextFormField
                field={getField(index)}
                onChange={onTextChanged}
                currentFields={getElementCurrentFields(index)}
                locale={locale}
                onCurrentValueResolved={unusedFun}
                key={key}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default MapFormField;
