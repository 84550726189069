import * as React from "react";
import { useContext } from "react";
import { CardContent, Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CardHeader from "@material-ui/core/CardHeader";
import { StatisticsContext } from "../../../components/context/statistics";
import useLCE from "../../../hooks/useLCE";
import { LCEWrapper } from "../../../components/lce/LCEWrapper";

const useStyles = makeStyles(theme => ({
  viewPortCenter: {
    position: "absolute",
    top: "50vh",
    left: "50vw",
    transform: "translate(-50%, -50%)"
  },
  dashboardContainer: {
    margin: "5vw 5vh"
  },
  cardTitle: {
    backgroundColor: "#0E2958",
    color: "#FFF"
  },
  card: {
    height: "15vh"
  }
}));

const DashboardPage = () => {
  const styles = useStyles();
  const statisticsApi = useContext(StatisticsContext)!;
  const lce = useLCE(() => statisticsApi.getQuotesStatistics("EN"));

  return (
    <LCEWrapper
      lce={lce}
      renderContent={quoteStatistics => (
        <Grid container className={styles.dashboardContainer}>
          <Grid item xs={1}>
            <Card className={styles.card}>
              <CardHeader
                title={
                  <Typography variant="h5" className={styles.cardTitle}>
                    Quotes
                  </Typography>
                }
                className={styles.cardTitle}
              />
              <CardContent>
                <Typography>
                  Amount of quotes: {quoteStatistics!.count}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    />
  );
};

export default DashboardPage;
