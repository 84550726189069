import React, { useCallback, useState } from "react";
import useStyles from "./styles";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper
} from "@material-ui/core";
import { DeveloperDiagnosticDialog } from "./DeveloperDiagnosticDialog";
import { contentDiscrepanciesDiagnosticOption } from "./content-discrepancies/contentDiscrepanciesDiagnosticOption";
import { DiagnosticState } from "./DiagnosticState";
import { RecursivePartial } from "../../../../utils/utilityTypes";
import { ViewModelFactory } from "../../../viewmodel/ViewModelFactory";

export interface DiagnosticOption {
  iconProvider: () => React.ElementType;
  label: string;
  render: ({
    diagnosticStateUpdater
  }: {
    diagnosticStateUpdater: (
      mergeDiagnosticState: (
        currentState: DiagnosticState
      ) => RecursivePartial<DiagnosticState>
    ) => void;
  }) => JSX.Element;
}

const DIAGNOSTIC_OPTIONS: DiagnosticOption[] = [
  contentDiscrepanciesDiagnosticOption
];

export const DeveloperDiagnosticsPage = () => {
  const [selectedDiagnosticOption, setSelectedDiagnosticOption] = useState<
    DiagnosticOption | undefined
  >(contentDiscrepanciesDiagnosticOption);
  const onDiagnosticOptionDialogCloseClick = useCallback(() => {
    setSelectedDiagnosticOption(undefined);
  }, []);

  const classes = useStyles();
  return (
    <ViewModelFactory>
      <Box className={classes.container}>
        <Paper>
          <List>
            {DIAGNOSTIC_OPTIONS.map(diagnosticOption => (
              <ListItem
                button={true}
                onClick={() => {
                  setSelectedDiagnosticOption(diagnosticOption);
                }}
              >
                <ListItemIcon>
                  {renderDiagnosticOptionIcon(diagnosticOption)}
                </ListItemIcon>
                <ListItemText primary={diagnosticOption.label} />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Box>
      <DeveloperDiagnosticDialog
        selectedDiagnosticOption={selectedDiagnosticOption}
        onCloseClick={onDiagnosticOptionDialogCloseClick}
      />
    </ViewModelFactory>
  );
};

function renderDiagnosticOptionIcon(diagnosticOption: DiagnosticOption) {
  const Icon = diagnosticOption.iconProvider();
  return <Icon />;
}
