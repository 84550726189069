import { FormInfo } from "../../pages/content-management/ContentManagementPageProps";
import { flatten } from "../../../utils/arrays/flatten";
import { mapOf, toArray, transformValues } from "../../../utils/maps";
import * as React from "react";
import { useEffect, useState } from "react";
import RevertAlertDialog from "../../dialogs/RevertAlertDialog";
import DeleteAlertDialog from "../../dialogs/DeleteAlertDialog";
import FormDialog from "../../dialogs/FormDialog/FormDialog";
import { CommitResource } from "../../../api/commits/Commit";
import ManagedResource from "../../../data/ManagedResource";
import { Pair } from "../../../utils/tuples/Pair";
import { Locale } from "../../../locale";
import compose from "../../../utils/compose";
import withLocale, { LocalePropsConsumer } from "../../hoc/withLocale";
import ContentManagementDialogsProps from "./ContentManagementDialogsProps";
import { ArchiveAlertDialog } from "../../dialogs/ArchiveAlertDialog";
import { ARCHIVE_ACTION, UN_ARCHIVE_ACTION } from "../actions/common-actions";
import { UnArchiveAlertDialog } from "../../dialogs/UnArchiveAlertDialog";

const ContentManagementDialogs = ({
  indexedFormInfoByResource,
  dialogStateModifierConsumer,
  isUpdating,
  fieldsUpdater,
  changesReverter,
  entryRemove,
  entryArchive,
  entryUnarchive,
  locale,
  defaultLocale
}: ContentManagementDialogsProps & LocalePropsConsumer) => {
  const [dialogState, setDialogState] = useState<{
    [resource: string]: {
      update?: boolean;
      restore?: boolean;
      remove?: boolean;
      add?: boolean;
      [ARCHIVE_ACTION]?: boolean;
      [UN_ARCHIVE_ACTION]?: boolean;
      managedResource?: ManagedResource<any>;
      localeToResource?: Map<Locale, ManagedResource<any> | undefined>;
    };
  }>({});
  useEffect(() => {
    dialogStateModifierConsumer(
      ({
        resourceType,
        actionType,
        localeToResource,
        managedResource,
        isOpen
      }) => {
        setDialogState({
          ...dialogState,
          [resourceType]: {
            ...(actionType && { [actionType]: isOpen }),
            localeToResource,
            managedResource
          }
        });
      }
    );
    return () => {
      dialogStateModifierConsumer(undefined);
    };
  }, [dialogState]);

  const handleDialogClose = (resourceType: CommitResource) =>
    setDialogState({ ...dialogState, [resourceType]: {} });
  const handleDialogUpdate = async (
    resourceType: CommitResource,
    updateData: Map<
      Locale,
      {
        updatedFields: Map<string, string[]>;
        updatedLinks: Map<string, Pair<string, string>[]>;
      }
    >
  ) => {
    fieldsUpdater(
      resourceType,
      dialogState[resourceType]?.managedResource,
      updateData
    );
  };

  const handleDialogRevert = (resourceType: CommitResource) => {
    dialogState[resourceType]?.managedResource &&
      changesReverter(resourceType, dialogState[resourceType]?.managedResource);
  };

  const handleDialogRemove = (resourceType: CommitResource) => {
    dialogState[resourceType]?.managedResource &&
      entryRemove(resourceType, dialogState[resourceType]?.managedResource);
  };

  const handleDialogArchive = (resourceType: CommitResource) => {
    dialogState[resourceType]?.managedResource &&
      entryArchive(resourceType, dialogState[resourceType]?.managedResource);
  };

  const handleDialogUnArchive = (resourceType: CommitResource) => {
    dialogState[resourceType]?.managedResource &&
      entryUnarchive(resourceType, dialogState[resourceType]?.managedResource);
  };

  return (
    <>
      {flatten(
        toArray(
          indexedFormInfoByResource || new Map<CommitResource, FormInfo>(),
          (formInfo, resourceType, index) => {
            const capitalizedResourceName = `${formInfo.name
              .charAt(0)
              .toUpperCase()}${formInfo.name.substring(1).toLowerCase()}`;
            const description = `Change anything related to this ${formInfo.name}. Keep in mind that you are the owner of anything you change.`;
            const commonDialogPros = {
              isLoading: isUpdating(resourceType),
              handleClose: () => handleDialogClose(resourceType)
            };
            const handleNewResourceRequest = (resource: string) => {
              setDialogState({
                ...dialogState,
                [resource]: { add: true }
              });
            };
            return [
              <RevertAlertDialog
                key={`revert-${index}`}
                handleConfirm={() => handleDialogRevert(resourceType)}
                isOpen={!!dialogState[resourceType]?.restore}
                revertInfo={`this ${formInfo.name} changes`}
                {...commonDialogPros}
              />,
              <DeleteAlertDialog
                key={`delete-${index}`}
                handleConfirm={() => handleDialogRemove(resourceType)}
                isOpen={!!dialogState[resourceType]?.remove}
                deleteInfo={`this ${formInfo.name}`}
                {...commonDialogPros}
              />,
              <ArchiveAlertDialog
                key={`archive-${index}`}
                handleConfirm={() => handleDialogArchive(resourceType)}
                isOpen={!!dialogState[resourceType]?.[ARCHIVE_ACTION]}
                archiveInfo={`this ${formInfo.name}`}
                {...commonDialogPros}
              />,
              <UnArchiveAlertDialog
                key={`unarchive-${index}`}
                handleConfirm={() => handleDialogUnArchive(resourceType)}
                isOpen={!!dialogState[resourceType]?.[UN_ARCHIVE_ACTION]}
                archiveInfo={`this ${formInfo.name}`}
                {...commonDialogPros}
              />,
              <FormDialog
                key={`update-${index}`}
                actionButtonText="Update"
                description={description}
                localeToFields={transformValues(
                  dialogState[resourceType]?.localeToResource ||
                    new Map<Locale, ManagedResource<any> | undefined>(),
                  (resource, fieldLocale) =>
                    formInfo.editFields(fieldLocale, resource!)
                )}
                handleFormSave={saveData =>
                  handleDialogUpdate(resourceType, saveData)
                }
                isOpen={!!dialogState[resourceType]?.update}
                title={`Edit ${capitalizedResourceName}`}
                {...commonDialogPros}
                handleNewResourceRequest={handleNewResourceRequest}
                defaultLocale={defaultLocale}
                resourceType={resourceType}
              />,
              <FormDialog
                key={`add-${index}`}
                actionButtonText="Add"
                description={description}
                localeToFields={transformValues(
                  dialogState[resourceType]?.localeToResource ||
                    mapOf(defaultLocale, undefined, locale, undefined),
                  (resource, fieldLocale) =>
                    formInfo.addFields(fieldLocale, resource)
                )}
                handleFormSave={saveData =>
                  handleDialogUpdate(resourceType, saveData)
                }
                isOpen={!!dialogState[resourceType]?.add}
                title={`Add ${capitalizedResourceName}`}
                {...commonDialogPros}
                handleNewResourceRequest={handleNewResourceRequest}
                defaultLocale={defaultLocale}
                resourceType={resourceType}
              />
            ];
          }
        )
      )}
    </>
  );
};

export default compose(
  React.memo,
  withLocale
)(ContentManagementDialogs) as React.ComponentType<
  ContentManagementDialogsProps
>;
