import { useCallback, useState } from "react";
import useLCE2 from "./useLCE2";
import { LCE } from "../architecture/lce/lce";

const useActionLCE = function<T, P = undefined, E extends Error = Error>(
  action: (payload: P) => Promise<T>
): [LCE<T | undefined>, (payload: P) => void] {
  const [payloadWrapper, wrapPayload] = useState<
    | {
        payload: P;
      }
    | undefined
  >();
  const invokeAction = useCallback((payload: P) => {
    wrapPayload({ payload });
  }, []);
  const actionLoading = useCallback(() => {
    if (payloadWrapper) {
      return action(payloadWrapper.payload);
    }
    return Promise.resolve(undefined);
  }, [action, payloadWrapper]);
  const lce = useLCE2<T | undefined>(actionLoading);

  return [lce, invokeAction];
};

export default useActionLCE;
