import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles(theme => ({
  scrappedContentGridHeader: {
    flexDirection: "column",
    padding: "0 4px"
  },
  scrapedContentItemContainer: {
    width: "100%",
    height: "100%"
  },
  scrapedContentItemCategory: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  scrapedQuotePartsContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%"
  },
  scrapedSourceResultsHeaderTable: { marginTop: "8px" }
}));
