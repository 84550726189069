import ManagedCategory from "../../../../../data/ManagedCategory";
import FormField from "../FormField";
import { extractFirstLocalizedProp } from "../../../../../utils/data/resources/managed";
import { FormFieldType } from "../FormFieldType";
import { Locale } from "../../../../../locale";
import { CategoryFields } from "../../../../../api/content/Category";
import { CommitResource } from "../../../../../api/commits/Commit";
import ManagedResource from "../../../../../data/ManagedResource";
import { OptionalProperties } from "../../../../../utils/generics/OptionalProperties";

const createCategoryFormFields: (
  currentLocale: Locale
) => ({
  resource
}: {
  resource?: ManagedCategory | undefined;
}) => OptionalProperties<FormField, "validate">[] = currentLocale => ({
  resource
} = {}) => [
  {
    id: CategoryFields.NAME,
    label: "Name",
    defaultValue:
      resource &&
      extractFirstLocalizedProp(currentLocale, resource, CategoryFields.NAME),
    type: FormFieldType.TEXT
  },
  {
    id: CategoryFields.IMAGE_URL,
    label: "Image (link)",
    defaultValue:
      resource &&
      extractFirstLocalizedProp(
        currentLocale,
        resource,
        CategoryFields.IMAGE_URL
      ),
    type: FormFieldType.TEXT
  }
];
export default createCategoryFormFields;
