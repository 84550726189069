import * as architecture from "../../architecture/view-model/ViewModelFactory";
import { ContentDiscrepanciesViewModel } from "../developer-console/diagnostics/viewmodel/ContentDiscrepanciesViewModel";
import { AnnouncementsViewModel } from "../community/announcements/AnnouncementsViewModel";

export enum ViewModelKeys {
  CONTENT_DISCREPANCIES_VIEW_MODEL = "CONTENT_DISCREPANCIES_VIEW_MODEL",
  ANNOUNCEMENTS_VIEW_MODEL = "ANNOUNCEMENTS_VIEW_MODEL"
}

export type ViewModelFactoryMap = architecture.ViewModelFactoryMap<{
  [ViewModelKeys.CONTENT_DISCREPANCIES_VIEW_MODEL]: () => ContentDiscrepanciesViewModel;
  [ViewModelKeys.ANNOUNCEMENTS_VIEW_MODEL]: () => AnnouncementsViewModel;
}>;
