import { Locale } from "../../../../../locale";
import FormField from "../FormField";
import { extractFirstLocalizedProp } from "../../../../../utils/data/resources/managed";
import { FormFieldType } from "../FormFieldType";
import { ManagedAuthor } from "../../../../../data/ManagedAuthor";
import { AuthorFields } from "../../../../../api/content/Author";
import { OptionalProperties } from "../../../../../utils/generics/OptionalProperties";

const createAuthorFormFields: (
  currentLocale: Locale
) => ({
  resource
}: {
  resource?: ManagedAuthor | undefined;
}) => OptionalProperties<FormField, "validate">[] = currentLocale => ({
  resource
} = {}) => [
  {
    id: AuthorFields.NAME,
    label: "Name",
    defaultValue:
      resource &&
      extractFirstLocalizedProp(currentLocale, resource, AuthorFields.NAME),
    type: FormFieldType.TEXT
  },
  {
    id: AuthorFields.DESCRIPTION,
    label: "Description",
    defaultValue:
      resource &&
      extractFirstLocalizedProp(
        currentLocale,
        resource,
        AuthorFields.DESCRIPTION
      ),
    type: FormFieldType.TEXT
  },
  {
    id: AuthorFields.CONTEXT,
    label: "Context",
    defaultValue:
      resource &&
      extractFirstLocalizedProp(currentLocale, resource, AuthorFields.CONTEXT),
    type: FormFieldType.TEXT
  },
  {
    id: AuthorFields.IMAGE_URL,
    label: "Image (link)",
    defaultValue:
      resource &&
      extractFirstLocalizedProp(
        currentLocale,
        resource,
        AuthorFields.IMAGE_URL
      ),
    type: FormFieldType.TEXT
  },
  {
    id: AuthorFields.ARTICLE_URL,
    label: "Article (link)",
    defaultValue:
      resource &&
      extractFirstLocalizedProp(
        currentLocale,
        resource,
        AuthorFields.ARTICLE_URL
      ),
    type: FormFieldType.TEXT
  },
  {
    id: AuthorFields.LICENSE,
    label: "License",
    defaultValue:
      resource &&
      extractFirstLocalizedProp(currentLocale, resource, AuthorFields.LICENSE),
    type: FormFieldType.TEXT
  }
];

export default createAuthorFormFields;
