export type LCE<T, E = Error> = {
  isLoading: boolean;
  content?: T;
  error?: E;
};

const LOADING_LCE = {
  isLoading: true
};

export function loading<T, E = Error>(): LCE<T, E> {
  return LOADING_LCE;
}

export function content<T, E = Error>(content: T): LCE<T, E> {
  return {
    isLoading: false,
    content
  };
}

export function error<T, E = Error>(e: E | undefined): LCE<T, E> {
  return {
    isLoading: false,
    error: e
  };
}

export const lce = {
  loading,
  content,
  error
};
