import { CalendarEvent } from "../../repository/use-case/calendar/CalendarEvent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import React from "react";

interface CalendarEventItemProps {
  event: CalendarEvent;
  onClick?: (element: React.MouseEvent<HTMLButtonElement>) => void;
}

export const CalendarEventItem = ({
  event,
  onClick
}: CalendarEventItemProps) => {
  return (
    <Button
      variant="contained"
      style={{ backgroundColor: event.color, textTransform: "none", width: '100%' }}
      onClick={onClick}
    >
      <Typography color="textPrimary">{event.title}</Typography>
    </Button>
  );
};
