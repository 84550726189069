import { Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React, { Attributes } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

export interface DestructiveAlertDialogProps extends Attributes {
  isOpen: boolean;
  title: string;
  message: string;
  confirmationText: string;
  handleClose: () => void;
  handleConfirm: () => void;
  isLoading: boolean;
}

export const InformativeAlertDialog = (props: DestructiveAlertDialogProps) => {
  return (
    <Dialog open={props.isOpen} onClose={props.handleClose}>
      {props.isLoading && <LinearProgress variant="query" />}
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
        <Button onClick={props.handleConfirm} autoFocus>
          {props.confirmationText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
