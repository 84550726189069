import { useHistory, useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import queryString from "query-string";
import { deepMerge } from "../utils/objects/deepMerge";
import { RecursivePartial } from "../utils/utilityTypes";

export function useInPageNavigation<T extends object>(): [
  Partial<T>,
  (params: T) => void,
  (params: RecursivePartial<T>) => void,
  () => void
] {
  const history = useHistory();
  const location = useLocation();

  const [pagePrams, setPagePrams] = useState<Partial<T>>({});

  useEffect(() => {
    if (location.search.trim().length) {
      setPagePrams(
        (queryString.parse(location.search, {
          parseBooleans: true,
          parseNumbers: true
        }) as unknown) as Partial<T>
      );
    } else {
      setPagePrams({});
    }
  }, [location]);

  const setParams = useCallback(
    (pathParams: T) => {
      const query = composeQueryString(pathParams);
      const path = location.pathname.endsWith("/")
        ? location.pathname.slice(0, -1)
        : location.pathname;
      history.replace(`${path}${query}`);
    },
    [history, location.pathname]
  );
  const updateParams = useCallback(
    (partialParams: RecursivePartial<T>) => {
      setParams(deepMerge(pagePrams as T, partialParams));
    },
    [pagePrams, setParams]
  );

  return [pagePrams, setParams, updateParams, history.goBack];
}

function composeQueryString(pathParams: object, withPrefix?: string): string {
  return Object.keys(pathParams).reduce((queryAcc, param) => {
    const pathParam = (pathParams as { [param: string]: any })[param];
    if (pathParam === undefined) {
      return queryAcc;
    }
    if (!queryAcc.length) {
      return withPrefix
        ? `${queryAcc}${withPrefix}?${param}=${pathParam}`
        : `${queryAcc}?${param}=${pathParam}`;
    }
    return `${queryAcc}&${param}=${pathParam}`;
  }, "");
}
