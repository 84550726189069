import {
  extractFirstContentField,
  extractFirstLocalizedProp,
  extractLocalizedProps
} from "../../../../../utils/data/resources/managed";
import { Resources } from "../../../../../data/Resources";
import { FormFieldType } from "../FormFieldType";
import { AuthorFields } from "../../../../../api/content/Author";
import LinkFormFieldType, {
  LinkFormFieldOption
} from "../link/LinkFormFieldType";
import { ManagedAuthor } from "../../../../../data/ManagedAuthor";
import AvatarListItem from "../../../../list/item/AvatarListItem";
import * as React from "react";
import { Locale } from "../../../../../locale";
import { CommitResource } from "../../../../../api/commits/Commit";
import ManagedResource from "../../../../../data/ManagedResource";

export const authorLinkOption = ({
  locale,
  currentLocale,
  managedContent,
  resource,
  linkedFieldName
}: {
  locale: Locale;
  currentLocale: Locale;
  managedContent: Map<CommitResource, Map<Locale, ManagedResource<any>[]>>;
  resource?: ManagedResource<any>;
  linkedFieldName: string;
}) =>
  ({
    defaultValues:
      resource &&
      extractLocalizedProps(currentLocale, resource, linkedFieldName).map(
        localizedProp => ({
          value: resource,
          label: localizedProp.value
        })
      ),
    id: Resources.AUTHORS,
    label: "Select Author",
    type: FormFieldType.LINK,
    options: (managedContent.get(Resources.AUTHORS)?.get(locale) || []).map(
      author => ({
        label:
          extractFirstLocalizedProp(currentLocale, author, AuthorFields.NAME)
            .value || "",
        value: author
      })
    ),
    renderOption: (option: LinkFormFieldOption<ManagedAuthor>) => (
      <AvatarListItem
        src={extractFirstContentField(option.value, "imageUrl")}
        text={
          extractFirstLocalizedProp(
            currentLocale,
            option.value,
            AuthorFields.NAME
          ).value || ""
        }
      />
    )
  } as LinkFormFieldType<ManagedAuthor>);
