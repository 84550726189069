import {
  FileManagerFileListItem,
  FileManagerViewModelBase,
  ViewMode
} from "../FileManagerViewModelBase";
import { useLocalViewModel } from "../../../architecture/view-model/useViewModel";
import { FileManagerGridDisplay } from "./FileManagerGridDisplay";
import React, { useCallback } from "react";
import { LocalViewModelFactory } from "../../../architecture/view-model/LocalViewModelFactory";
import { FileContextMenu } from "./FileContextMenu";
import { EmptyFolderContents } from "./EmptyFolderContents";
import { FileManagerListDisplay } from "./FileManagerListDisplay";
import { FileManagerDisplayPropsBase } from "./file-manager-display-base";
import { Motion, spring } from "react-motion";
import { Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { FileManagerFileDetails } from "../file-details/FileManagerFileDetails";

export interface FileManagerDisplayProps {
  viewModelFactory: LocalViewModelFactory<FileManagerViewModelBase>;
}

export const FileManagerDisplay = (props: FileManagerDisplayProps) => {
  const [viewState, viewModel] = useLocalViewModel(props.viewModelFactory);
  const onFileClick = useCallback(
    fileListItem => viewModel.onFileClick(fileListItem),
    [viewModel]
  );
  const renderContextMenu = useCallback(
    (fileItem: FileManagerFileListItem) => {
      return (
        <FileContextMenu
          fileItem={fileItem}
          viewModelFactory={props.viewModelFactory}
        />
      );
    },
    [props.viewModelFactory]
  );

  if (
    !viewState.pathContents.loading &&
    !viewState.pathContents.fileList.length
  ) {
    return <EmptyFolderContents />;
  }

  const Component = componentForDisplayMode(viewState.viewMode);

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      height={"100%"}
      overflow={"hidden"}
    >
      <div style={{ flexGrow: 1, overflow: "auto" }}>
        <Component
          fileList={viewState.pathContents.fileList}
          onFileClick={onFileClick}
          renderContextMenu={renderContextMenu}
        />
      </div>
      <Motion
        defaultStyle={{ width: 0 }}
        style={{
          width: spring(viewState.fileDetailedView?.fileItem ? 100 : 0)
        }}
      >
        {interpolatedStyle => {
          if (interpolatedStyle.width === 0) {
            return <></>;
          } else {
            return (
              <Paper
                style={{
                  width: `${(interpolatedStyle.width / 100) * 33}%`,
                  height: "100%"
                }}
                elevation={16}
              >
                <FileManagerFileDetails
                  viewModelFactory={props.viewModelFactory}
                />
              </Paper>
            );
          }
        }}
      </Motion>
    </Box>
  );
};

function componentForDisplayMode(
  displayMode: ViewMode
): React.ComponentType<FileManagerDisplayPropsBase> {
  switch (displayMode) {
    case ViewMode.GRID:
      return FileManagerGridDisplay;
    case ViewMode.LIST:
      return FileManagerListDisplay;
  }
  throw new Error(`Unknown displayMode: ${displayMode}`);
}
