import * as React from "react";
import { ContentOwnerViewModel } from "./ContentOwnerViewModel";
import { Avatar, createStyles, Theme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import TimelineAction from "./TimelineAction";

interface ContentOwnerProps {
  viewModel: ContentOwnerViewModel;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3)
    },
    smallHalfMargin: {
      marginRight: theme.spacing(1.5),
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    create: {
      color: theme.contentManagement?.create?.color
    },
    update: {
      color: theme.contentManagement?.update?.color
    },
    remove: {
      color: theme.contentManagement?.remove?.color
    },
    zeroPadding: {
      padding: 0
    }
  })
);

const ContentOwner = (props: ContentOwnerProps) => {
  const classes = useStyles();
  return (
    <List>
      {props.viewModel.contentOwnerTimeline.map((timelineEntry, index) => {
        return (
          <ListItem className={classes.zeroPadding} key={index}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-end"
            >
              <Grid item key="avatar">
                <Tooltip title={timelineEntry.owner?.name || ""}>
                  <Avatar
                    src={timelineEntry.owner?.imageUrl || ""}
                    className={classes.small}
                  />
                </Tooltip>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justify="center"
                alignItems="flex-end"
                key="content"
              >
                <List>
                  {timelineEntry.timeline.map((timeline, index) => (
                    <ListItem className={classes.zeroPadding} key={index}>
                      <TimelineAction
                        marginRight="8px"
                        colorType={
                          props.viewModel.contentDividerModificationColor
                        }
                        timeline={timeline}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ContentOwner;
