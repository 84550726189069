import makeStyles from "@material-ui/core/styles/makeStyles";
import scrollableContent from "../../styles/scrollableContent";
import { Theme } from "@material-ui/core";

const SPACING = "0.5vw";
const SIDE_WIDTH = "6%";

export const ownerStyle = (theme: Theme) => ({
  owner: {
    width: SIDE_WIDTH,
    marginLeft: SPACING,
    display: "flex",
    flexDirection: ("row" as unknown) as "row",
    justifyContent: "flex-end"
  }
});

export const actionsStyle = (theme: Theme) => ({
  actions: {
    marginRight: SPACING,
    width: SIDE_WIDTH
  }
});

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%"
  },
  divider: {
    alignSelf: "stretch",
    height: "auto",
    marginLeft: "0.2vw",
    width: "2px"
  },
  ...actionsStyle(theme),
  ...ownerStyle(theme),
  ...scrollableContent(theme)
}));

export default useStyles;
