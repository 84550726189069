import ContentRepository from "../../../repository/use-case/content/ContentRepository";
import { CommitsRepository } from "../../../repository/use-case/commits/CommitsRepository";
import UserRepository from "../../../repository/use-case/user/UserRepository";
import LocalesRepository from "../../../repository/use-case/locales/LocalesRepository";
import useObservableLCE from "../../../hooks/userObservableLCE";
import { map } from "rxjs/operators";
import { combineLatest } from "rxjs";
import { getSupportedLocales } from "../../../locale";

export function usePublishObservableLCE({
  contentRepository,
  commitsRepository,
  userRepository,
  localesRepository,
  deps = []
}: {
  contentRepository: ContentRepository;
  commitsRepository: CommitsRepository;
  userRepository: UserRepository;
  localesRepository: LocalesRepository;
  deps?: any[];
}) {
  const localizedSources = [contentRepository, commitsRepository];
  const otherSources = [userRepository];
  const combiner = ([content, commits, users]: any[]) => ({
    content,
    commits,
    users,
    defaultLocale: localesRepository.defaultLocale
  });
  return useObservableLCE(
    () =>
      Promise.all<any>([
        ...localizedSources.map(repository =>
          repository.loadLocalizedData(getSupportedLocales())
        ),
        ...otherSources.map(repository => repository.loadData())
      ]).then(combiner),
    combineLatest(
      (localizedSources.map(repository =>
        repository.getLocalizedDataObservable(getSupportedLocales())
      ) as any[]).concat(
        otherSources.map(repository => repository.getDataObservable())
      )
    ).pipe(map(combiner)),
    deps
  );
}
