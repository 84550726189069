export function swap<T>(array: T[], index1: number, index2: number): T[] {
  const copy = [...array];
  swapMutable(copy, index1, index2);
  return copy;
}

export function swapMutable<T>(array: T[], index1: number, index2: number) {
  const prev = array[index1];
  array[index1] = array[index2];
  array[index2] = prev;
}
