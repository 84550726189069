export enum Resources {
  QUOTES = "quotes",
  AUTHORS = "authors",
  CATEGORIES = "categories",
  USERS = "users",
  PODCASTS = "podcasts",
  VIDEOS = "videos",
  BOOKS = "books",
  MEDIA_PLATFORM = "mediaPlatforms",
  WEEKLY_TIP = "weeklyTip"
}
