import makeStyles from "@material-ui/core/styles/makeStyles";
import pageContainer from "../../../../styles/pageContainer";

const useStyles = makeStyles(theme => ({
  container: {
    ...pageContainer(theme)
  }
}));

export default useStyles;
