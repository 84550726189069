import compose from "../../../utils/compose";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { ExpansionPanel } from "@material-ui/core";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import ContentStatisticsGrid from "../../../components/publish/content/ContentStatisticsGrid";
import Divider from "@material-ui/core/Divider";
import { withAuth } from "../../../components/hoc/withAuth";
import { withRepositories } from "../../../components/hoc/withRepositories";
import withLCE from "../../../components/hoc/withLCE";
import ContentRepository from "../../../repository/use-case/content/ContentRepository";
import { CommitsRepository } from "../../../repository/use-case/commits/CommitsRepository";
import UserRepository from "../../../repository/use-case/user/UserRepository";
import { ContentData } from "../../../repository/use-case/content/ContentData";
import {
  Commit,
  CommitResource,
  commitResources
} from "../../../api/commits/Commit";
import User from "../../../api/auth/User";
import { Locale } from "../../../locale";
import LocalesRepository from "../../../repository/use-case/locales/LocalesRepository";
import { usePublishObservableLCE } from "./usePublishObservableLCE";
import mapContentToPublishPageData from "./mapContentToPublishPageData";
import SummaryPanel from "../../../components/publish/summary/SummaryPanel";
import ManagedResource from "../../../data/ManagedResource";
import withResourceManagementActions, {
  ResourceManagementProps
} from "../../../components/hoc/withResourceManagementActions";
import asMap from "../../../utils/arrays/asMap";
import ContentManagementDialogs from "../../../components/content/management/ContentManagementDialogs";
import withSystemConfiguration, {
  SystemConfigurationConsumerProps
} from "../../../components/hoc/withSystemConfiguration";
import { emptyPageData } from "./emptyPageData";
import { DialogStateCallback } from "./DialogStateCallback";
import { getFormFieldsFactory } from "./getFormFieldsFactory";
import { FormInfo } from "../../../components/pages/content-management/ContentManagementPageProps";
import SystemBanner from "../../../components/banner/SystemBanner";
import UploadBackupDialog from "../../../components/publish/backup/UploadBackupDialog";
import ReloadStagedDialog from "../../../components/publish/reload/ReloadStagedDialog";
import { useRepositories } from "../../../hooks/useRepositories";

interface PublishPageProps
  extends ResourceManagementProps,
    SystemConfigurationConsumerProps {
  content?: {
    content: Map<Locale, ContentData>;
    commits: Map<Locale, Map<CommitResource, Map<string, Commit>>>;
    users: Map<string, User>;
  };
  locale: Locale;
  defaultLocale: Locale;
  user?: User;
}

const PublishPageWrapper = (props: PublishPageProps) => {
  const { tasks } = useRepositories();

  const currentData = React.useMemo(() => {
    if (props.content) {
      return mapContentToPublishPageData(
        {
          users: props.content.users,
          defaultLocale: props.defaultLocale,
          content: props.content.content,
          commits: props.content.commits
        },
        (
          managedResource: ManagedResource<any>,
          resourceType: CommitResource,
          actionType: string,
          localeToResource: Map<Locale, ManagedResource<any>>
        ) => {
          dialogStateCallback?.callback?.({
            managedResource,
            actionType,
            isOpen: true,
            resourceType,
            localeToResource
          });
        },
        props.isPublishing
      );
    }
    return emptyPageData();
  }, [props.content]);

  useEffect(() => {
    (props.updateSuccess || new Map()).forEach((isSuccessful, resource) => {
      if (isSuccessful) {
        dialogStateCallback?.callback?.({
          resourceType: resource,
          isOpen: false
        });
      }
    });
  }, [props.updateSuccess]);

  const [dialogStateCallback, setDialogStateCallback] = useState<
    | {
        callback:
          | (({
              resourceType,
              actionType,
              managedResource,
              isOpen,
              localeToResource
            }: DialogStateCallback) => void)
          | undefined;
      }
    | undefined
  >();

  const [summaryExpanded, setSummaryExpanded] = useState(true);
  const onSummaryExpansionClicked = () => {
    setSummaryExpanded(!summaryExpanded);
  };

  const getFormFields = React.useCallback(
    getFormFieldsFactory(
      props.defaultLocale,
      props.locale,
      currentData.resourcesToIndexedManagedContent
    ),
    [
      props.defaultLocale,
      props.locale,
      currentData.resourcesToIndexedManagedContent
    ]
  );

  const [customDialogState, setCustomDialogState] = useState<{
    [type: string]: { open?: boolean; isLoading?: boolean; error?: Error };
  }>({});

  const mutateDialogState = React.useCallback(
    (name: string, field: string, value: any) => {
      setCustomDialogState({
        ...customDialogState,
        [name]: {
          ...customDialogState[name],
          [field]: value
        }
      });
    },
    [customDialogState]
  );

  const handleUploadBackupDialogClose = React.useCallback(() => {
    mutateDialogState("backup", "open", false);
  }, [mutateDialogState]);
  const handleUploadBackupDialogConfirm = React.useCallback(
    (id: string) => {
      mutateDialogState("backup", "isLoading", true);
      if (props.selectBackup) {
        props
          .selectBackup({ ownerId: props.user?.id, backupId: id })
          .then(() => {
            mutateDialogState("backup", "isLoading", false);
            handleUploadBackupDialogClose();
          })
          .catch(error => {
            mutateDialogState("backup", "error", error);
          });
      }
    },
    [handleUploadBackupDialogClose, mutateDialogState, props]
  );
  const listBackups = React.useCallback(
    () => props.listBackups?.({ ownerId: props.user?.id }),
    [props.listBackups, props.user]
  );

  const classes = useStyles();
  const uploadBackup = React.useCallback(async () => {
    mutateDialogState("backup", "open", true);
  }, [mutateDialogState]);
  const reloadStaged = React.useCallback(async () => {
    mutateDialogState("stage", "open", true);
  }, [mutateDialogState]);

  const onCommitSelectionChange = useCallback(
    (
      resourceName: string,
      locale: string,
      commitId: string,
      isSelected: boolean
    ) => {
      setCommitSelection(commitSelection => ({
        ...commitSelection,
        selectedCommits: new Map(
          isSelected
            ? [
                ...Array.from(commitSelection.selectedCommits.entries()),
                [commitId, { resourceName, locale }]
              ]
            : Array.from(commitSelection.selectedCommits.entries()).filter(
                ([id]) => id !== commitId
              )
        )
      }));
    },
    []
  );
  const [commitSelection, setCommitSelection] = useState<{
    enabled: boolean;
    selectedCommits: Map<string, { resourceName: string; locale: string }>;
    onChange: (
      resourceName: string,
      locale: string,
      commitId: string,
      isSelected: boolean
    ) => void;
  }>({
    enabled: false,
    selectedCommits: new Map(),
    onChange: onCommitSelectionChange
  });
  const publishIndividualToggle = useCallback(() => {
    setCommitSelection(currentState => ({
      ...currentState,
      enabled: !currentState.enabled,
      selectedIds: new Set()
    }));
  }, []);

  const performPublish = useCallback(async () => {
    const individualCommits = Array.from(
      commitSelection.selectedCommits.entries()
    ).map(([commitId, { resourceName, locale }]) => ({
      commitId,
      resourceName,
      locale
    }));
    if (individualCommits.length) {
      await tasks.createPublishTask({ individualCommits });
    } else {
      await tasks.createPublishTask();
    }
  }, [commitSelection.selectedCommits, tasks]);

  const getOwnerDetails = React.useCallback(
    (ownerId?: string) => {
      const user = ownerId ? props.content?.users?.get(ownerId) : undefined;
      return {
        imageUrl: user?.imageUrl,
        name: user?.name
      };
    },
    [props.content]
  );

  const handleStageDialogClose = React.useCallback(() => {
    mutateDialogState("stage", "open", false);
  }, [mutateDialogState]);
  const handleStageDialogConfirm = React.useCallback(
    (id: string) => {
      mutateDialogState("stage", "isLoading", true);
      if (props.selectStagedData) {
        props
          .selectStagedData(id)
          .then(() => {
            mutateDialogState("stage", "isLoading", false);
            handleUploadBackupDialogClose();
          })
          .catch(error => {
            mutateDialogState("stage", "error", error);
          });
      }
    },
    [handleUploadBackupDialogClose, mutateDialogState, props]
  );

  const indexedFormInfoByResource = React.useMemo(
    () =>
      asMap<CommitResource, CommitResource, FormInfo>(
        commitResources(),
        resourceType => resourceType,
        resourceType => ({
          name: resourceType,
          editFields: (locale, resource) =>
            getFormFields(resourceType, locale, resource) || [],
          addFields: locale => getFormFields(resourceType, locale) || []
        })
      ),
    [getFormFields]
  );
  const dialogStateModifierConsumer = React.useCallback(callback => {
    setDialogStateCallback({ callback });
  }, []);
  return (
    <>
      <SystemBanner {...props} />
      <Box className={classes.container}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
          spacing={2}
          className={classes.grid}
        >
          <Grid item className={classes.fixedGridItem}>
            <ExpansionPanel expanded={summaryExpanded}>
              <ExpansionPanelSummary
                onClick={onSummaryExpansionClicked}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Summary</Typography>
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails>
                <SummaryPanel
                  aggregatedCommits={currentData.aggregatedCommits}
                  updateContentLock={props.updateContentLock}
                  uploadBackup={uploadBackup}
                  isContentLock={props.isContentLock}
                  isPublishing={props.isPublishing}
                  reloadStaged={reloadStaged}
                  toggleIndividualPublish={publishIndividualToggle}
                  performPublish={performPublish}
                  publishEnabled={
                    !commitSelection.enabled ||
                    commitSelection.selectedCommits.size > 0
                  }
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid
            item
            direction="column"
            justify="center"
            alignItems="flex-start"
            className={classes.limitedGridItem}
          >
            <ContentStatisticsGrid
              commitStatisticsByResource={
                currentData.commitStatisticsByResource
              }
              resourcesToIndexedManagedContent={
                currentData.resourcesToIndexedManagedContent
              }
              defaultLocale={props.defaultLocale}
              commitSelection={commitSelection}
            />
          </Grid>
        </Grid>
        <ContentManagementDialogs
          indexedFormInfoByResource={indexedFormInfoByResource}
          dialogStateModifierConsumer={dialogStateModifierConsumer}
          isUpdating={props.isUpdating}
          fieldsUpdater={props.updateFields}
          changesReverter={props.revert}
          entryRemove={props.remove}
          entryArchive={props.archive}
          entryUnarchive={props.unarchive}
        />
        <UploadBackupDialog
          isOpen={!!customDialogState.backup?.open}
          handleClose={handleUploadBackupDialogClose}
          handleConfirm={handleUploadBackupDialogConfirm}
          listBackups={listBackups}
          isUploading={!!customDialogState.backup?.isLoading}
          uploadError={customDialogState.backup?.error}
        />
        <ReloadStagedDialog
          isOpen={!!customDialogState.stage?.open}
          handleClose={handleStageDialogClose}
          handleConfirm={handleStageDialogConfirm}
          isStaging={!!customDialogState.stage?.isLoading}
          stageError={customDialogState.stage?.error}
          listStaged={props.listStagedData}
          getOwnerDetails={getOwnerDetails}
        />
      </Box>
    </>
  );
};

const PublishPage = compose(
  withSystemConfiguration,
  withAuth,
  withRepositories(repositories => ({
    contentRepository: repositories.content,
    commitsRepository: repositories.commits,
    userRepository: repositories.user,
    localesRepository: repositories.locales
  })),
  withLCE(
    ({
      contentRepository,
      commitsRepository,
      userRepository,
      localesRepository
    }: {
      contentRepository: ContentRepository;
      commitsRepository: CommitsRepository;
      userRepository: UserRepository;
      localesRepository: LocalesRepository;
    }) =>
      usePublishObservableLCE({
        contentRepository,
        commitsRepository,
        userRepository,
        localesRepository
      })
  ),
  withResourceManagementActions,
  React.memo
)(PublishPageWrapper) as React.ComponentType;

PublishPageWrapper.whyDidYouRender = true;

export default PublishPage;
