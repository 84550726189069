import ConfigurationApi from "../ConfigurationApi";
import { Observable } from "rxjs";
import Configuration from "../Configuration";
import { FirebaseData } from "../../firebase/data/FirebaseData";
import DataBackupListResponse from "../DataBackupResponse";
import StagedDataDocument from "../StagedDataDocument";
import { FirebaseFunctions } from "../../firebase/functions/FirebaseFunctions";

export default class FirebaseConfigurationApi implements ConfigurationApi {
  constructor(
    private readonly firebaseData: FirebaseData,
    private readonly firebaseFunctions: FirebaseFunctions
  ) {}

  getConfigurationObservable(): Observable<Configuration> {
    return this.firebaseData.getConfigurationObservable();
  }

  setContentLock({ locked }: { locked: boolean }): Promise<void> {
    return this.firebaseFunctions.call("updateContentLock", {
      locked
    });
  }

  performPublish({ ownerId }: { ownerId?: string }): Promise<void> {
    return this.firebaseFunctions.call("publishNewData", {
      ownerId
    });
  }

  listBackups({
    ownerId
  }: {
    ownerId?: string;
  }): Promise<DataBackupListResponse> {
    return this.firebaseFunctions.call("listBackups", {
      ownerId
    });
  }

  selectBackup({
    ownerId,
    backupId
  }: {
    ownerId?: string;
    backupId: string;
  }): Promise<void> {
    return this.firebaseFunctions.call("selectBackup", {
      ownerId,
      backupId
    });
  }

  listStagedData(): Promise<StagedDataDocument[]> {
    return this.firebaseFunctions.call("listStagedData");
  }

  async selectStagedData(id: string): Promise<void> {
    return this.firebaseFunctions.call("loadStagedData", {
      stageId: id
    });
  }
}
