import React from "react";
import { Grid } from "@material-ui/core";
import Flag from "react-world-flags";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  flag: {
    width: "2em",
    height: "2em"
  }
}));

const CountryFlag = ({
  locale,
  countryCode
}: {
  locale?: string;
  countryCode: string;
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      spacing={1}
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <Flag code={countryCode} className={classes.flag} />
      </Grid>
      <Grid item>
        <Typography align="center">{locale ?? countryCode}</Typography>
      </Grid>
    </Grid>
  );
};

export default CountryFlag;
