import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  InputBase,
  Paper
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import { BehaviorSubject } from "rxjs";
import { debounceTime } from "rxjs/operators";

const DEBOUNCE_TIME = 500;
const CIRCULAR_PROGRESS_SIZE = 24;

const useStyles = makeStyles(theme => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  input: {
    paddingLeft: 8
  },
  emptySpace: { width: `${CIRCULAR_PROGRESS_SIZE}px` }
}));

const SearchInput = ({
  onSearchChanged,
  placeholder = "Search...",
  width = "256px",
  margin = "0",
  debounce = DEBOUNCE_TIME
}: {
  onSearchChanged: (text: string) => void;
  placeholder?: string;
  width?: string;
  margin?: string;
  debounce?: number;
}) => {
  const searchSubject = React.useMemo(
    () => new BehaviorSubject<string>(""),
    []
  );
  const [text, setText] = React.useState("");
  const [isFakeProcessing, setIsFakeProcessing] = useState<
    boolean | undefined
  >();
  useEffect(() => {
    const subscription = searchSubject
      .pipe(debounceTime(debounce))
      .subscribe({
        next: (searchTerm: string) => {
          setIsFakeProcessing(false);
          onSearchChanged(searchTerm);
        }
      });
    return () => {
      setIsFakeProcessing(false);
      subscription.unsubscribe();
    };
  }, [onSearchChanged, searchSubject]);
  const handleTextChange = React.useCallback(
    text => {
      setText(text);
      setIsFakeProcessing(true);
      searchSubject.next(text);
    },
    [searchSubject]
  );
  const onSearchChangedCallback = React.useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      handleTextChange(event.target.value);
    },
    [handleTextChange]
  );
  const onClearTextChange = React.useCallback(() => handleTextChange(""), [
    handleTextChange
  ]);
  const paperStyle = React.useMemo(() => ({ width, padding: 8, margin }), [
    width,
    margin
  ]);
  const classes = useStyles();
  return (
    <Paper style={paperStyle}>
      <Box className={classes.searchContainer}>
        <SearchIcon />
        <InputBase
          placeholder={placeholder}
          onChange={onSearchChangedCallback}
          className={classes.input}
          value={text}
          endAdornment={
            <>
              {isFakeProcessing ? (
                <CircularProgress
                  size={CIRCULAR_PROGRESS_SIZE}
                  variant="indeterminate"
                />
              ) : (
                <div className={classes.emptySpace} />
              )}
              {searchSubject.value && (
                <IconButton size="small" onClick={onClearTextChange}>
                  <ClearIcon />
                </IconButton>
              )}
            </>
          }
        />
      </Box>
    </Paper>
  );
};

export default SearchInput;
