import { Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React, { useCallback, useEffect, useState } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import { Pair } from "../../../utils/tuples/Pair";
import { Locale } from "../../../locale";
import FormDialogProps from "./FormDialogProps";
import { updateClickHandler } from "./updateClickHandler";
import CurrentFormInfo from "./CurrentFormInfo";
import { FormDialogContent } from "./FormDialogContent";
import _ from "lodash";

const FormDialog = (props: FormDialogProps) => {
  const [currentFormInfo, setFormInfo] = useState<CurrentFormInfo>({});

  const [invalidFields, setInvalidFields] = useState<
    Map<Locale, Map<string, string | undefined>>
  >(new Map());

  useEffect(() => {
    if (!props.isOpen) {
      setFormInfo({});
      setInvalidFields(new Map());
    }
  }, [props.isOpen]);

  const updateField = useCallback(
    (locale: Locale, id: string, value: string[]) => {
      setFormInfo(currentFormInfo => ({
        ...currentFormInfo,
        [locale]: {
          ...currentFormInfo[locale],
          updatedFields: {
            ...currentFormInfo[locale]?.updatedFields,
            [id]: value
          }
        }
      }));
    },
    []
  );

  const linkField = useCallback(
    (
      locale: Locale,
      resource: string,
      fieldsToValues: Pair<string, string>[]
    ) => {
      setFormInfo(currentFormInfo => ({
        ...currentFormInfo,
        [locale]: {
          ...currentFormInfo?.[locale],
          updatedLinks: {
            ...currentFormInfo?.[locale]?.updatedLinks,
            [resource]: fieldsToValues
          }
        }
      }));
    },
    []
  );

  return (
    <Dialog open={props.isOpen} onClose={props.handleClose}>
      {props.isLoading && <LinearProgress variant="query" />}
      <DialogTitle>{props.title}</DialogTitle>
      <FormDialogContent
        description={props.description}
        currentFormInfo={currentFormInfo}
        invalidFields={invalidFields}
        linkField={linkField}
        localeToFields={props.localeToFields}
        updateField={updateField}
        defaultLocale={props.defaultLocale}
        handleNewResourceRequest={props.handleNewResourceRequest}
      />
      <DialogActions>
        <Button onClick={props.handleClose} disabled={props.isLoading}>
          Cancel
        </Button>
        <Button
          onClick={updateClickHandler({
            currentFormInfo,
            localeToFields: props.localeToFields,
            handleFormSave: props.handleFormSave,
            handleClose: props.handleClose,
            setInvalidFields,
            resourceType: props.resourceType
          })}
          disabled={props.isLoading}
        >
          {props.actionButtonText}
        </Button>
      </DialogActions>
      {props.updateError && (
        <Typography>{props.updateError.message}</Typography>
      )}
    </Dialog>
  );
};

export default FormDialog;
