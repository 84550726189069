import { mapOf } from "../../../utils/maps";
import * as React from "react";
import EditIcon from "@material-ui/icons/Edit";
import RestoreIcon from "@material-ui/icons/Restore";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ArchiveIcon from "@material-ui/icons/Archive";
import { Unarchive } from "@material-ui/icons";

export const ARCHIVE_ACTION = "archive";
export const UN_ARCHIVE_ACTION = "unarchive";

const actionsMap = mapOf(
  "update",
  {
    icon: <EditIcon />,
    description: "Edit"
  },
  "remove",
  {
    icon: <DeleteForeverIcon />,
    description: "Delete"
  },
  "restore",
  {
    icon: <RestoreIcon />,
    description: "Revert"
  },
  ARCHIVE_ACTION,
  {
    icon: <ArchiveIcon />,
    description: "Archive"
  },
  UN_ARCHIVE_ACTION,
  {
    icon: <Unarchive />,
    description: "Un-Archive"
  }
);

export default actionsMap;
