import * as React from "react";
import { useState } from "react";
import { CommitResource } from "../../api/commits/Commit";
import ManagedResource from "../../data/ManagedResource";
import { Pair } from "../../utils/tuples/Pair";
import { CommitsRepository } from "../../repository/use-case/commits/CommitsRepository";
import { Locale } from "../../locale";
import User from "../../api/auth/User";
import asMap from "../../utils/arrays/asMap";
import compose from "../../utils/compose";
import { withAuth } from "./withAuth";
import { withRepositories } from "./withRepositories";
import withLocale from "./withLocale";
import { LCE } from "../../architecture/lce/lce";

export interface ResourceManagementProps {
  isUpdating: (resourceType: CommitResource) => boolean;
  updateSuccess?: Map<string, any>;
  updateError?: (resourceType: CommitResource) => Error | undefined;
  updateFields: (
    type: CommitResource,
    managedResource: ManagedResource<any> | undefined,
    updateData: Map<
      Locale,
      {
        updatedFields: Map<string, string[]>;
        updatedLinks: Map<string, Pair<string, string>[]>;
      }
    >
  ) => any;
  revert: (
    type: CommitResource,
    managedResource: ManagedResource<any> | undefined
  ) => any;
  remove: (
    type: CommitResource,
    managedResource: ManagedResource<any> | undefined
  ) => any;
  archive: (
    type: CommitResource,
    managedResource: ManagedResource<any> | undefined
  ) => any;
  unarchive: (
    type: CommitResource,
    managedResource: ManagedResource<any> | undefined
  ) => any;
}

interface ManagementProps {
  commitsRepository: CommitsRepository;
  locale: Locale;
  user: User | undefined;
}

const withResourceManagementActions = function<
  P extends ResourceManagementProps
>(WrappedComponent: React.ComponentType<P>) {
  const ResourceManagementActionsHOC = (props: P & ManagementProps) => {
    const [updateLCE, setUpdateLCE] = useState<{
      [resource: string]: LCE<{ isSuccessful: boolean }, Error>;
    }>({});

    const performUpdateAction = React.useCallback(
      async (resource: CommitResource, action: Promise<any>) => {
        setUpdateLCE({
          ...updateLCE,
          [resource]: {
            isLoading: true
          }
        });
        try {
          await action;
          setUpdateLCE({
            ...updateLCE,
            [resource]: {
              isLoading: false,
              content: { isSuccessful: true }
            }
          });
        } catch (e: any) {
          setUpdateLCE({
            ...updateLCE,
            [resource]: {
              isLoading: false,
              error: e
            }
          });
        }
      },
      [updateLCE]
    );

    const updateFields = React.useCallback(
      (
        type: CommitResource,
        managedResource: ManagedResource<any> | undefined,
        updateData: Map<
          Locale,
          {
            updatedFields: Map<string, string[]>;
            updatedLinks: Map<string, Pair<string, string>[]>;
          }
        >
      ) =>
        performUpdateAction(
          type,
          props.commitsRepository.updateCommit({
            resource: type,
            resourceId: managedResource?.content?.id,
            updateData,
            ownerId: props.user?.id || "",
            commitId: managedResource?.tentativeState?.commitId
          })
        ),
      [performUpdateAction, props.commitsRepository, props.user]
    );

    const revert = React.useCallback(
      (
        type: CommitResource,
        managedResource: ManagedResource<any> | undefined
      ) => {
        if (managedResource?.tentativeState.commitId) {
          performUpdateAction(
            type,
            props.commitsRepository.revertCommit(
              type,
              props.locale,
              managedResource.tentativeState.commitId
            )
          );
        } else {
          setUpdateLCE({
            ...updateLCE,
            [type]: {
              isLoading: false,
              error: new Error("Invalid commit id")
            }
          });
        }
      },
      [performUpdateAction, props.commitsRepository, props.locale, updateLCE]
    );

    const remove = React.useCallback(
      (
        type: CommitResource,
        managedResource: ManagedResource<any> | undefined
      ) => {
        if (managedResource?.content.id) {
          performUpdateAction(
            type,
            props.commitsRepository.deleteCommit(
              type,
              props.locale,
              managedResource.content.id,
              props.user?.id
            )
          );
        } else {
          setUpdateLCE({
            ...updateLCE,
            [type]: {
              isLoading: false,
              error: new Error("Invalid resource id")
            }
          });
        }
      },
      [
        performUpdateAction,
        props.commitsRepository,
        props.locale,
        props.user,
        updateLCE
      ]
    );

    const archive = React.useCallback(
      (
        type: CommitResource,
        managedResource: ManagedResource<any> | undefined
      ) => {
        performUpdateAction(
          type,
          props.commitsRepository.archiveCommit({
            resource: type,
            locale: props.locale,
            commitId: managedResource?.tentativeState?.commitId,
            resourceId: managedResource?.content?.id,
            ownerId: props.user?.id
          })
        );
      },
      [performUpdateAction, props.commitsRepository, props.locale, props.user]
    );

    const unarchive = React.useCallback(
      (
        type: CommitResource,
        managedResource: ManagedResource<any> | undefined
      ) => {
        performUpdateAction(
          type,
          props.commitsRepository.unarchiveCommit({
            resource: type,
            locale: props.locale,
            commitId: managedResource?.tentativeState?.commitId,
            resourceId: managedResource?.content?.id,
            ownerId: props.user?.id
          })
        );
      },
      [performUpdateAction, props.commitsRepository, props.locale, props.user]
    );

    const updating = React.useCallback(
      resourceType => !!updateLCE[resourceType]?.isLoading,
      [updateLCE]
    );
    const updateError = React.useCallback(
      resourceType => updateLCE[resourceType]?.error,
      [updateLCE]
    );
    const updateSuccess = React.useMemo(
      () =>
        asMap<string, string, any>(
          Object.keys(updateLCE),
          key => key,
          element => updateLCE[element].content?.isSuccessful || false
        ),
      [updateLCE]
    );
    return (
      <WrappedComponent
        {...props}
        updateFields={updateFields}
        revert={revert}
        remove={remove}
        archive={archive}
        unarchive={unarchive}
        isUpdating={updating}
        updateError={updateError}
        updateSuccess={updateSuccess}
      />
    );
  };

  return compose(
    withAuth,
    withLocale,
    withRepositories(repositories => ({
      commitsRepository: repositories.commits
    }))
  )(ResourceManagementActionsHOC);
};

export default withResourceManagementActions;
