import { Grid } from "@material-ui/core";
import { DescriptiveIconButton } from "../../../components/buttons/DescriptiveIconButton";
import { ChevronLeft, ChevronRight, Home } from "@material-ui/icons";
import React, { useCallback } from "react";
import { FileManagerViewModelBase } from "../FileManagerViewModelBase";
import { useLocalViewModel } from "../../../architecture/view-model/useViewModel";
import { LocalViewModelFactory } from "../../../architecture/view-model/LocalViewModelFactory";

export interface FileBrowserToolbarNavigationControls {
  viewModelFactory: LocalViewModelFactory<FileManagerViewModelBase>;
}

export const FileBrowserToolbarNavigationControls = (
  props: FileBrowserToolbarNavigationControls
) => {
  const [viewState, viewModel] = useLocalViewModel(props.viewModelFactory);
  const onClickNavigateBack = useCallback(() => {
    viewModel.onNavigateBack();
  }, [viewModel]);
  const onClickHome = useCallback(() => {
    viewModel.onNavigateHome();
  }, [viewModel]);
  const onClickNavigateForward = useCallback(() => {
    viewModel.onNavigateForward();
  }, [viewModel]);
  return (
    <Grid container justify={"center"} direction={"row"} spacing={1}>
      <Grid item>
        <DescriptiveIconButton
          enabled={viewState.navigationState.canNavigateBack}
          icon={<ChevronLeft />}
          onClick={onClickNavigateBack}
          description={"Backward"}
        />
      </Grid>
      <Grid item>
        <DescriptiveIconButton
          icon={<Home />}
          onClick={onClickHome}
          description={"Home directory"}
        />
      </Grid>
      <Grid item>
        <DescriptiveIconButton
          enabled={viewState.navigationState.canNavigateForward}
          icon={<ChevronRight />}
          onClick={onClickNavigateForward}
          description={"Forward"}
        />
      </Grid>
    </Grid>
  );
};
