import { Alert } from "@material-ui/lab";
import React from "react";
import { SendConversationMessageResponse } from "../../../../repository/use-case/users-voice/dto/SendConversationMessageResponse";
import { LCE } from "../../../../architecture/lce/lce";

export const ConversationAlert = ({
  sendReplyLCE
}: {
  sendReplyLCE: LCE<SendConversationMessageResponse>;
}) => {
  return sendReplyLCE.error ? (
    <Alert severity={"error"}>Failed to send message.</Alert>
  ) : sendReplyLCE.content ? (
    <Alert severity={"success"}>Message sent successfully</Alert>
  ) : (
    <></>
  );
};
