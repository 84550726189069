import LinkFormFieldType, { LinkFormFieldOption } from "./LinkFormFieldType";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { Pair } from "../../../../../utils/tuples/Pair";
import { formatFormFieldLabel } from "../../../../../utils/dialog/formatFormFieldLabel";
import FormFieldBaseProps from "../FormFieldBaseProps";
import { OptionalProperties } from "../../../../../utils/generics/OptionalProperties";
import { filterNills } from "../../../../../utils/arrays/filterNills";
import _ from "lodash";

type LinkFormOptionWithCreate = OptionalProperties<
  LinkFormFieldOption<any>,
  "value"
> & {
  isCreate?: boolean;
};

const selectKeyValuePair = (
  option: LinkFormFieldOption<any> | LinkFormOptionWithCreate
) => ({
  key: (option.value?.content?.id && "resourceId") || "commitId",
  value: option.value?.content?.id || option.value?.tentativeState?.commitId
});

const LinkFormField = ({
  field,
  onLinkChange,
  createNew,
  locale,
  errorMessage,
  onCurrentValueResolved,
  currentLinks,
  disabled
}: {
  field: LinkFormFieldType<any>;
  onLinkChange: (
    resource: string,
    fieldsToValues: Pair<string, string>[]
  ) => any;
  createNew?: (resource: string) => any;
  currentLinks: Pair<string, string>[];
  disabled: boolean;
} & FormFieldBaseProps<Pair<string, string>[]>) => {
  const resolveValue = (values: LinkFormOptionWithCreate[]) =>
    values.map(option => selectKeyValuePair(option));

  const defaultValue: LinkFormOptionWithCreate[] = field.defaultValues
    ? field.defaultValues
    : field.defaultValue?.value
    ? [{ label: field.defaultValue?.value }]
    : [];
  const [currentValue, setValue] = useState(defaultValue);
  useEffect(() => {
    onCurrentValueResolved(
      resolveValue(
        field.options.filter(option =>
          currentValue
            .map(currentOption => currentOption.label)
            .includes(option.label)
        )
      )
    );
  }, []);
  useEffect(() => {
    if (currentLinks) {
      setValue(
        filterNills(
          currentLinks.map(pair =>
            field.options.find(option =>
              _.isEqual(selectKeyValuePair(option), pair)
            )
          )
        )
      );
    }
  }, [currentLinks, field]);
  const commonProps = {
    options: (field.options as LinkFormOptionWithCreate[]).concat({
      label: "New",
      isCreate: true
    } as LinkFormOptionWithCreate),
    renderInput: (params: any) => (
      <TextField
        {...params}
        label={formatFormFieldLabel({ label: field.label, locale })}
        variant="outlined"
        fullWidth
        inputProps={{
          ...params.inputProps,
          autoComplete: "disabled"
        }}
        error={!!errorMessage}
        helperText={!!errorMessage && errorMessage}
      />
    ),
    getOptionLabel: (option: LinkFormOptionWithCreate) => option.label,
    renderOption: (option: LinkFormOptionWithCreate) =>
      option.isCreate || !option.value ? (
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <AddIcon />
          </Grid>
          <Grid item>
            <Typography>{option.label}</Typography>
          </Grid>
        </Grid>
      ) : (
        field.renderOption({ value: option.value, label: option.label })
      ),
    disabled
  };
  const onValueChange = (values: LinkFormOptionWithCreate[]) => {
    if (!!values.find(option => option.isCreate)) {
      createNew?.(field.id);
    } else {
      setValue(values);
      onLinkChange(field.id, resolveValue(values));
    }
  };
  if (field.multiChoice) {
    return (
      <Autocomplete
        {...commonProps}
        multiple={true}
        value={currentValue}
        onChange={(_: any, value: LinkFormOptionWithCreate[] | null) => {
          onValueChange(value || []);
        }}
      />
    );
  } else {
    return (
      <Autocomplete
        {...commonProps}
        multiple={false}
        value={currentValue[0] || { label: "" }}
        onChange={(_: any, value: LinkFormOptionWithCreate | null) => {
          onValueChange(value ? [value] : []);
        }}
      />
    );
  }
};

export default LinkFormField;
