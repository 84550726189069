import { DiagnosticJobStatus } from "../../../../../repository/use-case/developer-console/DiagnosticJobStatus";
import { InformationBanner } from "../../../../info/InformationBanner";
import React from "react";
import { DiagnosticJobState } from "../../../../../repository/use-case/developer-console/DiagnosticJobState";
import moment from "moment";

export interface ContentDiscrepanciesLatestJobProps {
  jobStatus: DiagnosticJobStatus;
  createdAtMillis: number | undefined;
  onClickViewReport: () => void;
}

const CREATED_AT_DATE_FORMAT = "DD/MM/yyyy HH:mm UTC";

export const ContentDiscrepanciesLatestJob = ({
  jobStatus,
  createdAtMillis,
  onClickViewReport
}: ContentDiscrepanciesLatestJobProps) => {
  const message = composeFullMessage(jobStatus.state, createdAtMillis);

  return (
    <InformationBanner
      message={message}
      flavor={stateToBannerFlavor(jobStatus.state)}
      cta={
        jobStatus.state === DiagnosticJobState.DONE ? "View Report" : undefined
      }
      onClickCTA={onClickViewReport}
    />
  );
};

function stateToBannerFlavor(jobState: DiagnosticJobState) {
  switch (jobState) {
    case DiagnosticJobState.DONE:
      return "success";
    case DiagnosticJobState.ERROR:
      return "error";
    case DiagnosticJobState.NONE:
    // FALLTHROUGH
    case DiagnosticJobState.RUNNING:
      return "info";
  }
}

function composeFullMessage(
  jobState: DiagnosticJobState,
  createdAtMillis: number | undefined
): string | undefined {
  const stateMessage = stateToMessage(jobState);
  if (!stateMessage) {
    return;
  }
  if (!createdAtMillis) {
    return stateMessage + ".";
  }
  const createdAt = moment(createdAtMillis).format(CREATED_AT_DATE_FORMAT);
  return `${stateMessage} at ${createdAt}.`;
}

function stateToMessage(jobState: DiagnosticJobState): string | undefined {
  switch (jobState) {
    case DiagnosticJobState.DONE:
      return "Latest run completed successfully";
    case DiagnosticJobState.ERROR:
      return "Latest run encountered an error";
    case DiagnosticJobState.RUNNING:
      return "Job is currently running";
    case DiagnosticJobState.NONE:
      return undefined;
  }
}
