export interface ListConversationsResponse {
  conversationEntries: ConversationEntry[];
}

export interface ConversationEntry {
  id: string;
  topic?: string;
  status: ConversationStatus;
  type: ConversationType;
  createdAt: Date;
  updatedAt?: Date;
  user?: ConversationUser;
}

export interface ConversationUser {
  id: string;
}

export enum ConversationType {
  NONE = "NONE",
  FEEDBACK = "FEEDBACK",
  QUESTION = "QUESTION",
  FEATURE_REQUEST = "FEATURE_REQUEST",
  BUG_REPORT = "BUG_REPORT",
  BROADCAST = "BROADCAST",
  OTHER = "OTHER",
  CONTENT_FEEDBACK = "CONTENT_FEEDBACK"
}

export enum ConversationStatus {
  NONE = "NONE",
  FEEDBACK_PENDING = "FEEDBACK_PENDING",
  FEEDBACK_RESOLVED = "FEEDBACK_RESOLVED"
}
