import useLCE2 from "../../hooks/useLCE2";
import { LCEWrapper } from "../../components/lce/LCEWrapper";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";
import React, { useCallback } from "react";
import { useRepositories } from "../../hooks/useRepositories";
import User from "../../api/auth/User";
import { useObservableRepositoryLCE } from "../../hooks/useObservableRepositoryLCE";
import Box from "@material-ui/core/Box";

export interface UsersListProps {
  disabled?: boolean;
  onUserClick?: (userInfo: User) => void;
  disabledUser?: (
    userInfo: User
  ) => {
    disabled: boolean;
    disabledRationale?: string;
  };
}

const defaultProps: UsersListProps = {};

export const UsersList = (props: UsersListProps = defaultProps) => {
  const { user } = useRepositories();
  const usersLCE = useObservableRepositoryLCE(
    async ([user]) => Array.from(user.values()),
    [],
    user
  );

  return (
    <LCEWrapper
      lce={usersLCE}
      renderContent={users => (
        <List>
          {users.map((userInfo, index) => {
            const disabledInfo = props.disabledUser?.(userInfo);
            const disabled = disabledInfo?.disabled ?? false;
            const disabledRationale =
              disabled && disabledInfo?.disabledRationale ? (
                <Typography variant={"subtitle2"} color={"error"}>
                  {disabledInfo?.disabledRationale}
                </Typography>
              ) : (
                <></>
              );
            return (
              <>
                <ListItem
                  disabled={props.disabled || disabled}
                  button={true}
                  onClick={() => props.onUserClick?.(userInfo)}
                >
                  <Box>
                    <ListItemText>{userInfo.name}</ListItemText>
                    {userInfo.email ? (
                      <Typography variant={"subtitle2"}>
                        {userInfo.email}
                      </Typography>
                    ) : (
                      <></>
                    )}
                    {disabledRationale}
                  </Box>
                </ListItem>
                {index < users.length - 1 ? <Divider /> : <></>}
              </>
            );
          })}
        </List>
      )}
    />
  );
};
