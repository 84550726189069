import AuthApi from "../../api/auth/AuthApi";
import User from "../../api/auth/User";
import { getCountryCodeForLocale, getSupportedLocales } from "../../locale";

export interface LocaleInfo {
  locale: string;
  countryCode: string;
  hasTranslations?: boolean;
}

export class HeaderViewModel {
  constructor(
    private authApi: AuthApi,
    private currentUser: User | null,
    private isAuthorized: boolean,
    private isDarkThemeEnabled: boolean,
    private locale: string,
    private localesWithTranslations: string[]
  ) {}

  get user(): User | null {
    return this.currentUser;
  }

  get isDrawerToggleVisible(): boolean {
    return this.isAuthorized;
  }

  signOut = (): Promise<void> => {
    return this.authApi.signOut();
  };

  get isDarkTheme(): boolean {
    return this.isDarkThemeEnabled;
  }

  get locales(): LocaleInfo[] {
    return getSupportedLocales()
      .map((locale: string) => ({
        locale,
        countryCode: getCountryCodeForLocale(locale),
        hasTranslations: this.localesWithTranslations.includes(locale)
      }))
      .sort(
        (a: LocaleInfo, b: LocaleInfo) =>
          (b.hasTranslations ? 1 : 0) - (a.hasTranslations ? 1 : 0)
      );
  }

  get selectedLocale(): string {
    return this.locale;
  }
}
