import HotObservableRepository from "./HotObservableRepository";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import LocalizedRepository from "./LocalizedRepository";
import { Locale } from "../locale";
import Cache from "../utils/cache/Cache";
import { catchError, first, map, switchMap } from "rxjs/operators";
import distinct from "../utils/arrays/distinct";
import asMap from "../utils/arrays/asMap";
import { LocalizedObservableData } from "./LocalizedObservableData";

export default abstract class HotLocalizedRepository<T, F = T>
  extends HotObservableRepository<LocalizedObservableData<T>>
  implements LocalizedRepository {
  private readonly cachedLocaleObservable: Cache<
    Locale,
    Observable<F>
  > = new Cache(locale => this._getDataObservable(locale));
  private localesSubject = new BehaviorSubject<Locale[]>([]);

  private sharedLocalizedObservables: Cache<
    Locale[],
    Observable<Map<Locale, T>>
  > = new Cache(locales =>
    combineLatest(
      distinct(locales).map(distinctLocale =>
        this.cachedLocaleObservable
          .get(distinctLocale)
          .pipe(map(dataFragment => ({ distinctLocale, dataFragment })))
      )
    ).pipe(
      map(localeToDataFragments =>
        asMap(
          localeToDataFragments,
          ({ distinctLocale }) => distinctLocale,
          ({ dataFragment }) => this._combiner([dataFragment])
        )
      )
    )
  );

  constructor(private readonly defaultLocale: Locale) {
    super();
    this.localesSubject.next([defaultLocale]);
  }

  _loadDataObservable: () => Observable<LocalizedObservableData<T>> = () =>
    combineLatest([
      this.localesSubject.pipe(
        map(locales => distinct(locales)),
        map(distinctLocales => ({
          observables: distinctLocales.map(distinctLocale =>
            this.cachedLocaleObservable.get(distinctLocale)
          ),
          locale: distinctLocales[distinctLocales.length - 1]
        })),
        switchMap(({ observables, locale }) =>
          combineLatest(observables).pipe(
            map(dataFragments => ({
              data: this._combiner(dataFragments),
              locale
            }))
          )
        )
      ),
      this.cachedLocaleObservable.get(this.defaultLocale).pipe(
        map(defaultDataFragment => ({
          data: this._combiner([defaultDataFragment]),
          locale: this.defaultLocale
        }))
      )
    ]).pipe(
      map(([localizedData, defaultData]) => ({ localizedData, defaultData }))
    );

  setLocale(locale: Locale): void {
    this.localesSubject.next([this.defaultLocale, locale]);
  }

  getLocalizedDataObservable(locales: Locale[]): Observable<Map<Locale, T>> {
    return this.sharedLocalizedObservables.get(locales);
  }

  loadLocalizedData(locales: Locale[]): Promise<Map<Locale, T>> {
    return this.getLocalizedDataObservable(locales)
      .pipe(
        first(),
        catchError((err: Error) => {
          if (err.name === "EmptyError") {
            return of(new Map());
          }
          throw err;
        })
      )
      .toPromise();
  }

  protected abstract readonly _combiner: (data: F[]) => T;

  protected abstract readonly _getDataObservable: (
    locale: Locale
  ) => Observable<F>;
}
