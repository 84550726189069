import { Resources } from "../../../../../data/Resources";
import createQuoteFormFields from "./createQuoteFormFields";
import createAuthorFormFields from "./createAuthorFormFields";
import createCategoryFormFields from "./createCategoryFormFields";
import { mapOf } from "../../../../../utils/maps";
import { CommitResource } from "../../../../../api/commits/Commit";
import FormField from "../FormField";
import { Locale } from "../../../../../locale";
import ManagedResource from "../../../../../data/ManagedResource";
import { wrapFormFieldsFactoryWithValidator } from "./wrapFormFieldsFactoryWithValidator";
import createMediaPlatformFormFields from "./createMediaPlatformFormFields";
import createMediaFeedFormFields from "./createMediaFeedFormFields";

export type FormFieldFactory = () => Map<
  Resources,
  ({
    locale,
    resource
  }: {
    locale: Locale;
    resource?: ManagedResource<any>;
  }) => FormField[]
>;

export const formFieldFactory: (
  defaultLocale: Locale,
  currentLocale: Locale,
  managedContent: Map<CommitResource, Map<Locale, ManagedResource<any>[]>>
) => FormFieldFactory = (
  defaultLocale,
  currentLocale,
  managedContent
) => () => {
  return mapOf<
    Resources,
    ({
      resource,
      locale
    }: {
      resource?: ManagedResource<any>;
      locale: Locale;
    }) => FormField[]
  >(
    Resources.QUOTES,
    wrapFormFieldsFactoryWithValidator(
      defaultLocale,
      createQuoteFormFields(currentLocale, managedContent)
    ),
    Resources.AUTHORS,
    wrapFormFieldsFactoryWithValidator(
      defaultLocale,
      createAuthorFormFields(currentLocale)
    ),
    Resources.CATEGORIES,
    wrapFormFieldsFactoryWithValidator(
      defaultLocale,
      createCategoryFormFields(currentLocale)
    ),
    Resources.MEDIA_PLATFORM,
    wrapFormFieldsFactoryWithValidator(
      defaultLocale,
      createMediaPlatformFormFields(currentLocale)
    ),
    Resources.PODCASTS,
    wrapFormFieldsFactoryWithValidator(
      defaultLocale,
      createMediaFeedFormFields(currentLocale, managedContent)
    ),
    Resources.VIDEOS,
    wrapFormFieldsFactoryWithValidator(
      defaultLocale,
      createMediaFeedFormFields(currentLocale, managedContent)
    ),
    Resources.BOOKS,
    wrapFormFieldsFactoryWithValidator(
      defaultLocale,
      createMediaFeedFormFields(currentLocale, managedContent)
    )
  );
};
