import { Checkbox, List, ListItem, ListItemText } from "@material-ui/core";
import * as React from "react";
import { ContentFilterOption } from "../../types/domainTypes";

export interface FilterPopoverProps {
  filter?: {
    options: ContentFilterOption[];
    activeOptions: ContentFilterOption[];
  };
  onFilterToggle?: (filer: ContentFilterOption) => void;
}

export const FilterPopover = (props: FilterPopoverProps) => {
  return (
    <List>
      {props.filter?.options.map(filterOption => (
        <ListItem button onClick={() => props.onFilterToggle?.(filterOption)}>
          <Checkbox
            checked={props.filter?.activeOptions.some(
              option => option.label === filterOption.label
            )}
            onChange={() => props.onFilterToggle?.(filterOption)}
          />
          <ListItemText
            primary={filterOption.label}
            secondary={filterOption.description}
          />
        </ListItem>
      ))}
    </List>
  );
};
