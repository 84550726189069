import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import FormField from "../FormField";
import { Locale } from "../../../../../locale";
import { formatFormFieldLabel } from "../../../../../utils/dialog/formatFormFieldLabel";
import FormFieldBaseProps from "../FormFieldBaseProps";

const TextFormField = ({
  field,
  onChange,
  currentFields,
  locale,
  errorMessage,
  onCurrentValueResolved
}: {
  field: FormField;
  onChange: (id: string, value: string) => any;
  currentFields: {
    [id: string]: string[];
  };
} & FormFieldBaseProps<string>) => {
  const currentValue =
    currentFields[field.id]?.[0] ?? field.defaultValue?.value;
  useEffect(() => {
    if (field.defaultValue?.isLocalized) {
      onCurrentValueResolved(field.defaultValue?.value || currentValue || "");
    }
  }, []);
  return (
    <TextField
      key={field.id}
      margin="dense"
      label={formatFormFieldLabel({ label: field.label, locale })}
      fullWidth
      multiline
      variant="outlined"
      value={currentValue}
      onChange={element => onChange(field.id, element.target.value)}
      error={!!errorMessage}
      helperText={!!errorMessage && errorMessage}
    />
  );
};

export default TextFormField;
