import AuthApi, { UnsubscribeAuthUserChanges } from "../AuthApi";
import User from "../User";
import * as firebase from "firebase";
import FirebaseAuth from "../../firebase/auth/FirebaseAuth";
import { mapFirebaseUserToAuthUser } from "../../firebase/mappers/userMapper";
import { Observable } from "rxjs";

export default class FirebaseAuthApi implements AuthApi {
  constructor(private firebaseAuth: FirebaseAuth) {}

  get signInWithPopup(): () => Promise<any> {
    return () =>
      this.firebaseAuth.auth.signInWithPopup(
        this.firebaseAuth.googleProvider()
      );
  }

  authUserChanges(
    callback: (authUser: User | null) => any
  ): UnsubscribeAuthUserChanges {
    return this.firebaseAuth.auth.onAuthStateChanged(
      (firebaseUser: firebase.User | null) => {
        callback(mapFirebaseUserToAuthUser(firebaseUser));
      }
    );
  }

  authObservable = new Observable<User | null>(subscriber => {
    return this.authUserChanges(subscriber.next);
  });

  signOut = (): Promise<void> => {
    return this.firebaseAuth.auth.signOut();
  };

  currentUser(): User | null {
    return mapFirebaseUserToAuthUser(this.firebaseAuth.auth.currentUser);
  }
}
