import * as firebase from "firebase";

export class FirebaseFunctions {
  private readonly functions: firebase.functions.Functions;

  constructor(private readonly firebaseApp: firebase.app.App) {
    this.functions = firebaseApp.functions();
  }

  call = <T = any, R = any>(functionName: string, data?: T): Promise<R> => {
    return this.functions
      .httpsCallable(functionName)(data)
      .then(res => res.data as R);
  };
}
