import React from "react";
import { ConversationEntry } from "../../../../repository/use-case/users-voice/dto/ListConversationsResponse";
import { Grid } from "@material-ui/core";
import { ConversationItemContent } from "./ConversationItemContent";

export const ConversationItem = ({
  id,
  status,
  type,
  createdAt,
  updatedAt,
  user,
  topic
}: ConversationEntry) => {
  return (
    <ConversationItemContent status={status} topic={topic} type={type} />
  );
};
