import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import useStyles from "../../community/users-voice/styles";
import { LCEWrapper } from "../../../components/lce/LCEWrapper";
import { useRepositories } from "../../../hooks/useRepositories";
import ScrollableContent from "../../../components/content/ScrollableContent";
import { LCE } from "../../../architecture/lce/lce";

const LOG_ENTRY_ITEM_SIZE = 80;

export const DeveloperConsoleLogsPage = () => {
  const classes = useStyles();

  const { developerConsole } = useRepositories();

  const [logEntriesLCE, setLogEntries] = useState<LCE<any>>({
    isLoading: true
  });

  useEffect(() => {
    developerConsole
      .listLogs({})
      .then(res => {
        setLogEntries({
          isLoading: false,
          content: res.logFiles
        });
      })
      .catch(error => setLogEntries({ isLoading: false, error }));
  }, [developerConsole]);

  const renderLogsList = useCallback(logEntries => {
    return (
      <ScrollableContent
        itemSize={LOG_ENTRY_ITEM_SIZE}
        data={logEntries}
        renderContentItem={logEntry => <>{logEntry.name}</>}
        selectable
        // selectedIndex={selectedConversationItem?.index}
        // onClickItem={onClickItem}
      />
    );
  }, []);

  return (
    <Grid direction={"row"} container spacing={4} className={classes.container}>
      <Grid item xs={3} className={classes.column}>
        <LCEWrapper lce={logEntriesLCE} renderContent={renderLogsList} />
      </Grid>
    </Grid>
  );
};
