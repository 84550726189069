import { Resources } from "../../Resources";
import { QuoteFields } from "../../../api/content/Quote";
import { MediaFeedFields } from "../../../api/content/MedaFeedContent";

const resourceLinkMappers = {
  [Resources.QUOTES]: (resourceType: Resources) => {
    switch (resourceType) {
      case Resources.AUTHORS:
        return QuoteFields.AUTHOR_ID;
      case Resources.CATEGORIES:
        return QuoteFields.CATEGORY_ID;
    }
  },
  [Resources.AUTHORS]: () => {},
  [Resources.CATEGORIES]: () => {},
  [Resources.VIDEOS]: mediaFeedLinkMapper(),
  [Resources.PODCASTS]: mediaFeedLinkMapper(),
  [Resources.BOOKS]: mediaFeedLinkMapper(),
  [Resources.USERS]: () => {},
  [Resources.MEDIA_PLATFORM]: () => {},
  [Resources.WEEKLY_TIP]: () => {}
};

export const getResourceLinkId = (resource: Resources) =>
  resourceLinkMappers[resource];

function mediaFeedLinkMapper() {
  return (resourceType: Resources) => {
    switch (resourceType) {
      case Resources.AUTHORS:
        return MediaFeedFields.AUTHOR_ID;
      case Resources.MEDIA_PLATFORM:
        return MediaFeedFields.PLATFORM_ID;
    }
  };
}
