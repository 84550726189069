import { FirebaseFunctions } from "../../../api/firebase/functions/FirebaseFunctions";
import { ListConversationsResponse } from "./dto/ListConversationsResponse";
import { ListMessagesResponse } from "./dto/ListMessagesResponse";
import { ListMessagesRequest } from "./dto/ListMessagesRequest";
import { DeleteConversationRequest } from "./dto/DeleteConversationRequest";
import { from, Observable, Subject } from "rxjs";
import { startWith, switchMap } from "rxjs/operators";
import { ChangeConversationStatusRequest } from "./dto/ChangeConversationStatusRequest";
import { SendMessageRequest } from "./dto/SendMessageRequest";
import { SendConversationMessageResponse } from "./dto/SendConversationMessageResponse";
import { ListConversationsRequest } from "./dto/ListConversationsRequest";

const FUNCTION_NAME_LIST_CONVERSATIONS = "listConversations";
const FUNCTION_NAME_LIST_MESSAGES = "listMessages";
const FUNCTION_NAME_DELETE_CONVERSATION = "deleteConversation";
const FUNCTION_NAME_CHANGE_CONVERSATION_STATUS = "changeConversationStatus";
const FUNCTION_NAME_SEND_CONVERSATION_MESSAGE = "sendConversationMessage";

export class UsersVoiceRepository {
  private readonly conversationUpdateNotification = new Subject();

  constructor(private readonly firebaseFunctions: FirebaseFunctions) {}

  listConversations = (
    request: ListConversationsRequest = {}
  ): Observable<ListConversationsResponse> => {
    return this.conversationUpdateNotification.pipe(
      startWith(undefined),
      switchMap(() =>
        from<Promise<ListConversationsResponse>>(
          this.firebaseFunctions.call(FUNCTION_NAME_LIST_CONVERSATIONS, request)
        )
      )
    );
  };

  listMessages = ({
    conversationId
  }: ListMessagesRequest): Promise<ListMessagesResponse> => {
    return this.firebaseFunctions.call(FUNCTION_NAME_LIST_MESSAGES, {
      conversationId
    });
  };

  deleteConversation = async (request: DeleteConversationRequest) => {
    const response = await this.firebaseFunctions.call(
      FUNCTION_NAME_DELETE_CONVERSATION,
      request
    );
    this.conversationUpdateNotification.next();
    return response;
  };

  changeConversationStatus = async (
    request: ChangeConversationStatusRequest
  ) => {
    const response = await this.firebaseFunctions.call(
      FUNCTION_NAME_CHANGE_CONVERSATION_STATUS,
      request
    );
    this.conversationUpdateNotification.next();
    return response;
  };

  sendConversationMessage = async (
    request: SendMessageRequest
  ): Promise<SendConversationMessageResponse> => {
    return await this.firebaseFunctions.call(
      FUNCTION_NAME_SEND_CONVERSATION_MESSAGE,
      request
    );
  };
}
