import ManagedResource from "./ManagedResource";
import Quote from "../api/content/Quote";

export enum QuoteContentFields {
  TEXT = "text",
  CONTEXT = "context",
  AUTHOR = "author",
  CATEGORY = "category"
}

export default interface ManagedQuote
  extends ManagedResource<
    Quote & {
      [QuoteContentFields.AUTHOR]?: string[];
      [QuoteContentFields.CATEGORY]?: string[];
    }
  > {}
