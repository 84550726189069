import React, { useState } from "react";
import { useRepositories } from "../../../hooks/useRepositories";
import { useObservableRepositoryLCE } from "../../../hooks/useObservableRepositoryLCE";
import { LCEWrapper } from "../../../components/lce/LCEWrapper";
import { TasksList } from "../../../components/tasks/TasksList";
import { Task } from "../../../api/tasks/Task";
import { Alert } from "@material-ui/lab";
import { useStyles } from "./styles";

const TasksPageWrapper = () => {
  const { tasks } = useRepositories();
  const tasksLCE = useObservableRepositoryLCE(
    ([tasks]) => Promise.resolve({ tasks }),
    [],
    tasks
  );

  const classes = useStyles();

  const [isAlertVisible, setAlertVisible] = useState(false);
  const showErrorAlert = React.useCallback(() => {
    setAlertVisible(true);
    setTimeout(() => setAlertVisible(false), 3000);
  }, [setAlertVisible]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <LCEWrapper
        lce={tasksLCE}
        renderContent={({ tasks }: { tasks: Task[] }) => {
          return <TasksList tasks={tasks} showErrorAlert={showErrorAlert} />;
        }}
      />
      {isAlertVisible && (
        <Alert variant="filled" severity="error" className={classes.alert}>
          Something went wrong.
        </Alert>
      )}
    </div>
  );
};

export const TasksPage = React.memo(TasksPageWrapper);
