import { extractFirstLocalizedProp } from "../../../../../utils/data/resources/managed";
import { FormFieldType } from "../FormFieldType";
import * as React from "react";
import FormField from "../FormField";
import ManagedQuote, {
  QuoteContentFields
} from "../../../../../data/ManagedQuote";
import { Locale } from "../../../../../locale";
import { CommitResource } from "../../../../../api/commits/Commit";
import ManagedResource from "../../../../../data/ManagedResource";
import { QuoteFields } from "../../../../../api/content/Quote";
import { OptionalProperties } from "../../../../../utils/generics/OptionalProperties";
import { authorLinkOption } from "./authorLinkOption";
import { categoryLinkOption } from "./categoriesLinkOption";

const createQuoteFormFields: (
  currentLocale: Locale,
  managedContent: Map<CommitResource, Map<Locale, ManagedResource<any>[]>>
) => ({
  resource,
  locale
}: {
  locale: Locale;
  resource?: ManagedQuote;
}) => OptionalProperties<FormField, "validate">[] = (
  currentLocale,
  managedContent
) => ({ resource, locale }) => [
  {
    defaultValue:
      resource &&
      extractFirstLocalizedProp(
        currentLocale,
        resource,
        QuoteContentFields.TEXT
      ),
    id: QuoteFields.TEXT,
    label: "Edit Text",
    type: FormFieldType.TEXT
  },
  {
    defaultValue:
      resource &&
      extractFirstLocalizedProp(
        currentLocale,
        resource,
        QuoteContentFields.CONTEXT
      ),
    id: QuoteFields.CONTEXT,
    label: "Edit Context",
    type: FormFieldType.TEXT
  },
  authorLinkOption({
    managedContent,
    resource,
    locale,
    currentLocale,
    linkedFieldName: QuoteContentFields.AUTHOR
  }),
  categoryLinkOption({
    managedContent,
    resource,
    locale,
    currentLocale,
    linkedFieldName: QuoteContentFields.CATEGORY
  })
];

export default createQuoteFormFields;
