import { Observable } from "rxjs";

export function doOnUnsubscribe<T>(onUnsubscribe: () => void) {
  return (source: Observable<T>) =>
    new Observable<T>(subscriber => {
      const internalSubscription = source.subscribe(subscriber);
      return () => {
        internalSubscription.unsubscribe();
        onUnsubscribe();
      };
    });
}
