import React, { useCallback, useMemo, useRef } from "react";
import { useStyles } from "../styles";
import { NewWeeklyTipSlide } from "./NewWeeklyTipSlide";
import { WeeklyTipSlideContainer } from "./WeeklyTipSlideContainer";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { SLIDE_SIZE } from "../weekly-tip-constants";
import { SlideControl } from "./SlideControl";
import { WeeklyTipSlideImage } from "./WeeklyTipSlideImage";

export interface SlideData {
  path?: string;
  isLoading?: boolean;
}

interface IntermediateSlide extends SlideData {
  isAddSlide?: boolean;
}

export const WeeklyTipSlides = ({
  slides,
  onSlidesForUploadSelected,
  onSlideControlClicked
}: {
  slides: SlideData[];
  onSlidesForUploadSelected: (slideFiles: File[]) => Promise<void>;
  onSlideControlClicked: (index: number, slideControl: SlideControl) => void;
}) => {
  const classes = useStyles();
  const rootRef = useRef<any>();

  const onFilesSelected = useCallback(
    async (files: { [index: number]: File }) => {
      await onSlidesForUploadSelected(Object.values(files));
    },
    [onSlidesForUploadSelected]
  );

  const allSlides: IntermediateSlide[] = useMemo(() => {
    return [
      ...slides,
      {
        isAddSlide: true
      }
    ];
  }, [slides]);

  return (
    <div className={classes.root} ref={ref => (rootRef.current = ref)}>
      <GridList
        className={classes.gridList}
        cols={calculateGridListColsCount(rootRef)}
        cellHeight={SLIDE_SIZE}
        spacing={2}
      >
        {allSlides.map((slide, index) => (
          <GridListTile key={slide.path ?? index}>
            {(slide.path || slide.isLoading) && (
              <WeeklyTipSlideContainer
                enabledControls={
                  slide.isLoading
                    ? []
                    : [
                        ...(index > 0 ? [SlideControl.MOVE_LEFT] : []),
                        ...(index < slides.length - 1
                          ? [SlideControl.MOVE_RIGHT]
                          : []),
                        SlideControl.DELETE
                      ]
                }
                onControlClicked={control =>
                  onSlideControlClicked(index, control)
                }
              >
                {slide.path ? (
                  <WeeklyTipSlideImage imagePath={slide.path} />
                ) : (
                  <CircularProgress />
                )}
              </WeeklyTipSlideContainer>
            )}
            {slide.isAddSlide && (
              <NewWeeklyTipSlide onFilesSelected={onFilesSelected} />
            )}
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
};

function calculateGridListColsCount(rootRef: any) {
  return Math.max(
    Math.floor((rootRef?.current?.offsetWidth ?? SLIDE_SIZE) / SLIDE_SIZE) - 1,
    1
  );
}
