import { CommitsRepository } from "../../../repository/use-case/commits/CommitsRepository";
import ContentRepository from "../../../repository/use-case/content/ContentRepository";
import UserRepository from "../../../repository/use-case/user/UserRepository";
import { Locale } from "../../../locale";
import LocalesRepository from "../../../repository/use-case/locales/LocalesRepository";
import ConfigurationRepository from "../../../repository/use-case/configuration/ConfigurationRepository";
import { TasksRepository } from "../../../repository/use-case/tasks/TasksRepository";
import { PermissionsRepository } from "../../../repository/use-case/permissions/PermissionsRepository";
import { WeeklyTipsRepository } from "../../../repository/use-case/weekly-tips/WeeklyTipsRepository";
import { CalendarRepository } from "../../../repository/use-case/calendar/CalendarRepository";
import { ScrapeRepository } from "../../../repository/use-case/scrape/ScrapeRepository";
import { UsersVoiceRepository } from "../../../repository/use-case/users-voice/UsersVoiceRepository";
import { DeveloperConsoleRepository } from "../../../repository/use-case/developer-console/DeveloperConsoleRepository";
import { ScrapeRepositoryV2 } from "../../../repository/use-case/scrape2/ScrapeRepositoryV2";
import { AnnouncementsRepository } from "../../../domain/announcements/data/AnnouncementsRepository";
import { FileManagementRepository } from "../../../domain/file-management/data/FileManagementRepository";
import { GlobalUserTasksRepository } from "../../../domain/global-user-tasks/data/GlobalUserTasksRepository";

export default class Repositories {
  constructor(
    readonly commits: CommitsRepository,
    readonly content: ContentRepository,
    readonly user: UserRepository,
    readonly locales: LocalesRepository,
    readonly configuration: ConfigurationRepository,
    readonly tasks: TasksRepository,
    readonly permissions: PermissionsRepository,
    readonly weeklyTips: WeeklyTipsRepository,
    readonly calendar: CalendarRepository,
    readonly scrape: ScrapeRepository,
    readonly scrapeV2: ScrapeRepositoryV2,
    readonly usersVoice: UsersVoiceRepository,
    readonly developerConsole: DeveloperConsoleRepository,
    readonly announcements: AnnouncementsRepository,
    readonly fileManagement: FileManagementRepository,
    readonly globalUserTasks: GlobalUserTasksRepository
  ) {}

  set locale(locale: Locale) {
    this.commits.setLocale(locale);
    this.content.setLocale(locale);
  }
}
