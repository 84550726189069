import LocalizedResource from "../../data/LocalizedResource";

export enum WeeklyTipFields {
  ID = "id",
  TOPIC = "topic",
  SLIDES = "slides"
}

export default interface WeeklyTip extends LocalizedResource {
  [WeeklyTipFields.ID]: string;
  [WeeklyTipFields.TOPIC]: string[];
  [WeeklyTipFields.SLIDES]: string[];
}
