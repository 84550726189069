import moment, { Moment } from "moment-timezone";
import { CalendarEvent } from "../../repository/use-case/calendar/CalendarEvent";
import { CreatableEventType } from "../../components/calendar/CreatableEventType";
import { Resources } from "../../data/Resources";

export function createEditEventDialogFormData(
  selectedEvent: CalendarEvent | undefined,
  selectedDates: Moment[],
  eventTypeResolver: (
    type: Resources | undefined
  ) => CreatableEventType | undefined,
  timezone: string
) {
  return {
    eventId: selectedEvent?.id,
    selectedDates: selectedEvent
      ? [
          moment(selectedEvent.startTime).tz(timezone),
          moment(selectedEvent.endTime).tz(timezone)
        ]
      : [
          (selectedDates[0] ?? moment.tz(timezone)).clone().startOf("day"),
          ...selectedDates.slice(1, -1),
          (selectedDates[selectedDates.length - 1] ?? moment.tz(timezone))
            .clone()
            .endOf("day")
        ],
    title: selectedEvent?.title,
    color: selectedEvent?.color,
    ...(selectedEvent && {
      eventCandidate: {
        ...(selectedEvent.resource?.id && {
          eventEntry: {
            id: selectedEvent.resource?.id
          }
        }),
        eventType: eventTypeResolver(selectedEvent.resource?.type)
      }
    })
  };
}
