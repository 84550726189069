import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles(theme => ({
  calendarPageWrapper: {
    margin: "2vw",
    maxHeight: "calc(100% - 4vw)",
    display: "flex",
    flexDirection: "column"
  },
  calendarPaperWrapper: {
    height: "100%",
    marginTop: "1vw",
    display: "flex",
    flexDirection: "column"
  },
  calendarTimeZoneInfo: {
    width: "fit-content"
  }
}));
