import {
  Tooltip,
  IconButton,
  Theme,
  MuiThemeProvider
} from "@material-ui/core";
import * as React from "react";

export const TaskActionButton = ({
  description,
  icon,
  onClick,
  taskActionType
}: {
  description?: string;
  icon: any;
  onClick: () => void;
  taskActionType: "cancel" | "retry" | "delete";
}) => {
  const theme = React.useCallback(
    (theme: Theme) => ({
      ...theme,
      overrides: {
        ...theme.overrides,
        MuiIconButton: {
          ...theme.overrides?.MuiIconButton,
          label: {
            color:
              taskActionType &&
              theme.tasks &&
              theme.tasks[taskActionType]?.color
          }
        }
      }
    }),
    [taskActionType]
  );

  const button = (
    <MuiThemeProvider theme={theme}>
      <IconButton size="small" onClick={onClick}>
        {icon}
      </IconButton>
    </MuiThemeProvider>
  );
  return description ? <Tooltip title={description}>{button}</Tooltip> : button;
};
