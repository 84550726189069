import * as React from "react";
import { useEffect } from "react";
import login_bg from "../../assets/login_bg.jpeg";
import { LoginViewModel } from "../../components/login/LoginViewModel";
import User from "../../api/auth/User";
import { Box, Typography } from "@material-ui/core";
import LoginOptions from "../../components/login/LoginOptions";
import { useStyles } from "./styles";

const LoginPage = ({
  viewModel,
  onUserLoggedIn
}: {
  viewModel: LoginViewModel;
  onUserLoggedIn: Function;
}) => {
  useEffect(() => {
    return viewModel.registerAuthUserChanges((authUser: User) => {
      if (authUser) {
        onUserLoggedIn();
      }
    });
  }, [onUserLoggedIn]);

  const onLoginSubmit = React.useCallback(async () => {
    try {
      const res = await viewModel.getSignInWithPopup()();
      console.log(res);
    } catch (e: any) {}
  }, [viewModel]);

  const classes = useStyles();
  return (
    <Box className={classes.pageContainer}>
      <img
        src={login_bg}
        alt={"Page background"}
        style={{ objectFit: "cover", flex: 1, overflow: "auto" }}
      />
      <Box className={classes.loginBoxContainer}>
        <Typography variant="h2" align="center">
          Welcome,
        </Typography>
        <Typography variant="h3" align="center">
          Please login.
        </Typography>
        <Box className={classes.loginOptions}>
          <LoginOptions onLoginSubmitted={onLoginSubmit} />
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;
