import { Grid } from "@material-ui/core";
import * as React from "react";
import WarningChip from "../chips/WarningChip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { PropsWithChildren } from "react";

const useStyles = makeStyles(theme => ({
  warning: {
    marginTop: "0.5vh",
    padding: "0.3vw 0.3vh"
  }
}));

const WarningWrapper = (
  props: {
    show: boolean;
    message: string;
    tooltip?: string;
  } & PropsWithChildren<any>
) => {
  const { show, message, tooltip, children } = props;
  const classes = useStyles();
  return (
    <Grid container direction="column" alignItems="center" justify="center">
      {children && <Grid item>{children}</Grid>}
      {show && (
        <Grid item>
          <WarningChip
            text={message}
            className={classes.warning}
            description={tooltip}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default WarningWrapper;
