import { Grid, IconButton, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React, { useMemo } from "react";
import useTheme from "@material-ui/core/styles/useTheme";
// import { VisibilityOff } from "@material-ui/icons";

export const ScrapedContentActions = ({
  isNew,
  onAddClicked
}: // onHideClicked,
// enableHide
{
  isNew: boolean;
  onAddClicked: () => void;
  // onHideClicked?: () => void;
  // enableHide?: boolean;
}) => {
  const theme = useTheme();
  const gridItems = useMemo(() => {
    const items: JSX.Element[] = [];

    // if (enableHide) {
    //   items.push(
    //     <Grid item>
    //       <Tooltip title="Hide from the list">
    //         <IconButton size="small" onClick={onHideClicked}>
    //           <VisibilityOff
    //             htmlColor={theme.contentManagement?.remove.color}
    //           />
    //         </IconButton>
    //       </Tooltip>
    //     </Grid>
    //   );
    // }

    items.push(
      <Grid item>
        {isNew ? (
          <IconButton size="small" onClick={onAddClicked}>
            <AddIcon htmlColor={theme.contentManagement?.create.color} />
          </IconButton>
        ) : (
          <Tooltip title="Already in collection">
            <IconButton size="small">
              <CheckCircleIcon color="primary" />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    );

    return items;
  }, [isNew, onAddClicked, theme.contentManagement]);
  return (
    <Grid container direction={"row"} spacing={1}>
      {gridItems}
    </Grid>
  );
};
