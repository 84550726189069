import Paper from "@material-ui/core/Paper/Paper";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  extractContentField,
  extractFirstContentField,
  extractFirstLocalizedProp
} from "../../utils/data/resources/managed";
import { ManagedWeeklyTip } from "../../data/ManagedWeeklyTip";
import WeeklyTip, { WeeklyTipFields } from "../../api/content/WeeklyTip";
import { useStyles } from "./styles";
import { SlideData, WeeklyTipSlides } from "./slides/WeeklyTipSlides";
import Button from "@material-ui/core/Button";
import { Motion } from "react-motion";
import {
  UPDATE_BUTTON_ANIMATED_DISABLED_STYLE,
  UPDATE_BUTTON_ANIMATED_ENABLED_STYLE,
  UPDATE_BUTTON_DEFAULT_DISABLED_STYLE,
  UPDATE_BUTTON_DEFAULT_ENABLED_STYLE
} from "./weekly-tip-constants";
import { usePrevious } from "../../hooks/usePrevious";
import { SlideControl } from "./slides/SlideControl";
import { swap } from "../../utils/arrays/swap";

export const WeeklyTipEditFields = ({
  weeklyTip,
  onChange,
  locale,
  uploadFile
}: {
  weeklyTip: ManagedWeeklyTip | undefined;
  onChange: (updatedFields: {
    [WeeklyTipFields.TOPIC]: string[];
    [WeeklyTipFields.SLIDES]: string[];
  }) => void;
  locale: string;
  uploadFile(file: File): Promise<{ fullPath: string }>;
}) => {
  const classes = useStyles();
  const topic =
    weeklyTip && extractFirstContentField(weeklyTip, WeeklyTipFields.TOPIC);
  const [currentForm, setForm] = useState<
    Pick<WeeklyTip, WeeklyTipFields.TOPIC | WeeklyTipFields.SLIDES>
  >({
    [WeeklyTipFields.TOPIC]: [topic ?? ""],
    [WeeklyTipFields.SLIDES]:
      (weeklyTip && extractContentField(weeklyTip, WeeklyTipFields.SLIDES)) ??
      []
  });

  const onTopicNameChange = useCallback(element => {
    const newTopic = element.target.value;
    setForm(form => ({
      ...form,
      [WeeklyTipFields.TOPIC]: [newTopic]
    }));
  }, []);

  const onSaveTopicNameClicked = useCallback(() => {
    onChange(currentForm);
  }, [currentForm, onChange]);

  const previousForm = usePrevious(currentForm);
  useEffect(() => {
    if (previousForm && previousForm.slides !== currentForm.slides) {
      onChange(currentForm);
    }
  }, [currentForm, onChange, previousForm]);

  const [uploadingFiles, setUploadingFiles] = useState<Set<string>>(new Set());
  const onSlidesForUploadSelected = useCallback(
    async (files: File[]): Promise<void> => {
      const filesToUploadNames = new Set(files.map(file => file.name));
      const removeUploadingFile = () => {
        setUploadingFiles(fileNames => {
          return new Set(
            Array.from(fileNames).filter(
              previousName => !filesToUploadNames.has(previousName)
            )
          );
        });
      };
      try {
        setUploadingFiles(
          fileNames =>
            new Set([
              ...Array.from(fileNames),
              ...Array.from(filesToUploadNames)
            ])
        );
        const uploadResults = await Promise.all(
          files.map(singleFile =>
            uploadFile(singleFile).catch(e =>
              console.log("failed to upload file ", e)
            )
          )
        );
        const validPaths = uploadResults
          .filter(result => result)
          .map(result => (result as { fullPath: string }).fullPath);
        setForm(currentForm => ({
          ...currentForm,
          [WeeklyTipFields.SLIDES]: [
            ...currentForm[WeeklyTipFields.SLIDES].filter(
              currentPath => !validPaths.includes(currentPath)
            ),
            ...validPaths
          ]
        }));
        removeUploadingFile();
      } catch (e: any) {
        removeUploadingFile();
        throw e;
      }
    },
    [uploadFile]
  );

  const currentSlides = useMemo(() => {
    const currentSlides: SlideData[] = currentForm[WeeklyTipFields.SLIDES].map(
      slideUrl => ({
        path: slideUrl
      })
    );
    return currentSlides.concat(
      Array.from(uploadingFiles).map(() => ({ isLoading: true }))
    );
  }, [currentForm, uploadingFiles]);

  const onSlideControlClicked = useCallback(
    (index: number, slideControl: SlideControl) => {
      const updateSlides = (currentSlides: string[]): string[] => {
        switch (slideControl) {
          case SlideControl.MOVE_LEFT:
            return swap(currentSlides, index, index - 1);
          case SlideControl.MOVE_RIGHT:
            return swap(currentSlides, index, index + 1);
          case SlideControl.DELETE:
            return currentSlides.filter(
              (_, slideIndex) => slideIndex !== index
            );
        }
      };
      setForm(currentForm => ({
        ...currentForm,
        [WeeklyTipFields.SLIDES]: [
          ...updateSlides(currentForm[WeeklyTipFields.SLIDES])
        ]
      }));
    },
    []
  );

  return (
    <Paper>
      <Grid
        container
        direction={"column"}
        className={classes.fieldsContainer}
        spacing={2}
      >
        <Grid item container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <TextField
              variant="outlined"
              label="Tip Name"
              placeholder="Tip Name"
              value={currentForm.topic[0] ?? ""}
              size="medium"
              onChange={onTopicNameChange}
              className={classes.weeklyTipNameTextField}
            />
          </Grid>
          {
            <Motion
              defaultStyle={
                isTopicChanged(weeklyTip, currentForm, locale)
                  ? UPDATE_BUTTON_DEFAULT_DISABLED_STYLE
                  : UPDATE_BUTTON_DEFAULT_ENABLED_STYLE
              }
              style={
                isTopicChanged(weeklyTip, currentForm, locale)
                  ? UPDATE_BUTTON_ANIMATED_DISABLED_STYLE
                  : UPDATE_BUTTON_ANIMATED_ENABLED_STYLE
              }
            >
              {interpolatedStyle => (
                <Grid item>
                  <Button
                    onClick={onSaveTopicNameClicked}
                    variant="contained"
                    color="primary"
                    style={interpolatedStyle}
                  >
                    <Typography>Update</Typography>
                  </Button>
                </Grid>
              )}
            </Motion>
          }
        </Grid>
        <Grid item>
          <Typography variant="h5">Slides</Typography>
        </Grid>
        <Grid item>
          <WeeklyTipSlides
            onSlidesForUploadSelected={onSlidesForUploadSelected}
            slides={currentSlides}
            onSlideControlClicked={onSlideControlClicked}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

function isTopicChanged(
  weeklyTip: ManagedWeeklyTip | undefined,
  form: Pick<WeeklyTip, WeeklyTipFields.TOPIC | WeeklyTipFields.SLIDES>,
  locale: string
) {
  return (
    weeklyTip &&
    extractFirstLocalizedProp(locale, weeklyTip, WeeklyTipFields.TOPIC)
      .value !== form.topic[0]
  );
}
