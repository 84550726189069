import React, { Attributes } from "react";
import DestructiveAlertDialog from "./DestructiveAlertDialog";

interface DeleteAlertDialogProps extends Attributes {
  isOpen: boolean;
  deleteInfo: string;
  handleClose: () => void;
  handleConfirm: () => void;
  isLoading: boolean;
}

const DeleteAlertDialog = (props: DeleteAlertDialogProps) => {
  return (
    <DestructiveAlertDialog
      isLoading={props.isLoading}
      isOpen={props.isOpen}
      title={`Delete ${props.deleteInfo}?`}
      message={`Are you sure you want to delete ${props.deleteInfo}?\nIt will be removed in next publish.`}
      destructiveButtonText="Delete"
      handleClose={props.handleClose}
      handleConfirm={props.handleConfirm}
    />
  );
};

export default DeleteAlertDialog;
