export default function asMap<T, K, V>(
  array: T[],
  keyTransformer: (element: T) => K,
  valueTransformer: (element: T) => V
) {
  return array.reduce((map, elem) => {
    map.set(keyTransformer(elem), valueTransformer(elem));
    return map;
  }, new Map() as Map<K, V>);
}
