import * as firebase from "firebase";
import { BehaviorSubject, Observable } from "rxjs";
import { toObservable, UploadTaskProgress } from "./observable-storage-task";
import { shareReplay } from "rxjs/operators";

export interface UploadMetadata {
  cacheControl?: string | null;
  contentType?: string | null;
  customMetadata?: {
    [key: string]: string;
  } | null;
}

export class FirebaseStorage {
  private readonly storage: firebase.storage.Storage;

  constructor(private readonly firebaseApp: firebase.app.App) {
    this.storage = this.firebaseApp.storage();
  }

  private readonly taskObservables = new BehaviorSubject<
    Observable<UploadTaskProgress>[]
  >([]);

  get tasks(): Observable<Observable<UploadTaskProgress>[]> {
    return this.taskObservables;
  }

  createUploadTask = ({
    file,
    path,
    metadata
  }: {
    file: File;
    path: string;
    metadata?: UploadMetadata;
  }) => {
    this.taskObservables.next([
      ...this.taskObservables.value,
      toObservable(
        this.storage
          .ref()
          .child(path)
          .put(file, metadata)
      ).pipe(shareReplay())
    ]);
  };

  upload = async ({
    file,
    path,
    metadata
  }: {
    file: File;
    path: string;
    metadata?: UploadMetadata;
  }): Promise<{ fullPath: string }> => {
    const fileReference = this.storage.ref().child(path);

    await fileReference.put(file, metadata);
    return {
      fullPath: fileReference.fullPath
    };
  };

  getDownloadUrl = (fullPath: string): Promise<string> => {
    return this.storage.ref(fullPath).getDownloadURL();
  };

  getMetadata = (fullPath: string): Promise<any> => {
    return this.storage.ref(fullPath).getMetadata();
  };

  listFiles = (
    path: string
  ): Promise<{
    fileNames: string[];
  }> => {
    return this.storage
      .ref(path)
      .list()
      .then(listResult => ({
        fileNames: listResult.items.map(item => item.name)
      }));
  };
}
