import ObservableRepository from "./ObservableRepository";
import { Observable } from "rxjs";
import { distinctUntilChanged, first } from "rxjs/operators";
import _ from "lodash";

export default abstract class HotObservableRepository<T>
  implements ObservableRepository<T> {
  private sharedObservable?: Observable<T>;

  getDataObservable(): Observable<T> {
    if (!this.sharedObservable) {
      this.sharedObservable = this._loadDataObservable().pipe(distinctUntilChanged((a, b) => _.isEqual(a, b)));
    }
    return this.sharedObservable;
  }

  protected abstract readonly _loadDataObservable: () => Observable<T>;

  loadData(): Promise<T> {
    return this.getDataObservable()
      .pipe(first())
      .toPromise();
  }
}
