import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { useStyles } from "../styles";
import Flag from "react-world-flags";

const PublishSummaryStatistics = ({
  totalAdded,
  totalChanged,
  totalRemoved,
  totalArchived,
  totalUnarchived,
  newTranslations
}: {
  totalAdded: number;
  totalChanged: number;
  totalRemoved: number;
  totalArchived: number;
  totalUnarchived: number;
  newTranslations: {
    [locale: string]: { countryCode: string; total: number };
  };
}) => {
  const classes = useStyles();
  const renderTypography = (text: string) => () => (
    <Typography align="center">{text}</Typography>
  );
  const items: {
    shouldShow: () => boolean;
    className: string;
    renderLeft: () => JSX.Element;
    renderRight: () => JSX.Element;
  }[] = [
    {
      shouldShow: () => totalAdded > 0,
      className: classes.added,
      renderLeft: renderTypography(`+${totalAdded}`),
      renderRight: renderTypography("Added")
    },
    {
      shouldShow: () => totalUnarchived > 0,
      className: classes.unarchived,
      renderLeft: renderTypography(`+${totalUnarchived}`),
      renderRight: renderTypography("Unarchived")
    },
    {
      shouldShow: () => totalChanged > 0,
      className: classes.changed,
      renderLeft: renderTypography(`${totalChanged}`),
      renderRight: renderTypography("Changed")
    },
    {
      shouldShow: () => totalRemoved > 0,
      className: classes.removed,
      renderLeft: renderTypography(`-${totalRemoved}`),
      renderRight: renderTypography("Removed")
    },
    {
      shouldShow: () => totalArchived > 0,
      className: classes.archived,
      renderLeft: renderTypography(`-${totalArchived}`),
      renderRight: renderTypography("Archived")
    },
    ...Object.keys(newTranslations).map(locale => ({
      shouldShow: () => (newTranslations[locale]?.total || 0) > 0,
      className: classes.translated,
      renderLeft: () => (
        <Flag
          style={{ width: "1em", height: "1em" }}
          code={newTranslations[locale]?.countryCode ?? ""}
        />
      ),
      renderRight: renderTypography(
        `${newTranslations[locale]?.total || 0} new ${locale} translation${
          (newTranslations[locale]?.total || 0) > 1 ? "s" : ""
        }`
      )
    }))
  ];
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={1}
      className={classes.parentContainerHeight}
    >
      {items
        .filter(item => item.shouldShow())
        .map(({ className, renderLeft, renderRight }) => (
          <Grid
            container
            item
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Box className={className as string}>{renderLeft()}</Box>
            </Grid>
            <Grid item justify="center" alignItems="center">
              <Box>{renderRight()}</Box>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};

export default PublishSummaryStatistics;
