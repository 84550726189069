import OwnerTimelineEntry from "../../../data/OwnerTimelineEntry";

export class ContentOwnerViewModel {
  constructor(
    private readonly ownerTimeline: OwnerTimelineEntry[],
    private readonly modificationType?:
      | "create"
      | "update"
      | "remove"
      | "archive"
      | "unarchive"
  ) {}

  get contentOwnerTimeline() {
    return this.ownerTimeline;
  }

  get contentDividerModificationColor() {
    return this.modificationType;
  }
}
