import { combineLatest } from "rxjs";
import useObservableLCE from "./userObservableLCE";
import { flatMap } from "rxjs/operators";
import ObservableRepository from "../repository/ObservableRepository";
import { LCE } from "../architecture/lce/lce";

export function useObservableRepositoryLCE<
  T1,
  T2,
  T3,
  R extends object,
  E extends Error
>(
  combiner: (sources: [T1, T2, T3]) => Promise<R>,
  deps: any[],
  r1: ObservableRepository<T1>,
  r2: ObservableRepository<T2>,
  r3: ObservableRepository<T3>
): LCE<R, E>;

export function useObservableRepositoryLCE<
  T1,
  T2,
  R extends object,
  E extends Error
>(
  combiner: (sources: [T1, T2]) => Promise<R>,
  deps: any[],
  r1: ObservableRepository<T1>,
  r2: ObservableRepository<T2>
): LCE<R, E>;

export function useObservableRepositoryLCE<
  T1,
  R extends object,
  E extends Error
>(
  combiner: (sources: [T1]) => Promise<R>,
  deps: any[],
  r1: ObservableRepository<T1>
): LCE<R, E>;

export function useObservableRepositoryLCE(
  combiner: (...values: Array<any>) => Promise<any>,
  deps: any[],
  ...sources: ObservableRepository<any>[]
) {
  return useObservableLCE(
    () => Promise.all(sources.map(source => source.loadData())).then(combiner),
    combineLatest(sources.map(source => source.getDataObservable())).pipe(
      flatMap(combiner)
    ),
    deps
  );
}
