import { mapOf } from "../../../utils/maps";
import * as React from "react";
import FileCopyIcon from '@material-ui/icons/FileCopy';

export const CLIPBOARD_ACTION = "clipboard";

export const clipboardAction = mapOf(
  CLIPBOARD_ACTION,
  {
    icon: <FileCopyIcon />,
    description: "Copy"
  },
);
