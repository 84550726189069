import React from "react";
import { useStyles } from "./styles";
import { LCEWrapper } from "../../components/lce/LCEWrapper";
import useLCE from "../../hooks/useLCE";
import { useRepositories } from "../../hooks/useRepositories";
import { Permission } from "../../api/permissions/Permission";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { PortalDestination } from "../../components/portal/PortalDestination";
import { CMSRoutes } from "../../constants/routes/cms-routes";
import { useHistory } from "react-router-dom";
import { Motion, spring } from "react-motion";
import { CommunityRoutes } from "../../constants/routes/community-routes";
import { DeveloperConsoleRoutes } from "../../constants/routes/developer-console-routes";

const portalDestinationsByPermission = {
  [Permission.PERMISSION_ACCESS_CMS]: {
    name: "Content Management System",
    description:
      "Manage all of our content, including quotes, authors and more.",
    path: `/cms/${CMSRoutes.DASHBOARD}`
  },
  [Permission.PERMISSION_COMMUNITY_ENGAGEMENT]: {
    name: "Community Engagement",
    description: "Engage with our community with messaging and polls.",
    path: `/community/${CommunityRoutes.DASHBOARD}`
  },
  [Permission.PERMISSION_DEVELOPER_CONSOLE]: {
    name: "Developer Console",
    description: "Access developer tools & actions",
    path: `/developer-console/${DeveloperConsoleRoutes.DASHBOARD}`
  }
};

export const PortalPage = () => {
  const classes = useStyles();

  const { permissions } = useRepositories();
  const permissionLCE = useLCE(permissions.getScreenPermissions);
  const history = useHistory();

  const renderPermittedDestinations = (permissions: Permission[]) => {
    if (permissions.length === 0) {
      return (
        <Typography className={classes.viewPortCenter}>
          Insufficient permissions. Please contact an admin.
        </Typography>
      );
    }

    const destinations = permissions.map(
      permission => portalDestinationsByPermission[permission]
    );

    if (destinations.length === 1) {
      history.push(destinations[0].path);
      return <div />;
    }

    return (
      <Grid
        container
        alignItems={"center"}
        justify={"center"}
        spacing={2}
        xs={12}
        className={classes.pageContainer}
      >
        {destinations.map((destination, index) => (
          <Grid item>
            <Motion
              defaultStyle={{ bottom: -20, opacity: 0 }}
              style={{
                bottom: spring(0, {
                  stiffness: 160 * (index * 0.5 + 1),
                  damping: 40
                }),
                opacity: spring(1, {
                  stiffness: 160 * (index * 0.5 + 1),
                  damping: 40
                })
              }}
            >
              {interpolatedStyle => (
                <div
                  style={{
                    position: "relative",
                    ...interpolatedStyle
                  }}
                >
                  <PortalDestination
                    name={destination.name}
                    description={destination.description}
                    onClick={() => history.push(destination.path)}
                  />
                </div>
              )}
            </Motion>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <LCEWrapper
      lce={permissionLCE}
      renderContent={renderPermittedDestinations}
    />
  );
};
