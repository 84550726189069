import { Box, List, ListItem, Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import moment from "moment";
import { LocalViewModelFactory } from "../../../architecture/view-model/LocalViewModelFactory";
import {
  FileDetails,
  FileManagerViewModelBase
} from "../FileManagerViewModelBase";
import { useLocalViewModel } from "../../../architecture/view-model/useViewModel";
import { LCEWrapper } from "../../../components/lce/LCEWrapper";

export interface FileManagerFileMetadataProps {
  viewModelFactory: LocalViewModelFactory<FileManagerViewModelBase>;
}

const dateFormat = "DD/MM/yyyy HH:mm";
const valueFormatterPerKey: Record<string, (value: string) => string> = {
  size: bytesString => {
    const bytes = +bytesString;
    if (bytes < 1e3) {
      return `${bytes} bytes`;
    }
    if (bytes < 1e6) {
      return `${(bytes / 1e3).toFixed(2)} kilobytes`;
    }
    if (bytes < 1e9) {
      return `${(bytes / 1e6).toFixed(2)} megabytes`;
    }
    if (bytes < 1e12) {
      return `${(bytes / 1e9).toFixed(2)} gigabytes`;
    }
    return `${(bytes / 1e12).toFixed(2)} terabytes`;
  },
  timeCreated: timestamp => moment(timestamp).format(dateFormat),
  updated: timestamp => moment(timestamp).format(dateFormat)
};

export const FileManagerFileMetadata = (
  props: FileManagerFileMetadataProps
) => {
  const [viewState] = useLocalViewModel(props.viewModelFactory);
  const theme = useTheme();

  const renderContent = useCallback(
    ({ metadata }: FileDetails) => (
      <List>
        {Object.keys(metadata).map((metadataKey, index, array) => {
          const value = metadata[metadataKey];
          return (
            <ListItem
              divider={index < array.length - 1}
              style={{
                backgroundColor:
                  index % 2 !== 0
                    ? theme.components?.list?.alternatingColor
                    : undefined
              }}
            >
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <Typography>{metadataKey}</Typography>
                <Typography>
                  {valueFormatterPerKey[metadataKey]?.(value) ?? value}
                </Typography>
              </Box>
            </ListItem>
          );
        })}
      </List>
    ),
    []
  );

  if (!viewState.fileDetailedView?.detailsLCE) {
    return <></>;
  }

  return (
    <LCEWrapper
      lce={viewState.fileDetailedView.detailsLCE}
      renderContent={renderContent}
    />
  );
};
