import { Grid, Theme, Typography, useTheme } from "@material-ui/core";
import React from "react";
import {
  ConversationEntry,
  ConversationStatus, ConversationType
} from "../../../../repository/use-case/users-voice/dto/ListConversationsResponse";

export const ConversationItemContent = ({
  status,
  topic,
  type
}: Pick<ConversationEntry, "status" | "topic" | "type">) => {
  const theme = useTheme();
  const statusText = conversationStatusText(status);
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Typography variant={"h6"}>{conversationTypeText(type)}</Typography>
      </Grid>
      {topic && [
        <Grid item>
          <Typography variant={"h6"}>•</Typography>
        </Grid>,
        <Grid item>
          <Typography variant={"h6"}>{topic}</Typography>
        </Grid>
      ]}
      {statusText && [
        <Grid item>
          <Typography variant={"h6"}>•</Typography>
        </Grid>,
        <Grid item>
          <Typography
            variant={"h6"}
            style={{
              color: conversationStatusColor(theme, status)
            }}
          >
            {conversationStatusText(status)}
          </Typography>
        </Grid>
      ]}
    </Grid>
  );
};

function conversationTypeText(type: ConversationType) {
  switch (type) {
    case ConversationType.FEEDBACK:
      return "Feedback";
    case ConversationType.QUESTION:
      return "Question";
    case ConversationType.FEATURE_REQUEST:
      return "Feature Request";
    case ConversationType.BUG_REPORT:
      return "Bug Report";
    case ConversationType.BROADCAST:
      return "Broadcast";
    case ConversationType.OTHER:
      return "Other";
    default:
      return "";
  }
}


function conversationStatusText(status: ConversationStatus) {
  switch (status) {
    case ConversationStatus.FEEDBACK_PENDING:
      return "Pending";
    case ConversationStatus.FEEDBACK_RESOLVED:
      return "Resolved";
    default:
      return "";
  }
}

function conversationStatusColor(theme: Theme, status: ConversationStatus) {
  switch (status) {
    case ConversationStatus.FEEDBACK_PENDING:
      return theme.communityManagement?.usersVoice.conversation.status.pending;
    case ConversationStatus.FEEDBACK_RESOLVED:
      return theme.communityManagement?.usersVoice.conversation.status.resolved;
    default:
      return "#000";
  }
}
