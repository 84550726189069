import * as React from "react";
import { Attributes, useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/auth";
import User from "../../api/auth/User";

export function withAuth<P extends Attributes>(
  WrappedComponent: React.ComponentType<P>
) {
  return (props: P) => {
    const [user, setUser] = useState<User | undefined>();

    const authApi = useContext(AuthContext)!;
    useEffect(() =>
      authApi.authUserChanges(user => {
        setUser(user);
      }), [authApi]);

    return <WrappedComponent user={user} {...props} authApi={authApi} />;
  };
}
