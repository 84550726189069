import { AnnouncementsRepository } from "../../../domain/announcements/data/AnnouncementsRepository";
import firebase from "firebase";
import { Observable } from "rxjs";
import { Announcement } from "../../../domain/announcements/entities/announcement";
import { FirestoreRealtimeAPI, RealtimeSystems } from "./FirestoreRealtimeAPI";
import { collectionAsObservable } from "../../../utils/firebase/asObservable";

export class FirebaseAnnouncementsRepository
  implements AnnouncementsRepository {
  constructor(
    private readonly firestore: firebase.firestore.Firestore,
    private readonly realtimeAPI: FirestoreRealtimeAPI
  ) {}

  announcements(): Observable<Announcement[]> {
    return collectionAsObservable(
      this.firestore.collection("data/announcements/EN"),
      snapshot =>
        snapshot.docs.map(
          doc =>
            ({
              id: doc.id,
              ...doc.data()
            } as Announcement)
        )
    );
  }

  createNew(userId: string): Promise<void> {
    return this.realtimeAPI.postCommand(RealtimeSystems.ANNOUNCEMENTS, userId, {
      name: "create"
    });
  }

  delete(userId: string, announcementId: string): Promise<void> {
    return this.realtimeAPI.postCommand(RealtimeSystems.ANNOUNCEMENTS, userId, {
      name: "delete",
      announcementId
    });
  }

  update(
    userId: string,
    announcementId: string,
    updatedFields: Partial<Announcement>
  ): Promise<void> {
    return this.realtimeAPI.postCommand(RealtimeSystems.ANNOUNCEMENTS, userId, {
      name: "update",
      announcement: {
        id: announcementId,
        updatedFields
      }
    });
  }
}
