import { BaseViewModel } from "../../../architecture/view-model/BaseViewModel";
import { AnnouncementsRepository } from "../../../domain/announcements/data/AnnouncementsRepository";
import { combineLatest, Subject, Subscription } from "rxjs";
import { Announcement } from "../../../domain/announcements/entities/announcement";
import { cloneDeep, isEqual, keyBy } from "lodash";
import { map, startWith } from "rxjs/operators";
import { NavigationEffect } from "../../viewmodel/navigation/NavigationEffect";
import { UpdateNavigationCallback } from "../../viewmodel/navigation/UpdateNavigationCallback";
import User from "../../../api/auth/User";

export interface AnnouncementsViewState {
  announcements: Announcement[];
  showCreateAnnouncement?: boolean;
  selectedAnnouncement?: {
    announcement: Announcement;
    editing?: boolean;
  };
  editValues?: Partial<Announcement>;
  pendingAction?: {
    actionConfirmation?: AnnouncementsActionConfirmation;
    isLoading?: boolean;
  };
  actionsState?: {
    saveEnabled: boolean;
  };
  changeDifference?: Partial<
    {
      [Property in keyof Announcement]: {
        previousValue: Announcement[Property];
        currentValue: Announcement[Property];
      };
    }
  >;
}

export type AnnouncementsActionConfirmation =
  | AnnouncementActionDelete
  | AnnouncementActionCancelEdit
  | AnnouncementActionSaveEdit;

export interface AnnouncementActionDelete {
  type: "delete";
}

export interface AnnouncementActionCancelEdit {
  type: "cancelEdit";
}

export interface AnnouncementActionSaveEdit {
  type: "saveEdit";
}

interface Navigation {
  announcementId?: string;
  editing?: boolean;
}

export class AnnouncementsViewModel
  extends BaseViewModel<AnnouncementsViewState, NavigationEffect<Navigation>>
  implements UpdateNavigationCallback<Navigation> {
  private announcementsSubscription?: Subscription;

  private navigationParams = new Subject<Navigation>();

  constructor(
    private readonly currentUser: User | null,
    private readonly announcementsRepository: AnnouncementsRepository
  ) {
    super({
      announcements: []
    });
  }

  init() {
    combineLatest([
      this.announcementsRepository
        .announcements()
        .pipe(
          map(announcements =>
            keyBy(announcements, announcement => announcement.id)
          )
        ),
      this.navigationParams.pipe(startWith(undefined))
    ]).subscribe(([announcementsDictionary, navigationParams]) => {
      const announcement = navigationParams?.announcementId
        ? announcementsDictionary[navigationParams.announcementId]
        : undefined;
      this.setState({
        announcements: Object.values(announcementsDictionary),
        showCreateAnnouncement: true,
        selectedAnnouncement: announcement
          ? {
              announcement,
              editing: !!navigationParams?.editing
            }
          : undefined,
        ...(!navigationParams?.editing && {
          editValues: undefined,
          changeDifference: undefined
        })
      });
    });
  }

  clear() {
    this.announcementsSubscription?.unsubscribe();
  }

  onNavigationLocationUpdated(navigationParams: Partial<Navigation>): void {
    this.navigationParams.next(navigationParams);
  }

  onClickAnnouncement(announcement: Announcement) {
    if (
      this.currentState().selectedAnnouncement?.announcement?.id ===
      announcement.id
    ) {
      return;
    }
    this.mergeState({
      editValues: undefined,
      changeDifference: undefined
    });
    this.nextEffect({
      navigation: {
        setLocationParams: {
          announcementId: announcement.id
        }
      }
    });
  }

  onClickCreateAnnouncement() {
    if (!this.currentUser) {
      return;
    }
    this.announcementsRepository.createNew(this.currentUser.id);
  }

  onClickEdit() {
    this.nextEffect({
      navigation: {
        updateLocationParams: {
          editing: true
        }
      }
    });
  }

  onClickDelete() {
    this.mergeState({
      pendingAction: {
        actionConfirmation: {
          type: "delete"
        }
      }
    });
  }

  onClickCancelEdit() {
    if (!this.currentState().editValues) {
      this.setCancelEditNavigation();
      return;
    }

    this.mergeState({
      pendingAction: {
        actionConfirmation: {
          type: "cancelEdit"
        }
      }
    });
  }

  onClickSaveEdits() {
    this.mergeState({
      pendingAction: {
        actionConfirmation: {
          type: "saveEdit"
        }
      }
    });
  }

  onEditAnnouncementProperty(
    propertyKey: keyof Announcement,
    value: Announcement[typeof propertyKey]
  ) {
    if (isEqual(this.currentState().editValues?.[propertyKey], value)) {
      return;
    }
    this.mergeState({
      editValues: {
        [propertyKey]: value
      }
    });
    const changeDifference = this.calculateChangeDifference();
    this.mergeState({
      changeDifference,
      actionsState: {
        saveEnabled: !!Object.keys(changeDifference).length
      }
    });
  }

  onCancelAction() {
    this.mergeState({
      pendingAction: undefined
    });
  }

  onConfirmAction() {
    const currentActionType = this.currentState().pendingAction
      ?.actionConfirmation?.type;
    if (currentActionType === undefined) {
      return;
    }

    const currentAnnouncementId = this.currentState().selectedAnnouncement
      ?.announcement?.id;

    switch (currentActionType) {
      case "delete":
        if (currentAnnouncementId && this.currentUser?.id) {
          this.mergeState({ pendingAction: { isLoading: true } });
          this.announcementsRepository
            .delete(this.currentUser.id, currentAnnouncementId)
            .then(() => {
              this.mergeState({ pendingAction: { isLoading: false } });
              this.nextEffect({ navigation: { clearParams: ["editing"] } });
            });
        }
        break;
      case "cancelEdit":
        this.setCancelEditNavigation();
        break;
      case "saveEdit":
        const editValues = this.currentState().editValues;
        if (currentAnnouncementId && editValues && this.currentUser?.id) {
          this.mergeState({ pendingAction: { isLoading: true } });
          this.announcementsRepository
            .update(this.currentUser?.id, currentAnnouncementId, editValues)
            .then(() => {
              this.mergeState({ pendingAction: { isLoading: false } });
              this.nextEffect({ navigation: { clearParams: ["editing"] } });
            });
        }
        break;
    }
  }

  private setCancelEditNavigation() {
    this.nextEffect({
      navigation: {
        clearParams: ["editing"]
      }
    });
  }

  private calculateChangeDifference(): Partial<
    {
      [Property in keyof Announcement]: {
        previousValue: Announcement[Property];
        currentValue: Announcement[Property];
      };
    }
  > {
    const editValues = this.currentState().editValues;
    const announcement = this.currentState().selectedAnnouncement?.announcement;
    if (!editValues || !announcement) {
      return {};
    }
    return Object.keys(editValues).reduce(
      (changes, property) => {
        const previousValue = announcement[property as keyof Announcement];
        const currentValue = cloneDeep(
          editValues?.[property as keyof Announcement]
        );
        if (!isEqual(previousValue, currentValue)) {
          changes[property as keyof Announcement] = {
            previousValue,
            currentValue
          };
        }
        return changes;
      },
      {} as Record<
        string,
        {
          previousValue: any;
          currentValue: any;
        }
      >
    );
  }
}
