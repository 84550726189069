import { RecursivePartial } from "../utilityTypes";
import { cloneDeep, isFunction, isObject, uniq } from "lodash";

export interface DeepMergeOptions {
  mergeArrays?: boolean;
}

export function deepMerge<T extends object>(
  source: T,
  object: RecursivePartial<T>,
  options?: DeepMergeOptions
): T {
  const mergedKeys = new Set<string>(Object.keys(object));
  return {
    ...Object.keys(source).reduce((result, key) => {
      if (!hasOwnPropertySafe(source, key)) {
        return result;
      }

      const sourceAsIndexable: Record<string, any> = source;
      const resultAsIndexable: Record<string, any> = result;

      if (!object || !hasOwnPropertySafe(object, key)) {
        resultAsIndexable[key] = cloneDeep(sourceAsIndexable[key]);
        return result;
      }
      const objectAsIndexable: Record<string, any> = object;

      if (
        isObject(sourceAsIndexable[key]) &&
        !Array.isArray(sourceAsIndexable[key]) &&
        !isFunction(sourceAsIndexable[key])
      ) {
        if (!sourceAsIndexable[key] || !isObject(sourceAsIndexable[key])) {
          resultAsIndexable[key] = {};
        }
        if (
          objectAsIndexable[key] === undefined &&
          hasOwnPropertySafe(object, key)
        ) {
          resultAsIndexable[key] = undefined;
        } else {
          resultAsIndexable[key] = deepMerge(
            sourceAsIndexable[key],
            objectAsIndexable[key],
            options
          );
        }
      } else if (
        options?.mergeArrays &&
        Array.isArray(sourceAsIndexable[key]) &&
        Array.isArray(objectAsIndexable[key])
      ) {
        resultAsIndexable[key] = uniq(
          sourceAsIndexable[key].concat(objectAsIndexable[key])
        );
      } else {
        resultAsIndexable[key] = objectAsIndexable[key];
      }

      mergedKeys.delete(key);

      return result;
    }, {} as T),
    ...Array.from(mergedKeys).reduce((acc, key) => {
      if (hasOwnPropertySafe(object, key)) {
        acc[key] = (object as Record<string, any>)[key];
      }
      return acc;
    }, {} as Record<string, any>)
  };
}

function hasOwnPropertySafe(object: any, property: string) {
  return Object.prototype.hasOwnProperty.call(object, property);
}
