import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  List,
  ListItem,
  Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContentText from "@material-ui/core/DialogContentText";

export type ListDialogItem = { id: string };

const ListDialog = ({
  isOpen,
  handleClose,
  handleConfirm,
  listData,
  isProcessingAction,
  actionError,
  title,
  description,
  sort,
  renderItem
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: (id: string) => void;
  listData: () => Promise<ListDialogItem[]> | undefined;
  isProcessingAction: boolean;
  actionError?: Error;
  title: string;
  description: string;
  sort?: (a: any, b: any) => number;
  renderItem: (data: ListDialogItem) => JSX.Element;
}) => {
  const [listedData, setListedData] = useState<any[] | undefined>();
  const [listError, setListError] = useState<Error | undefined>();
  const [selectedItem, setSelectedItem] = useState<string | undefined>();
  useEffect(() => {
    if (isOpen) {
      (async () => {
        try {
          setListedData(await listData());
        } catch (e: any) {
          setListError(e);
        }
      })();
    }
  }, [isOpen, listData]);
  useEffect(() => {
    if (!isOpen) {
      setListedData(undefined);
    }
  }, [isOpen]);

  const onConfirm = React.useCallback(() => {
    if (selectedItem) {
      handleConfirm(selectedItem);
    } else {
      handleClose();
    }
  }, [handleClose, handleConfirm, selectedItem]);

  return (
    <Dialog open={isOpen}>
      {isProcessingAction && <LinearProgress variant="query" />}
      {(actionError || listError) && (
        <Typography>
          {`An error occurred: ${(actionError ?? listError)?.message}`}
        </Typography>
      )}
      <DialogTitle>
        <Typography>{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        {listedData ? (
          <List dense>
            {(listedData ?? []).sort(sort).map(data => (
              <ListItem
                button
                selected={selectedItem === data.id}
                onClick={() => setSelectedItem(data.id)}
              >
                {renderItem(data)}
              </ListItem>
            ))}
          </List>
        ) : (
          <CircularProgress />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onConfirm} autoFocus>
          <Typography>Load</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ListDialog);
