import React from "react";
import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import { useViewModel } from "../../../viewmodel/useViewModel";
import { ViewModelKeys } from "../../../viewmodel/ViewModelFactoryMap";
import { Announcement } from "../../../../domain/announcements/entities/announcement";
import Box from "@material-ui/core/Box";
import { AnnouncementDetailsActions } from "./AnnouncementDetailsActions";
import { AnnouncementsViewModel } from "../AnnouncementsViewModel";
import { announcementRenderByProperty } from "./announcement-render-by-property";
import { AnnouncementTitle } from "./AnnouncementTitle";

export const AnnouncementDetails = ({
  announcementId
}: {
  announcementId: string;
}) => {
  const [viewState, viewModel] = useViewModel(
    ViewModelKeys.ANNOUNCEMENTS_VIEW_MODEL
  );
  if (!viewState.selectedAnnouncement?.announcement) {
    return <></>;
  }

  const announcement = viewState.selectedAnnouncement.announcement;
  const isEditing =
    announcement.id === announcementId &&
    viewState.selectedAnnouncement.editing;

  return (
    <Paper style={{ padding: 8 }}>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <AnnouncementTitle />
        <AnnouncementDetailsActions
          announcementId={announcementId}
          isEditing={!!isEditing}
        />
      </Box>
      <Divider style={{ marginTop: 4, marginBottom: 4 }} />
      <Grid container direction={"column"} spacing={1}>
        {Object.keys(announcement)
          .map(
            announcementProperty =>
              [
                announcementProperty as keyof Announcement,
                announcementRenderByProperty[
                  announcementProperty as keyof Announcement
                ]
              ] as const
          )
          .filter(
            ([property, formInfo]) =>
              formInfo &&
              announcementRenderByProperty[property]?.renderDetails !== false
          )
          .map(([announcementProperty, formInfo]) => (
            <Grid
              item
              container
              direction={"row"}
              spacing={1}
              alignItems={"center"}
            >
              <Grid item xs={2}>
                <Typography>{`${formInfo?.label}:`}</Typography>
              </Grid>
              <Grid item xs={10}>
                {renderAnnouncementField(
                  viewModel,
                  announcement,
                  viewState.editValues,
                  announcementProperty,
                  !!isEditing
                )}
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Paper>
  );
};

function renderAnnouncementField(
  viewModel: AnnouncementsViewModel,
  announcement: Announcement,
  editValues: Partial<Announcement> | undefined,
  property: keyof Announcement,
  isEditing: boolean
) {
  const renderFunction =
    isEditing && announcementRenderByProperty[property]?.renderDynamic
      ? announcementRenderByProperty[property]?.renderDynamic
      : announcementRenderByProperty[property]?.renderStatic;

  return (
    (renderFunction as
      | ((value: any, viewModel: AnnouncementsViewModel) => JSX.Element)
      | undefined)?.(
      editValues?.[property] ?? announcement[property],
      viewModel
    ) ?? <></>
  );
}
