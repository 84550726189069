import { useRepositories } from "../hooks/useRepositories";
import useLCE from "../hooks/useLCE";
import { Permission } from "../api/permissions/Permission";
import { LCEWrapper } from "../components/lce/LCEWrapper";
import React, { useCallback } from "react";

export const RenderWithPermission = ({
  requiredPermissions,
  renderWhenPermitted
}: {
  requiredPermissions: Permission[];
  renderWhenPermitted: () => JSX.Element;
}) => {
  const { permissions } = useRepositories();
  const permissionLCE = useLCE(() => permissions.getScreenPermissions());

  const renderContent = useCallback(() => renderWhenPermitted(), [
    renderWhenPermitted
  ]);
  return <LCEWrapper lce={permissionLCE} renderContent={renderContent} />;
};
