import React from "react";
import { Divider } from "@material-ui/core";
import useStyles from "../styles";
import Box from "@material-ui/core/Box";
import { FileManagerViewModelBase } from "../FileManagerViewModelBase";
import { FileBrowserBreadcrumbs } from "./FileBrowserBreadcrumbs";
import { FileBrowserToolbarNavigationControls } from "./FileBrowserToolbarNavigationControls";
import { FileBrowserToolbarActions } from "./FileBrowserToolbarActions";
import { LocalViewModelFactory } from "../../../architecture/view-model/LocalViewModelFactory";

export interface FileBrowserToolbarProps {
  viewModelFactory: LocalViewModelFactory<FileManagerViewModelBase>;
}

export const FileBrowserToolbar = (props: FileBrowserToolbarProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.toolBar}>
      <Box className={classes.toolbarNavigationControls}>
        <FileBrowserToolbarNavigationControls
          viewModelFactory={props.viewModelFactory}
        />
      </Box>
      <Divider
        orientation={"vertical"}
        className={classes.toolbarDivider}
        variant={"middle"}
      />
      <Box className={classes.toolbarBreadcrumbs}>
        <FileBrowserBreadcrumbs viewModelFactory={props.viewModelFactory} />
      </Box>
      <Box className={classes.toolbarActions}>
        <FileBrowserToolbarActions viewModelFactory={props.viewModelFactory} />
      </Box>
    </Box>
  );
};
