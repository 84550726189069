import { toArray } from "../../../utils/maps";
import { FormFieldType } from "./fields/FormFieldType";
import LinkFormFieldType from "./fields/link/LinkFormFieldType";
import React from "react";
import { DialogContent } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import TextFormField from "./fields/text/TextFormField";
import LinkFormField from "./fields/link/LinkFormField";
import { Locale } from "../../../locale";
import FormField from "./fields/FormField";
import CurrentFormInfo from "./CurrentFormInfo";
import { Pair } from "../../../utils/tuples/Pair";
import MapFormField from "./fields/map/MapFormField";

export const FormDialogContent = ({
  description,
  localeToFields,
  updateField,
  currentFormInfo,
  invalidFields,
  linkField,
  handleNewResourceRequest,
  defaultLocale
}: {
  description: string;
  localeToFields: Map<Locale, FormField[]>;
  updateField: (locale: Locale, id: string, value: string[]) => void;
  currentFormInfo: CurrentFormInfo;
  invalidFields: Map<Locale, Map<string, string | undefined>>;
  linkField: (
    locale: Locale,
    resource: string,
    fieldsToValues: Pair<string, string>[]
  ) => void;
  handleNewResourceRequest?: (resource: string) => void;
  defaultLocale: Locale;
}) => {
  return (
    <DialogContent>
      <DialogContentText>{description}</DialogContentText>
      <Grid container spacing={2} wrap="nowrap">
        {toArray(localeToFields, (fields, locale, index) => {
          const fieldLocale = localeToFields.size > 1 ? locale : undefined;
          return (
            <Grid item container direction="column" spacing={2} key={index}>
              {fields
                .map(field => {
                  switch (field.type) {
                    case FormFieldType.TEXT:
                      return (
                        <TextFormField
                          field={field}
                          onChange={(id, value) =>
                            updateField(locale, id, [value])
                          }
                          currentFields={
                            currentFormInfo[locale]?.updatedFields || {}
                          }
                          locale={fieldLocale}
                          errorMessage={invalidFields
                            .get(locale)
                            ?.get(field.id)}
                          onCurrentValueResolved={value =>
                            updateField(locale, field.id, [value])
                          }
                        />
                      );
                    case FormFieldType.LINK:
                      return (
                        <LinkFormField
                          field={field as LinkFormFieldType<any>}
                          onLinkChange={(resource, fieldsToValues) =>
                            linkField(locale, resource, fieldsToValues)
                          }
                          currentLinks={
                            currentFormInfo[locale]?.updatedLinks?.[field.id]
                          }
                          createNew={handleNewResourceRequest}
                          locale={fieldLocale}
                          errorMessage={invalidFields
                            .get(locale)
                            ?.get(field.id)}
                          onCurrentValueResolved={fieldsToValues => {
                            linkField(locale, field.id, fieldsToValues);
                          }}
                          disabled={locale !== defaultLocale}
                        />
                      );
                    case FormFieldType.TEXT_MAP:
                      return (
                        <MapFormField
                          dataFactory={field.formFieldOptions.dataFactory}
                          renderKey={field.formFieldOptions.renderKey}
                          currentFields={
                            currentFormInfo[locale]?.updatedFields || {}
                          }
                          currentLinksById={
                            currentFormInfo[locale]?.updatedLinks
                          }
                          field={field}
                          onValuesChanged={updateField}
                          locale={fieldLocale ?? defaultLocale}
                          onCurrentValueResolved={values => {
                            updateField(locale, field.id, values);
                          }}
                        />
                      );
                  }
                })
                .map(element => (
                  <Grid item>{element}</Grid>
                ))}
            </Grid>
          );
        })}
      </Grid>
    </DialogContent>
  );
};
