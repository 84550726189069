import Dialog from "@material-ui/core/Dialog";
import React, { useCallback, useEffect, useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import { Typography } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Moment } from "moment-timezone";
import DialogContent from "@material-ui/core/DialogContent";
import { EditEventForm } from "./EditEventForm";
import { CreatableEventType } from "../CreatableEventType";
import { CreateEventEntry } from "../CreatableEventEntry";
import LinearProgress from "@material-ui/core/LinearProgress";
import { EventFormData } from "./EventFormData";

export const EditEventDialog = ({
  open,
  onClose,
  onSave,
  initialFormData,
  creatableEventTypes,
  listCreatableEventEntries,
  momentProvider
}: {
  open: boolean;
  onClose: () => void;
  onSave: (formData: EventFormData) => Promise<void>;
  initialFormData: EventFormData;
  creatableEventTypes: CreatableEventType[];
  listCreatableEventEntries: (
    eventType: CreatableEventType
  ) => Promise<CreateEventEntry[]>;
  momentProvider: () => Moment;
}) => {
  const [currentForm, setForm] = useState<EventFormData>(initialFormData);
  const setSelectedDates = useCallback((selectedDates: Moment[]) => {
    setForm(form => ({
      ...form,
      selectedDates
    }));
  }, []);
  useEffect(() => {
    if (!open) {
      setSelectedDates([
        (initialFormData.selectedDates[0]?.clone() ?? momentProvider()).startOf(
          "day"
        ),
        (initialFormData.selectedDates[1]?.clone() ?? momentProvider()).endOf(
          "day"
        )
      ]);
    }
  }, [initialFormData, initialFormData.selectedDates, open, setSelectedDates]);

  const onSelectedCreatableEvent = useCallback(
    (
      eventType: CreatableEventType | undefined,
      eventEntry: CreateEventEntry | null
    ) => {
      setForm(form => ({
        ...form,
        eventCandidate: { eventType, eventEntry }
      }));
    },
    []
  );

  const onEventTitleChanged = useCallback((newTitle: string) => {
    setForm(form => ({
      ...form,
      title: newTitle
    }));
  }, []);
  const onColorChanged = useCallback((newColor: string) => {
    setForm(form => ({
      ...form,
      color: newColor
    }));
  }, []);

  const [isSaving, setSaving] = useState(false);
  const onSaveClicked = useCallback(async () => {
    const { eventEntry, eventType } = currentForm.eventCandidate ?? {};
    if (!eventEntry || !eventType) {
      throw new Error("invalid event");
    }
    setSaving(true);
    try {
      await onSave(currentForm as EventFormData);
    } catch (e: any) {
      console.error(e);
    } finally {
      setSaving(false);
    }
  }, [currentForm, onSave]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      {isSaving ? <LinearProgress /> : <div />}
      <DialogTitle>
        <Typography variant="h5">
          {currentForm.eventId ? "Edit Event" : "Create New Event"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <EditEventForm
          currentForm={currentForm}
          onDatesUpdated={setSelectedDates}
          creatableEventTypes={creatableEventTypes}
          listCreatableEventEntries={listCreatableEventEntries}
          onSelectedCreatableEvent={onSelectedCreatableEvent}
          onEventTitleChanged={onEventTitleChanged}
          onColorChanged={onColorChanged}
          momentProvider={momentProvider}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <Typography>Cancel</Typography>
        </Button>
        <Button
          onClick={onSaveClicked}
          variant="contained"
          color="primary"
          disabled={
            !currentForm?.eventCandidate?.eventType ||
            !currentForm?.eventCandidate?.eventEntry
          }
        >
          <Typography>Save</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
