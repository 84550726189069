import { CommitResource } from "../../../../api/commits/Commit";
import React, { useCallback, useState } from "react";
import { BasicDialog } from "../../../../presentation/dialog/BasicDialog";
import { ExportFileFormat } from "./ExportFileFormat";
import { ExportFileFormatSelection } from "./ExportFileFormatSelection";
import { ExportFileNameSelection } from "./ExportFileNameSelection";
import { ExportedFieldsSelection } from "./ExportedFieldsSelection";
import { ExportDialogActions } from "./ExportDialogActions";

export interface ExportContentManagementDialogProps {
  open: boolean;
  resource: CommitResource;
  onCloseClick: () => void;
}

export const ExportContentManagementDialog = (
  props: ExportContentManagementDialogProps
) => {
  const [selectedFormat, setSelectedFormat] = useState<ExportFileFormat>(
    ExportFileFormat.CSV
  );
  const [selectedContentFields, setSelectedContentFields] = useState<string[]>(
    []
  );
  const [fileName, setFileName] = useState(props.resource.toString());
  const onSelectedFileFormatChange = useCallback(
    (updatedFileFormat: ExportFileFormat) => {
      setSelectedFormat(updatedFileFormat);
    },
    []
  );
  const onFileNameChange = useCallback((updatedFileName: string) => {
    setFileName(updatedFileName);
  }, []);
  const onFieldsSelectionChange = useCallback((selectedFields: string[]) => {
    setSelectedContentFields(selectedFields);
  }, []);
  const renderContent = useCallback(() => {
    return (
      <>
        <ExportFileFormatSelection
          selectedFormat={selectedFormat}
          onSelectionChange={onSelectedFileFormatChange}
        />
        <ExportFileNameSelection
          placeholder={props.resource.toString()}
          onFileNameChange={onFileNameChange}
        />
        <ExportedFieldsSelection
          resource={props.resource}
          onFieldsSelectionChange={onFieldsSelectionChange}
        />
      </>
    );
  }, [
    onFieldsSelectionChange,
    onFileNameChange,
    onSelectedFileFormatChange,
    props.resource,
    selectedFormat
  ]);
  const renderActions = useCallback(() => {
    return (
      <ExportDialogActions
        resource={props.resource}
        selectedContentFields={selectedContentFields}
        fileName={fileName}
        selectedFormat={selectedFormat}
      />
    );
  }, [fileName, props.resource, selectedContentFields, selectedFormat]);
  return (
    <BasicDialog
      open={props.open}
      title={`Export ${props.resource}`}
      onCloseClick={props.onCloseClick}
      renderContent={renderContent}
      renderActions={renderActions}
    />
  );
};
