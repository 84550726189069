import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { QuoteTextPart } from "../../pages/quotesplorer/ScrappedContent";
import { useStyles } from "./styles";

export const ScrapedQuoteParts = ({
  textParts
}: {
  textParts: QuoteTextPart[];
}) => {
  const classes = useStyles();
  return (
    <div className={classes.scrapedQuotePartsContainer}>
      {textParts.reduce((acc, part) => {
        if (typeof part === "object") {
          acc.push(
            <Link target={(part as any).target} href={(part as any).link}>
              {(part as any).label}
            </Link>
          );
        } else {
          acc.push(<Typography>{part}&nbsp;</Typography>);
        }
        return acc;
      }, [] as any[])}
    </div>
  );
};
