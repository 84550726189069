import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles(theme => ({
  listContainer: {
    backgroundColor: theme.drawer?.backgroundColor,
    height: "100%"
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  selectedItemColor: {
    color: theme.drawer?.selectedItemColor
  }
}));
