import { useContext } from "react";
import Repositories, { RepositoriesContext } from "../components/context/repository";
import LocaleContext from "../components/context/locale";

export const useRepositories = (): Repositories => {
  const repositories = useContext(RepositoriesContext)!;
  const locale = useContext(LocaleContext)?.localeObservable?.value;
  if (locale) {
    repositories.locale = locale;
  }
  return repositories;
};