import { CommitResource } from "../../../api/commits/Commit";
import { Resources } from "../../../data/Resources";
import { QuoteFields } from "../../../api/content/Quote";
import { QuoteContentFields } from "../../../data/ManagedQuote";
import { AuthorFields } from "../../../api/content/Author";
import { CategoryFields } from "../../../api/content/Category";
import { MediaPlatformFields } from "../../../api/content/MediaPlatform";
import { MediaFeedFields } from "../../../api/content/MedaFeedContent";
import { MediaFeedContentFields } from "../../../data/ManagedMediaFeedContent";
import { WeeklyTipFields } from "../../../api/content/WeeklyTip";

export function getContentHeadersForResource(
  resource: CommitResource
): {
  [field: string]: {
    title: string;
    isImage?: boolean;
    isUrl?: boolean;
  };
} {
  switch (resource) {
    case Resources.QUOTES:
      return {
        [QuoteFields.TEXT]: { title: "Text" },
        [QuoteContentFields.AUTHOR]: { title: "Author" },
        [QuoteContentFields.CATEGORY]: { title: "Categories" }
      };
    case Resources.AUTHORS:
      return {
        [AuthorFields.IMAGE_URL]: { title: "Image", isImage: true },
        [AuthorFields.NAME]: { title: "Name" },
        [AuthorFields.DESCRIPTION]: { title: "Description" },
        [AuthorFields.CONTEXT]: { title: "Context" },
        [AuthorFields.ARTICLE_URL]: { title: "Article", isUrl: true },
        [AuthorFields.LICENSE]: { title: "License" }
      };
    case Resources.CATEGORIES:
      return {
        [CategoryFields.NAME]: { title: "Name" },
        [CategoryFields.IMAGE_URL]: { title: "Image", isImage: true }
      };
    case Resources.MEDIA_PLATFORM:
      return {
        [MediaPlatformFields.ICON]: { title: "Image", isImage: true },
        [MediaPlatformFields.NAME]: { title: "Name" }
      };
    case Resources.PODCASTS:
    // FALLTHROUGH
    case Resources.VIDEOS:
    // FALLTHROUGH
    case Resources.BOOKS:
      return mediaFeedPlatformHeaders();
    case Resources.WEEKLY_TIP: {
      return {
        [WeeklyTipFields.TOPIC]: { title: "Topic" }
      };
    }
  }
}

function mediaFeedPlatformHeaders() {
  return {
    [MediaFeedFields.NAME]: { title: "Name" },
    [MediaFeedFields.DESCRIPTION]: { title: "Description" },
    [MediaFeedFields.IMAGE]: { title: "Image", isImage: true },
    [MediaFeedContentFields.AUTHOR]: { title: "Author" },
    [MediaFeedContentFields.PLATFORM]: { title: "Platforms" },
    [MediaFeedContentFields.CATEGORY]: { title: "Categories" }
  };
}
