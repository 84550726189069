import { forOwn, isFunction, isObject, isUndefined } from "lodash";

export function enumerateDeep<T extends object>(
  object: T,
  iterator: (value: T[keyof T], key: string) => boolean
) {
  enumerateDeepRecursive("", object, iterator);
}

function enumerateDeepRecursive<T extends object>(
  parentKey: string,
  object: T,
  iterator: (value: T[keyof T], key: string) => boolean
) {
  for (const key in object) {
    const value = object[key as keyof T];
    const proceed = iterator(value, key);

    if (!proceed) {
      break;
    }

    if (
      isUndefined(value) ||
      !isObject(value) ||
      isFunction(value) ||
      Array.isArray(value)
    ) {
      continue;
    }

    enumerateDeepRecursive(key + ".", value as any, iterator);
  }
}
