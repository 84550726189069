import React, { PropsWithChildren, useCallback, useState } from "react";
import { useStyles } from "../styles";
import { Box } from "@material-ui/core";
import { Motion, spring } from "react-motion";
import { SLIDE_BORDER_RADIUS } from "../weekly-tip-constants";
import { SlideControls } from "./SlideControls";
import { SlideControl } from "./SlideControl";

interface WeeklyTipSlideContainerSelfProps {
  dashed?: boolean;
  enabledControls?: SlideControl[];
  onControlClicked?(control: SlideControl): void;
}

export const WeeklyTipSlideContainer = ({
  dashed = false,
  onControlClicked,
  enabledControls = [],
  children
}: PropsWithChildren<WeeklyTipSlideContainerSelfProps>) => {
  const classes = useStyles();

  const [isHovering, serIsHovering] = useState(false);
  const onMouseEnter = useCallback(() => serIsHovering(true), []);
  const onMouseLeave = useCallback(() => serIsHovering(false), []);

  return (
    <Motion
      defaultStyle={{ opacity: 0 }}
      style={{
        opacity: spring(1, {
          stiffness: 160,
          damping: 40
        })
      }}
    >
      {interpolatedStyle => (
        <Box
          border={1}
          borderRadius={SLIDE_BORDER_RADIUS}
          borderColor={"#fff"}
          className={classes.slideContainer}
          style={{
            borderStyle: dashed ? "dashed" : "solid",
            opacity: interpolatedStyle.opacity
          }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {children}
          {isHovering && enabledControls?.length ? (
            <Motion
              defaultStyle={{ top: -20, opacity: 0 }}
              style={{
                top: spring(0),
                opacity: spring(1)
              }}
            >
              {controlInterpolatedStyle => (
                <div
                  className={classes.controlStyle}
                  style={controlInterpolatedStyle}
                >
                  <div className={classes.slideControlsPadding}>
                    <SlideControls
                      enabledControls={enabledControls}
                      onControlClicked={onControlClicked}
                    />
                  </div>
                </div>
              )}
            </Motion>
          ) : (
            <div />
          )}
        </Box>
      )}
    </Motion>
  );
};
