import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  showImage: {
    margin: "auto",
    borderRadius: 8,
    width: theme.spacing(11),
    height: theme.spacing(11),
  },
  platformAvatar: {
    margin: "auto",
  }
}));
