import { Grid, Typography } from "@material-ui/core";
import { Check, Info, Warning } from "@material-ui/icons";
import React, { useMemo } from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import Button from "@material-ui/core/Button";

export interface InformationBannerProps {
  flavor?: "info" | "error" | "success";
  message: string | undefined;
  cta?: string;
  onClickCTA?: () => void;
  style?: React.CSSProperties;
}

const ICON_XS = 1 as const;
const CTA_XS = 3 as const;

export const InformationBanner = (props: InformationBannerProps) => {
  const theme = useTheme();
  const style = useMemo(
    () => ({
      backgroundColor: theme.info?.flavors?.[props.flavor ?? "info"],
      padding: "1%",
      width: "100%",
      borderRadius: 8
    }),
    [props.flavor, theme.info]
  );
  const cta = useMemo(() => {
    if (!props.cta) {
      return <></>;
    }
    return (
      <Grid item xs={CTA_XS}>
        <Button
          color={"default"}
                variant={"outlined"}
          onClick={props.onClickCTA}
        >
          {props.cta}
        </Button>
      </Grid>
    );
  }, [props.cta]);
  return (
    <Grid
      container
      direction={"row"}
      alignItems={"center"}
      style={{
        ...style,
        ...props.style
      }}
    >
      <Grid item xs={ICON_XS}>
        {flavorToIcon(props.flavor)}
      </Grid>
      <Grid item xs={(12 - ICON_XS - (props.cta ? CTA_XS : 0)) as any}>
        <Typography>{props.message}</Typography>
      </Grid>
      {cta}
    </Grid>
  );
};

function flavorToIcon(flavor: InformationBannerProps["flavor"]): JSX.Element {
  switch (flavor) {
    case "success":
      return <Check />;
    case "error":
      return <Warning />;
    default:
      return <Info />;
  }
}
