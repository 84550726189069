import { Checkbox, Typography } from "@material-ui/core";
import React, { PropsWithChildren, useMemo } from "react";
import { CheckboxProps } from "@material-ui/core/Checkbox/Checkbox";
import { omit } from "lodash";

type CheckboxRowProps = PropsWithChildren<CheckboxProps> & {
  checkboxPosition?: "left" | "right";
};

export const CheckboxRow = (props: CheckboxRowProps) => {
  const items = useMemo(() => {
    const children = <Typography>{props.children}</Typography>;
    const checkbox = (
      <Checkbox {...omit(props, "children", "checkboxPosition")} />
    );
    if (props.checkboxPosition === "left") {
      return [checkbox, children];
    } else {
      return [children, checkbox];
    }
  }, [props]);
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between"
      }}
    >
      {items}
    </div>
  );
};
