import { CommitType } from "./CommitType";
import { Locale } from "../../locale";
import { Resources } from "../../data/Resources";

export interface Commit {
  locale: Locale;
  commitId: string;
  ownerId: string;
  type: CommitType;
  resourceId?: string;
  updatedFields: {
    [fieldName: string]: {
      value: any;
      ownerId?: string;
      updateTime: number;
      locales: Locale[];
    }[];
  };
  linkedResources: {
    [resource: string]: {
      resourceId?: string;
      commitId?: string;
      ownerId?: string;
      updateTime: number;
    }[];
  };
  updateTime: number;
}

export type CommitResource = Exclude<
  Resources,
  Resources.USERS
>;

const nonCommitResources = [Resources.USERS];
export function commitResources() {
  return Object.values(Resources).filter(
    resource => !nonCommitResources.includes(resource)
  ) as CommitResource[];
}
