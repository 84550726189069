import ManagedResource from "./ManagedResource";
import MedaFeedContent from "../api/content/MedaFeedContent";

export enum MediaFeedContentFields {
  PLATFORM = "platform",
  AUTHOR = "author",
  CATEGORY = "category"
}

export default interface ManagedMediaFeedContent
  extends ManagedResource<
    MedaFeedContent & {
      [MediaFeedContentFields.AUTHOR]?: string[];
      [MediaFeedContentFields.PLATFORM]?: string[];
      [MediaFeedContentFields.CATEGORY]?: string[];
    }
  > {}
