import * as React from "react";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useStyles } from "../styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

const PublishActionsPanel = ({
  actions
}: {
  actions: {
    description: string;
    renderIcon: () => JSX.Element;
    onClick: () => any;
    isLoading?: boolean;
    disable?: boolean;
  }[];
}) => {
  const classes = useStyles();
  return (
    <Grid container alignItems="flex-start" direction="column">
      {actions.map(panelAction => (
        <Grid item className={classes.actionPanelItem} spacing={2}>
          <Box className={classes.actionRowContainer}>
            <Button
              disabled={panelAction.disable || !!panelAction.isLoading}
              onClick={panelAction.onClick}
              className={classes.actionPanelItem}
              startIcon={panelAction.renderIcon()}
            >
              <Typography>{panelAction.description}</Typography>
            </Button>
            {panelAction.isLoading && (
              <Grid item>
                <CircularProgress size={20} />
              </Grid>
            )}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default PublishActionsPanel;
