import {
  FileManagerAction,
  FileManagerFileListItem,
  FileManagerViewModelBase,
  ViewMode
} from "./FileManagerViewModelBase";
import { FileManagementRepository } from "../../domain/file-management/data/FileManagementRepository";

export interface FileManagerViewEffects {
  type: "selected_view_mode";
  viewMode: ViewMode;
}

export class FileManagerViewModel extends FileManagerViewModelBase<
  FileManagerViewEffects
> {
  constructor(
    rootPath: string,
    fileManagementRepository: FileManagementRepository
  ) {
    super(
      {
        rootPath,
        toolbar: {
          actionGroups: [
            [
              {
                type: "upload",
                icon: "CloudUpload",
                description: "Upload"
              },
              {
                type: "new_folder",
                icon: "CreateNewFolder",
                description: "New Folder"
              }
            ],
            [
              {
                type: "view_mode_list",
                icon: "List",
                description: "Show as List"
              },
              {
                type: "view_mode_grid",
                icon: "GridOn",
                description: "Show as Grid"
              }
            ],
            [
              { type: "sort", icon: "Sort", description: "Sort" },
              { type: "search", icon: "Search", description: "Search" }
            ]
          ]
        }
      },
      fileManagementRepository
    );
  }

  onActionClick(action: FileManagerAction) {
    switch (action.type) {
      case "upload":
        this.initiateUpload();
        break;
      case "new_folder":
        this.mergeState({
          pendingAction: {
            type: "new_folder"
          }
        });
        break;
      case "view_mode_list":
        this.nextEffect({
          type: "selected_view_mode",
          viewMode: ViewMode.LIST
        });
        break;
      case "view_mode_grid":
        this.nextEffect({
          type: "selected_view_mode",
          viewMode: ViewMode.GRID
        });
        break;
    }
  }

  onClickDeleteFile(file: FileManagerFileListItem) {
    this.mergeState({
      pendingAction: {
        type: "delete_file",
        payload: file
      }
    });
  }

  onClickRenameFile(file: FileManagerFileListItem) {
    this.mergeState({
      pendingAction: {
        type: "rename_file",
        payload: file
      }
    });
  }
}
