import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import HeaderHOC from "./components/header/HeaderHOC";
import { AuthContext } from "./components/context/auth";
import User from "./api/auth/User";
import AppRoutes from "./components/routes/AppRoutes";
import { BrowserRouter as Router } from "react-router-dom";
import { CssBaseline, Grid, MuiThemeProvider } from "@material-ui/core";
import themes from "./styles/theme";
import { RepositoriesContext } from "./components/context/repository";
import useCookies from "react-cookie/lib/useCookies";
import { Cookies } from "./cookies";
import Defaults from "./defaults";
import LocaleContext from "./components/context/locale";
import StaticDrawer, {
  NavigationChildrenStateMap
} from "./components/drawer/StaticDrawer";
import { useNavigation } from "./hooks/useNavigation";
import CurrentUserContext from "./context/CurrentUserContext";

const App = () => {
  const authApi = useContext(AuthContext)!;
  const userRepository = useContext(RepositoriesContext)!.user;

  const [currentUserState, setUserState] = useState<{
    user: User | null;
    isAuthorized?: boolean;
  }>({ user: null });

  useEffect(
    () =>
      authApi.authUserChanges((user: User) => {
        setUserState({
          isAuthorized: !!user,
          user
        });
        (async () => {
          if (user) {
            await userRepository.updateUserInfo(user);
          }
        })();
      }),
    [authApi, userRepository]
  );

  const [currentPath, setPath] = useState<string | undefined>();

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const onDrawerToggle = React.useCallback(
    () => setDrawerState(!isDrawerOpen),
    [isDrawerOpen]
  );
  const onDrawerClosed = React.useCallback(() => setDrawerState(false), []);
  const setDrawerState = (isOpen: boolean) => setDrawerOpen(isOpen);

  const [cookies, setCookie] = useCookies<Cookies>([Cookies.BRIGHTNESS, Cookies.LOCALE]);
  const [isDarkTheme, setDarkTheme] = useState(
    cookies[Cookies.BRIGHTNESS]?.isDarkTheme || Defaults.isDarkTheme
  );

  const onBrightnessToggle = () => {
    setCookie(Cookies.BRIGHTNESS, { isDarkTheme: !isDarkTheme });
    setDarkTheme(!isDarkTheme);
  };

  const { localeObservable } = useContext(LocaleContext)!;
  const [locale, setLocale] = useState(
    cookies[Cookies.LOCALE] || Defaults.locale
  );
  const onLocaleSelected = (code: string) => {
    setLocale(code);
    setCookie(Cookies.LOCALE, code);
  };
  localeObservable.next(locale);

  const onDestinationSelected = React.useCallback(
    (pathName: string) => {
      const validatedPathName =
        "/" + (pathName.endsWith("/") ? pathName : `${pathName}/`);
      setPath(validatedPathName);
      onDrawerClosed();
    },
    [currentPath, onDrawerClosed]
  );
  const [childrenStates, setChildrenStates] = useState<
    NavigationChildrenStateMap | undefined
  >(cookies[Cookies.NAVIGATION]);
  const onChildrenStatesChanged = React.useCallback(
    (stateMap: { [child: string]: boolean }) => {
      setCookie(Cookies.NAVIGATION, stateMap);
      setChildrenStates(stateMap);
    },
    [setCookie]
  );

  const navigation = useNavigation();
  const [destinationsRoot, setDestinationsRoot] = useState<
    string | undefined
  >();
  const onCurrentPathChanged = React.useCallback((pathName: string) => {
    setPath(pathName);
    const root = pathName.split("/").filter(path => path)[0];
    setDestinationsRoot(root);
  }, []);
  return (
    <CurrentUserContext.Provider value={currentUserState.user}>
      <div
        style={{
          height: "100vh",
          width: "100vw"
        }}
      >
        <MuiThemeProvider theme={isDarkTheme ? themes.dark : themes.bright}>
          <CssBaseline />
          <Grid
            container
            direction="column"
            style={{ height: "100%" }}
            wrap="nowrap"
          >
            {currentUserState.user && (
              <Grid item>
                <HeaderHOC
                  onDrawerToggle={onDrawerToggle}
                  currentUser={currentUserState.user}
                  authApi={authApi}
                  isAuthorized={currentUserState.isAuthorized}
                  onBrightnessToggle={onBrightnessToggle}
                  isDarkTheme={isDarkTheme}
                  onLocaleSelected={onLocaleSelected}
                  locale={locale}
                />
              </Grid>
            )}
            <Grid
              item
              container
              direction="row"
              style={{ height: "100%", overflowY: "hidden" }}
              wrap={"nowrap"}
            >
              {currentUserState.isAuthorized && (
                <Grid item>
                  {navigation.destinations(destinationsRoot).length ? (
                    <StaticDrawer
                      onDestinationSelected={onDestinationSelected}
                      currentPath={currentPath}
                      controlledChildrenStates={childrenStates}
                      onChildrenStatesChanged={onChildrenStatesChanged}
                      destinations={navigation.destinations(destinationsRoot)}
                    />
                  ) : null}
                </Grid>
              )}
              <Grid item xs style={{ height: "100%" }}>
                <Router>
                  <AppRoutes
                    isAuthorized={currentUserState.isAuthorized}
                    authApi={authApi}
                    onCurrentPathChanged={onCurrentPathChanged}
                    currentPath={currentPath}
                  />
                </Router>
              </Grid>
            </Grid>
          </Grid>
        </MuiThemeProvider>
      </div>
    </CurrentUserContext.Provider>
  );
};
export default App;
