import React, { PropsWithChildren } from "react";
import { useRepositories } from "../../hooks/useRepositories";
import { LazySupplier } from "../../utils/supplier/LazySupplier";
import { ContentDiscrepanciesViewModel } from "../developer-console/diagnostics/viewmodel/ContentDiscrepanciesViewModel";
import * as architecture from "../../architecture/view-model/ViewModelFactory";
import { ViewModelFactoryMap, ViewModelKeys } from "./ViewModelFactoryMap";
import { ViewModelFactoryContext } from "../../architecture/view-model/ViewModelFactoryContext";
import { AnnouncementsViewModel } from "../community/announcements/AnnouncementsViewModel";
import { useCurrentUser } from "../../context/CurrentUserContext";

export const ViewModelFactory = ({ children }: PropsWithChildren<any>) => {
  const repositories = useRepositories();
  const contentDiscrepanciesViewModelSupplier = new LazySupplier(
    () =>
      new ContentDiscrepanciesViewModel(
        repositories.developerConsole,
        repositories.content
      )
  );
  const currentUser = useCurrentUser();
  const announcementsViewModelSupplier = new LazySupplier(
    () => new AnnouncementsViewModel(currentUser, repositories.announcements)
  );
  return (
    <ViewModelFactoryContext.Provider
      value={
        new architecture.ViewModelFactory<ViewModelFactoryMap>({
          [ViewModelKeys.CONTENT_DISCREPANCIES_VIEW_MODEL]: () =>
            contentDiscrepanciesViewModelSupplier.get(),
          [ViewModelKeys.ANNOUNCEMENTS_VIEW_MODEL]: () =>
            announcementsViewModelSupplier.get()
        })
      }
    >
      {children}
    </ViewModelFactoryContext.Provider>
  );
};
