import { Grid } from "@material-ui/core";
import React from "react";
import moment from "moment";
import Typography from "@material-ui/core/Typography";

export const DaysOfTheWeek = () => {
  return (
    <Grid container justify="space-between">
      {moment.weekdaysShort().map(weekDay => (
        <Grid item xs>
          <Typography variant="h6" align="right" style={{marginRight: '0.25vw'}}>
            {weekDay}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
