import { Avatar } from "@material-ui/core";
import React from "react";
import { useDownloadURLFromPath } from "../../../hooks/useDownloadURLFromPath";
import { LCEWrapper } from "../../lce/LCEWrapper";
import { SLIDE_BORDER_RADIUS, SLIDE_SIZE } from "../weekly-tip-constants";

export const WeeklyTipSlideImageComponent = ({
  imagePath
}: {
  imagePath: string;
}) => {
  const imageUrlLCE = useDownloadURLFromPath(imagePath);
  return (
    <LCEWrapper
      lce={imageUrlLCE}
      renderContent={url => (
        <Avatar
          src={url}
          style={{
            width: SLIDE_SIZE,
            height: SLIDE_SIZE,
            borderRadius: SLIDE_BORDER_RADIUS
          }}
        />
      )}
    />
  );
};

export const WeeklyTipSlideImage = React.memo(WeeklyTipSlideImageComponent);
