import { GlobalUserTasksRepository } from "../../domain/global-user-tasks/data/GlobalUserTasksRepository";
import { combineLatest, Observable } from "rxjs";
import { UserTasksSummary } from "../../domain/global-user-tasks/entities/UserTasksSummary";
import { UserTask } from "../../domain/global-user-tasks/entities/UserTask";
import { FirebaseStorage } from "../../api/firebase/storage/FirebaseStorage";
import { map, switchMap } from "rxjs/operators";

export class GlobalUserTasksRepositoryImpl
  implements GlobalUserTasksRepository {
  constructor(private readonly firebaseStorage: FirebaseStorage) {}

  tasksSummary(): Observable<UserTasksSummary> {
    return this.firebaseStorage.tasks.pipe(
      switchMap(taskObservables => combineLatest(taskObservables)),
      map(uploadTasks => {
        let totalTaskBytes = 0;
        let totalBytesTransferred = 0;
        let activeCount = 0;
        for (const uploadTask of uploadTasks) {
          totalTaskBytes += uploadTask.totalBytes;
          totalBytesTransferred += uploadTask.bytesTransferred;
          if (uploadTask.bytesTransferred < uploadTask.totalBytes) {
            activeCount++;
          }
        }
        return {
          totalCount: uploadTasks.length,
          activeCount,
          progress: (100 * totalBytesTransferred) / totalTaskBytes
        };
      })
    );
  }

  tasksList(): Observable<Observable<UserTask>[]> {
    return this.firebaseStorage.tasks.pipe(
      map(taskObservables =>
        taskObservables.map(observable =>
          observable.pipe(
            map(uploadTask => ({
              label: uploadTask.name,
              progress: uploadTask.percentageCompleted
            }))
          )
        )
      )
    );
  }
}
