import React, { CSSProperties, useCallback } from "react";
import { useLocalViewModel } from "../../../architecture/view-model/useViewModel";
import { LocalViewModelFactory } from "../../../architecture/view-model/LocalViewModelFactory";
import { FileManagerViewModelBase } from "../FileManagerViewModelBase";
import { Divider, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { DescriptiveIconButton } from "../../../components/buttons/DescriptiveIconButton";
import { Close } from "@material-ui/icons";
import { FileManagerFileMetadata } from "./FileManagerFileMetadata";
import { FileManagerFileActions } from "./FileManagerFileActions";
import { FileManagerPublicLinks } from "./FileManagerPublicLinks";

export interface FileManagerFileDetailsProps {
  viewModelFactory: LocalViewModelFactory<FileManagerViewModelBase>;
}

const FAB_LOADER_SIZE_PX = 48;
export const FileManagerFileDetails = (props: FileManagerFileDetailsProps) => {
  const [viewState, viewModel] = useLocalViewModel(props.viewModelFactory);

  const onClickClose = useCallback(() => {
    viewModel.onCloseFileDetails();
  }, [viewModel]);

  if (!viewState.fileDetailedView?.fileItem) {
    return <></>;
  }
  let fileName = viewState.fileDetailedView.fileItem.name;
  let src = undefined;
  if (viewState.fileDetailedView.fileItem.type === "file") {
    const { extension, thumbnail_medium } = viewState.fileDetailedView.fileItem;
    if (viewState.fileDetailedView.fileItem.extension) {
      fileName += "." + extension;
    }
    src = thumbnail_medium;
  }

  return (
    <Box
      width={"100%"}
      height={"100%"}
      padding={"16px"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Box position={"relative"}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            {src ? <img src={src} style={thumbnailStyle} /> : <></>}
            <Typography variant={"h4"} component={"h1"}>
              {fileName}
            </Typography>
          </Box>
          <DescriptiveIconButton
            onClick={onClickClose}
            icon={<Close />}
            description={"Hide Details"}
          />
        </Box>
        <Divider />
        <Box
          position={"absolute"}
          right={36}
          bottom={-(FAB_LOADER_SIZE_PX / 2)}
          width={"100%"}
          height={FAB_LOADER_SIZE_PX}
        >
          <FileManagerFileActions viewModelFactory={props.viewModelFactory} />
        </Box>
      </Box>
      <Box marginTop={`${FAB_LOADER_SIZE_PX / 2}px`}>
        <FileManagerFileMetadata viewModelFactory={props.viewModelFactory} />
      </Box>
      <Box marginTop={"8px"} flexShrink={1} overflow={"hidden"}>
        <FileManagerPublicLinks viewModelFactory={props.viewModelFactory} />
      </Box>
    </Box>
  );
};

const thumbnailStyle: CSSProperties = {
  height: "4rem",
  marginRight: 8
};
