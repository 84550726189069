import { RecursivePartial } from "../../../utils/utilityTypes";

export interface NavigationEffect<T extends object> {
  navigation: {
    setPath?: string;
    setLocationParams?: T;
    updateLocationParams?: RecursivePartial<T>;
    clearParams?: (keyof T)[];
    goBack?: true;
  };
}

export function isNavigationEffect<T extends object>(
  effect: any
): effect is NavigationEffect<T> {
  return (
    effect.navigation &&
    (effect.navigation.setLocationParams ||
      effect.navigation.updateLocationParams ||
      effect.navigation.clearParams ||
      effect.navigation.setPath ||
      effect.navigation.goBack === true)
  );
}
