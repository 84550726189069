import { ContentApi } from "../ContentApi";
import Quote from "../Quote";
import { FirebaseData } from "../../firebase/data/FirebaseData";
import Author from "../Author";
import User from "../../auth/User";
import { Observable } from "rxjs";
import Category from "../Category";
import Podcast from "../Podcast";
import MediaPlatform from "../MediaPlatform";
import Video from "../Video";
import Book from "../Book";
import WeeklyTip from "../WeeklyTip";
import { Locale } from "../../../locale";
import { CommitResource } from "../../commits/Commit";
import {
  FirebaseStorage,
  UploadMetadata
} from "../../firebase/storage/FirebaseStorage";

export class FirebaseContentApi implements ContentApi {
  constructor(
    private readonly firebaseData: FirebaseData,
    private readonly firebaseStorage: FirebaseStorage
  ) {}

  getUsers: () => Promise<Map<string, User>> = () => {
    return this.firebaseData.getUsers();
  };

  getAuthorsObservable(locale: string): Observable<Author[]> {
    return this.firebaseData.getAuthorsObservable(locale);
  }

  getQuotesObservable(locale: string): Observable<Quote[]> {
    return this.firebaseData.getQuotesObservable(locale);
  }

  getCategoriesObservable(locale: string): Observable<Category[]> {
    return this.firebaseData.getCategoriesObservable(locale);
  }

  getMediaPlatformsObservable(locale: string): Observable<MediaPlatform[]> {
    return this.firebaseData.getMediaPlatformsObservable(locale);
  }

  getPodcastsObservable(locale: string): Observable<Podcast[]> {
    return this.firebaseData.getPodcastsObservable(locale);
  }

  getVideosObservable(locale: string): Observable<Video[]> {
    return this.firebaseData.getVideosObservable(locale);
  }

  getBooksObservable(locale: string): Observable<Book[]> {
    return this.firebaseData.getBooksObservable(locale);
  }

  getWeeklyTipsObservable(locale: string): Observable<WeeklyTip[]> {
    return this.firebaseData.getWeeklyTipsObservable(locale);
  }

  async uploadFile({
    resource,
    locale,
    resourceOrCommitId,
    file,
    metadata
  }: {
    resource: CommitResource;
    locale: Locale;
    resourceOrCommitId: string;
    file: File;
    metadata?: UploadMetadata;
  }): Promise<{ fullPath: string }> {
    return this.firebaseStorage.upload({
      file,
      path: `${resource}/${locale}/${resourceOrCommitId}/${file.name}`,
      metadata
    });
  }
}
