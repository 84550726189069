import React from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import { UserTasksSummary } from "../../../domain/global-user-tasks/entities/UserTasksSummary";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ButtonBase } from "@material-ui/core";
import { ActivityIndicatorPopover } from "./ActivityIndicatorPopover";
import { PopoverWrapper } from "../../popover/PopoverWrapper";

export interface PersonalActivityIndicatorProps {
  userTasksSummary: UserTasksSummary;
}

export const PersonalActivityIndicator = (
  props: PersonalActivityIndicatorProps
) => {
  const theme = useTheme();
  const { progress, activeCount, totalCount } = props.userTasksSummary;
  return (
    <PopoverWrapper render={() => <ActivityIndicatorPopover />}>
      <ButtonBase
        style={{
          width: "48px",
          height: "48px"
        }}
        disableRipple
      >
        <CircularProgressbar
          value={progress ?? 100}
          text={
            activeCount === 0
              ? `${totalCount}`
              : `${totalCount - activeCount}/${totalCount}`
          }
          styles={buildStyles({
            textSize: "2em",
            textColor: theme.textColors?.primary,
            pathColor: theme.palette.primary.main
          })}
        />
      </ButtonBase>
    </PopoverWrapper>
  );
};
