import React from "react";
import { Message } from "../../../../repository/use-case/users-voice/dto/ListMessagesResponse";
import { MessagesList } from "./MessagesList";
import Grid from "@material-ui/core/Grid";
import {
  ConversationActions,
  ConversationActionSelection
} from "./ConversationActions";
import { ConversationEntry } from "../../../../repository/use-case/users-voice/dto/ListConversationsResponse";
import LinearProgress from "@material-ui/core/LinearProgress";

export const Conversation = ({
  conversation,
  messages,
  onConversationActionSelection,
  isReplyLoading
}: {
  conversation: ConversationEntry;
  messages: Message[];
  onConversationActionSelection: (
    conversationActionSelection: ConversationActionSelection
  ) => void;
  isReplyLoading: boolean;
}) => {
  return (
    <>
      {isReplyLoading && <LinearProgress variant="query" />}
      <Grid container direction={"column"} spacing={2}>
        <Grid item>
          <MessagesList messages={messages} />
        </Grid>
        <Grid item>
          <ConversationActions
            status={conversation.status}
            type={conversation.type}
            onConversationActionSelection={onConversationActionSelection}
            isReplyLoading={isReplyLoading}
          />
        </Grid>
      </Grid>
    </>
  );
};
