import { Resources } from "../../../data/Resources";
import { Locale } from "../../../locale";
import ManagedResource from "../../../data/ManagedResource";
import { formFieldFactory } from "../../../components/dialogs/FormDialog/fields/factory/formFieldFactory";
import { transformValues } from "../../../utils/maps";
import { CommitResource } from "../../../api/commits/Commit";
import ManagedContent from "../../../data/ManagedContent";
import IndexedResource from "../../../data/IndexedResource";

export const getFormFieldsFactory = (
  defaultLocale: Locale,
  locale: Locale,
  resourcesToIndexedManagedContent: Map<
    CommitResource,
    Map<Locale, ManagedContent<IndexedResource>>[]
  >
) => (
  resourceType: Resources,
  locale: Locale,
  resource?: ManagedResource<any>
) =>
  formFieldFactory(
    defaultLocale || "",
    locale || "",
    transformValues(resourcesToIndexedManagedContent, localeToManagedContent =>
      localeToManagedContent.reduce(
        (localeToResources, localeToManagedContent) => {
          localeToManagedContent.forEach((managedContent, locale) => {
            const resources = localeToResources.get(locale) || [];
            resources.push(managedContent.managedResource);
            localeToResources.set(locale, resources);
          });
          return localeToResources;
        },
        new Map<Locale, ManagedResource<any>[]>()
      )
    )
  )().get(resourceType)?.({
    resource,
    locale
  });
