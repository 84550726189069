import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  pageContainer: {
    display: "flex"
  },
  loginBoxContainer: {
    position: "absolute",
    top: "50vh",
    left: "50vw",
    transform: "translate(-50%, -50%)",
    padding: '64px',
    borderRadius: 8,
    backgroundColor: theme.header?.backgroundColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  loginOptions: {
    marginTop: 32
  }
}));
