import ContentRow from "./ContentRow";
import { ContentItemViewModel } from "../ContentItemViewModel";
import * as React from "react";
import { Typography } from "@material-ui/core";
import EqualSpacedHorizontalGrid from "../../grid/EqualSpacedHorizontalGrid";
import Box from "@material-ui/core/Box";

const ContentHeader = ({ headers }: { headers: string[] }) => (
  <ContentRow
    viewModel={
      new ContentItemViewModel(
        (
          <EqualSpacedHorizontalGrid>
            {Object.values(headers).map((quoteProp, index) => (
              <Box borderRight={index < headers.length - 1 ? 1 : 0} key={index}>
                <Typography align="center" variant="h6">
                  {quoteProp}
                </Typography>
              </Box>
            ))}
          </EqualSpacedHorizontalGrid>
        )
      )
    }
  />
);

export default ContentHeader;
