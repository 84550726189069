import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.header?.backgroundColor,
    paddingTop: "16px",
    paddingBottom: "16px"
  },
  logo: {
    marginLeft: "16px",
    alignItems: "center",
    height: "100%"
  },
  bo: {
    color: "black",
    marginLeft: "0.5vw",
    backgroundColor: "floralwhite",
    borderRadius: "0.3vh",
    padding: "0.15vw"
  },
  user: {
    height: "100%",
    paddingRight: "0.5vw"
  },
  displayName: {
    marginLeft: "0.25vw"
  },
  divider: {
    height: "36px",
    width: "1px",
    margin: "0 0.5vw",
    backgroundColor: "white"
  },
  drawerContainer: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  drawer: {
    color: "#fff",
    marginLeft: "0.5vw"
  }
}));

export default useStyles;
