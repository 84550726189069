import firebase from "firebase";
import firebaseConfig from "./firebaseConfig";
import FirebaseAuth from "./auth/FirebaseAuth";
import { FirebaseData } from "./data/FirebaseData";
import { Locale } from "../../locale";
import { FirebaseStorage } from "./storage/FirebaseStorage";
import { FirebaseFunctions } from "./functions/FirebaseFunctions";

class FirebaseApi {
  private readonly firebaseApp: firebase.app.App;
  private readonly firebaseAuth: FirebaseAuth;
  private readonly firebaseData: FirebaseData;
  private readonly firebaseStorage: FirebaseStorage;
  private readonly firebaseFunctions: FirebaseFunctions;

  constructor(private readonly defaultLocale: Locale) {
    this.firebaseApp = firebase.initializeApp(firebaseConfig);
    this.firebaseAuth = new FirebaseAuth(this.firebaseApp);
    this.firebaseData = new FirebaseData(this.firebaseApp, defaultLocale);
    this.firebaseStorage = new FirebaseStorage(this.firebaseApp);
    this.firebaseFunctions = new FirebaseFunctions(this.firebaseApp);
  }

  get authentication(): FirebaseAuth {
    return this.firebaseAuth;
  }

  get data(): FirebaseData {
    return this.firebaseData;
  }

  /*tryWhitelistAccess(): Promise<boolean> {
    return this.firebaseApp
      .firestore()
      .collection("permissions")
      .where("email", "==", this.getUser()?.email)
      .get()
      .then(() => true)
      .catch(() => false);
  }*/
  get storage(): FirebaseStorage {
    return this.firebaseStorage;
  }

  get functions(): FirebaseFunctions {
    return this.firebaseFunctions;
  }

  get firestore() {
    return this.firebaseApp.firestore();
  }
}

export default FirebaseApi;
