import {
  GenericFormDialog,
  GenericFormDialogField,
  GenericFormDialogTextInputField
} from "../../../components/dialogs/GenericFormDialog";
import React, { useCallback, useMemo } from "react";
import { LocalViewModelFactory } from "../../../architecture/view-model/LocalViewModelFactory";
import { FileManagerViewModelBase } from "../FileManagerViewModelBase";
import { useLocalViewModel } from "../../../architecture/view-model/useViewModel";

export interface CreateNewFolderDialogProps {
  viewModelFactory: LocalViewModelFactory<FileManagerViewModelBase>;
}

export const CreateNewFolderDialog = (props: CreateNewFolderDialogProps) => {
  const [viewState, viewModel] = useLocalViewModel(props.viewModelFactory);
  const onClose = useCallback(() => viewModel.onClickCancelPendingAction(), [
    viewModel
  ]);
  const currentDirectoryNames = useMemo(() => {
    return new Set(
      viewState.pathContents.fileList
        .filter(file => file.type === "directory")
        .map(directory => directory.name)
    );
  }, [viewState.pathContents.fileList]);
  const folderNameField = useMemo<GenericFormDialogTextInputField>(
    () => ({
      type: "textInput",
      hint: "Folder Name",
      validate(value: string | undefined): string | null {
        if (!value || !value.trim()) {
          return "Please enter a folder name";
        }
        if (currentDirectoryNames.has(value)) {
          return "Directory with this name already exists.";
        }
        // TODO Regex
        return null;
      }
    }),
    [currentDirectoryNames]
  );
  const fields = useMemo(() => [folderNameField], [folderNameField]);
  const actions = useMemo(
    () => [
      {
        type: "cancel" as const,
        label: "Cancel"
      },
      {
        type: "save" as const,
        label: "Save",
        onSave: (currentValues: Map<GenericFormDialogField, any>) => {
          viewModel.onCreateNewFolder(currentValues.get(folderNameField));
        }
      }
    ],
    [folderNameField, viewModel]
  );
  return (
    <GenericFormDialog
      open={viewState.pendingAction?.type === "new_folder"}
      onClose={onClose}
      title={"New Folder"}
      actions={actions}
      fields={fields}
    />
  );
};
