import { BaseViewModel } from "../../../../architecture/view-model/BaseViewModel";
import { DeveloperConsoleRepository } from "../../../../repository/use-case/developer-console/DeveloperConsoleRepository";
import _, { keyBy, mapValues } from "lodash";
import { Resources } from "../../../../data/Resources";
import { ContentDiscrepanciesJobReport } from "../../../../repository/use-case/developer-console/ContentDiscrepanciesJobReport";
import { lce, LCE } from "../../../../architecture/lce/lce";
import { LocalizedObservableData } from "../../../../repository/LocalizedObservableData";
import { ContentData } from "../../../../repository/use-case/content/ContentData";
import ContentRepository from "../../../../repository/use-case/content/ContentRepository";
import { combineLatest, Subscription } from "rxjs";
import IndexedResource from "../../../../data/IndexedResource";
import { CommitResource } from "../../../../api/commits/Commit";
import { QuoteFields } from "../../../../api/content/Quote";
import { AuthorFields } from "../../../../api/content/Author";
import { CategoryFields } from "../../../../api/content/Category";
import { MediaFeedFields } from "../../../../api/content/MedaFeedContent";
import { MediaPlatformFields } from "../../../../api/content/MediaPlatform";
import { WeeklyTipFields } from "../../../../api/content/WeeklyTip";

export interface ViewState {
  reportDisplayed: boolean;
  contentItemsSelections: Record<
    string,
    {
      label: string;
      selected: boolean;
    }
  >;
  reportLCE: LCE<
    [ContentDiscrepanciesJobReport, LocalizedObservableData<ContentData>]
  >;
  commitResourcesSelections: {
    [resourceName: string]: {
      selectionCount: number;
      resourceMap: {
        [resourceId: string]: {
          isSelected: boolean;
          label: string;
          disabled: boolean;
        };
      };
    };
  };
}

export class ContentDiscrepanciesViewModel extends BaseViewModel<ViewState> {
  private reportAndContentSubscription?: Subscription;

  constructor(
    private readonly developerConsoleRepository: DeveloperConsoleRepository,
    private readonly contentRepository: ContentRepository
  ) {
    super({
      reportDisplayed: false,
      contentItemsSelections: mapValues(
        keyBy(Object.values(Resources)),
        resourceName => {
          _.startCase(resourceName);
          const label = _.startCase(resourceName);
          return {
            label: label.endsWith("s") ? label : label + "s",
            selected: false
          };
        }
      ),
      reportLCE: lce.loading(),
      commitResourcesSelections: {}
    });
  }

  init() {
    this.reportAndContentSubscription = combineLatest([
      this.developerConsoleRepository.loadContentDiscrepanciesJobReport(),
      this.contentRepository.getDataObservable()
    ]).subscribe({
      next: reportAndContent => {
        this.mergeState({
          reportLCE: lce.content(reportAndContent),
          commitResourcesSelections: this.defaultSelectedCommitResources(
            ...reportAndContent
          )
        });
      },
      error: error => this.mergeState({ reportLCE: lce.error(error) })
    });
  }

  private defaultSelectedCommitResources(
    jobReport: ContentDiscrepanciesJobReport,
    content: LocalizedObservableData<ContentData>
  ) {
    const missingContent = mapValues(
      jobReport.missingIds,
      (value, resourceName) => {
        return value.missingResourceIds.list.map(id =>
          content.localizedData.data[resourceName].get(id)
        );
      }
    );
    return Object.keys(missingContent).reduce(
      (initialSelectedCommitResources, resourceName) => {
        const resources = missingContent[resourceName];
        initialSelectedCommitResources[resourceName] = resources.reduce(
          (selectionData, resourceData) => {
            const resourceId = (resourceData as IndexedResource).id;
            selectionData.selectionCount++;
            selectionData.resourceMap[resourceId] = {
              isSelected: true,
              label:
                (resourceData?.[
                  contentRepresentationField[
                    resourceName as CommitResource
                  ] as keyof typeof resourceData
                ] as string[])?.[0] ?? "",
              disabled: !!jobReport.missingIds[resourceName]?.missingResourceIds
                ?.committedResourceIds?.[resourceId]
            };
            return selectionData;
          },
          { selectionCount: 0, resourceMap: {} } as {
            selectionCount: number;
            resourceMap: Record<
              string,
              {
                isSelected: boolean;
                label: string;
                disabled: boolean;
              }
            >;
          }
        );
        return initialSelectedCommitResources;
      },
      {} as Record<
        string,
        {
          selectionCount: number;
          resourceMap: Record<
            string,
            {
              isSelected: boolean;
              label: string;
            }
          >;
        }
      >
    );
  }

  clear() {
    this.reportAndContentSubscription?.unsubscribe();
  }

  onClickViewReport() {
    this.mergeState({ reportDisplayed: true });
  }

  onToggleContentDiagnosticItemSelection(contentItemKey: string) {
    this.mergeState({
      contentItemsSelections: {
        [contentItemKey]: {
          selected: !this.currentState().contentItemsSelections[contentItemKey]
            .selected
        }
      }
    });
  }

  onClickRun() {
    this.developerConsoleRepository.findContentDiscrepancies({
      resources: {
        list: Object.keys(this.currentState().contentItemsSelections).filter(
          resourceName =>
            this.currentState().contentItemsSelections[resourceName].selected
        )
      }
    });
  }

  onToggleCommitResourceSelection(resourceName: string, resourceId: string) {
    const currentSelection = this.currentState().commitResourcesSelections[
      resourceName
    ];
    const isSelected = currentSelection.resourceMap[resourceId].isSelected;
    this.mergeState({
      commitResourcesSelections: {
        [resourceName]: {
          selectionCount:
            currentSelection.selectionCount + (isSelected ? -1 : 1),
          resourceMap: {
            [resourceId]: {
              isSelected: !isSelected
            }
          }
        }
      }
    });
  }

  onToggleAllCommitResourcesSelection(resourceName: string) {
    const commitResourceSelections = this.currentState()
      .commitResourcesSelections[resourceName];
    const totalResourcesCount = Object.keys(
      commitResourceSelections.resourceMap
    ).length;
    const allResourcesSelected =
      commitResourceSelections.selectionCount === totalResourcesCount;
    this.mergeState({
      commitResourcesSelections: {
        [resourceName]: {
          selectionCount: allResourcesSelected ? 0 : totalResourcesCount,
          resourceMap: mapValues(commitResourceSelections.resourceMap, () => ({
            isSelected: !allResourcesSelected
          }))
        }
      }
    });
  }

  onClickCommit() {
    const resourcesToCommit = mapValues(
      this.currentState().commitResourcesSelections,
      selectionByResourceName =>
        Object.keys(selectionByResourceName.resourceMap).filter(
          resourceId =>
            selectionByResourceName.resourceMap[resourceId].isSelected
        )
    );
    this.developerConsoleRepository.commitContentDiscrepancies({
      selectedResources: mapValues(resourcesToCommit, selectedIds => ({
        list: selectedIds
      }))
    });
  }
}

const contentRepresentationField: { [resource in CommitResource]: string } = {
  [Resources.QUOTES]: QuoteFields.TEXT,
  [Resources.AUTHORS]: AuthorFields.NAME,
  [Resources.CATEGORIES]: CategoryFields.NAME,
  [Resources.PODCASTS]: MediaFeedFields.NAME,
  [Resources.VIDEOS]: MediaFeedFields.NAME,
  [Resources.BOOKS]: MediaFeedFields.NAME,
  [Resources.MEDIA_PLATFORM]: MediaPlatformFields.NAME,
  [Resources.WEEKLY_TIP]: WeeklyTipFields.TOPIC
};
