import { Grid } from "@material-ui/core";
import * as React from "react";
import EqualSpacedHorizontalGrid from "../../grid/EqualSpacedHorizontalGrid";
import CountryFlag from "../../localization/CountryFlag";
import ContentFieldDiff from "./ContentFieldDiff";
import { Locale } from "../../../locale";
import ContentDiffData from "./ContentDiffData";

const ContentDiffRow = ({
  locale,
  fieldToDiff,
  defaultLocale,
  headers
}: {
  fieldToDiff: ContentDiffData;
  locale: Locale;
  defaultLocale: Locale | undefined;
  headers: { key: string; isImage?: boolean; isUrl?: boolean }[];
}) => {
  return (
    <EqualSpacedHorizontalGrid item flatten>
      <CountryFlag countryCode={locale} />
      {headers.map(header => {
        const content = [];
        const changes = fieldToDiff.changesByField.get(header.key) || {
          previous: [],
          current: []
        };
        const maxLen = Math.max(
          1,
          Math.max(changes.previous.length, changes.current.length)
        );
        for (let i = 0; i < maxLen; i++) {
          content.push(
            <ContentFieldDiff
              isImage={header.isImage}
              isUrl={header.isUrl}
              previous={changes.previous[i]}
              current={changes.current[i]}
              isMandatoryValue={
                locale === defaultLocale || !(header.isImage || header.isUrl)
              }
              isLocalizationRequired={
                locale !== defaultLocale && !(header.isImage || header.isUrl)
              }
            />
          );
        }
        return <Grid item>{content}</Grid>;
      })}
    </EqualSpacedHorizontalGrid>
  );
};

export default ContentDiffRow;
