import { Card } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import ContentCardViewModel from "./ContentCardViewModel";
import * as React from "react";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { ContentActionViewModel } from "../actions/ContentActionViewModel";
import { ContentOwnerViewModel } from "../owner/ContentOwnerViewModel";
import ContentOwner from "../owner/ContentOwner";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ownerStyle } from "../styles";
import ContentActions from "../actions/ContentActions";
import { PropsWithChildren } from "react";

const useStyles = makeStyles(theme => ({
  ...ownerStyle(theme),
  contentContainer: {
    width: "100%"
  }
}));

const ContentCard = ({
  viewModel
}: { viewModel: ContentCardViewModel } & PropsWithChildren<any>) => {
  const contentActions = viewModel.getContentActions();
  const ownerTimeline = viewModel.getOwnerTimeline();
  const classes = useStyles();
  return (
    <Card>
      <CardHeader title={viewModel.getRenderHeaderComponent()} />
      <CardContent>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item>{viewModel.getRenderContent()}</Grid>
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            className={classes.contentContainer}
          >
            <Grid item>
              <Box>
                {contentActions.length > 0 && (
                  <ContentActions
                    viewModel={new ContentActionViewModel(contentActions)}
                  />
                )}
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.owner}>
                {ownerTimeline && (
                  <ContentOwner
                    viewModel={
                      new ContentOwnerViewModel(
                        ownerTimeline,
                        viewModel.contentDividerModificationColor
                      )
                    }
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ContentCard;
