import { useCookies } from "react-cookie";
import { CookieSetOptions } from "universal-cookie";
import { Cookies } from "../cookies";
import { useCallback } from "react";

export function useTypedCookies<T extends object>(cookie: Cookies) {
  const [allCookies, setCookieValue, setCookieOptions] = useCookies([cookie]);
  const setThisCookieValue = useCallback(
    (value: T, options?: CookieSetOptions) => {
      setCookieValue(cookie, value, options);
    },
    [cookie, setCookieValue]
  );
  const setThisCookieOptions = useCallback(
    (options?: CookieSetOptions) => {
      setCookieOptions(cookie, options);
    },
    [cookie, setCookieOptions]
  );
  return [
    allCookies[cookie] as T | undefined,
    setThisCookieValue,
    setThisCookieOptions
  ] as const;
}
