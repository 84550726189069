import { ViewModel } from "./ViewModel";
import { Observable, Subject } from "rxjs";
import { map, scan, shareReplay, startWith, tap } from "rxjs/operators";
import { deepMerge } from "../../utils/objects/deepMerge";
import { RecursivePartial } from "../../utils/utilityTypes";
import { doOnSubscribe } from "../../utils/observables/doOnSubscribe";
import { doOnUnsubscribe } from "../../utils/observables/doOnUnsubscribe";

const autoBind = require("react-autobind");

type StateUpdate<State extends object> =
  | PartialStateUpdate<State>
  | SetStateUpdate<State>;

interface PartialStateUpdate<State extends object> {
  type: "partial";
  update: RecursivePartial<State>;
}

interface SetStateUpdate<State extends object> {
  type: "full";
  update: State;
}

export abstract class BaseViewModel<State extends object, Effect = undefined>
  implements ViewModel<State, Effect> {
  private readonly partialStateUpdatesSubject: Subject<StateUpdate<State>>;
  private readonly effectsSubject = new Subject<Effect>();
  readonly state: Observable<State>;
  readonly partialStateUpdates: Observable<RecursivePartial<State>>;

  private mutableCurrentState: State;

  private subscribers: number = 0;

  protected constructor(initialState: State) {
    this.mutableCurrentState = initialState;
    this.partialStateUpdatesSubject = new Subject<StateUpdate<State>>();
    this.partialStateUpdates = this.partialStateUpdatesSubject.pipe(
      doOnSubscribe(() => this.subscribers++),
      doOnUnsubscribe(() => this.subscribers--),
      tap(stateUpdate => {
        if (stateUpdate.type === "full") {
          this.mutableCurrentState = stateUpdate.update;
        } else {
          this.mutableCurrentState = this.calculateNextState(
            stateUpdate.update
          );
        }
      }),
      map(stateUpdate => stateUpdate.update as RecursivePartial<State>),
      shareReplay()
    );

    this.state = this.partialStateUpdates.pipe(
      map(() => this.mutableCurrentState),
      startWith(this.mutableCurrentState)
    );

    autoBind(this);
  }

  readonly effects: Observable<Effect> = this.effectsSubject;

  currentState() {
    return this.mutableCurrentState;
  }

  protected mergeState(partialState: RecursivePartial<State>) {
    this.partialStateUpdatesSubject.next({
      type: "partial",
      update: partialState
    });
    if (this.subscribers <= 0) {
      this.mutableCurrentState = this.calculateNextState(partialState);
    }
  }

  protected setState(state: State) {
    const nextState = this.overrideNextState(state);
    this.partialStateUpdatesSubject.next({
      type: "full",
      update: nextState
    });
    if (this.subscribers <= 0) {
      this.mutableCurrentState = nextState;
    }
  }

  protected nextEffect<E extends Effect>(effect: E) {
    this.effectsSubject.next(effect);
  }

  private calculateNextState(stateUpdate: RecursivePartial<State>) {
    const nextStateCandidate = deepMerge(
      this.mutableCurrentState,
      stateUpdate,
      {
        mergeArrays: false
      }
    );
    return this.overrideNextState(nextStateCandidate);
  }

  protected overrideNextState(nextStateCandidate: State): State {
    return nextStateCandidate;
  }
}
