import React, { CSSProperties, useMemo } from "react";
import { ButtonBase, Grid, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import useStyles from "../styles";
import { ContextMenuPopover } from "../../../components/context-menu/ContextMenuPopover";
import {
  defaultThumbnailForItem,
  FileManagerDisplayPropsBase,
  getFullName,
  stateIndicatorForItem
} from "./file-manager-display-base";

export interface FileManagerGridDisplayProps
  extends FileManagerDisplayPropsBase {}

const thumbnailStyle: CSSProperties = {
  objectFit: "cover",
  width: "16rem",
  height: "16rem"
};

export const FileManagerGridDisplay = (props: FileManagerGridDisplayProps) => {
  const classes = useStyles();
  const fileGridItems = useMemo(() => {
    return props.fileList.map(fileItem => {
      return (
        <Grid item>
          <ContextMenuPopover render={() => props.renderContextMenu(fileItem)}>
            <ButtonBase focusRipple onClick={() => props.onFileClick(fileItem)}>
              <Box className={classes.fileContentsDisplayGridItem}>
                {defaultThumbnailForItem(fileItem, thumbnailStyle)}
                <Box display={"flex"} flexDirection={"row"}>
                  <Typography>{getFullName(fileItem)}</Typography>
                  <Box marginLeft={4}>{stateIndicatorForItem(fileItem)}</Box>
                </Box>
              </Box>
            </ButtonBase>
          </ContextMenuPopover>
        </Grid>
      );
    });
  }, [classes.fileContentsDisplayGridItem, props]);
  return (
    <Grid container direction={"row"} spacing={2} style={{ margin: 16 }}>
      {fileGridItems}
    </Grid>
  );
};
