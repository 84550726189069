import { LCE } from "./lce";
import { Observable } from "rxjs";

export function promiseToLCEObservable<T>(
  promise: Promise<T>
): Observable<LCE<T>> {
  return new Observable<LCE<T>>(subscriber => {
    subscriber.next({ isLoading: true });
    promise
      .then(result => {
        subscriber.next({
          isLoading: false,
          content: result
        });
      })
      .catch(error =>
        subscriber.next({
          isLoading: false,
          error
        })
      )
      .finally(() => {
        subscriber.complete();
      });
    return () => {
      subscriber.next({
        isLoading: false
      });
    };
  });
}
