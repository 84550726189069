import React, { useMemo } from "react";
import { DiagnosticOption } from "./DeveloperDiagnosticsPage";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { DiagnosticState } from "./DiagnosticState";
import { useIncrementalState } from "../../../../utils/hooks/useIncrementalState";
import LinearProgress from "@material-ui/core/LinearProgress";

const closeIconButtonStyle = {
  display: "flex",
  alignItems: "center",
  marginRight: 16
};

export const DeveloperDiagnosticDialog = ({
  selectedDiagnosticOption,
  onCloseClick
}: {
  selectedDiagnosticOption: DiagnosticOption | undefined;
  onCloseClick: () => void;
}) => {
  const [diagnosticState, setDiagnosticState] = useIncrementalState<
    DiagnosticState
  >({
    asyncActionRunning: false,
    actions: {}
  });

  return (
    <Dialog open={!!selectedDiagnosticOption} fullWidth>
      <LinearProgress
        variant="query"
        hidden={!diagnosticState.asyncActionRunning}
      />
      <Grid container justify={"space-between"}>
        <Grid item>
          <DialogTitle>{selectedDiagnosticOption?.label ?? ""}</DialogTitle>
        </Grid>
        <Grid item style={closeIconButtonStyle}>
          <IconButton onClick={onCloseClick}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        {selectedDiagnosticOption?.render({
          diagnosticStateUpdater: setDiagnosticState
        })}
      </DialogContent>
      {diagnosticState?.actions ? (
        <DialogActions>
          {Object.keys(diagnosticState.actions).map(actionId => {
            const action = diagnosticState.actions[actionId];
            if (!action) return <></>;
            return (
              <Button
                disabled={action.enabled === false}
                color={action.style}
                variant={action.variant}
                onClick={async () => {
                  setDiagnosticState(() => ({
                    asyncActionRunning: true
                  }));
                  await action.onClick();
                  setDiagnosticState(() => ({
                    asyncActionRunning: false
                  }));
                }}
              >
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      ) : (
        <></>
      )}
    </Dialog>
  );
};
