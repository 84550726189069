import makeStyles from "@material-ui/core/styles/makeStyles";
import { contentCircles } from "../../../styles/summaryCircles";

export const useStyles = makeStyles(theme => ({
  ...contentCircles(theme),
  parentContainerHeight: {
    height: "100%"
  },
  panelItemWrapper: {
    borderRadius: "8px",
    borderColor: theme.publish?.panel?.item?.color,
    padding: "8px"
  },
  container: {
    margin: "8px"
  },
  actionPanelItem: {
    width: "100%",
    justifyContent: "start"
  },
  actionRowContainer: {
    display: "flex",
    flexFlow: "row",
    alignItems: "center"
  }
}));
