import React, { useCallback, useEffect, useMemo, useState } from "react";
import useLCE2 from "../../hooks/useLCE2";
import { LCEWrapper } from "../../components/lce/LCEWrapper";
import {
  Checkbox,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from "@material-ui/core";
import { useRepositories } from "../../hooks/useRepositories";

export interface PermissionsListProps {
  forUserEmail: string;
  onPermissionSelectionChange: (selectedPermissions: string[]) => void;
  disabled?: boolean;
}

export interface Permission {
  id: string;
  selected: boolean;
}

export const PermissionsList = (props: PermissionsListProps) => {
  const { permissions } = useRepositories();
  const allPermissionsPromise = useMemo(() => permissions.listPermissions(), [
    permissions
  ]);
  const loadPermissions = useCallback(async () => {
    const [
      allPermissionsResponse,
      userPermissionsResponse
    ] = await Promise.all([
      allPermissionsPromise,
      permissions.listUserPermissions({ user: { email: props.forUserEmail } })
    ]);
    const userPermissionSet = new Set(
      userPermissionsResponse.enabledPermissions.ids
    );
    return allPermissionsResponse.list.ids.map(id => ({
      id,
      selected: userPermissionSet.has(id)
    }));
  }, [allPermissionsPromise, permissions, props.forUserEmail]);
  const permissionsLCE = useLCE2(loadPermissions);

  const [selectedPermissions, setSelectedPermissions] = useState(
    new Set<string>()
  );

  useEffect(() => {
    if (permissionsLCE.content) {
      setSelectedPermissions(
        new Set(
          Array.from(
            permissionsLCE.content
              .filter(permission => permission.selected)
              .map(permission => permission.id)
          )
        )
      );
    }
  }, [permissionsLCE.content]);

  useEffect(() => {
    props.onPermissionSelectionChange(Array.from(selectedPermissions));
  }, [props.onPermissionSelectionChange, selectedPermissions]);

  return (
    <LCEWrapper
      lce={permissionsLCE}
      renderContent={permissions => (
        <List>
          {permissions.map(permission => (
            <ListItem>
              <ListItemText>{permission.id}</ListItemText>
              <ListItemSecondaryAction>
                <Checkbox
                  color="primary"
                  disabled={props.disabled}
                  checked={selectedPermissions.has(permission.id)}
                  onClick={() =>
                    setSelectedPermissions(currentPermissionSelections => {
                      const updatedSelections = new Set(
                        currentPermissionSelections
                      );
                      if (updatedSelections.has(permission.id)) {
                        updatedSelections.delete(permission.id);
                      } else {
                        updatedSelections.add(permission.id);
                      }
                      return updatedSelections;
                    })
                  }
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    />
  );
};
