import * as architecture from "../../architecture/view-model/useViewModel";
import { ViewModelFactoryMap, ViewModelKeys } from "./ViewModelFactoryMap";
import { EffectCallback } from "../../architecture/view-model/useViewModel";

export function useViewModel<K extends ViewModelKeys>(
  viewModelKey: K,
  onEffect?: EffectCallback<ViewModelFactoryMap, K>
) {
  return architecture.useViewModel<ViewModelFactoryMap, K>(
    viewModelKey,
    onEffect
  );
}
