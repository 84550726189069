import { FirebaseData } from "../../firebase/data/FirebaseData";
import { CommitsApi } from "../CommitsApi";
import { Commit, CommitResource } from "../Commit";
import { Observable } from "rxjs";
import { Pair } from "../../../utils/tuples/Pair";
import { Locale } from "../../../locale";
import { FirebaseFunctions } from "../../firebase/functions/FirebaseFunctions";
import { toObject, transformValues } from "../../../utils/maps";

export class FirebaseCommitApi implements CommitsApi {
  constructor(
    private readonly firebaseData: FirebaseData,
    private readonly firebaseFunctions: FirebaseFunctions
  ) {}

  getCommit: (
    resource: CommitResource,
    locale: string,
    commitId: string
  ) => Promise<Commit> = (resource, commitId, locale) => {
    return this.firebaseData.getCommit(resource, locale, commitId);
  };

  getCommits: (
    resource: CommitResource,
    locale: string
  ) => Promise<Commit[]> = (resource, locale) =>
    this.firebaseData.getCommits(resource, locale);

  getCommitsObservable(
    resource: CommitResource,
    locale: string
  ): Observable<Commit[]> {
    return this.firebaseData.getCommitsObservable(resource, locale);
  }

  async updateCommit({
    resource,
    ownerId,
    updateData,
    commitId,
    resourceId
  }: {
    resource: CommitResource;
    ownerId: string;
    updateData: Map<
      Locale,
      {
        updatedFields: Map<string, string[]>;
        updatedLinks: Map<string, Pair<string, string>[]>;
      }
    >;
    commitId?: string;
    resourceId?: string;
  }): Promise<{ commitId: string }> {
    const newCommitId: string = await this.firebaseFunctions.call(
      "updateCommit",
      {
        ownerId,
        resource,
        commitId,
        resourceId,
        data: toObject(
          transformValues(updateData, data => ({
            updatedFields:
              data.updatedFields.size && toObject(data.updatedFields),
            linkedResources:
              data.updatedLinks.size &&
              toObject(
                transformValues(data.updatedLinks, keyValuePairs =>
                  keyValuePairs.map(pair => ({ [pair.key]: pair.value }))
                )
              )
          }))
        )
      }
    );
    const updatedCommitId = newCommitId || commitId;
    if (!updatedCommitId) {
      throw Error("Failed to update commit");
    }
    return { commitId: updatedCommitId };
  }

  revertCommit: (
    resource: CommitResource,
    locale: string,
    commitId: string
  ) => Promise<any> = (resource, locale, commitId) => {
    return this.firebaseFunctions.call("revertCommit", {
      resource,
      locale,
      commitId
    });
  };

  deleteCommit(
    resource: CommitResource,
    locale: string,
    resourceId: string,
    ownerId?: string
  ): Promise<void> {
    return this.firebaseFunctions.call("deleteCommit", {
      resourceId,
      ownerId,
      locale,
      resource
    });
  }

  archiveCommit(
    resource: CommitResource,
    locale: string,
    resourceId?: string,
    commitId?: string,
    ownerId?: string
  ): Promise<void> {
    return this.firebaseFunctions.call("archiveCommit", {
      resourceId,
      commitId,
      ownerId,
      locale,
      resource
    });
  }

  unarchiveCommit(
    resource: CommitResource,
    locale: string,
    resourceId?: string,
    commitId?: string,
    ownerId?: string
  ): Promise<void> {
    return this.firebaseFunctions.call("unarchiveCommit", {
      resourceId,
      commitId,
      ownerId,
      locale,
      resource
    });
  }
}
