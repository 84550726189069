import React from "react";
import { Message } from "../../../../repository/use-case/users-voice/dto/ListMessagesResponse";
import { Box, Grid, Paper, Typography, useTheme } from "@material-ui/core";
import moment from "moment";

export const MessageItem = ({ message }: { message: Message }) => {
  const theme = useTheme();
  return (
    <Box
      style={{
        justifyContent: message.isOutgoing ? "flex-start" : "flex-end",
        display: "flex"
      }}
    >
      <Paper
        style={{
          width: "75%",
          background: message.isOutgoing
            ? theme.messages?.usersVoice?.incoming
            : theme.messages?.usersVoice?.outgoing
        }}
      >
        <Grid
          style={{ padding: "0.5em" }}
          container
          direction={"column"}
          spacing={2}
        >
          <Grid item>
            <Typography variant={"body1"}>{message.content}</Typography>
          </Grid>
          <Grid item>
            <Typography variant={"caption"}>
              {`${message.isOutgoing ? "received at " : "sent at"} ${moment(
                message.sentAt
              ).format("MMM DD yyyy, HH:mm")}`}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
