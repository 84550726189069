import React, { useCallback, useMemo, useState } from "react";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const MAX_IN_BETWEEN_PAGES_COUNT = 8;

export const Paginator = (props: PaginatorProps) => {
  if (props.pagesCount <= 0) {
    throw new Error("Invalid pages count, expected at least 1 page.");
  }

  const [currentPage, setPage] = useState(props.initialPage ?? 1);

  const pagesGridItems = useMemo(() => {
    // Grid contains 12 items at most.
    // At all times we want to show navigation arrows, so that's 2.
    // Always show the first page, that's 3.
    // The rest should be current page + whats around
    // If whats around doesn't include the last page, we need to add it (at the cost of one of the other pages around)
    const pageNumbers: number[] = [1];

    // Start from second page.
    const firstPage = currentPage - MAX_IN_BETWEEN_PAGES_COUNT / 2 - 1;
    for (
      let i = Math.max(firstPage, 2);
      i < props.pagesCount &&
      pageNumbers.length < MAX_IN_BETWEEN_PAGES_COUNT + 1;
      i++
    ) {
      pageNumbers.push(i);
    }

    if (pageNumbers[pageNumbers.length - 1] !== props.pagesCount) {
      pageNumbers.push(props.pagesCount);
    } else {
      if (pageNumbers[1] !== undefined && pageNumbers[1] !== 2) {
        pageNumbers.shift();
        pageNumbers.unshift(1, pageNumbers[1] - 1);
      }
    }

    return pageNumbers.map(pageNumber => (
      <Grid item>
        <IconButton
          color={pageNumber === currentPage ? "primary" : "default"}
          onClick={() => {
            props.onPageSelected?.(pageNumber);
            setPage(pageNumber);
          }}
        >
          <Typography>{pageNumber}</Typography>
        </IconButton>
      </Grid>
    ));
  }, [currentPage, props]);

  const onNextPageClick = useCallback(() => {
    setPage(currentPage => {
      const nextPage = Math.min(currentPage + 1, props.pagesCount);
      props.onPageSelected?.(nextPage);
      return nextPage;
    });
  }, [props]);

  const onPreviousPageClick = useCallback(() => {
    setPage(currentPage => {
      const previousPage = Math.max(currentPage - 1, 0);
      props.onPageSelected?.(previousPage);
      return previousPage;
    });
  }, [props]);

  return (
    <Grid container direction={"column"} spacing={1} style={{height: '100%'}}>
      <Grid item style={{ maxHeight: "calc(100% - 64px)" }}>
        {props.renderPage(currentPage)}
      </Grid>
      <Grid item container direction={"row"} spacing={1} justify={"center"}>
        {currentPage !== 1 ? (
          <Grid item>
            <Tooltip title="Previous page">
              <IconButton onClick={onPreviousPageClick}>
                <ChevronLeftIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        ) : (
          <></>
        )}
        {pagesGridItems}
        {currentPage !== props.pagesCount ? (
          <Grid item>
            <Tooltip title="Next page">
              <IconButton onClick={onNextPageClick}>
                <ChevronRightIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

export interface PaginatorProps {
  pagesCount: number;
  onPageSelected?: (pageNumber: number) => void;
  initialPage?: number;
  renderPage: (pageNumber: number) => JSX.Element;
}
