import React, { useMemo } from "react";
import { Button, Grid, MuiThemeProvider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useThemeOverride } from "../../../utils/styles/mergeThemeOverride";
import useTheme from "@material-ui/core/styles/useTheme";

interface SelectedEventPopoverContentProps {
  onEditClicked: () => void;
  onDeleteClicked: () => void;
}

const actionButtonStyle: React.CSSProperties = {
  textTransform: "none",
  width: "100%"
};

export const SelectedEventPopoverContent = ({
  onEditClicked,
  onDeleteClicked
}: SelectedEventPopoverContentProps) => {
  const theme = useTheme();
  const actions = useMemo(
    () => [
      {
        icon: <EditIcon />,
        text: "Edit Event",
        onPress: onEditClicked,
        themeCMSColor: "update"
      },
      {
        icon: <DeleteForeverIcon />,
        text: "Delete Event",
        onPress: onDeleteClicked,
        themeCMSColor: "remove"
      }
    ],
    [onDeleteClicked, onEditClicked]
  );
  const buttonTheme = useThemeOverride(theme => ({
    MuiButton: {
      label: {
        justifyContent: "flex-start"
      }
    }
  }));
  return (
    <>
      <MuiThemeProvider theme={buttonTheme}>
        <Grid container direction="column" spacing={1} alignItems="flex-start">
          {actions.map(action => (
            <Grid item style={{ width: "100%" }}>
              <Button
                startIcon={action.icon}
                style={{
                  ...actionButtonStyle,
                  color: (theme.contentManagement as any)?.[
                    action.themeCMSColor
                  ]?.color
                }}
                onClick={action.onPress}
              >
                <Typography>{action.text}</Typography>
              </Button>
            </Grid>
          ))}
        </Grid>
      </MuiThemeProvider>
    </>
  );
};
