import { useCallback, useState } from "react";
import { ConversationEntry } from "../../../repository/use-case/users-voice/dto/ListConversationsResponse";
import { useRepositories } from "../../../hooks/useRepositories";
import { SendConversationMessageResponse } from "../../../repository/use-case/users-voice/dto/SendConversationMessageResponse";
import { LCE } from "../../../architecture/lce/lce";

export function useSendConversationReply(
  selectedConversation: ConversationEntry | undefined
) {
  const { usersVoice } = useRepositories();
  const [sendReplyLCE, setSendReplyLCE] = useState<
    LCE<SendConversationMessageResponse>
  >({ isLoading: false });
  const onConversationReply = useCallback(
    (content: string) => {
      if (!selectedConversation) {
        setSendReplyLCE({
          isLoading: false,
          error: new Error("No selected conversation")
        });
        return;
      }

      const { id, user } = selectedConversation;

      if (!user?.id) {
        setSendReplyLCE({
          isLoading: false,
          error: new Error("No selected user")
        });
        return;
      }

      setSendReplyLCE({ isLoading: true });
      usersVoice
        .sendConversationMessage({
          conversationId: id,
          content,
          userId: user?.id
        })
        .then(response =>
          setSendReplyLCE({ isLoading: false, content: response })
        )
        .catch(error => {
          setSendReplyLCE({
            isLoading: false,
            error
          });
        });
    },
    [selectedConversation, usersVoice]
  );

  return {
    sendReplyLCE,
    onConversationReply
  };
}
