import { Popover } from "@material-ui/core";
import React, { useCallback, useEffect } from "react";
import { SelectedEventPopoverContent } from "./SelectedEventPopoverContent";

interface SelectedEventPopoverProps {
  registerEventSelectionCallback: (
    callback: (element: React.MouseEvent<HTMLButtonElement>) => void
  ) => void;
  onEditClicked: () => void;
  onDeleteClicked: () => void;
}

export const SelectedEventPopover = ({
  registerEventSelectionCallback,
  onEditClicked,
  onDeleteClicked
}: SelectedEventPopoverProps) => {
  const [
    anchorElement,
    setAnchorElement
  ] = React.useState<HTMLButtonElement | null>(null);
  const onEventSelected = useCallback(
    (element: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorElement(element.currentTarget);
    },
    []
  );
  useEffect(() => {
    registerEventSelectionCallback(onEventSelected);
  }, [onEventSelected, registerEventSelectionCallback]);
  const onPopoverClose = useCallback(() => {
    setAnchorElement(null);
  }, []);

  const wrapWithClose = useCallback(
    (functionToWrap: Function) => (...args: any[]) => {
      setAnchorElement(null);
      functionToWrap(args);
    },
    []
  );

  return (
    <Popover
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={onPopoverClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
    >
      <SelectedEventPopoverContent
        onEditClicked={wrapWithClose(onEditClicked)}
        onDeleteClicked={wrapWithClose(onDeleteClicked)}
      />
    </Popover>
  );
};
