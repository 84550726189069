export default function distinct<T>(
  arr: T[],
  selector?: (elem: T) => any
): T[] {
  const selected = new Set();
  const distinct: T[] = [];
  arr.forEach(curElem => {
    const key = (selector && selector(curElem)) || curElem;
    if (!selected.has(key)) {
      distinct.push(curElem);
      selected.add(key);
    }
  });
  return distinct;
}
