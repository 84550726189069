import { CMSRoutes } from "../../constants/routes/cms-routes";
import { Redirect, Route, Switch, useHistory } from "react-router";
import { Logo } from "../logo/Logo";
import React, { useEffect, useMemo } from "react";
import LoginPageHOC from "../../pages/login/LoginPageHOC";
import PrivateRoute from "./PrivateRoute";
import AuthApi from "../../api/auth/AuthApi";
import QuotesPage from "../../pages/cms/quotes/QuotesPage";
import AuthorsPage from "../../pages/cms/authors/AuthorsPage";
import CategoriesPage from "../../pages/cms/categories/CategoriesPage";
import PublishPage from "../../pages/cms/publish/PublishPage";
import MediaPlatformPage from "../../pages/cms/media-platforms/MediaPlatformsPage";
import { VideoFeedPage } from "../../pages/cms/videos/VideoFeedPage";
import { PodcastFeedPage } from "../../pages/cms/podcasts/PodcastFeedPage";
import { BooksFeedPage } from "../../pages/cms/books/BooksFeedPage";
import { TasksPage } from "../../pages/cms/tasks/TasksPage";
import { WeeklyTipsPage } from "../../pages/cms/weekly-tips/WeeklyTipsPage";
import { MainRoutes } from "../../constants/routes/main-routes";
import { PortalPage } from "../../pages/portal/PortalPage";
import DashboardPage from "../../pages/cms/dashboard/DashboardPage";
import { CommunityRoutes } from "../../constants/routes/community-routes";
import { PollsPage } from "../../pages/community/polls/PollsPage";
import { CommunityDashboardPage } from "../../pages/community/dashboard/CommunityDashboardPage";
import { CalendarPage } from "../../pages/calendar/CalendarPage";
import { UsersVoicePage } from "../../presentation/community/users-voice/UsersVoicePage";
import { DeveloperConsoleRoutes } from "../../constants/routes/developer-console-routes";
import { DeveloperConsoleLogsPage } from "../../presentation/developer-console/logs/DeveloperConsoleLogs";
import { QuotesplorerPage } from "../../pages/quotesplorer2/QuotesplorerPageV2";
import { DeveloperDiagnosticsPage } from "../../presentation/developer-console/diagnostics/view/DeveloperDiagnosticsPage";
import { PermissionManagementScreen } from "../../presentation/permissions/PermissionManagementScreen";
import { AnnouncementsPage } from "../../presentation/community/announcements/AnnouncementsPage";
import { FileManagerPage } from "../../presentation/file-manager/FileManagerPage";

interface AppRoutesProps {
  isAuthorized: boolean | undefined;
  authApi: AuthApi;
  onCurrentPathChanged: (pathName: string) => void;
  currentPath: string | undefined;
}

type RoutesTree = { [route: string]: RoutesTree | JSX.Element };

const ROUTES_TREE = {
  [MainRoutes.PORTAL]: <PortalPage />,
  cms: {
    [CMSRoutes.DASHBOARD]: <DashboardPage />,
    [CMSRoutes.CALENDAR]: <CalendarPage />,
    [CMSRoutes.QUOTESPLORER]: <QuotesplorerPage />,
    [CMSRoutes.QUOTES]: <QuotesPage />,
    [CMSRoutes.AUTHORS]: <AuthorsPage />,
    [CMSRoutes.CATEGORIES]: <CategoriesPage />,
    [CMSRoutes.PODCASTS]: <PodcastFeedPage />,
    [CMSRoutes.PUBLISH]: <PublishPage />,
    [CMSRoutes.MEDIA_PLATFORMS]: <MediaPlatformPage />,
    [CMSRoutes.WEEKLY_TIPS]: <WeeklyTipsPage />,
    [CMSRoutes.VIDEOS]: <VideoFeedPage />,
    [CMSRoutes.BOOKS]: <BooksFeedPage />,
    [CMSRoutes.TASKS]: <TasksPage />,
    [CMSRoutes.FILE_MANAGER]: <FileManagerPage />
  },
  community: {
    [CommunityRoutes.DASHBOARD]: <CommunityDashboardPage />,
    [CommunityRoutes.POLLS]: <PollsPage />,
    [CommunityRoutes.USERS_VOICE]: <UsersVoicePage />,
    [CommunityRoutes.ANNOUNCEMENTS]: <AnnouncementsPage />
  },
  "developer-console": {
    [DeveloperConsoleRoutes.DASHBOARD]: <DeveloperConsoleLogsPage />,
    [DeveloperConsoleRoutes.LOGS]: <DeveloperConsoleLogsPage />,
    [DeveloperConsoleRoutes.DIAGNOSTICS]: <DeveloperDiagnosticsPage />,
    [DeveloperConsoleRoutes.PERMISSIONS]: <PermissionManagementScreen />
  }
};

export function getPath(
  route: MainRoutes | CMSRoutes | CommunityRoutes | DeveloperConsoleRoutes
): string | undefined {
  return getPathRecursive(route, "", ROUTES_TREE);
}

function getPathRecursive(
  route: string,
  currentPath: string,
  currentTree: Record<string, any>
): string | undefined {
  for (const currentRoute in currentTree) {
    if (currentRoute === route) {
      return currentPath + "/" + currentRoute;
    }
    if (typeof currentTree[currentRoute] === "object") {
      const resultFromChild = getPathRecursive(
        route,
        currentPath + "/" + currentRoute,
        currentTree[currentRoute]
      );
      if (resultFromChild) {
        return resultFromChild;
      }
    }
  }
}

const buildPaths = (
  routes: RoutesTree,
  parent: string
): { path: string; element: JSX.Element }[] => {
  return Object.keys(routes).reduce((routeJSXes, path) => {
    const nextPathOrJSX = routes[path];
    const nextPath = `${parent}${path}`;
    if (React.isValidElement(nextPathOrJSX)) {
      routeJSXes.push({
        path: nextPath,
        element: nextPathOrJSX
      });
    } else {
      routeJSXes.push(
        ...buildPaths(nextPathOrJSX as RoutesTree, `${nextPath}/`)
      );
    }
    return routeJSXes;
  }, [] as { path: string; element: JSX.Element }[]);
};

const AppRoutes = function(props: AppRoutesProps) {
  const history = useHistory();

  useEffect(() => {
    props.onCurrentPathChanged(history.location.pathname);
    return history.listen(location => {
      props.onCurrentPathChanged(location.pathname);
    });
  }, [history, props]);

  useEffect(() => {
    if (props.currentPath && history.location.pathname !== props.currentPath) {
      history.push(props.currentPath);
    }
  }, [history, props.currentPath]);

  const paths = useMemo(() => buildPaths(ROUTES_TREE, "/"), []);

  return (
    <Switch>
      <Route path={`/${MainRoutes.LOGIN}`}>
        <div className="App-header-logo-image">
          <Logo />
        </div>
        <LoginPageHOC authApi={props.authApi} />
      </Route>
      {paths.map(({ path, element }) => (
        <PrivateRoute path={path} isAuthorized={props.isAuthorized}>
          {element}
        </PrivateRoute>
      ))}
      <Route path={MainRoutes.ANY}>
        <Redirect to={`/${MainRoutes.PORTAL}`} />
      </Route>
    </Switch>
  );
};

export default React.memo(AppRoutes);
