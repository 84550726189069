export function indexBy<K, V>(
  array: V[],
  keySelector: (value: V) => K
): Map<K, V> {
  const map = new Map<K, V>();
  array.forEach(element => {
    map.set(keySelector(element), element);
  });
  return map;
}
