import ContentCardViewModel from "../../../components/content/card/ContentCardViewModel";
import {
  extractFirstContentField,
  extractFirstLocalizedProp
} from "../../../utils/data/resources/managed";
import * as React from "react";
import { Grid } from "@material-ui/core";
import ContentCard from "../../../components/content/card/ContentCard";
import WarningWrapper from "../../../components/warning/WarningWrapper";
import Typography from "@material-ui/core/Typography";
import { Locale } from "../../../locale";
import ManagedCategory from "../../../data/ManagedCategory";
import ManagedContent from "../../../data/ManagedContent";
import Avatar from "@material-ui/core/Avatar";
import { CategoryFields } from "../../../api/content/Category";

const avatarStyle = { width: "auto", height: 350, borderRadius: "1%" };

const CategoryContent = ({
  managedContent: {
    managedResource: category,
    contentActions,
    modificationType,
    ownerTimeline
  },
  locale,
  quotesCount
}: {
  managedContent: ManagedContent<ManagedCategory>;
  locale: Locale;
  quotesCount: number;
}) => {
  return (
    <Grid item xs={3}>
      <ContentCard
        viewModel={
          new ContentCardViewModel(
            (
              <Avatar
                style={avatarStyle}
                src={extractFirstContentField(
                  category,
                  CategoryFields.IMAGE_URL
                )}
                alt={"category image"}
              />
            ),
            (
              <WarningWrapper
                message="Missing Translation"
                show={
                  !extractFirstLocalizedProp(
                    locale,
                    category,
                    CategoryFields.NAME
                  ).isLocalized
                }
              >
                <Typography variant="h5">
                  {`${
                    extractFirstLocalizedProp(
                      locale,
                      category,
                      CategoryFields.NAME
                    ).value
                  } (${quotesCount})`}
                </Typography>
              </WarningWrapper>
            ),
            contentActions,
            modificationType,
            ownerTimeline
          )
        }
      />
    </Grid>
  );
};

export default CategoryContent;
