import React from "react";
import { HeaderViewModel } from "./HeaderViewModel";
import Typography from "@material-ui/core/Typography";
import { GridSize, MuiThemeProvider } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import useStyles from "./styles";
import IconButton from "@material-ui/core/IconButton";
import Brightness2Icon from "@material-ui/icons/Brightness2";
import BrightnessHighIcon from "@material-ui/icons/BrightnessHigh";
import Grid from "@material-ui/core/Grid";
import themes from "./Themes";
import LocaleAutoComplete from "./LocaleAutoComplete";
import { useRepositories } from "../../hooks/useRepositories";
import { useObservableLCE2 } from "../../hooks/useObservableLCE2";
import { PersonalActivityIndicator } from "./activity-indicator/PersonalActivityIndicator";

interface HeaderProps {
  viewModel: HeaderViewModel;
  onDrawerToggle: () => void;
  onBrightnessToggle: () => void;
  onLocaleSelected: (code: string) => void;
}

export const Header = (props: HeaderProps) => {
  const headerSections: JSX.Element[] = [];

  const classes = useStyles();

  headerSections.push(
    <Grid
      container
      alignItems="center"
      justify="flex-start"
      className={classes.logo}
    >
      <Grid item>
        <Typography variant="h5">Legendary Quotes</Typography>
      </Grid>
      <Grid item>
        <Typography variant={"h5"} className={classes.bo}>
          BO
        </Typography>
      </Grid>
    </Grid>
  );

  const { globalUserTasks } = useRepositories();
  const userTasksSummaryLCE = useObservableLCE2(globalUserTasks.tasksSummary());

  headerSections.push(
    <Grid
      container
      className={classes.user}
      alignItems="center"
      justify="flex-end"
      spacing={1}
    >
      {userTasksSummaryLCE.content ? (
        <Grid item>
          <PersonalActivityIndicator
            userTasksSummary={{
              activeCount: userTasksSummaryLCE.content.activeCount,
              totalCount: userTasksSummaryLCE.content.totalCount,
              progress: userTasksSummaryLCE.content.progress
            }}
          />
        </Grid>
      ) : (
        <></>
      )}
      {props.viewModel.user && (
        <Grid item>
          <LocaleAutoComplete
            selectedLocale={props.viewModel.selectedLocale}
            localeInfo={props.viewModel.locales}
            onLocaleSelected={props.onLocaleSelected}
          />
        </Grid>
      )}
      <Grid item>
        <MuiThemeProvider theme={themes.brightnessToggleButtonTheme}>
          <IconButton onClick={props.onBrightnessToggle}>
            {props.viewModel.isDarkTheme ? (
              <BrightnessHighIcon />
            ) : (
              <Brightness2Icon />
            )}
          </IconButton>
        </MuiThemeProvider>
      </Grid>
      {props.viewModel.user && (
        <Grid item>
          <Avatar src={props.viewModel.user.imageUrl || ""} />
        </Grid>
      )}
      {props.viewModel.user && (
        <Grid item>
          <Typography className={classes.displayName}>
            {props.viewModel.user.name}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Divider orientation="vertical" className={classes.divider} />
      </Grid>
      <Grid item>
        <MuiThemeProvider theme={themes.signOutButtonTheme}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={props.viewModel.signOut}
          >
            Sign Out
          </Button>
        </MuiThemeProvider>
      </Grid>
    </Grid>
  );

  return (
    <header>
      <Grid container className={classes.root}>
        {headerSections.map(section => (
          <Grid item xs={(12 / headerSections.length) as GridSize}>
            {section}
          </Grid>
        ))}
      </Grid>
    </header>
  );
};
