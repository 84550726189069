import { useCallback } from "react";
import { Theme } from "@material-ui/core";
import { Overrides } from "@material-ui/core/styles/overrides";

type ThemeOverride = Overrides | ((theme: Theme) => Overrides);

export function useThemeOverride(override: ThemeOverride) {
  return useCallback(
    (theme: Theme) => {
      return {
        ...theme,
        overrides: {
          ...theme.overrides,
          ...spreadTheme(
            theme.overrides,
            typeof override === "function" ? override(theme) : override
          )
        }
      };
    },
    [override]
  );
}

function spreadTheme(
  themeChild: Overrides | undefined,
  overrideChild: Overrides | undefined
): any {
  if (!overrideChild || typeof overrideChild !== "object") {
    return {};
  }
  return {
    ...Object.keys(overrideChild).reduce((acc, key) => {
      const themeChildValue = (themeChild as any)?.[key];
      const overrideChildElement = (overrideChild as any)[key];
      if (overrideChildElement === null || overrideChildElement === undefined) {
        return acc;
      }
      if (typeof overrideChildElement === "object") {
        return {
          ...acc,
          [key]: {
            ...themeChildValue,
            ...(typeof overrideChildElement === "object" &&
              overrideChildElement),
            ...spreadTheme(
              (themeChild as any)?.[key],
              (overrideChild as any)[key]
            )
          }
        };
      }
      return {
        ...acc,
        [key]: overrideChildElement
      };
    }, {})
  };
}
