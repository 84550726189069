import * as React from "react";
import { IconButton, MuiThemeProvider, Tooltip } from "@material-ui/core";
import { useThemeOverride } from "../../utils/styles/mergeThemeOverride";

export interface IconButtonProps {
  enabled?: boolean;
  onClick: () => void;
  icon: JSX.Element;
  color?: string;
  description?: string;
}

export const DescriptiveIconButton = (props: IconButtonProps) => {
  const actionButton = (
    <IconButton
      onClick={props.onClick}
      size="small"
      disabled={props.enabled === false}
    >
      {props.icon}
    </IconButton>
  );
  const theme = useThemeOverride(theme => ({
    ...(props.color && {
      MuiIconButton: {
        label: {
          color: props.color
        }
      }
    }),
    ...(props.enabled === false && {
      MuiSvgIcon: {
        root: {
          fill: theme.state?.disabled
        }
      }
    })
  }));
  return (
    <MuiThemeProvider theme={theme}>
      {props.description ? (
        <Tooltip title={props.description}>{actionButton}</Tooltip>
      ) : (
        actionButton
      )}
    </MuiThemeProvider>
  );
};
