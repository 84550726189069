import { Observable } from "rxjs";
import * as firebase from "firebase";
import { filter } from "rxjs/operators";

export function asObservable<T>(
  snapshotListener: (
    onNext: (next: T) => any,
    onError: (error: any) => any,
    onCompletion: () => any
  ) => Function
) {
  return new Observable<T>(subscriber =>
    snapshotListener(
      next => subscriber.next(next),
      error => subscriber.error(error),
      () => subscriber.complete()
    )
  );
}

export function collectionAsObservable<T>(
  collectionOrQuery:
    | firebase.firestore.CollectionReference
    | firebase.firestore.Query,
  snapshotMapper: (snapshot: firebase.firestore.QuerySnapshot) => T,
  filters: ((data: T) => boolean)[] = DEFAULT_FILTERS
): Observable<T> {
  return asObservable<T>((onNext, onError, onCompletion) =>
    collectionOrQuery.onSnapshot(
      next => onNext(snapshotMapper(next)),
      error => onError(error),
      () => onCompletion()
    )
  ).pipe(
    filter(data => !filters.some(filterFunction => !filterFunction(data)))
  );
}

export function documentAsObservable<T>(
  document: firebase.firestore.DocumentReference,
  snapshotMapper: (snapshot: firebase.firestore.DocumentSnapshot) => T
): Observable<T> {
  return asObservable((onNext, onError, onCompletion) =>
    document.onSnapshot(
      next => onNext(snapshotMapper(next)),
      error => onError(error),
      () => onCompletion()
    )
  );
}

const DEFAULT_FILTERS = [
  (data: any) => !data.archived
];
