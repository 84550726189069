import DestructiveAlertDialog from "../../../components/dialogs/DestructiveAlertDialog";
import { LocalViewModelFactory } from "../../../architecture/view-model/LocalViewModelFactory";
import {
  FileManagerFileListItem,
  FileManagerViewModelBase
} from "../FileManagerViewModelBase";
import { useLocalViewModel } from "../../../architecture/view-model/useViewModel";
import React from "react";

export interface DeleteFileDialogProps {
  viewModelFactory: LocalViewModelFactory<FileManagerViewModelBase>;
}

export const DeleteFileDialog = (props: DeleteFileDialogProps) => {
  const [viewState, viewModel] = useLocalViewModel(props.viewModelFactory);
  const payload: FileManagerFileListItem | undefined =
    viewState.pendingAction?.payload;
  if (!payload) {
    return <></>;
  }
  const message =
    payload?.type === "directory"
      ? "Are you sure you want to delete this directory? All files inside and their sub-directories will be deleted. This cannot be undone."
      : "Are you sure you want to delete this file? This cannot be undone.";
  return (
    <DestructiveAlertDialog
      isOpen={viewState.pendingAction?.type === "delete_file"}
      title={"Delete file"}
      message={message}
      destructiveButtonText={"Delete"}
      handleClose={viewModel.onClickCancelPendingAction}
      handleConfirm={() => viewModel.onConfirmDeleteFile(payload)}
      isLoading={!!viewState.pendingAction?.isLoading}
    />
  );
};
