export enum CMSRoutes {
  DASHBOARD = "dashboard",
  CALENDAR = "calendar",
  QUOTES = "quotes",
  AUTHORS = "authors",
  CATEGORIES = "categories",
  WEEKLY_TIPS = "weekly-tips",
  PODCASTS = "podcasts",
  VIDEOS = "videos",
  BOOKS = "books",
  MEDIA_PLATFORMS = "media-platforms",
  PUBLISH = "publish",
  TASKS = "tasks",
  QUOTESPLORER = "quotesplorer",
  FILE_MANAGER = "file-manager"
}
