import React, { useState } from "react";
import { Tab, AppBar, Tabs } from "@material-ui/core";
import { Motion, spring } from "react-motion";
import { ActivePolls } from "../../../components/polls/ActivePolls";
import { PastPolls } from "../../../components/polls/PastPolls";
import Grid from "@material-ui/core/Grid";
import { InactivePollsList } from "../../../components/polls/InactivePollsList";
import Toolbar from "@material-ui/core/Toolbar";

enum PollTabs {
  ACTIVE,
  PAST
}

export const PollsPage = () => {
  const [selectedTab, setSelectedTab] = useState(PollTabs.ACTIVE);

  const tabsMap = {
    [PollTabs.ACTIVE]: <ActivePolls />,
    [PollTabs.PAST]: <PastPolls />
  };

  return (
    <div>
      <AppBar position="static">
        <Tabs
          value={selectedTab}
          onChange={(_, value) => setSelectedTab(value as PollTabs)}
          centered
        >
          <Tab label="Active Polls" value={PollTabs.ACTIVE} />
          <Tab label="Past Polls" value={PollTabs.PAST} />
        </Tabs>
      </AppBar>
      {
        <Motion
          key={selectedTab}
          defaultStyle={{ opacity: 0 }}
          style={{
            opacity: spring(1)
          }}
        >
          {interpolatedStyle => (
            <div style={interpolatedStyle}>{tabsMap[selectedTab]}</div>
          )}
        </Motion>
      }
    </div>
  );
};
