import CardContent from "@material-ui/core/CardContent";
import { Card, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";

export const PortalDestination = ({
  name,
  description,
  onClick,
}: {
  name: string;
  description: string;
  onClick: Function;
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.card} onClick={() => onClick()}>
      <CardContent>
        <>
          <Typography variant="h5" align={"center"}>{name}</Typography>
          <Typography className={classes.description}>{description}</Typography>
        </>
      </CardContent>
    </Card>
  );
};
