import { ContentActionViewModel } from "./ContentActionViewModel";
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import ContentActionButton from "./ContentActionButton";

export type ContentActionType = "update" | "remove" | "restore";

export interface ContentAction {
  description?: string;
  icon?: any;
  onClicked?: () => void;
  actionType?: ContentActionType;
  enabled?: boolean;
}

interface ContentActionProps {
  viewModel: ContentActionViewModel;
}

const ContentActions = ({ viewModel }: ContentActionProps) => (
  <Grid container>
    {viewModel.getActions().map((contentAction, index) => (
      <Grid item key={index}>
        <ContentActionButton contentAction={contentAction} />
      </Grid>
    ))}
  </Grid>
);

export default ContentActions;
