import { filterValues, toArray, transformValues } from "../../../utils/maps";
import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import { CommitResource } from "../../../api/commits/Commit";
import ManagedContent from "../../../data/ManagedContent";
import { Locale } from "../../../locale";
import IndexedResource from "../../../data/IndexedResource";
import { getContentHeadersForResource } from "../../../utils/data/resources/getContentHeadersForResource";
import { useStyles } from "./styles";
import ContentStatisticsPanel from "./ContentStatisticsPanel";
import { pipe } from "rxjs";

const emptyTranslations = {};

const ContentStatisticsGrid = ({
  resourcesToIndexedManagedContent,
  commitStatisticsByResource,
  defaultLocale,
  commitSelection
}: {
  resourcesToIndexedManagedContent: Map<
    CommitResource,
    Map<Locale, ManagedContent<IndexedResource>>[]
  >;
  commitStatisticsByResource: Map<
    CommitResource,
    {
      added: number;
      changed: number;
      removed: number;
      archived: number;
      unarchived: number;
      newTranslations: {
        [locale: string]: { countryCode: string; total: number };
      };
    }
  >;
  defaultLocale: Locale | undefined;
  commitSelection: {
    enabled: boolean;
    selectedCommits: Map<string, { resourceName: string; locale: string }>;
    onChange: (
      resourceName: string,
      locale: string,
      commitId: string,
      isSelected: boolean
    ) => void;
  };
}) => {
  const classes = useStyles();
  const contentChanges = React.useMemo(
    () =>
      pipe(
        (
          resourcesToIndexedManagedContent: Map<
            CommitResource,
            Map<Locale, ManagedContent<IndexedResource>>[]
          >
        ) =>
          transformValues(
            resourcesToIndexedManagedContent,
            localeToManagedContent =>
              localeToManagedContent
                .map(idToContentMap =>
                  filterValues(
                    idToContentMap,
                    diffData => diffData.modificationType !== undefined
                  )
                )
                .filter(idToContentMap => idToContentMap.size > 0)
          ),
        (
          resourcesToIndexedManagedContent: Map<
            CommitResource,
            Map<Locale, ManagedContent<IndexedResource>>[]
          >
        ) =>
          filterValues(
            resourcesToIndexedManagedContent,
            value => value.length > 0
          ),
        (
          resourcesToIndexedManagedContent: Map<
            CommitResource,
            Map<Locale, ManagedContent<IndexedResource>>[]
          >
        ) =>
          toArray(
            resourcesToIndexedManagedContent,
            (localeToManagedContent, resourceType, index) => (
              <ContentStatisticsPanel
                resourceName={resourceType}
                content={localeToManagedContent}
                totalAdded={
                  commitStatisticsByResource.get(resourceType)?.added || 0
                }
                totalChanged={
                  commitStatisticsByResource.get(resourceType)?.changed || 0
                }
                totalRemoved={
                  commitStatisticsByResource.get(resourceType)?.removed || 0
                }
                totalArchived={
                  commitStatisticsByResource.get(resourceType)?.archived || 0
                }
                totalUnarchived={
                  commitStatisticsByResource.get(resourceType)?.unarchived || 0
                }
                totalNewLocales={
                  commitStatisticsByResource.get(resourceType)
                    ?.newTranslations || emptyTranslations
                }
                headers={getContentHeadersForResource(resourceType)}
                defaultLocale={defaultLocale}
                withMargins={index > 0}
                commitSelection={commitSelection}
              />
            )
          )
      )(resourcesToIndexedManagedContent),
    [
      commitSelection,
      commitStatisticsByResource,
      defaultLocale,
      resourcesToIndexedManagedContent
    ]
  );
  return (
    <Box className={classes.statisticsItemContainer}>
      {contentChanges.length === 0 ? (
        <Typography variant="h5">Nothing to publish.</Typography>
      ) : (
        contentChanges
      )}
    </Box>
  );
};

export default React.memo(ContentStatisticsGrid);
