import UserApi from "../UserApi";
import User from "../../auth/User";
import { FirebaseData } from "../../firebase/data/FirebaseData";
import { Observable } from "rxjs";

export default class FirebaseUserApi implements UserApi {
  constructor(private readonly firebaseData: FirebaseData) {}

  updateUserInfo: (user: User) => Promise<void> = user =>
    this.firebaseData.updateUserInfo(user);
  getUsers: () => Promise<Map<string, User>> = () =>
    this.firebaseData.getUsers();

  getUsersObservable(): Observable<Map<string, User>> {
    return this.firebaseData.getUsersObservable();
  }

}
