import { FirebaseFunctions } from "../../../api/firebase/functions/FirebaseFunctions";
import { Observable } from "rxjs";
import { FirebaseData } from "../../../api/firebase/data/FirebaseData";
import { DiagnosticJob } from "./DiagnosticJob";
import { ContentDiscrepanciesJobReport } from "./ContentDiscrepanciesJobReport";

const FUNCTION_NAME_LIST_LOGS = "listLogs";
const FUNCTION_NAME_LOAD_REPORT = "loadContentDiscrepanciesReport";
const FUNCTION_FIND_CONTENT_DISCREPANCIES = "findContentDiscrepancies";
const FUNCTION_NAME_COMMIT_CONTENT_DISCREPANCIES = "commitContentDiscrepancies";

export interface FindContentDiscrepanciesRequest {
  resources: {
    list: string[];
  };
}

export type FindContentDiscrepanciesResponse = void;

export interface CommitContentDiscrepanciesRequest {
  selectedResources: {
    [resourceName: string]: {
      list: string[];
    };
  };
}

export type CommitContentDiscrepanciesResponse = void;

export class DeveloperConsoleRepository {
  constructor(
    private readonly firebaseFunctions: FirebaseFunctions,
    private readonly firebaseData: FirebaseData
  ) {}

  listLogs(request: any): Promise<any> {
    return this.firebaseFunctions.call(FUNCTION_NAME_LIST_LOGS, request);
  }

  findContentDiscrepancies(
    request: FindContentDiscrepanciesRequest
  ): Promise<FindContentDiscrepanciesResponse> {
    return this.firebaseFunctions.call(
      FUNCTION_FIND_CONTENT_DISCREPANCIES,
      request
    );
  }

  get diagnosticsJobsStatuses(): Observable<DiagnosticJob[]> {
    return this.firebaseData.getDiagnosticsJobsObservable();
  }

  loadContentDiscrepanciesJobReport(): Promise<ContentDiscrepanciesJobReport> {
    return this.firebaseFunctions.call(FUNCTION_NAME_LOAD_REPORT, {});
  }

  commitContentDiscrepancies(
    request: CommitContentDiscrepanciesRequest
  ): Promise<CommitContentDiscrepanciesResponse> {
    return this.firebaseFunctions.call(
      FUNCTION_NAME_COMMIT_CONTENT_DISCREPANCIES,
      request
    );
  }
}
