import { CreatableEventEntryType } from "./CreatableEventEntryType";
import { CreatableEventEntryInfo } from "./CreatableEventEntryInfo";
import { FirebaseFunctions } from "../../../api/firebase/functions/FirebaseFunctions";
import { CalendarEvent } from "./CalendarEvent";
import { Locale } from "../../../locale";

const LIST_CREATABLE_EVENT_ENTRIES_FUNCTION_NAME = "listCreatableEventEntries";
const LIST_EVENTS_FUNCTION_NAME = "listCalendarEvents";
const CREATE_NEW_EVENT_FUNCTION_NAME = "createNewCalendarEvent";
const EDIT_EVENT_FUNCTION_NAME = "editCalendarEvent";
const DELETE_EVENT_FUNCTION_NAME = "deleteCalendarEvent";

interface EventData {
  startTime: string;
  endTime: string;
  eventType: CreatableEventEntryType;
  entryId: string;
  title: string;
  locale: Locale;
  color: string;
}

export class CalendarRepository {
  constructor(private readonly firebaseFunctions: FirebaseFunctions) {}

  listCreatableEventEntries = async ({
    eventType
  }: {
    eventType: CreatableEventEntryType;
  }): Promise<CreatableEventEntryInfo[]> => {
    const { creatableEventEntries } = await this.firebaseFunctions.call(
      LIST_CREATABLE_EVENT_ENTRIES_FUNCTION_NAME,
      {
        eventType
      }
    );
    return creatableEventEntries;
  };

  listCalendarEvents = async ({
    startTime,
    endTime,
    locale
  }: {
    startTime: string;
    endTime: string;
    locale: Locale;
  }): Promise<CalendarEvent[]> => {
    const { events } = await this.firebaseFunctions.call(
      LIST_EVENTS_FUNCTION_NAME,
      {
        startTime,
        endTime,
        locale
      }
    );
    return events;
  };

  createNewEvent = async (eventData: EventData): Promise<CalendarEvent> => {
    const { newEvent } = await this.firebaseFunctions.call(
      CREATE_NEW_EVENT_FUNCTION_NAME,
      eventData
    );
    return newEvent;
  };

  editEvent = async (
    eventId: string,
    eventData: EventData
  ): Promise<CalendarEvent> => {
    const { updatedEvent } = await this.firebaseFunctions.call(
      EDIT_EVENT_FUNCTION_NAME,
      {
        eventId,
        updatedData: eventData
      }
    );
    return updatedEvent;
  };

  deleteEvent = async ({ eventId }: { eventId: string }) => {
    await this.firebaseFunctions.call(DELETE_EVENT_FUNCTION_NAME, {
      eventId
    });
  };
}
