import { Commit } from "../../api/commits/Commit";
import User from "../../api/auth/User";
import OwnerTimelineEntry from "../OwnerTimelineEntry";
import distinct from "../../utils/arrays/distinct";
import { CommitType } from "../../api/commits/CommitType";
import moment from "moment";
import { chainSort } from "../../utils/functional/chainSort";

export default function calculateTimeline(resource: string) {
  return (
    commit: Commit,
    owners: Map<string, User>
  ): { [ownerId: string]: OwnerTimelineEntry } =>
    distinct(
      Object.keys(commit.updatedFields)
        .map(
          key =>
            ({
              key,
              ...commit.updatedFields[key]?.[0],
              type: CommitType.UPDATE_FIELD
            } as {
              key?: string;
              type: CommitType;
              ownerId: string;
              updateTime: number;
            })
        )
        .concat({
          type: commit.type,
          ownerId: commit.ownerId,
          updateTime: commit.updateTime
        })
        .concat(
          Object.keys(commit.linkedResources).map(linkedResource => ({
            key: linkedResource,
            type: CommitType.UPDATE_FIELD,
            ownerId: commit.linkedResources[linkedResource]?.[0]?.ownerId || "",
            updateTime: commit.linkedResources[linkedResource]?.[0]?.updateTime
          }))
        )
        .filter(value => !!value.ownerId)
        .filter(value => value.type !== CommitType.UPDATE_FIELD || value.key)
        .filter(value => value.updateTime),
      value => ({
        updateTime: value.updateTime,
        type: value.type
      })
    )
      .sort(
        chainSort(
          (field1, field2) => field1.updateTime - field2.updateTime,
          (field1, field2) => field1.type - field2.type
        )
      )
      .reduce(
        (ownerStack, value) => {
          if (
            !ownerStack.length ||
            ownerStack[ownerStack.length - 1].ownerId !== value.ownerId
          ) {
            ownerStack.push({ ownerId: value.ownerId, changes: [value] });
          } else if (
            ownerStack[ownerStack.length - 1].ownerId === value.ownerId
          ) {
            ownerStack[ownerStack.length - 1].changes.push(value);
          }
          return ownerStack;
        },
        [] as {
          ownerId: string;
          changes: {
            key?: string;
            type: CommitType;
            ownerId: string;
            updateTime: number;
          }[];
        }[]
      )
      .reduce((entries, ownerStack) => {
        ownerStack.changes.forEach(change => {
          const owner = owners.get(change.ownerId);
          const updatedEntry = entries[change.ownerId] || { timeline: [] };
          updatedEntry.owner = owner;
          updatedEntry.timeline.push({
            type: change.type,
            timestamp: change.updateTime,
            description: `${owner?.name} has ${typeAsActionString(
              change.type
            )} ${
              change.key ? `field ${change.key}` : `this ${resource}`
            } ${moment(change.updateTime).fromNow()}`
          });
          entries[change.ownerId] = updatedEntry;
        });
        return entries;
      }, {} as { [ownerId: string]: OwnerTimelineEntry });
}

function typeAsActionString(commitType: CommitType) {
  switch (commitType) {
    case CommitType.UPDATE_FIELD:
      return "updated";
    case CommitType.CREATE_ENTRY:
      return "created";
    case CommitType.DELETE_ENTRY:
      return "deleted";
    case CommitType.ARCHIVE_ENTRY:
      return "archived";
    case CommitType.UNARCHIVE_ENTRY:
      return "unarchived";
  }
}
