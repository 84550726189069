import React, { Attributes } from "react";
import DestructiveAlertDialog from "./DestructiveAlertDialog";

interface RevertAlertDialogProps extends Attributes {
  isOpen: boolean;
  revertInfo: string;
  reversible?: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  isLoading: boolean;
}

const RevertAlertDialog = (props: RevertAlertDialogProps) => {
  return (
    <DestructiveAlertDialog
      isLoading={props.isLoading}
      isOpen={props.isOpen}
      title={`Revert ${props.revertInfo}?`}
      message={`Are you sure you want to revert ${props.revertInfo}?${
        !props.reversible ? "\nIt cannot be undone." : ""
      }`}
      destructiveButtonText="Revert"
      handleClose={props.handleClose}
      handleConfirm={props.handleConfirm}
    />
  );
};

export default RevertAlertDialog;
