import { ScrapedQuote } from "../../pages/quotesplorer/ScrappedContent";
import { tokenizeWithoutSymbols } from "../../utils/diff/tokenizer";
import { pascalCase } from "../../utils/strings/casing";
import Grid from "@material-ui/core/Grid";
import { ScrapedContentActions } from "./ScrapedContentActions";
import { ScrapedQuoteParts } from "./ScrapedQuoteParts";
import Typography from "@material-ui/core/Typography";
import { Box, Chip } from "@material-ui/core";
import React from "react";
import { IndexedData } from "./data-indexing";
import { useStyles } from "./styles";
import { ThumbDown, ThumbUp } from "@material-ui/icons";

export const ScrapedContentItem = ({
  data,
  indexedData,
  onAddQuoteClicked,
  onAddAuthorClicked,
  onAddCategoryClicked
}: {
  data: ScrapedQuote;
  indexedData: IndexedData;
  onAddQuoteClicked: (
    quoteText: string,
    author: string,
    categories?: string[]
  ) => void;
  onAddAuthorClicked: (authorName: string) => void;
  onAddCategoryClicked: (categoryName: string) => void;
}) => {
  const { textParts, author, metadata } = data;
  const quoteAsText = textParts
    .map(part => (typeof part === "string" ? part : ""))
    .join(" ");
  const quoteExists = indexedData.quotes.has(
    tokenizeWithoutSymbols(quoteAsText).sentence
  );
  const authorExists = indexedData.authors.has(
    tokenizeWithoutSymbols(author).sentence
  );
  const categories = metadata?.tags?.map(pascalCase)?.map(categoryName => ({
    name: categoryName,
    exists: indexedData.categories.has(
      tokenizeWithoutSymbols(categoryName).sentence
    )
  }));
  const knownCategories = categories
    ?.filter(categoryData => categoryData.exists)
    ?.map(categoryData => categoryData.name);

  const upVotes = metadata?.upVotes ?? 0;
  const downVotes = metadata?.downVotes ?? 0;

  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={1}
      className={classes.scrapedContentItemContainer}
    >
      <Grid item xs={6}>
        <Box
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={1}
            style={{ height: "100%", width: "100%" }}
            wrap={"nowrap"}
          >
            <Grid item>
              <ScrapedContentActions
                isNew={!quoteExists}
                onAddClicked={() =>
                  onAddQuoteClicked(quoteAsText, author, knownCategories)
                }
              />
            </Grid>
            <Grid item>
              <ScrapedQuoteParts textParts={textParts} />
            </Grid>
          </Grid>
          {upVotes || downVotes ? (
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Chip icon={<ThumbUp />} label={upVotes} />
              </Grid>
              <Grid item>
                <Chip icon={<ThumbDown />} label={downVotes} />
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Box>
      </Grid>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        spacing={1}
        xs={2}
      >
        <Grid item>
          <ScrapedContentActions
            isNew={!authorExists}
            onAddClicked={() => onAddAuthorClicked(author)}
          />
        </Grid>
        <Grid item>
          <Typography>{author}</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justify={"center"}
        spacing={1}
        xs={4}
      >
        {!categories?.length ? (
          <Grid item>
            <Typography>N/A</Typography>
          </Grid>
        ) : (
          categories.map(({ name, exists }) => (
            <Grid item>
              <Box className={classes.scrapedContentItemCategory}>
                <ScrapedContentActions
                  isNew={!exists}
                  onAddClicked={() => onAddCategoryClicked(name)}
                />
                <Typography>{name}</Typography>
              </Box>
            </Grid>
          ))
        )}
      </Grid>
    </Grid>
  );
};
