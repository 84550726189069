import { ViewModelKeys } from "../ViewModelFactoryMap";
import { useViewModel } from "../useViewModel";
import { useEffect } from "react";
import { isImplementsNavigationCallback } from "./UpdateNavigationCallback";
import { useNavigationEffectCallback } from "./useNavigationEffectCallback";

export function useViewModelInPageNavigation<
  PageParams extends object,
  K extends ViewModelKeys
>(viewModelKey: K) {
  const [pathname, pageParams, viewModelEffects] = useNavigationEffectCallback<
    PageParams
  >();
  const [, viewModel] = useViewModel(viewModelKey, viewModelEffects);
  useEffect(() => {
    if (isImplementsNavigationCallback<PageParams>(viewModel)) {
      viewModel.onNavigationLocationUpdated(pageParams, pathname);
    }
  }, [pageParams, pathname, viewModel]);
}
