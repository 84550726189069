export default class Cache<K, V> {
  private readonly backingMap: Map<K, V> = new Map();

  constructor(private loader: (key: K) => V) {}

  get(k: K): V {
    let v = this.backingMap.get(k);
    if (!v) {
      v = this.loader(k);
      this.backingMap.set(k, v);
    }
    return v;
  }

  set(key: K, value: V) {
    this.backingMap.set(key, value);
  }

  has(key: K): boolean {
    return this.backingMap.has(key);
  }
}
