import { getCountryCodeForLocale, Locale } from "../../../locale";
import { ContentData } from "../../../repository/use-case/content/ContentData";
import { Commit, CommitResource } from "../../../api/commits/Commit";
import User from "../../../api/auth/User";
import ManagedContent from "../../../data/ManagedContent";
import IndexedResource from "../../../data/IndexedResource";
import distinct from "../../../utils/arrays/distinct";
import { mapOf, transformValues } from "../../../utils/maps";
import managedResourceMappers from "../../../data/mappers/resources/content-mappers";
import mapResourcesToManagedContent from "../../../data/mappers/resources-to-managed-content";
import commonActions from "../../../components/content/actions/common-actions";
import { extractId } from "../../../utils/data/resources/managed";
import PublishPageData from "./PublishPageData";
import ManagedResource from "../../../data/ManagedResource";
import LocalizedResource from "../../../data/LocalizedResource";

export default function mapContentToPublishPageData(
  content: {
    content: Map<Locale, ContentData>;
    commits: Map<Locale, Map<CommitResource, Map<string, Commit>>>;
    users: Map<string, User>;
    defaultLocale: Locale;
  },
  onClicked: (
    managedResource: ManagedResource<any>,
    resourceType: CommitResource,
    actionType: string,
    localeToResource: Map<Locale, ManagedResource<any>>
  ) => void,
  isPublishing: boolean
): PublishPageData {
  const allLocales = distinct(
    Array.from(content.commits.keys()).concat(
      Array.from(content.content.keys())
    )
  );

  const resourcesToIndexedManagedContent = transformValues(
    allLocales.reduce((map, locale) => {
      const resourceToContent = transformValues(
        managedResourceMappers({
          contentData: {
            locale: content.defaultLocale,
            data: content.content.get(content.defaultLocale) || {
              categories: new Map(),
              authors: new Map(),
              quotes: new Map(),
              mediaPlatforms: new Map(),
              podcasts: new Map(),
              videos: new Map(),
              books: new Map(),
              weeklyTips: new Map()
            }
          },
          commits: content.commits.get(locale) || new Map(),
          users: content.users
        }),
        (value, key) =>
          mapResourcesToManagedContent(
            value,
            { normal: commonActions },
            (managedResource, actionType) =>
              onClicked(
                managedResource,
                key,
                actionType,
                mapOf(locale, managedResource)
              ),
            isPublishing
          )
      );
      resourceToContent.forEach((content, resource) => {
        const indexedLocaleToContent =
          map.get(resource) ||
          new Map<
            string,
            Map<Locale, ManagedContent<IndexedResource & LocalizedResource>>
          >();
        content.forEach(singleContent => {
          const id = extractId(singleContent.managedResource);
          const localeToContent =
            indexedLocaleToContent.get(id) ||
            new Map<
              Locale,
              ManagedContent<IndexedResource & LocalizedResource>
            >();
          localeToContent.set(locale, singleContent);
          indexedLocaleToContent.set(id, localeToContent);
        });
        map.set(resource, indexedLocaleToContent);
      });
      return map;
    }, new Map<CommitResource, Map<string, Map<Locale, ManagedContent<IndexedResource & LocalizedResource>>>>()),
    indexedContent => Array.from(indexedContent.values())
  );

  const commitStatisticsByResource: Map<
    CommitResource,
    {
      added: number;
      changed: number;
      removed: number;
      archived: number;
      unarchived: number;
      newTranslations: {
        [locale: string]: { countryCode: string; total: number };
      };
    }
  > = new Map();

  resourcesToIndexedManagedContent.forEach(
    (managedContentRows, resourceType) => {
      managedContentRows.forEach(managedContent => {
        managedContent.forEach((singleManagedContent, locale) => {
          const previous = commitStatisticsByResource.get(resourceType) || {
            added: 0,
            changed: 0,
            removed: 0,
            archived: 0,
            unarchived: 0,
            newTranslations: {}
          };

          function isAddedAsTranslation() {
            const isTranslated =
              singleManagedContent.managedResource.tentativeState
                .commitLocale !== content.defaultLocale &&
              !content.content
                .get(
                  singleManagedContent.managedResource.tentativeState
                    .commitLocale ?? ""
                )
                ?.[resourceType]?.get(
                  extractId(singleManagedContent.managedResource)
                );
            if (isTranslated) {
              previous.newTranslations[locale] = {
                countryCode: getCountryCodeForLocale(locale),
                total: (previous.newTranslations[locale]?.total || 0) + 1
              };
            }
            return isTranslated;
          }
          switch (singleManagedContent.modificationType) {
            case "create":
              if (!isAddedAsTranslation()) {
                previous.added++;
              }
              break;
            case "update":
              if (!isAddedAsTranslation()) {
                previous.changed++;
              }
              break;
            case "remove":
              previous.removed++;
              break;
            case "archive":
              previous.archived++;
              break;
            case "unarchive":
              previous.unarchived++;
              break;
          }
          commitStatisticsByResource.set(resourceType, previous);
        });
      });
    }
  );

  return {
    resourcesToIndexedManagedContent,
    commitStatisticsByResource,
    aggregatedCommits: Array.from(commitStatisticsByResource.values()).reduce(
      (statistics, commitStatistics) => {
        statistics.added += commitStatistics.added;
        statistics.removed += commitStatistics.removed;
        statistics.changed += commitStatistics.changed;
        statistics.archived += commitStatistics.archived;
        statistics.unarchived += commitStatistics.unarchived;
        statistics.newTranslations = {
          ...statistics.newTranslations,
          ...Object.keys(commitStatistics.newTranslations).reduce(
            (newTranslations, locale) => ({
              ...newTranslations,
              [locale]: {
                ...statistics.newTranslations[locale],
                ...commitStatistics.newTranslations[locale],
                total:
                  (statistics.newTranslations[locale]?.total || 0) +
                  commitStatistics.newTranslations[locale].total
              }
            }),
            {}
          )
        };
        return statistics;
      },
      {
        added: 0,
        changed: 0,
        removed: 0,
        archived: 0,
        unarchived: 0,
        newTranslations: {}
      }
    )
  };
}
