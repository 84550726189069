import { Avatar } from "@material-ui/core";
import * as React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const AvatarListItem = ({
  src,
  text
}: {
  src: any;
  text: string;
}) => (
  <Grid container alignItems="center" spacing={1}>
    <Grid item>
      <Avatar src={src} />
    </Grid>
    <Grid item>
      <Typography>{text}</Typography>
    </Grid>
  </Grid>
);

export default AvatarListItem;
