import React from "react";
import moment from "moment";
import ListDialog from "../../dialogs/ListDialog/ListDialog";
import DataBackupResponse from "../../../api/configuration/DataBackupResponse";
import {
  Avatar,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import DescriptionIcon from "@material-ui/icons/Description";
import { DATE_FORMAT, UTC_FORMAT } from "../../../constants/time";

type BackupFile = { name: string; createdAt: string };
const sortBackupFiles = (a: BackupFile, b: BackupFile) =>
  moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf();

const UploadBackupDialog = ({
  isOpen,
  handleClose,
  handleConfirm,
  listBackups,
  isUploading,
  uploadError
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: (id: string) => void;
  listBackups: () => Promise<DataBackupResponse> | undefined;
  isUploading: boolean;
  uploadError?: Error;
}) => {
  const listData = React.useMemo(
    () => () => listBackups()?.then(response => response.files),
    [listBackups]
  );
  const renderItem = React.useCallback(
    ({ createdAt, name }) => (
      <>
        <ListItemAvatar>
          <Avatar>
            <DescriptionIcon />
          </Avatar>
        </ListItemAvatar>
        <Grid container spacing={1}>
          <Grid item>
            <ListItemText>
              <Typography>
                {moment
                  .utc(moment(createdAt).format(UTC_FORMAT))
                  .local()
                  .format(DATE_FORMAT)}
              </Typography>
            </ListItemText>
          </Grid>
          <Grid item>
            <ListItemText>
              <Typography>{name}</Typography>
            </ListItemText>
          </Grid>
        </Grid>
      </>
    ),
    []
  );
  return (
    <ListDialog
      handleConfirm={handleConfirm}
      handleClose={handleClose}
      isOpen={isOpen}
      listData={listData}
      isProcessingAction={isUploading}
      actionError={uploadError}
      title="Select a backup to load"
      description="Loading a backup could take a few seconds so please be patient.
      Generally, avoid this action unless absolutely necessary."
      sort={sortBackupFiles}
      renderItem={renderItem}
    />
  );
};

export default React.memo(UploadBackupDialog);
