import { Commit, CommitResource } from "../../../api/commits/Commit";
import { Locale } from "../../../locale";
import { Resources } from "../../../data/Resources";

export function extractLinkedResourceField(
  linkedResources: {
    [resource: string]: { resourceId?: string; commitId?: string }[];
  },
  commits: Map<Resources, Map<string, Commit>>,
  indexedResources: Map<string, any>,
  resourceLocales: Locale[],
  resource: Resources,
  resourceName: string,
  fieldName: string
) {
  return {
    [resourceName]: linkedResources[resource]?.reduce((acc, link) => {
      const linkedCommit =
        (link.commitId && commits.get(resource)?.get(link.commitId || "")) ||
        undefined;
      const linkedResourceData =
        (link?.resourceId && indexedResources.get(link?.resourceId)) ||
        undefined;
      (
        linkedCommit?.updatedFields?.[fieldName] ||
        (linkedResourceData?.[`${fieldName}`] || []).map((value: any) => ({
          value,
          locales: linkedResourceData.untranslatedFields.includes(fieldName)
            ? []
            : resourceLocales
        }))
      ).forEach((commit: Partial<{ value: string; locales: Locale[] }>) => {
        acc.push({
          value: commit.value || "",
          locales: commit.locales || []
        });
      });
      return acc;
    }, [] as { value: string; locales: Locale[] }[])
  };
}
