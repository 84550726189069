import DestructiveAlertDialog from "../../dialogs/DestructiveAlertDialog";
import React, { useCallback, useEffect, useState } from "react";

interface DeleteCalendarEventDialogProps {
  registerCallback: (setDialogOpen: (open: boolean) => void) => void;
  deleteEvent: () => Promise<void>;
}

export const DeleteCalendarEventDialog = ({
  registerCallback,
  deleteEvent
}: DeleteCalendarEventDialogProps) => {
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {
    registerCallback((open: boolean) => {
      setOpen(open);
    });
  }, [registerCallback]);

  const handleDeleteAlertClose = useCallback(() => {
    setOpen(false);
  }, []);

  const [isDeleting, setDeleting] = useState(false);
  const handleDeleteConfirm = useCallback(async () => {
    setDeleting(true);
    try {
      await deleteEvent();
      setOpen(false);
    } catch (e: any) {
      console.error(e);
    } finally {
      setDeleting(false);
    }
  }, [deleteEvent]);

  return (
    <DestructiveAlertDialog
      isOpen={isOpen}
      title="Delete Event"
      message="Are you sure you want to delete this event? This cannot be undone."
      destructiveButtonText="Delete"
      handleClose={handleDeleteAlertClose}
      handleConfirm={handleDeleteConfirm}
      isLoading={isDeleting}
    />
  );
};
