import * as React from "react";
import compose from "../../utils/compose";
import { withRepositories } from "./withRepositories";
import withLCE from "./withLCE";
import { useObservableRepositoryLCE } from "../../hooks/useObservableRepositoryLCE";
import ConfigurationRepository from "../../repository/use-case/configuration/ConfigurationRepository";
import Configuration from "../../api/configuration/Configuration";
import DataBackupListResponse from "../../api/configuration/DataBackupResponse";
import StagedDataDocument from "../../api/configuration/StagedDataDocument";

export interface SystemConfigurationConsumerProps {
  isContentLock: boolean;
  isPublishing: boolean;
  updateContentLock?: ({ locked }: { locked: boolean }) => Promise<void>;
  performPublish?: ({ ownerId }: { ownerId?: string }) => Promise<void>;
  listBackups?: ({
    ownerId
  }: {
    ownerId?: string;
  }) => Promise<DataBackupListResponse>;
  selectBackup?: ({
    ownerId,
    backupId
  }: {
    ownerId?: string;
    backupId: string;
  }) => Promise<void>;
  listStagedData?(): Promise<StagedDataDocument[]>;
  selectStagedData?(id: string): Promise<void>;
}

function withSystemConfiguration<P extends SystemConfigurationConsumerProps>(
  WrappedComponent: React.ComponentType<P>
) {
  const SystemConfigurationHOC = (
    props: P & {
      content: { configuration: Configuration | undefined } | undefined;
      configurationRepository?: ConfigurationRepository;
    }
  ) => {
    const listBackups = React.useCallback(
      ({ ownerId }) => props.configurationRepository?.listBackups({ ownerId }),
      [props.configurationRepository]
    );
    const selectBackup = React.useCallback(
      ({ ownerId, backupId }) =>
        props.configurationRepository?.selectBackup({ ownerId, backupId }),
      [props.configurationRepository]
    );
    const updateContentLock = React.useCallback(
      ({ locked }) => props.configurationRepository?.setContentLock({ locked }),
      [props.configurationRepository]
    );
    const performPublish = React.useCallback(
      ({ ownerId }) =>
        props.configurationRepository?.performPublish({ ownerId }),
      [props.configurationRepository]
    );
    const listStagedData = React.useCallback(
      () => props.configurationRepository?.listStagedData?.(),
      [props.configurationRepository]
    );
    const selectStagedData = React.useCallback(
      (id: string) => props.configurationRepository?.selectStagedData(id),
      [props.configurationRepository]
    );
    return (
      <WrappedComponent
        {...props}
        isContentLock={props.content?.configuration?.contentLock}
        isPublishing={props.content?.configuration?.isPublishing}
        updateContentLock={updateContentLock}
        performPublish={performPublish}
        listBackups={listBackups}
        selectBackup={selectBackup}
        listStagedData={listStagedData}
        selectStagedData={selectStagedData}
      />
    );
  };
  return compose(
    withRepositories(repositories => ({
      configurationRepository: repositories.configuration
    })),
    withLCE(
      (props: { configurationRepository: ConfigurationRepository }) =>
        useObservableRepositoryLCE(
          ([configuration]) => Promise.resolve({ configuration }),
          [],
          props.configurationRepository
        ),
      { showLoadingScreen: false }
    )
  )(SystemConfigurationHOC);
}

export default withSystemConfiguration;
