import { useCallback, useState } from "react";
import { createManagedAuthor, createManagedCategory, createManagedQuote } from "./new-managed-resource-factory";
import { Resources } from "../../data/Resources";
import { mapOf } from "../../utils/maps";
import { CommitResource } from "../../api/commits/Commit";
import { Locale } from "../../locale";
import ManagedResource from "../../data/ManagedResource";
import { useRepositories } from "../../hooks/useRepositories";

export function useCreateManagedContent() {
  const { locales } = useRepositories();
  const [createCallback, setCreateCallback] = useState<{
    callback?: (
      resourceType: CommitResource,
      localeToResource?: Map<Locale, ManagedResource<any>>
    ) => any;
  }>({});
  const onAddQuoteClicked = useCallback(
    (quoteText: string, authorText: string, categories?: string[]) => {
      const managedQuote = createManagedQuote(
        quoteText,
        authorText,
        categories
      );
      createCallback?.callback?.(
        Resources.QUOTES,
        mapOf(locales.defaultLocale, managedQuote)
      );
    },
    [createCallback, locales.defaultLocale]
  );
  const onAddAuthorClicked = useCallback(
    (authorText: string) => {
      const managedAuthor = createManagedAuthor(authorText);
      createCallback?.callback?.(
        Resources.AUTHORS,
        mapOf(locales.defaultLocale, managedAuthor)
      );
    },
    [createCallback, locales.defaultLocale]
  );
  const onAddCategoryClicked = useCallback(
    (categoryName: string) => {
      const managedCategory = createManagedCategory(categoryName);
      createCallback?.callback?.(
        Resources.AUTHORS,
        mapOf(locales.defaultLocale, managedCategory)
      );
    },
    [createCallback, locales.defaultLocale]
  );

  return {
    setCreateCallback,
    onAddQuoteClicked,
    onAddAuthorClicked,
    onAddCategoryClicked
  }
}