import { Locale } from "../../../locale";
import ManagedContent from "../../../data/ManagedContent";
import ContentDiffData from "./ContentDiffData";

export default function mapIndexedContentToIndexedDiffData(
  content: Map<Locale, ManagedContent<any>>[]
): Map<Locale, ContentDiffData>[] {
  return content.reduce((accContentDiffData, localeToContent) => {
    const localeToContentDiffData = new Map<Locale, ContentDiffData>();
    localeToContent.forEach((content, locale) => {
      const contentDiffData: ContentDiffData = {
        locale,
        commitId: content.managedResource.tentativeState.commitId,
        modificationType: content.modificationType,
        ownerTimeline: content.ownerTimeline,
        contentActions: content.contentActions,
        changesByField: new Map()
      };
      Object.keys(content.managedResource.content).forEach(contentKey => {
        contentDiffData.changesByField.set(contentKey, {
          previous: content.managedResource.content[contentKey] || [],
          current: []
        });
      });
      Object.keys(
        content.managedResource.tentativeState.updatedContent
      ).forEach(contentKey => {
        contentDiffData.changesByField.set(contentKey, {
          ...(contentDiffData.changesByField.get(contentKey) || {
            previous: [],
            current: []
          }),
          current: (
            content.managedResource.tentativeState.updatedContent[contentKey] ||
            []
          ).map(({ value }) => value)
        });
      });
      localeToContentDiffData.set(locale, contentDiffData);
    });
    accContentDiffData.push(localeToContentDiffData);
    return accContentDiffData;
  }, [] as Map<Locale, ContentDiffData>[]);
}
