import { ContentApi } from "../../../api/content/ContentApi";
import { ContentData } from "./ContentData";
import { Locale } from "../../../locale";
import HotLocalizedRepository from "../../HotLocalizedRepository";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { indexBy } from "../../../utils/arrays/indexBy";
import { transformValues } from "../../../utils/maps";
import _ from "lodash";
import { CommitResource } from "../../../api/commits/Commit";
import { UploadMetadata } from "../../../api/firebase/storage/FirebaseStorage";

export default class ContentRepository extends HotLocalizedRepository<
  ContentData
> {
  constructor(defaultLocale: string, private contentApi: ContentApi) {
    super(defaultLocale);
  }

  uploadFile = (params: {
    resource: CommitResource;
    locale: Locale;
    resourceOrCommitId: string;
    file: File;
    metadata?: UploadMetadata;
  }): Promise<{ fullPath: string }> => {
    return this.contentApi.uploadFile(params);
  };

  protected readonly _combiner: (data: ContentData[]) => ContentData = data => {
    return data.reduce((contentData, contentDataEntry) => {
      Object.keys(contentDataEntry).forEach(resourceType => {
        const currentResources = contentDataEntry[resourceType];
        const previousResources = contentData[resourceType] || currentResources;
        contentData[resourceType] = transformValues(
          previousResources,
          previousResource => {
            const resource = currentResources.get(previousResource.id) || {};
            const previousKeys = Object.keys(previousResource);
            const currentKeys = Object.keys(resource);
            const combinedResource = _.merge(previousResource, resource);
            for (const key of previousKeys) {
              if (!currentKeys.includes(key)) {
                combinedResource.untranslatedFields.push(key);
              }
            }
            combinedResource.hasOwnPresence = currentResources.has(
              previousResource.id
            );
            return combinedResource;
          }
        );
      });
      return contentData;
    }, {} as ContentData);
  };

  protected readonly _getDataObservable: (
    locale: Locale
  ) => Observable<ContentData> = locale => {
    return combineLatest([
      this.asIndexed(this.contentApi.getQuotesObservable(locale)),
      this.asIndexed(this.contentApi.getAuthorsObservable(locale)),
      this.asIndexed(this.contentApi.getCategoriesObservable(locale)),
      this.asIndexed(this.contentApi.getPodcastsObservable(locale)),
      this.asIndexed(this.contentApi.getMediaPlatformsObservable(locale)),
      this.asIndexed(this.contentApi.getVideosObservable(locale)),
      this.asIndexed(this.contentApi.getBooksObservable(locale)),
      this.asIndexed(this.contentApi.getWeeklyTipsObservable(locale))
    ]).pipe(
      map(
        ([
          quotes,
          authors,
          categories,
          podcasts,
          mediaPlatforms,
          videos,
          books,
          weeklyTips
        ]) =>
          ({
            quotes,
            authors,
            categories,
            podcasts,
            mediaPlatforms,
            videos,
            books,
            weeklyTips
          } as ContentData)
      )
    );
  };

  private asIndexed<T extends { id: string }>(
    resourceObservable: Observable<T[]>
  ) {
    return resourceObservable.pipe(
      map(resources => indexBy(resources, resource => resource.id))
    );
  }
}
