import { Commit } from "../../api/commits/Commit";
import { CommitType } from "../../api/commits/CommitType";
import * as firebase from "firebase";
import Quote, { QuoteFields } from "../../api/content/Quote";
import Author, { AuthorFields } from "../../api/content/Author";
import Category, { CategoryFields } from "../../api/content/Category";
import { Locale } from "../../locale";
import LocalizedResource from "../LocalizedResource";
import Configuration from "../../api/configuration/Configuration";
import User from "../../api/auth/User";
import Podcast from "../../api/content/Podcast";
import MediaPlatform, {
  MediaPlatformFields
} from "../../api/content/MediaPlatform";
import Video from "../../api/content/Video";
import { MediaFeedFields } from "../../api/content/MedaFeedContent";
import Book from "../../api/content/Book";
import { Task } from "../../api/tasks/Task";
import WeeklyTip, { WeeklyTipFields } from "../../api/content/WeeklyTip";

export function mapDocumentToUser(
  documentSnapshot: firebase.firestore.DocumentSnapshot
): User {
  return documentSnapshot.data() as User;
}

export function mapDocumentToCommit(
  documentSnapshot: firebase.firestore.DocumentSnapshot
): Commit {
  const parentPath = documentSnapshot.ref.parent.path;
  const locale = documentSnapshot.ref.parent.path.substring(
    parentPath.lastIndexOf("/") + 1
  );
  const updatedFields = (documentSnapshot.get("updatedFields") || {}) as {
    [fieldName: string]: {
      value: string;
      ownerId?: string;
      updateTime: number;
      locales: Locale[];
    }[];
  };
  for (const field in updatedFields) {
    updatedFields[field] = updatedFields[field].map(updateData => ({
      ...updateData,
      locales: [locale]
    }));
  }
  return {
    ownerId: documentSnapshot.get("ownerId"),
    updateTime: documentSnapshot.get("updateTime"),
    commitId: documentSnapshot.id,
    resourceId: documentSnapshot.get("resourceId"),
    type: documentSnapshot.get("type") || CommitType.CREATE_ENTRY,
    updatedFields,
    locale,
    linkedResources: documentSnapshot.get("linkedResources") || {}
  };
}

export function mapDocumentToQuote(
  documentSnapshot: firebase.firestore.DocumentSnapshot
): Quote {
  return mapDocumentToResource(documentSnapshot, QuoteFields);
}

export function mapDocumentToAuthor(
  documentSnapshot: firebase.firestore.DocumentSnapshot
): Author {
  return mapDocumentToResource(documentSnapshot, AuthorFields);
}

export function mapDocumentToCategory(
  documentSnapshot: firebase.firestore.DocumentSnapshot
): Category {
  return mapDocumentToResource(documentSnapshot, CategoryFields);
}

export function mapDocumentToPodcast(
  documentSnapshot: firebase.firestore.DocumentSnapshot
): Podcast {
  return mapDocumentToResource(documentSnapshot, MediaFeedFields);
}

export function mapDocumentToVideo(
  documentSnapshot: firebase.firestore.DocumentSnapshot
): Video {
  return mapDocumentToResource(documentSnapshot, MediaFeedFields);
}

export function mapDocumentToBook(
  documentSnapshot: firebase.firestore.DocumentSnapshot
): Book {
  return mapDocumentToResource(documentSnapshot, MediaFeedFields);
}

export function mapDocumentToWeeklyTip(
  documentSnapshot: firebase.firestore.DocumentSnapshot
): WeeklyTip {
  return mapDocumentToResource(documentSnapshot, WeeklyTipFields);
}

export function mapDocumentToMediaPlatform(
  documentSnapshot: firebase.firestore.DocumentSnapshot
): MediaPlatform {
  return mapDocumentToResource(documentSnapshot, MediaPlatformFields);
}

function mapDocumentToResource<T extends LocalizedResource>(
  documentSnapshot: firebase.firestore.DocumentSnapshot,
  fieldsMap: { [key: string]: string } & { ID: string } & { archived?: boolean }
): T {
  const resource: { [field: string]: string | string[] } = {
    untranslatedFields: [] as string[],
    [fieldsMap.ID]: documentSnapshot.id
  };
  Object.values(fieldsMap)
    .filter((fieldName): fieldName is string => fieldName !== fieldsMap.ID)
    .forEach(fieldName => {
      const value = documentSnapshot.get(fieldName);
      if (value !== undefined) {
        resource[fieldName] = value;
      }
    });
  resource[fieldsMap.ID] = documentSnapshot.id;
  resource.archived = documentSnapshot.get("archived")?.[0];
  resource.updatedAt = documentSnapshot.get("updatedAt");
  resource.createdAt = documentSnapshot.get("createdAt");
  return (resource as unknown) as T;
}

export function mapDocumentToConfiguration(
  documentSnapshot: firebase.firestore.DocumentSnapshot
): Configuration {
  return {
    contentLock: documentSnapshot.get("contentLock"),
    isPublishing: documentSnapshot.get("isPublishing")
  };
}

export function mapDocumentToTask(
  documentSnapshot: firebase.firestore.DocumentSnapshot
): Task {
  return {
    createdAt: documentSnapshot
      .get("createdAt")
      ?.toDate()
      ?.toISOString(),
    id: documentSnapshot.id,
    status: documentSnapshot.get("status"),
    type: documentSnapshot.get("type"),
    updatedAt: documentSnapshot
      .get("updatedAt")
      ?.toDate()
      ?.toISOString(),
    cancelable: documentSnapshot.get("cancelable") ?? false,
    retryable: documentSnapshot.get("retryable") ?? false,
    deletable: documentSnapshot.get("deletable") ?? false
  };
}
