import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Box } from "@material-ui/core";
import React from "react";
import { useStyles } from "../styles";

export const CalendarNavigation = ({
  onPreviousClicked,
  onTodayClicked,
  onNextClicked
}: {
  onPreviousClicked?: () => void;
  onTodayClicked?: () => void;
  onNextClicked?: () => void;
}) => {
  const classes = useStyles();
  return (
    <Box>
      <IconButton onClick={onPreviousClicked}>
        <ChevronLeftIcon />
      </IconButton>
      <Button className={classes.toolbarTodayButton} onClick={onTodayClicked}>
        <Typography variant="h6">Today</Typography>
      </Button>
      <IconButton onClick={onNextClicked}>
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
};
