import {
  FileManagementRepository,
  GetFileDetailsResponse
} from "../../../domain/file-management/data/FileManagementRepository";
import firebase from "firebase";
import { FirestoreRealtimeAPI, RealtimeSystems } from "./FirestoreRealtimeAPI";
import { Observable } from "rxjs";
import { collectionAsObservable } from "../../../utils/firebase/asObservable";
import { File } from "../../../domain/file-management/entities/File";
import AuthApi from "../../../api/auth/AuthApi";
import { FirebaseStorage } from "../../../api/firebase/storage/FirebaseStorage";
import { v4 as uuidv4 } from "uuid";
import { FirebaseFunctions } from "../../../api/firebase/functions/FirebaseFunctions";

const FILE_MANAGER_PATH_BASE = "cms/file-manager";
const CALLABLE_FUNCTION_GET_FILE_DETAILS = "getFileDetails";
const CALLABLE_FUNCTION_GENERATE_PUBLIC_LINKS = "generateFilePublicLinks";

export class FirebaseFileManagementRepository
  implements FileManagementRepository {
  constructor(
    private readonly firestore: firebase.firestore.Firestore,
    private readonly firebaseStorage: FirebaseStorage,
    private readonly realtimeAPI: FirestoreRealtimeAPI,
    private readonly authApi: AuthApi,
    private readonly firebaseFunctions: FirebaseFunctions
  ) {}

  observeFiles(atPath: string): Observable<File[]> {
    return collectionAsObservable(
      this.firestore.collection(
        FirebaseFileManagementRepository.mapFilePathToFilesCollection(atPath)
      ),
      snapshot =>
        snapshot.docs.map(documentSnapshot => ({
          ...(documentSnapshot.data() as File),
          id: documentSnapshot.id
        }))
    );
  }

  beginFileUpload(path: string, files: FileList): void {
    const trimmedPath = path.trim();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const extensionDelimiterIndex = file.name.lastIndexOf(".");
      this.firebaseStorage.createUploadTask({
        file,
        path: `${FILE_MANAGER_PATH_BASE}/${uuidv4()}${
          extensionDelimiterIndex
            ? file.name.substring(extensionDelimiterIndex, file.name.length)
            : ""
        }`,
        metadata: {
          customMetadata: {
            name:
              extensionDelimiterIndex === -1
                ? file.name
                : file.name.substring(0, extensionDelimiterIndex),
            path: trimmedPath
          }
        }
      });
    }
  }

  createNewFolder(path: string, folderName: string): Promise<void> {
    return this.realtimeAPI.postCommand(
      RealtimeSystems.FILE_MANAGEMENT,
      this.currentUserId(),
      {
        name: "create_folder",
        folderName,
        path
      }
    );
  }

  deleteFile(path: string, fileName: string): Promise<void> {
    return this.realtimeAPI.postCommand(
      RealtimeSystems.FILE_MANAGEMENT,
      this.currentUserId(),
      {
        name: "delete_file",
        path,
        fileName
      }
    );
  }

  renameFile(path: string, fileName: string, newName: string): Promise<void> {
    return this.realtimeAPI.postCommand(
      RealtimeSystems.FILE_MANAGEMENT,
      this.currentUserId(),
      {
        name: "rename_file",
        path,
        fileName,
        newName
      }
    );
  }

  getFileDetails(
    path: string,
    fileId: string
  ): Promise<GetFileDetailsResponse> {
    return this.firebaseFunctions.call(CALLABLE_FUNCTION_GET_FILE_DETAILS, {
      path,
      fileId
    });
  }

  generateDownloadLink(fileObjectPath: string): Promise<string> {
    return this.firebaseStorage.getDownloadUrl(fileObjectPath);
  }

  generatePublicLinks(
    path: string,
    fileId: string,
    linkInfo: {
      path: string;
      fileName: string;
      variants: string[];
      resizeForPlatforms: {
        capResolution: boolean;
        ios: boolean;
        android: boolean;
      };
    }
  ): Promise<void> {
    return this.firebaseFunctions.call(
      CALLABLE_FUNCTION_GENERATE_PUBLIC_LINKS,
      {
        uploadFileInfo: {
          path,
          fileId
        },
        destinationInfo: {
          path: linkInfo.path,
          fileName: linkInfo.fileName
        },
        resizeOptions: {
          capResolution: linkInfo.resizeForPlatforms.capResolution,
          resizedPlatforms: {
            ANDROID: linkInfo.resizeForPlatforms.android,
            IOS: linkInfo.resizeForPlatforms.ios
          }
        },
        variants: linkInfo.variants
      }
    );
  }

  private currentUserId(): string {
    const userId = this.authApi.currentUser()?.id;
    if (!userId) {
      throw new Error("Requires signed-in user.");
    }
    return userId;
  }

  private static mapFilePathToDocumentPath(path: string) {
    path = path.trim();
    if (path.startsWith("/")) {
      path = path.substring(1, path.length);
    }
    if (path.endsWith("/")) {
      path = path.substring(0, path.length - 1);
    }
    return "path_tree/" + path.split("/").join("/subpaths/");
  }

  private static mapFilePathToFilesCollection(path: string) {
    return (
      FirebaseFileManagementRepository.mapFilePathToDocumentPath(path) +
      "/files"
    );
  }
}
