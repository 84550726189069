import React, { useCallback, useState } from "react";
import { Task } from "../../api/tasks/Task";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { TaskStatus } from "../../api/tasks/TaskStatus";
import { TasksPanel } from "./TasksPanel";
import { useRepositories } from "../../hooks/useRepositories";
import { mapOf, transformValues } from "../../utils/maps";

const TASK_PREFIXES = {
  [TaskStatus.PENDING]: "Pending",
  [TaskStatus.RUNNING]: "Running",
  [TaskStatus.SUCCESS]: "Completed",
  [TaskStatus.FAILURE]: "Failed",
  [TaskStatus.CANCELED]: "Canceled"
};

const TASK_ORDER = {
  [TaskStatus.RUNNING]: 0,
  [TaskStatus.FAILURE]: 1,
  [TaskStatus.PENDING]: 2,
  [TaskStatus.CANCELED]: 3,
  [TaskStatus.SUCCESS]: 4
};

function createTaskGroupForTaskStatus(
  taskStatus: TaskStatus
): { title: string; tasks: Task[] } {
  return {
    title: `${TASK_PREFIXES[taskStatus]} Tasks`,
    tasks: []
  };
}

export const TasksList = ({
  tasks,
  showErrorAlert
}: {
  tasks: Task[];
  showErrorAlert: Function;
}) => {
  const classes = useStyles();
  const { tasks: tasksRepository } = useRepositories();
  const [executingTaskIds, setExecutingTaskIds] = useState<string[]>([]);
  const [taskPanelExpandState, setPanelExpandState] = useState<
    Map<TaskStatus, boolean>
  >(
    mapOf(
      TaskStatus.RUNNING,
      true,
      TaskStatus.PENDING,
      false,
      TaskStatus.FAILURE,
      false,
      TaskStatus.SUCCESS,
      false,
      TaskStatus.CANCELED,
      false
    )
  );

  const wrapWithExecution = (
    taskExecution: (taskId: string) => Promise<void>
  ) => (taskId: string) => {
    setExecutingTaskIds(previousTaskIds => previousTaskIds.concat(taskId));
    taskExecution(taskId)
      .catch(() => showErrorAlert())
      .then(() => {
        setExecutingTaskIds(previousTaskIds =>
          previousTaskIds.filter(id => id !== taskId)
        );
      });
  };

  const onTaskRetry = useCallback(
    wrapWithExecution(tasksRepository.retryTask),
    [tasksRepository, showErrorAlert]
  );
  const onTaskCancel = useCallback(
    wrapWithExecution(tasksRepository.cancelTask),
    [tasksRepository, showErrorAlert]
  );
  const onTaskDelete = useCallback(
    wrapWithExecution(tasksRepository.deleteTask),
    [tasksRepository, showErrorAlert]
  );

  const onExpandTaskPanel = useCallback(
    (status: TaskStatus, isExpanded: boolean) => {
      setPanelExpandState(previousState => {
        return transformValues(
          previousState,
          (currentIsExpanded, panelStatus) => {
            return panelStatus === status ? isExpanded : currentIsExpanded;
          }
        );
      });
    },
    []
  );

  if (tasks.length === 0) {
    return (
      <Typography color="textPrimary" className={classes.viewPortCenter}>
        No tasks to show.
      </Typography>
    );
  }

  const tasksGroupedByStatus = tasks.reduce((groupedTasks, task) => {
    const previousGroup =
      groupedTasks.get(task.status) ??
      createTaskGroupForTaskStatus(task.status);
    previousGroup.tasks.push(task);
    groupedTasks.set(task.status, previousGroup);
    return groupedTasks;
  }, new Map<TaskStatus, { title: string; tasks: Task[] }>());

  return (
    <Grid
      container
      direction="column"
      className={classes.container}
      spacing={2}
    >
      {Array.from(tasksGroupedByStatus.entries())
        .sort(
          ([status1], [status2]) => TASK_ORDER[status1] - TASK_ORDER[status2]
        )
        .map(([status, task]) => (
          <Grid item>
            <TasksPanel
              {...task}
              executingTaskIds={executingTaskIds}
              onTaskCancelClicked={onTaskCancel}
              onTaskRetryClicked={onTaskRetry}
              onTaskDeleteClicked={onTaskDelete}
              isExpanded={!!taskPanelExpandState.get(status)}
              onExpandClicked={isExpanded =>
                onExpandTaskPanel(status, isExpanded)
              }
            />
          </Grid>
        ))}
    </Grid>
  );
};
