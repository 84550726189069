import { QuoteFields } from "../../../api/content/Quote";
import { Commit, CommitResource } from "../../../api/commits/Commit";
import ManagedQuote, { QuoteContentFields } from "../../ManagedQuote";
import User from "../../../api/auth/User";
import { AuthorFields } from "../../../api/content/Author";
import { extractValues } from "../../../utils/maps";
import toManagedResource from "./managed-resource-mapper";
import calculateTimeline from "../owner-timeline-mapper";
import { Resources } from "../../Resources";
import { CategoryFields } from "../../../api/content/Category";
import { extractLinkedResourceField } from "../../../utils/data/mappers/extractLinkedResourceField";
import { filterNills } from "../../../utils/arrays/filterNills";
import { LocaleData } from "../../../repository/LocaleData";
import { ContentData } from "../../../repository/use-case/content/ContentData";
import { CLIPBOARD_ACTION } from "../../../components/content/actions/clipboard-action";

export default function toManagedQuotes({
  commits,
  users,
  contentData
}: {
  commits: Map<CommitResource, Map<string, Commit>>;
  users: Map<string, User>;
  contentData: LocaleData<ContentData>;
}): ManagedQuote[] {
  return toManagedResource({
    calculateTimeline: calculateTimeline("quote"),
    commits: commits.get(Resources.QUOTES) || new Map(),
    users,
    contentCollection: extractValues(contentData.data.quotes, quote => ({
      ...{
        ...quote,
        author: filterNills(
          quote[QuoteFields.AUTHOR_ID]?.map(
            id => contentData.data.authors.get(id)?.[AuthorFields.NAME]?.[0]
          ) ?? []
        ),
        category: filterNills(
          quote[QuoteFields.CATEGORY_ID]?.map(
            id => contentData.data.categories.get(id)?.[CategoryFields.NAME]?.[0]
          ) ?? []
        ),
        untranslatedFields: [
          ...quote.untranslatedFields,
          ...(quote.untranslatedFields.includes(QuoteFields.AUTHOR_ID)
            ? [QuoteContentFields.AUTHOR]
            : []),
          ...(quote.untranslatedFields.includes(QuoteFields.CATEGORY_ID)
            ? [QuoteContentFields.CATEGORY]
            : [])
        ]
      }
    })),
    resolveLinksToFields: linkedResources => ({
      ...extractLinkedResourceField(
        linkedResources,
        commits,
        contentData.data.authors,
        [contentData.locale],
        Resources.AUTHORS,
        QuoteContentFields.AUTHOR,
        AuthorFields.NAME
      ),
      ...extractLinkedResourceField(
        linkedResources,
        commits,
        contentData.data.categories,
        [contentData.locale],
        Resources.CATEGORIES,
        QuoteContentFields.CATEGORY,
        CategoryFields.NAME
      )
    }),
    locale: contentData.locale,
    customActions: {
      [CLIPBOARD_ACTION]: true
    }
  });
}
