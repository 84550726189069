import React, { Attributes } from "react";
import { InformativeAlertDialog } from "./InformativeAlertDialog";

interface ArchiveAlertDialogProps extends Attributes {
  isOpen: boolean;
  archiveInfo: string;
  handleClose: () => void;
  handleConfirm: () => void;
  isLoading: boolean;
}

export const UnArchiveAlertDialog = (props: ArchiveAlertDialogProps) => {
  return (
    <InformativeAlertDialog
      isLoading={props.isLoading}
      isOpen={props.isOpen}
      title={`Un Archive ${props.archiveInfo}?`}
      message={`Are you sure you want to unarchive ${props.archiveInfo}?\nIt will be re-added in next publish.`}
      confirmationText="Un Archive"
      handleClose={props.handleClose}
      handleConfirm={props.handleConfirm}
    />
  );
};
