import LocalizedResource from "../../data/LocalizedResource";

export enum MediaFeedFields {
  ID = "id",
  NAME = "name",
  DESCRIPTION = "description",
  IMAGE = "image",
  PLATFORM_ID = "platformId",
  CATEGORY_ID = "categoryId",
  PLATFORM_LINK = "platformLink",
  AUTHOR_ID = "authorId"
}

export default interface MediaFeed extends LocalizedResource {
  [MediaFeedFields.ID]: string;
  [MediaFeedFields.NAME]: string[];
  [MediaFeedFields.DESCRIPTION]: string[];
  [MediaFeedFields.IMAGE]: string[];
  [MediaFeedFields.PLATFORM_ID]: string[] | undefined;
  [MediaFeedFields.CATEGORY_ID]: string[] | undefined;
  [MediaFeedFields.PLATFORM_LINK]: string[] | undefined;
  [MediaFeedFields.AUTHOR_ID]: string[] | undefined;
}
