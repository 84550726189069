import React, { useContext, useState } from "react";
import { NavigationContext } from "../navigation";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import { useStyles } from "./styles";
import Destination from "../navigation/Destination";
import { Collapse } from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import RotationAnimatedComponent from "../animated/RotationAnimatedComponent";

export type NavigationChildrenStateMap = { [child: string]: boolean };

function isDescendantSelected(
  currentDestination: Destination,
  selectedPath: string | undefined
): boolean {
  return (
    (currentDestination.relativePath &&
      selectedPath?.endsWith(currentDestination.relativePath)) ||
    (currentDestination?.children?.some(destination =>
      isDescendantSelected(destination, selectedPath)
    ) ??
      false)
  );
}

function flattenDestinations(
  classes: any,
  childrenStates: NavigationChildrenStateMap,
  onChildrenStateChanged: (child: string) => void,
  destinationList: Destination[],
  currentPath: string | undefined,
  onDestinationSelected: (path: string) => void,
  isNested: boolean = false
) {
  return destinationList.reduce((destinationElements, destination, index) => {
    if (destination.children) {
      destinationElements.push(
        <ListItem
          button
          onClick={() => onChildrenStateChanged(destination.name)}
        >
          <ListItemIcon>
            <RotationAnimatedComponent
              render={() => <ArrowRightIcon />}
              toDegrees={childrenStates[destination.name] ? 90 : 0}
            />
          </ListItemIcon>
          <ListItemText
            primary={destination.name}
            classes={{
              ...(isDescendantSelected(destination, currentPath) && {
                primary: classes.selectedItemColor
              })
            }}
          />
        </ListItem>
      );
      destinationElements.push(
        <Collapse component="li" in={childrenStates[destination.name]}>
          <List dense disablePadding>
            {flattenDestinations(
              classes,
              childrenStates,
              onChildrenStateChanged,
              destination.children,
              currentPath,
              onDestinationSelected,
              true
            )}
          </List>
        </Collapse>
      );
    } else {
      destinationElements.push(
        <ListItem
          button
          onClick={() =>
            destination.relativePath &&
            onDestinationSelected(destination.relativePath!)
          }
          className={isNested && classes.nested}
        >
          <ListItemIcon>{destination.icon}</ListItemIcon>
          <ListItemText
            primary={destination.name}
            classes={{
              ...(destination.relativePath &&
                currentPath?.endsWith(destination.relativePath) && {
                  primary: classes.selectedItemColor
                })
            }}
          />
        </ListItem>
      );
    }
    return destinationElements;
  }, [] as JSX.Element[]);
}

const StaticDrawer = ({
  currentPath,
  onDestinationSelected,
  controlledChildrenStates,
  onChildrenStatesChanged,
  destinations
}: {
  onDestinationSelected: (pathName: string) => void;
  currentPath?: string;
  controlledChildrenStates?: NavigationChildrenStateMap;
  onChildrenStatesChanged?: (stateMap: { [child: string]: boolean }) => void;
  destinations: Array<Destination>;
}) => {
  const [childrenStates, setChildrenState] = useState<
    NavigationChildrenStateMap
  >(
    controlledChildrenStates ??
      destinations
        .filter(destination => destination.children)
        .reduce(
          (initialExpanded, destination) => ({
            ...initialExpanded,
            [destination.name]: true
          }),
          {}
        )
  );
  const onChildStateChanged = React.useCallback(
    (child: string) => {
      const newStates = {
        ...childrenStates,
        [child]: !childrenStates[child]
      };
      setChildrenState(newStates);
      onChildrenStatesChanged?.(newStates);
    },
    [childrenStates, onChildrenStatesChanged]
  );
  const classes = useStyles();
  return (
    <List dense className={classes.listContainer}>
      {flattenDestinations(
        classes,
        controlledChildrenStates ?? childrenStates,
        onChildStateChanged,
        destinations,
        currentPath?.endsWith("/") ? currentPath?.slice(0, -1) : currentPath,
        onDestinationSelected
      )}
    </List>
  );
};

export default React.memo(StaticDrawer);
