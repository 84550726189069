import ContentCardViewModel from "../content/card/ContentCardViewModel";
import {
  extractFirstContentField,
  extractFirstLocalizedProp
} from "../../utils/data/resources/managed";
import * as React from "react";
import { Grid } from "@material-ui/core";
import ContentCard from "../content/card/ContentCard";
import WarningWrapper from "../warning/WarningWrapper";
import Typography from "@material-ui/core/Typography";
import { Locale } from "../../locale";
import ManagedContent from "../../data/ManagedContent";
import { ManagedWeeklyTip } from "../../data/ManagedWeeklyTip";
import { WeeklyTipFields } from "../../api/content/WeeklyTip";
import { WeeklyTipSlideImage } from "./slides/WeeklyTipSlideImage";
import { WeeklyTipSlideContainer } from "./slides/WeeklyTipSlideContainer";

const WeeklyTipContent = ({
  managedContent: {
    managedResource: weeklyTip,
    contentActions,
    modificationType,
    ownerTimeline
  },
  locale
}: {
  managedContent: ManagedContent<ManagedWeeklyTip>;
  locale: Locale;
}) => {
  return (
    <Grid item xs={3}>
      <ContentCard
        viewModel={
          new ContentCardViewModel(
            (
              <WeeklyTipSlideContainer>
                <WeeklyTipSlideImage
                  imagePath={
                    extractFirstContentField(
                      weeklyTip,
                      WeeklyTipFields.SLIDES
                    ) ?? ""
                  }
                />
              </WeeklyTipSlideContainer>
            ),
            (
              <WarningWrapper
                message="Missing Translation"
                show={
                  !extractFirstLocalizedProp(
                    locale,
                    weeklyTip,
                    WeeklyTipFields.TOPIC
                  ).isLocalized
                }
              >
                <Typography variant="h5">
                  {
                    extractFirstLocalizedProp(
                      locale,
                      weeklyTip,
                      WeeklyTipFields.TOPIC
                    ).value
                  }
                </Typography>
              </WarningWrapper>
            ),
            contentActions,
            modificationType,
            ownerTimeline
          )
        }
      />
    </Grid>
  );
};

export default WeeklyTipContent;
