import { Theme } from "@material-ui/core";

const scrollableContent = (theme: Theme) => ({
  contentContainer: {
    ...scrollableContentContainer(theme)
  },
  scrollableContent: {
    ...scrollableContentWrapper(theme)
  }
});

export const scrollableContentContainer = (theme: Theme) => ({
  height: "100%",
  overflow: "hidden"
});

export const scrollableContentWrapper = (theme: Theme) => ({
  height: "100%",
  overflow: "auto"
});

export default scrollableContent;
