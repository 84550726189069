import { Theme } from "@material-ui/core";
import { centerFlex } from "./centerFlex";

const circleBgStyle = {
  borderRadius: "50%",
  width: "48px",
  height: "48px",
  justifyContent: "space-between"
};

export const contentCircles = (theme: Theme) => ({
  added: {
    ...addedCircle(theme),
    ...centerFlex(theme)
  },
  changed: {
    ...changedCircle(theme),
    ...centerFlex(theme)
  },
  removed: {
    ...removedCircle(theme),
    ...centerFlex(theme)
  },
  archived: {
    ...archivedCircle(theme),
    ...centerFlex(theme)
  },
  unarchived: {
    ...unarchivedCircle(theme),
    ...centerFlex(theme)
  },
  translated: {
    ...translatedCircle(theme),
    ...centerFlex(theme)
  }
});

export const addedCircle = (theme: Theme) => ({
  backgroundColor: theme?.contentManagement?.create?.color,
  ...circleBgStyle
});

export const changedCircle = (theme: Theme) => ({
  backgroundColor: theme?.contentManagement?.update?.color,
  ...circleBgStyle
});

export const removedCircle = (theme: Theme) => ({
  backgroundColor: theme?.contentManagement?.remove?.color,
  ...circleBgStyle
});

export const archivedCircle = (theme: Theme) => ({
  backgroundColor: theme?.contentManagement?.archive?.color,
  ...circleBgStyle
});

export const unarchivedCircle = (theme: Theme) => ({
  backgroundColor: theme?.contentManagement?.unarchive?.color,
  ...circleBgStyle
});

export const translatedCircle = (theme: Theme) => ({
  backgroundColor: theme.contentManagement?.translated.color,
  ...circleBgStyle
});
