import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import React, { useCallback } from "react";
import { ExportFileFormat } from "./ExportFileFormat";

export interface ExportFileFormatSelectionProps {
  selectedFormat: ExportFileFormat;
  onSelectionChange: (selectedFormat: ExportFileFormat) => void;
}

export const ExportFileFormatSelection = ({
  onSelectionChange,
  selectedFormat
}: ExportFileFormatSelectionProps) => {
  const onChange = useCallback(
    changeEvent =>
      onSelectionChange(changeEvent.target.value as ExportFileFormat),
    [onSelectionChange]
  );
  return (
    <RadioGroup name={"format"} value={selectedFormat} onChange={onChange}>
      {Object.keys(ExportFileFormat).map(fileFormat => {
        return (
          <FormControlLabel
            value={fileFormat.toLowerCase()}
            control={<Radio />}
            label={fileFormat}
          />
        );
      })}
    </RadioGroup>
  );
};
