import { useEffect, useState } from "react";
import { Observable, Unsubscribable } from "rxjs";
import { LCE } from "../architecture/lce/lce";

const useObservableLCE = function<T extends object, E extends Error>(
  contentLoading: () => Promise<T>,
  observable: Observable<T>,
  deps: any[]
) {
  const [lce, setLCE] = useState<LCE<T, E>>({ isLoading: true });

  useEffect(() => {
    let subscription: Unsubscribable | undefined;
    (async () => {
      try {
        setLCE({ isLoading: true });
        const content = await contentLoading();
        setLCE({ isLoading: false, content });
        subscription = observable.subscribe({
          next: (data: T) => setLCE({ isLoading: false, content: data }),
          error: e => setLCE({ ...lce, isLoading: false, error: e })
        });
      } catch (e: any) {
        setLCE({ ...lce, isLoading: false, error: e });
      }
    })();
    return () => {
      subscription?.unsubscribe();
    };
  }, deps);

  return lce;
};

export default useObservableLCE;
