import React, { useMemo } from "react";
import { Grid, GridSize } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { SlideControl } from "./SlideControl";
import Tooltip from "@material-ui/core/Tooltip";
import { useStyles } from "../styles";

const MAX_GRID_XS = 12;

const controlData = {
  [SlideControl.MOVE_LEFT]: {
    icon: <ChevronLeftIcon />,
    description: "Move Left",
    xs: 2,
    alignment: "flex-start"
  },
  [SlideControl.MOVE_RIGHT]: {
    icon: <ChevronRightIcon />,
    description: "Move Right",
    xs: 2,
    alignment: "flex-start"
  },
  [SlideControl.DELETE]: {
    icon: <DeleteForeverIcon />,
    description: "Delete Slide",
    xs: undefined,
    alignment: "flex-end"
  }
};

export const SlideControls = ({
  enabledControls,
  onControlClicked
}: {
  enabledControls: SlideControl[];
  onControlClicked?(control: SlideControl): void;
}) => {
  const classes = useStyles();
  const icons = useMemo(() => {
    const freeSpace =
      MAX_GRID_XS -
      enabledControls.reduce(
        (totalRequiredSpace, control) =>
          totalRequiredSpace + (controlData[control]?.xs ?? 0),
        0
      );
    const controlSpaceDistribution = enabledControls.filter(
      control => !controlData[control].xs
    ).length;
    return enabledControls.map(control => {
      const controlDatum = controlData[control];
      const xs = controlDatum.xs ?? freeSpace / controlSpaceDistribution;
      return (
        <Grid item xs={xs as GridSize}>
          <div
            className={classes.slideControlItemWrapper}
            style={{ alignItems: controlDatum.alignment }}
          >
            <Tooltip title={controlDatum.description}>
              <IconButton
                onClick={() => onControlClicked?.(control)}
                size="small"
              >
                {controlDatum.icon}
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      );
    });
  }, [enabledControls, onControlClicked]);
  return <Grid container>{icons}</Grid>;
};
