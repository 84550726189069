import React, { Attributes } from "react";
import DestructiveAlertDialog from "./DestructiveAlertDialog";

interface ArchiveAlertDialogProps extends Attributes {
  isOpen: boolean;
  archiveInfo: string;
  handleClose: () => void;
  handleConfirm: () => void;
  isLoading: boolean;
}

export const ArchiveAlertDialog = (props: ArchiveAlertDialogProps) => {
  return (
    <DestructiveAlertDialog
      isLoading={props.isLoading}
      isOpen={props.isOpen}
      title={`Archive ${props.archiveInfo}?`}
      message={`Are you sure you want to archive ${props.archiveInfo}?\nIt will be removed in next publish, but will remain accessible from the backoffce.`}
      destructiveButtonText="Archive"
      handleClose={props.handleClose}
      handleConfirm={props.handleConfirm}
    />
  );
};
