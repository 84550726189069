import React from "react";
import { Grid } from "@material-ui/core";
import { CalendarActionBarDateAction } from "./CalendarActionBarDateAction";

export const CalendarActionBar = ({
  onCreateActionClicked
}: {
  onCreateActionClicked: () => void;
}) => {
  return (
    <Grid container justify="space-between">
      <Grid item>
        <CalendarActionBarDateAction onActionClicked={onCreateActionClicked} />
      </Grid>
    </Grid>
  );
};
