import { ScrappedContent } from "../../pages/quotesplorer/ScrappedContent";
import { tokenizeWithoutSymbols } from "../../utils/diff/tokenizer";
import { IndexedData } from "./data-indexing";
import {
  FILTER_ATTRIBUTE_EXISTING,
  FILTER_ATTRIBUTES,
  FILTER_AUTHORS,
  FILTER_CATEGORIES, Filters
} from "./useFilters";

export function applyFilters({
  scrappedContent,
  filters,
  indexedData
}: {
  scrappedContent: ScrappedContent;
  filters: Filters;
  indexedData: IndexedData;
}) {
  const authorsFilterData = filters.get(FILTER_AUTHORS);
  const tagsFilterData = filters.get(FILTER_CATEGORIES);
  const attributesFilterData = filters.get(FILTER_ATTRIBUTES);
  return scrappedContent.quotes.filter(scrapedQuote => {
    if (authorsFilterData) {
      const authorFilterOption = authorsFilterData.options.get(
        scrapedQuote.author
      );
      if (authorFilterOption && !authorFilterOption.selected) {
        return false;
      }
    }

    if (tagsFilterData) {
      const remainingSelectedTags = scrapedQuote.metadata?.tags?.filter(tag => {
        const tagFilterOption = tagsFilterData.options.get(tag);
        return tagFilterOption === undefined || tagFilterOption.selected;
      });
      if (remainingSelectedTags?.length === 0) {
        return false;
      }
    }

    if (attributesFilterData) {
      const showExistingQuotes = attributesFilterData.options.get(
        FILTER_ATTRIBUTE_EXISTING
      );
      if (showExistingQuotes?.selected === false) {
        const quoteAsText = scrapedQuote.textParts
          .map(part => (typeof part === "string" ? part : ""))
          .join(" ");
        return !indexedData.quotes.has(
          tokenizeWithoutSymbols(quoteAsText).sentence
        );
      }
    }

    return true;
  });
}
