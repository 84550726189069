import { useEffect, useState } from "react";
import { Observable } from "rxjs";
import { LCE } from "../architecture/lce/lce";

export function useObservableLCE2<T, E extends Error>(
  observable: Observable<T>
) {
  const [lce, setLCE] = useState<LCE<T, E>>({ isLoading: true });

  useEffect(() => {
    try {
      observable.subscribe({
        next: content => setLCE({ content, isLoading: false }),
        error: error => setLCE({ error, isLoading: false })
      });
    } catch (error: any) {
      setLCE({ isLoading: false, error });
    }
  }, []);

  return lce;
}
