import { LocalizedProp } from "../../../types/domainTypes";
import EqualSpacedHorizontalGrid from "../../../components/grid/EqualSpacedHorizontalGrid";
import React from "react";
import { Avatar, Grid, Tooltip } from "@material-ui/core";
import WarningWrapper from "../../../components/warning/WarningWrapper";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import { trimLongText } from "../../../utils/strings/trimLongText";
import { useStyles } from "./styles";

interface MediaFeedContentProps {
  name: LocalizedProp<string>;
  description: LocalizedProp<string>;
  imageUrl?: string;
  platforms: { name: string; icon?: string; url: string }[];
  authors: LocalizedProp<string>[];
  categories: LocalizedProp<string>[];
}

export const MediaFeedContent = (props: MediaFeedContentProps) => {
  const classes = useStyles();
  return (
    <EqualSpacedHorizontalGrid>
      <WarningWrapper
        show={!props.name.isLocalized}
        message={"Missing Translation"}
      >
        <Typography>{props.name.value}</Typography>
      </WarningWrapper>
      <WarningWrapper
        show={!props.description.isLocalized}
        message={"Missing Translation"}
      >
        <Typography>
          {trimLongText({ text: props.description.value ?? "" })}
        </Typography>
      </WarningWrapper>
      <Avatar src={props.imageUrl} className={classes.showImage} />
      <Grid container justify="center" alignItems="center">
        {props.authors.map((author, index) => (
          <Grid item key={index}>
            <WarningWrapper
              show={!author.isLocalized}
              message={"Missing Translation"}
            >
              <Typography>{author.value}</Typography>
            </WarningWrapper>
          </Grid>
        ))}
      </Grid>
      <Grid container justify="center" alignItems="center">
        {props.platforms.map((platform, index) => (
          <Grid item key={index}>
            <Container>
              <Link target="_blank" href={platform.url}>
                {platform.icon ? (
                  <Tooltip title={platform.name}>
                    <Avatar
                      src={platform.icon}
                      className={classes.platformAvatar}
                    />
                  </Tooltip>
                ) : (
                  <Typography>{platform.name}</Typography>
                )}
              </Link>
            </Container>
          </Grid>
        ))}
      </Grid>
      <Grid container justify="center" alignItems="center" direction="column">
        {props.categories.map((category, index) => (
          <Grid item key={index}>
            <WarningWrapper
              show={!category.isLocalized}
              message={"Missing Translation"}
            >
              <Typography>{category.value}</Typography>
            </WarningWrapper>
          </Grid>
        ))}
      </Grid>
    </EqualSpacedHorizontalGrid>
  );
};
