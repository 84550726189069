import * as React from "react";
import ScrollableContent, { ScrollableContentProps } from "./ScrollableContent";
import { OptionalProperties } from "../../utils/generics/OptionalProperties";

interface GroupedScrollableContentProps extends OptionalProperties<ScrollableContentProps, "renderContentItem"> {
  groupSize: number;
  renderGroup: (data: any[]) => JSX.Element;
}

const GroupedScrollableContent = (props: GroupedScrollableContentProps) => {
  const groupedData = React.useMemo(() => {
    return Array(Math.ceil(props.data.length / props.groupSize))
      .fill(0)
      .map((_, index) => index * props.groupSize)
      .map(begin => props.data.slice(begin, begin + props.groupSize));
  }, [props.data, props.groupSize]);
  return (
    <ScrollableContent
      data={groupedData}
      renderContentItem={props.renderGroup}
      keyResolver={props.keyResolver}
      itemSize={props.itemSize}
      withDividers={props.withDividers}
      wrapWithPaper={props.wrapWithPaper}
    />
  );
};

export default React.memo(GroupedScrollableContent);
