import { Subscription } from "rxjs";

export class CompositeSubscription {
  private readonly subscriptions: Subscription[] = [];

  add = (subscription: Subscription) => {
    this.subscriptions.push(subscription);
  };

  unsubscribeAll = () => {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions.length = 0;
  };
}
