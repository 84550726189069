import { ManagedAuthor } from "../../ManagedAuthor";
import toManagedResource from "./managed-resource-mapper";
import calculateTimeline from "../owner-timeline-mapper";
import { Commit, CommitResource } from "../../../api/commits/Commit";
import User from "../../../api/auth/User";
import Author from "../../../api/content/Author";
import { extractValues } from "../../../utils/maps";
import { Resources } from "../../Resources";
import { Locale } from "../../../locale";

export default function toManagedAuthors({
  commits,
  users,
  authors,
  locale
}: {
  commits: Map<CommitResource, Map<string, Commit>>;
  users: Map<string, User>;
  authors: Map<string, Author>;
  locale: Locale;
}): ManagedAuthor[] {
  return toManagedResource({
    calculateTimeline: calculateTimeline("author"),
    commits: commits.get(Resources.AUTHORS) || new Map(),
    users,
    contentCollection: extractValues(authors, author => ({
      ...author
    })),
    locale
  });
}
