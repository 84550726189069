import { useViewModel } from "../../../viewmodel/useViewModel";
import { ViewModelKeys } from "../../../viewmodel/ViewModelFactoryMap";
import { TextField, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { InfoRounded } from "@material-ui/icons";

export const AnnouncementTitle = () => {
  const [viewState, viewModel] = useViewModel(
    ViewModelKeys.ANNOUNCEMENTS_VIEW_MODEL
  );

  if (!viewState.selectedAnnouncement?.editing) {
    return (
      <Typography variant={"h6"} component={"h1"}>
        {viewState.selectedAnnouncement?.announcement?.title}
      </Typography>
    );
  }

  return (
    <TextField
      defaultValue={viewState.selectedAnnouncement?.announcement?.title}
      onBlur={event => {
        viewModel.onEditAnnouncementProperty("title", event.target.value);
      }}
      style={{ width: "100%" }}
      variant={"outlined"}
      InputProps={{
        endAdornment: (
          <Tooltip
            title={"Title is only used internally and not visible to users."}
          >
            <InfoRounded />
          </Tooltip>
        )
      }}
    />
  );
};
