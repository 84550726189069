import * as React from "react";
import { useState } from "react";
import { Grid } from "@material-ui/core";
import PublishSummaryStatistics from "./statistics/PublishSummaryStatistics";
import PublishActionsPanel from "./actions/PublishActionsPanel";
import { useStyles } from "./styles";
import Box from "@material-ui/core/Box";
import LockIcon from "@material-ui/icons/Lock";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import StorageIcon from "@material-ui/icons/Storage";
import { LockOpen } from "@material-ui/icons";
import FilterNoneIcon from "@material-ui/icons/FilterNone";

const SummaryPanel = ({
  aggregatedCommits,
  updateContentLock,
  performPublish,
  toggleIndividualPublish,
  uploadBackup,
  isContentLock,
  isPublishing,
  publishEnabled
}: {
  aggregatedCommits: {
    added: number;
    changed: number;
    removed: number;
    archived: number;
    unarchived: number;
    newTranslations: {
      [locale: string]: { countryCode: string; total: number };
    };
  };
  updateContentLock?: ({ locked }: { locked: boolean }) => Promise<void>;
  performPublish: () => Promise<void>;
  toggleIndividualPublish?: () => void;
  uploadBackup?: () => Promise<void>;
  reloadStaged?: () => Promise<void>;
  isContentLock: boolean;
  isPublishing: boolean;
  publishEnabled: boolean;
}) => {
  const [loadingActions, setLoadingActions] = useState<{
    publish?: boolean;
    individualPublish?: boolean;
    lock?: boolean;
    upload?: boolean;
    reload?: boolean;
  }>({});

  const classes = useStyles();
  const clickWrapper = (
    actionType: string,
    action: () => Promise<void> | undefined
  ) => () => {
    setLoadingActions({ ...loadingActions, [actionType]: true });
    action()?.then(() => {
      setLoadingActions({ ...loadingActions, [actionType]: false });
    });
  };

  const panelItems: JSX.Element[] = [];
  const anyStatistics =
    aggregatedCommits.added ||
    aggregatedCommits.changed ||
    aggregatedCommits.removed ||
    aggregatedCommits.archived ||
    aggregatedCommits.unarchived ||
    Object.values(aggregatedCommits.newTranslations)
      .map(({ total }) => total)
      .some(total => total);
  if (anyStatistics) {
    panelItems.push(
      <PublishSummaryStatistics
        totalAdded={aggregatedCommits.added}
        totalChanged={aggregatedCommits.changed}
        totalRemoved={aggregatedCommits.removed}
        totalArchived={aggregatedCommits.archived}
        totalUnarchived={aggregatedCommits.unarchived}
        newTranslations={aggregatedCommits.newTranslations}
      />
    );
  }

  panelItems.push(
    <PublishActionsPanel
      actions={[
        {
          isLoading: loadingActions.lock,
          description: isContentLock ? "Unlock changes" : "Lock changes",
          renderIcon: () => (isContentLock ? <LockOpen /> : <LockIcon />),
          onClick: clickWrapper("lock", () =>
            updateContentLock?.({
              locked: !isContentLock
            })
          ),
          disable: isPublishing
        },
        {
          isLoading: loadingActions.publish,
          description: "Publish changes",
          renderIcon: () => <CloudUploadIcon />,
          onClick: clickWrapper("publish", performPublish),
          disable: isPublishing || !publishEnabled
        },
        {
          isLoading: loadingActions.individualPublish,
          description: "Publish individual changes",
          renderIcon: () => <FilterNoneIcon />,
          onClick: clickWrapper("individualPublish", () =>
            Promise.resolve(toggleIndividualPublish?.())
          ),
          disable: isPublishing
        },
        {
          isLoading: loadingActions.upload,
          description: "Load Backup",
          renderIcon: () => <StorageIcon />,
          onClick: clickWrapper("upload", () => uploadBackup?.()),
          disable: isPublishing
        }
      ]}
    />
  );

  return (
    <Grid
      container
      spacing={2}
      justify="flex-start"
      alignItems="center"
      className={classes.container}
    >
      {panelItems.map(panelItem => (
        <Grid item className={classes.parentContainerHeight}>
          <Box className={classes.panelItemWrapper} border={1}>
            {panelItem}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default SummaryPanel;
