import React from "react";
import { Moment } from "moment";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";

export const CalendarActionBarDateAction = ({
  onActionClicked
}: {
  onActionClicked: () => void;
}) => {
  return (
    <Button
      startIcon={<AddIcon />}
      variant="contained"
      color="primary"
      onClick={onActionClicked}
    >
      <Typography>New Event</Typography>
    </Button>
  );
};
