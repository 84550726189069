import React from "react";
import { DaysOfTheWeek } from "./DaysOfTheWeek";
import { MonthDates } from "./MonthDates";
import { Moment } from "moment";
import { CalendarEvent } from "../../../repository/use-case/calendar/CalendarEvent";
import { Box } from "@material-ui/core";

export const CalendarMonthView = ({
  momentToShow,
  selectedDates,
  onDatesSelected,
  onDateRangeDisplayed,
  events,
  onEventSelected,
  momentProvider
}: {
  momentToShow?: Moment;
  selectedDates: Moment[];
  onDatesSelected: (dates: Moment[]) => void;
  onDateRangeDisplayed: (dates: Moment[]) => void;
  events: CalendarEvent[];
  onEventSelected?: (
    element: React.MouseEvent<HTMLButtonElement>,
    event: CalendarEvent
  ) => void;
  momentProvider: () => Moment;
}) => {
  return (
    <>
      <DaysOfTheWeek />
      <Box style={{ overflowY: "auto", height: "auto", alignSelf: "stretch" }}>
        <MonthDates
          selectedDates={selectedDates}
          momentToShow={momentToShow}
          onDatesSelected={onDatesSelected}
          onDateRangeDisplayed={onDateRangeDisplayed}
          events={events}
          onEventSelected={onEventSelected}
          momentProvider={momentProvider}
        />
      </Box>
    </>
  );
};
