import { BaseViewModel } from "../../architecture/view-model/BaseViewModel";
import type * as Icons from "@material-ui/icons";
import { FileManagementRepository } from "../../domain/file-management/data/FileManagementRepository";
import { combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { File, FileState } from "../../domain/file-management/entities/File";
import { UpdateNavigationCallback } from "../viewmodel/navigation/UpdateNavigationCallback";
import { NavigationEffect } from "../viewmodel/navigation/NavigationEffect";
import { CompositeSubscription } from "../../utils/observables/composite-subscription";
import { lce, LCE } from "../../architecture/lce/lce";
import { promiseToLCEObservable } from "../../architecture/lce/lce-promise-observable";
import { FileAction } from "../../domain/file-management/entities/FileAction";
import { PredefinedFileActions } from "../../domain/file-management/entities/PredefinedFileActions";

export interface FileManagerPathFragment {
  name: string;
  relativePath: string;
}

export interface FileManagerAction {
  type: string;
  icon: keyof typeof Icons;
  description?: string;
  active?: boolean;
}

export type FileManagerFileListItem = FileManagerDirectory | FileManagerFile;

export interface FileManagerDirectory {
  type: "directory";
  id: string;
  name: string;
}

export interface FileManagerFile {
  type: "file";
  id: string;
  name: string;
  extension?: string;
  thumbnail_small?: string;
  thumbnail_medium?: string;
  fileType: string;
  state: FileState;
  fileObjectBacklink?: string;
  publicLinks?: {
    original: string;
    resizedPerPlatform?: Record<string, Record<string,string>>
  }
}

type FileManagerPathStack = {
  fragments: FileManagerPathFragment[];
  fullPath: string;
};

export enum ViewMode {
  LIST,
  GRID
}

export interface FileDetails {
  metadata: Record<string, string>;
  availableActions: FileAction[];
}

export interface FileManagerViewState {
  rootPath: string;
  pathIndex: number;
  pathStack: FileManagerPathStack[];
  toolbar: {
    actionGroups: FileManagerAction[][];
  };
  pathContents: {
    loading: boolean;
    fileList: FileManagerFileListItem[]
  };
  navigationState: {
    canNavigateBack: boolean,
    canNavigateForward: boolean
  };
  pendingAction?: {
    type: string;
    payload?: any;
    isLoading?: boolean;
    error?: Error;
  };
  viewMode: ViewMode;
  fileDetailedView?: {
    fileId?: string;
    fileItem?: FileManagerFileListItem,
    detailsLCE?: LCE<FileDetails>,
    actionState?: Record<PredefinedFileActions, LCE<any>>
  };
}

const defaultInitialState = {
  pathIndex: 0,
  navigationState: {
    canNavigateBack: false,
    canNavigateForward: false
  },
  pathContents: { loading: false, fileList: [] },
  viewMode: ViewMode.LIST
};

export type FileManagerViewEffectsBase = FileManagerInitiateUploadViewEffect | FileManagerInitiateDownloadViewEffect;

export interface FileManagerInitiateUploadViewEffect {
  type: "initiate_upload";
}

export interface FileManagerInitiateDownloadViewEffect {
  type: "initiate_download";
  url: string;
  fileName: string;
}

export interface NavigationParams {
  fileId?: string;
}

export abstract class FileManagerViewModelBase<ViewEffects = {}> extends BaseViewModel<FileManagerViewState, ViewEffects | FileManagerViewEffectsBase | Partial<NavigationEffect<any>>> implements UpdateNavigationCallback<any> {

  private readonly compositeSubscription = new CompositeSubscription();

  protected constructor(
    initialState: Omit<FileManagerViewState, keyof typeof defaultInitialState | "pathStack">,
    protected readonly fileManagementRepository: FileManagementRepository
  ) {
    super({
      ...defaultInitialState,
      ...initialState,
      pathStack: [{
        fullPath: initialState.rootPath,
        fragments: []
      }]
    });
  }

  init() {
    const currentFilesObservable = this.state
      .pipe(
        map(state => state.pathStack[state.pathIndex]?.fullPath ?? state.rootPath),
        distinctUntilChanged(),
        tap(() => {
          this.mergeState({
            pathContents: {
              loading: true
            }
          });
        }),
        switchMap(path => this.fileManagementRepository.observeFiles(path)),
        shareReplay()
      );
    this.compositeSubscription.add(currentFilesObservable
      .subscribe(files => {
        this.mergeState({
          pathContents: {
            loading: false,
            fileList: files.map(FileManagerViewModelBase.mapFileToItem).sort((f1, f2) => f1.name.localeCompare(f2.name))
          }
        });
      }));

    const currentFileId = this.state.pipe(
      map(state => state.fileDetailedView?.fileId),
      distinctUntilChanged(),
      filter((fileId): fileId is string => !!fileId),
      shareReplay()
    );
    const currentFile = combineLatest([
      currentFileId,
      currentFilesObservable
    ]).pipe(
      map(([fileId, currentFiles]) => currentFiles.filter(file => file.id === fileId)[0]),
      shareReplay()
    );
    const fileDetailsResponse = currentFileId.pipe(
      switchMap(fileId => promiseToLCEObservable(this.fileManagementRepository.getFileDetails(this.currentPathname(), fileId)))
    );

    this.compositeSubscription.add(combineLatest([
      currentFile,
      fileDetailsResponse
    ])
      .pipe(
        map(([file, fileDetailsResponseLCE]) => ({
          file,
          fileDetailsLCE: fileDetailsResponseLCE
        })),
        filter(fileDetails => !!fileDetails)
      ).subscribe(({ file, fileDetailsLCE }) => {
        this.mergeState({
          fileDetailedView: {
            fileItem: FileManagerViewModelBase.mapFileToItem(file),
            detailsLCE: fileDetailsLCE
          }
        });
      }));
  }

  clear() {
    this.compositeSubscription.unsubscribeAll();
  }

  protected overrideNextState(nextStateCandidate: FileManagerViewState): FileManagerViewState {
    return {
      ...nextStateCandidate,
      navigationState: {
        canNavigateBack: nextStateCandidate.pathIndex > 0,
        canNavigateForward: nextStateCandidate.pathIndex < nextStateCandidate.pathStack.length - 1
      }
    };
  }

  onNavigationLocationUpdated(navigationParams: Partial<NavigationParams>, pathname: string): void {
    const updatedState: Partial<FileManagerViewState> = {
      fileDetailedView: {}
    };
    if (navigationParams.fileId) {
      updatedState.fileDetailedView = {
        fileId: navigationParams.fileId,
        fileItem: undefined
      };
    } else {
      updatedState.fileDetailedView = undefined;
    }
    if (this.currentPathname() !== pathname) {
      // The navigated url was probably entered manually by the user (or referred to by link?).
      // In this case we want to rebuild the entire navigation stack
      const relativePath = pathname.replace(this.currentState().rootPath, "");
      const updatedStack = relativePath.split("/").filter(fragment => fragment).reduce((pathStack, fragment) => {
        const previousPath = pathStack[pathStack.length - 1];
        pathStack.push(this.createNextPathFragment(previousPath, fragment));
        return pathStack;
      }, [{
        fragments: [],
        fullPath: this.currentState().rootPath
      }] as FileManagerPathStack[]);
      updatedState.pathStack = updatedStack;
      updatedState.pathIndex = updatedStack.length - 1;
    }
    this.mergeState(updatedState);
  }

  private currentPathname(): string {
    return this.currentPath()?.fullPath ?? this.currentState().rootPath;
  }

  private currentPath(): FileManagerPathStack | undefined {
    return this.currentState().pathStack[this.currentState().pathIndex];
  }

  protected navigateToPath(path: string) {
    this.nextEffect({ navigation: { setPath: path } });
  }

  onNavigateBack() {
    const pathIndex = this.currentState().pathIndex;
    if (pathIndex === 0) {
      // Already at root.
      return;
    }
    const nextIndex = pathIndex - 1;
    this.navigateToPath(this.currentState().pathStack[nextIndex].fullPath);
    this.mergeState({
      pathIndex: nextIndex
    });
  }

  onNavigateHome() {
    this.navigateToPath(this.currentState().rootPath);
    this.mergeState({
      pathIndex: 0
    });
  }

  onNavigateForward() {
    const pathIndex = this.currentState().pathIndex;
    if (pathIndex === this.currentState().pathStack.length - 1) {
      // Already at the top of the stack.
      return;
    }
    const nextIndex = pathIndex + 1;
    this.navigateToPath(this.currentState().pathStack[nextIndex].fullPath);
    this.mergeState({
      pathIndex: nextIndex
    });
  }

  onNavigateToBreadcrumb(fragment: FileManagerPathFragment) {
    this.navigateToPath(`${this.currentState().rootPath}/${fragment.relativePath}`);
  }

  abstract onActionClick(action: FileManagerAction): void;

  onClickCancelPendingAction() {
    this.mergeState({
      pendingAction: undefined
    });
  }

  private createNextPathFragment(currentPathStack: FileManagerPathStack, fragment: string) {
    const currentRelativePath = currentPathStack?.fragments[currentPathStack?.fragments?.length - 1]?.relativePath;
    return {
      fragments: [
        ...(currentPathStack?.fragments ?? []),
        {
          name: fragment,
          relativePath: currentRelativePath ? currentRelativePath + "/" + fragment : fragment
        }
      ],
      fullPath: currentPathStack?.fullPath ? (currentPathStack.fullPath + "/" + fragment) : (this.currentState().rootPath + "/" + fragment)
    };
  }

  onFileClick(fileItem: FileManagerFileListItem) {
    if (isDirectory(fileItem)) {
      const currentPath = this.currentState().pathStack[this.currentState().pathIndex];
      const nextPath = currentPath.fullPath + `/${fileItem.name}`;
      const updatedPathStack = [...this.currentState().pathStack];
      updatedPathStack.push(this.createNextPathFragment(currentPath, fileItem.name));
      this.navigateToPath(nextPath);
    } else {
      if (fileItem.id === this.currentState().fileDetailedView?.fileId) {
        return;
      }
      this.nextEffect({
        navigation: {
          updateLocationParams: {
            fileId: fileItem.id
          }
        }
      });
    }
  }

  onCloseFileDetails() {
    this.nextEffect({
      navigation: {
        clearParams: ["fileId"]
      }
    });
  }

  onCreateNewFolder(folderName: string) {
    this.performPendingAction(this.fileManagementRepository.createNewFolder(this.currentPathname(), folderName));
  }

  onConfirmDeleteFile(fileItem: FileManagerFileListItem) {
    this.performPendingAction(this.fileManagementRepository.deleteFile(
      this.currentPathname(),
      fileItem.name
    ));
  }

  onConfirmNewName(fileItem: FileManagerFileListItem, newName: string) {
    this.performPendingAction(this.fileManagementRepository.renameFile(
      this.currentPathname(),
      fileItem.name, newName
    ));
  }

  onConfirmGeneratePublicLinks(
    fileItem: FileManagerFileListItem,
    options: {
      path: string,
      name: string,
      variants: string[],
      resize: {
        capResolution: boolean,
        ios: boolean,
        android: boolean
      }
    }
  ) {
    this.performPendingAction(this.fileManagementRepository.generatePublicLinks(
      this.currentPathname(),
      fileItem.id,
      {
        path: options.path,
        fileName: options.name,
        resizeForPlatforms: options.resize,
        variants: options.variants
      }
    ))
  }

  private performPendingAction(action: Promise<any>) {
    this.mergeState({
      pendingAction: {
        isLoading: true
      }
    })
    return action.then(() => this.mergeState({
      pendingAction: undefined
    }))
      .catch(error => this.mergeState({
        pendingAction: {
          isLoading: false,
          error
        }
      }));
  }

  abstract onClickDeleteFile(file: FileManagerFileListItem): void;

  abstract onClickRenameFile(file: FileManagerFileListItem): void;

  onSelectFilesToUpload(fileList: FileList) {
    this.fileManagementRepository.beginFileUpload(
      this.currentPathname(),
      fileList
    );
  }

  onSelectViewMode(viewMode: ViewMode) {
    this.mergeState({
      viewMode
    });
  }

  onClickAction(actionId: PredefinedFileActions) {
    if (this.currentState().fileDetailedView?.actionState?.[actionId].isLoading) {
      return;
    }

    const currentFileId = this.currentState().fileDetailedView?.fileId;
    if (!currentFileId) {
      this.mergeState({
        fileDetailedView: {
          actionState: {
            [actionId]: lce.error(new Error("No selected file."))
          }
        }
      });
      return;
    }

    const currentFile = this.currentState().pathContents.fileList.find(file => file.id === currentFileId);
    if (!currentFile || currentFile.type !== "file") {
      this.mergeState({
        fileDetailedView: {
          actionState: {
            [actionId]: lce.error(new Error("Unable to resolve current file."))
          }
        }
      });
      return;
    }

    if (!currentFile.fileObjectBacklink) {
      this.mergeState({
        fileDetailedView: {
          actionState: {
            [actionId]: lce.error(new Error("Current file doesn't have a corresponding object in storage."))
          }
        }
      });
      return;
    }

    let actionObservable: Observable<LCE<any>> | undefined;
    switch (actionId) {
      case PredefinedFileActions.FILE_ACTION_DOWNLOAD:
        actionObservable = promiseToLCEObservable(this.fileManagementRepository.generateDownloadLink(currentFile.fileObjectBacklink)).pipe(
          tap(lce => {
            if (lce.content) {
              this.nextEffect({
                type: "initiate_download",
                url: lce.content,
                fileName: currentFile.name
              });
            }
          })
        );
        break;
      case PredefinedFileActions.FILE_ACTION_GENERATE_APP_RESOURCE:
        this.mergeState({
          pendingAction: {
            type: "generate_links_form"
          }
        });
        break;
    }

    if (actionObservable) {
      this.compositeSubscription.add(actionObservable.subscribe(lce => {
        if (this.currentState().fileDetailedView?.fileId !== currentFileId) {
          // Make sure to not update action state if the selected file is updated during execution.
          return;
        }
        this.mergeState({
          fileDetailedView: {
            actionState: {
              [actionId]: lce
            }
          }
        });
      }));

    }
  }

  protected initiateUpload() {
    this.nextEffect({
      type: "initiate_upload"
    });
  }

  private static mapFileToItem(file: File): FileManagerFileListItem {
    if (file.type === "directory") {
      return {
        id: file.id,
        type: "directory",
        name: file.name
      };
    } else {
      return {
        id: file.id,
        type: "file",
        name: file.name,
        extension: file.extension,
        thumbnail_small: file.thumbnail_small,
        thumbnail_medium: file.thumbnail_medium,
        fileType: file.fileType,
        state: file.state,
        fileObjectBacklink: file.fileObjectBacklink,
        publicLinks: file.publicLinks
      };
    }
  }
}

function isDirectory(fileItem: FileManagerFileListItem): fileItem is FileManagerDirectory {
  return fileItem.type === "directory";
}
