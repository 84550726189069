import React, { DOMAttributes, ReactNode, useCallback } from "react";

export interface ManipulateChildrenProps {
  injectProps: (child: ReactNode) => DOMAttributes<any>;
}

export const ManipulateChildren = (
  props: React.PropsWithChildren<ManipulateChildrenProps>
) => {
  const cloneChildWithInjectedProps = useCallback(
    (child: ReactNode, index: number = 0) => {
      if (isJSXElement(child)) {
        return React.cloneElement(child, {
          ...props.injectProps(child)
        });
      }
      return child;
    },
    []
  );

  return (
    <>
      {Array.isArray(props.children)
        ? props.children.map((child, index) =>
            cloneChildWithInjectedProps(child, index)
          )
        : cloneChildWithInjectedProps(props.children)}
    </>
  );
};

function isJSXElement(child: ReactNode): child is JSX.Element {
  return child && (child as JSX.Element).props;
}
