import * as React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./styles";

export const LCEDefaultError = (error: Error) => {
  const classes = useStyles();
  return (
    <Typography color="textPrimary" className={classes.viewPortCenter}>
      {error?.message}
    </Typography>
  );
};
