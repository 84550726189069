import React, { useCallback, useEffect, useState } from "react";
import {
  ConversationEntry,
  ConversationStatus
} from "../../../../repository/use-case/users-voice/dto/ListConversationsResponse";
import { useRepositories } from "../../../../hooks/useRepositories";
import { LCEWrapper } from "../../../../components/lce/LCEWrapper";
import useLCE2 from "../../../../hooks/useLCE2";
import { Conversation } from "./Conversation";
import { ConversationActionSelection } from "./ConversationActions";
import { DeleteConversationDialog } from "./dialogs/DeleteConversationDialog";
import { ConfirmStatusChangeDialog } from "./dialogs/ConfirmStatusChangeDialog";
import { SendConversationMessageResponse } from "../../../../repository/use-case/users-voice/dto/SendConversationMessageResponse";
import { LCE } from "../../../../architecture/lce/lce";

export const ConversationWindow = ({
  conversation,
  onConversationDeleted,
  sendReplyLCE,
  onConversationStatusChanged,
  onReply
}: {
  conversation: ConversationEntry;
  onConversationDeleted: () => void;
  sendReplyLCE: LCE<SendConversationMessageResponse>;
  onConversationStatusChanged?: () => void;
  onReply?: (content: string) => void;
}) => {
  const { usersVoice } = useRepositories();
  const [messagesUpdated, setMessagesUpdated] = useState(0);
  const contentLoading = useCallback(
    () =>
      usersVoice.listMessages({
        conversationId: conversation.id
      }),
    [conversation.id, usersVoice, messagesUpdated]
  );
  useEffect(() => {
    if (sendReplyLCE.content) {
      setMessagesUpdated(currentCount => currentCount + 1);
    }
  }, [sendReplyLCE.content]);
  const messagesLCE = useLCE2(contentLoading);

  const [visibleDialog, setVisibleDialog] = useState<{
    openDialog?: "delete" | "send" | "status";
    newStatus?: ConversationStatus;
  }>({});

  const onConversationActionSelection = useCallback(
    (conversationActionSelection: ConversationActionSelection) => {
      switch (conversationActionSelection.type) {
        case "delete":
          setVisibleDialog({ openDialog: "delete" });
          break;
        case "send":
          onReply?.(conversationActionSelection.messageContent);
          break;
        case "status":
          setVisibleDialog({
            openDialog: "status",
            newStatus: conversationActionSelection.newStatus
          });
          break;
      }
    },
    [onReply]
  );

  const onDialogClosed = useCallback(
    ({
      didDelete,
      didChange
    }: { didDelete?: boolean; didChange?: boolean } | undefined = {}) => {
      setVisibleDialog({});
      if (didDelete) {
        onConversationDeleted();
      }
      if (didChange) {
        onConversationStatusChanged?.();
      }
    },
    [onConversationDeleted, onConversationStatusChanged]
  );

  return (
    <>
      <LCEWrapper
        lce={messagesLCE}
        renderContent={({ messages }) => (
          <Conversation
            conversation={conversation}
            messages={messages}
            onConversationActionSelection={onConversationActionSelection}
            isReplyLoading={sendReplyLCE.isLoading}
          />
        )}
      />
      <DeleteConversationDialog
        conversationId={conversation.id}
        isOpen={visibleDialog.openDialog === "delete"}
        handleClose={onDialogClosed}
      />
      <ConfirmStatusChangeDialog
        conversationId={conversation.id}
        isOpen={visibleDialog.openDialog === "status"}
        handleClose={onDialogClosed}
        newStatus={visibleDialog.newStatus!}
      />
    </>
  );
};
