import {
  GenericFormDialog,
  GenericFormDialogField,
  GenericFormDialogTextInputField
} from "../../../components/dialogs/GenericFormDialog";
import React, { useCallback, useMemo } from "react";
import { LocalViewModelFactory } from "../../../architecture/view-model/LocalViewModelFactory";
import {
  FileManagerFileListItem,
  FileManagerViewModelBase
} from "../FileManagerViewModelBase";
import { useLocalViewModel } from "../../../architecture/view-model/useViewModel";

export interface CreateNewFolderDialogProps {
  viewModelFactory: LocalViewModelFactory<FileManagerViewModelBase>;
}

export const RenameFileDialog = (props: CreateNewFolderDialogProps) => {
  const [viewState, viewModel] = useLocalViewModel(props.viewModelFactory);
  const onClose = useCallback(() => viewModel.onClickCancelPendingAction(), [
    viewModel
  ]);
  const currentFile: FileManagerFileListItem | undefined =
    viewState.pendingAction?.payload;
  const newNameField = useMemo<GenericFormDialogTextInputField>(
    () => ({
      type: "textInput",
      hint: "New Name",
      defaultValue: currentFile?.name,
      validate(value: string | undefined): string | null {
        if (!value || !value.trim()) {
          return "Please enter a valid name.";
        }
        if (currentFile?.name === value) {
          return "Please enter a different name.";
        }
        return null;
      }
    }),
    [currentFile]
  );
  const fields = useMemo(() => [newNameField], [newNameField]);
  const actions = useMemo(() => {
    if (!currentFile) {
      return [];
    }
    return [
      {
        type: "cancel" as const,
        label: "Cancel"
      },
      {
        type: "save" as const,
        label: "Save",
        onSave: (currentValues: Map<GenericFormDialogField, any>) => {
          viewModel.onConfirmNewName(
            currentFile,
            currentValues.get(newNameField)
          );
        }
      }
    ];
  }, [currentFile, newNameField, viewModel]);
  return (
    <GenericFormDialog
      isLoading={viewState.pendingAction?.isLoading}
      open={viewState.pendingAction?.type === "rename_file"}
      onClose={onClose}
      title={"Rename File"}
      actions={actions}
      fields={fields}
    />
  );
};
