import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Divider, LinearProgress, Paper } from "@material-ui/core";
import { FileBrowserToolbar } from "./toolbar/FileBrowserToolbar";
import useStyles from "./styles";
import { getPath } from "../../components/routes/AppRoutes";
import { CMSRoutes } from "../../constants/routes/cms-routes";
import { FileManagerDisplay } from "./display/FileManagerDisplay";
import { useRepositories } from "../../hooks/useRepositories";
import { LocalViewModelFactory } from "../../architecture/view-model/LocalViewModelFactory";
import { LazySupplier } from "../../utils/supplier/LazySupplier";
import { FileManagerViewModel } from "./FileManagerViewModel";
import {
  useLocalViewModel,
  ViewModelEffects
} from "../../architecture/view-model/useViewModel";
import { FileUpload } from "../../components/upload/FileUpload";
import { useLocalViewModelInPageNavigation } from "../viewmodel/navigation/useLocalViewModelInPageNavigation";
import { CreateNewFolderDialog } from "./actions/CreateNewFolderDialog";
import { DeleteFileDialog } from "./actions/DeleteFileDialog";
import { RenameFileDialog } from "./actions/RenameFileDialog";
import { useCookies } from "react-cookie";
import { Cookies } from "../../cookies";
import Box from "@material-ui/core/Box";
import * as FileSaver from "file-saver";
import { GeneratePublicLinksDialog } from "./actions/GeneratePublicLinksDialog";

export const FileManagerPage = () => {
  const { fileManagement } = useRepositories();
  const viewModelSupplier = useMemo(
    () =>
      new LazySupplier(() => {
        const rootPath = getPath(CMSRoutes.FILE_MANAGER);
        if (!rootPath) {
          throw new Error(`Unable to find path for ${CMSRoutes.FILE_MANAGER}`);
        }
        return new FileManagerViewModel(rootPath, fileManagement);
      }),
    [fileManagement]
  );
  const localViewModelFactory = useMemo(
    () => new LocalViewModelFactory(() => viewModelSupplier.get()),
    [viewModelSupplier]
  );

  const [cookies, setCookies] = useCookies([Cookies.FILE_MANAGER_VIEW_MODE]);
  const initiateUploadRef = useRef<Function>();
  const onViewEffect = useCallback(
    (effect: ViewModelEffects<ReturnType<typeof viewModelSupplier["get"]>>) => {
      if (!("type" in effect)) {
        return;
      }
      switch (effect.type) {
        case "initiate_upload":
          initiateUploadRef.current?.();
          break;
        case "initiate_download":
          FileSaver.saveAs(effect.url, effect.fileName);
          break;
        case "selected_view_mode":
          setCookies(Cookies.FILE_MANAGER_VIEW_MODE, {
            viewMode: effect.viewMode
          });
          break;
      }
    },
    [setCookies, viewModelSupplier]
  );
  const [viewState, viewModel] = useLocalViewModel(
    localViewModelFactory,
    onViewEffect
  );
  useLocalViewModelInPageNavigation(localViewModelFactory);
  const onFilesSelected = useCallback(
    (fileList: FileList | null) => {
      if (fileList) {
        viewModel.onSelectFilesToUpload(fileList);
      }
    },
    [viewModel]
  );
  useEffect(() => {
    const viewMode = cookies[Cookies.FILE_MANAGER_VIEW_MODE]?.viewMode;
    if (viewMode !== undefined) {
      viewModel.onSelectViewMode(viewMode);
    }
  }, [cookies, viewModel]);

  const classes = useStyles();
  return (
    <Paper className={classes.container}>
      {viewState.pathContents.loading && <LinearProgress variant={"query"} />}
      <Box style={{ margin: 8 }}>
        <FileBrowserToolbar viewModelFactory={localViewModelFactory} />
      </Box>
      <Divider />
      <FileManagerDisplay viewModelFactory={localViewModelFactory} />
      <FileUpload
        accept={["image/*", ".zip"]}
        multiple
        onFilesSelected={onFilesSelected}
        render={initiateUpload => {
          initiateUploadRef.current = initiateUpload;
        }}
      />
      <CreateNewFolderDialog viewModelFactory={localViewModelFactory} />
      <DeleteFileDialog viewModelFactory={localViewModelFactory} />
      <RenameFileDialog viewModelFactory={localViewModelFactory} />
      <GeneratePublicLinksDialog viewModelFactory={localViewModelFactory} />
    </Paper>
  );
};
