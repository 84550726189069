import { FileManagerViewModelBase } from "../FileManagerViewModelBase";
import { useLocalViewModel } from "../../../architecture/view-model/useViewModel";
import { Breadcrumbs, Chip, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { NavigateNext } from "@material-ui/icons";
import { LocalViewModelFactory } from "../../../architecture/view-model/LocalViewModelFactory";

export interface FileBrowserBreadcrumbsProps {
  viewModelFactory: LocalViewModelFactory<FileManagerViewModelBase>;
}

export const FileBrowserBreadcrumbs = (props: FileBrowserBreadcrumbsProps) => {
  const [viewState, viewModel] = useLocalViewModel(props.viewModelFactory);
  const breadcrumbs = useMemo(() => {
    const currentPath = viewState.pathStack[viewState.pathIndex];
    const currentFragments = currentPath?.fragments ?? [
      {
        name: "Home",
        relativePath: viewState.rootPath
      }
    ];
    return currentFragments.reduce((acc, fragment, index) => {
      if (index === currentFragments.length - 1) {
        acc.push(<Typography color="textPrimary">{fragment.name}</Typography>);
      } else {
        acc.push(
          <Chip
            label={fragment.name}
            onClick={() => viewModel.onNavigateToBreadcrumb(fragment)}
          />
        );
      }
      return acc;
    }, [] as JSX.Element[]);
  }, [viewModel, viewState.pathIndex, viewState.pathStack, viewState.rootPath]);

  return (
    <Breadcrumbs
      separator={<NavigateNext fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};
