import React from "react";
import Destination from "./Destination";
import { DeveloperConsoleRoutes } from "../../constants/routes/developer-console-routes";
import { Receipt, Healing, Lock } from "@material-ui/icons";

export const developerConsoleDestinations = (): Array<Destination> => [
  {
    name: "Logs",
    relativePath: DeveloperConsoleRoutes.LOGS,
    icon: <Receipt />
  },
  {
    name: "Diagnostics",
    relativePath: DeveloperConsoleRoutes.DIAGNOSTICS,
    icon: <Healing />
  },
  {
    name: "Permissions",
    relativePath: DeveloperConsoleRoutes.PERMISSIONS,
    icon: <Lock />
  }
];
