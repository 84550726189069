import { CommitResource } from "../../api/commits/Commit";
import { Locale } from "../../locale";
import ManagedResource from "../../data/ManagedResource";
import { Resources } from "../../data/Resources";
import { extractFirstLocalizedProp } from "../../utils/data/resources/managed";
import { AuthorFields } from "../../api/content/Author";
import { tokenizeWithoutSymbols } from "../../utils/diff/tokenizer";
import { QuoteFields } from "../../api/content/Quote";
import { CategoryFields } from "../../api/content/Category";
import LocalesRepository from "../../repository/use-case/locales/LocalesRepository";

export function createIndexedData({
  managedContentByLocale,
  locales
}: {
  managedContentByLocale: Map<
    CommitResource,
    Map<Locale, ManagedResource<any>[]>
  >;
  locales: LocalesRepository;
}): IndexedData {
  function extractKeyIdentifier(commitResource: CommitResource, field: string) {
    return new Set(
      managedContentByLocale
        .get(commitResource)
        ?.get(locales.defaultLocale)
        ?.map(managedResource => {
          const keyIdentifier = extractFirstLocalizedProp(
            locales.defaultLocale,
            managedResource,
            field
          ).value;
          return tokenizeWithoutSymbols(keyIdentifier ?? "").sentence;
        })
    );
  }

  return {
    authors: extractKeyIdentifier(Resources.AUTHORS, AuthorFields.NAME),
    quotes: extractKeyIdentifier(Resources.QUOTES, QuoteFields.TEXT),
    categories: extractKeyIdentifier(Resources.CATEGORIES, CategoryFields.NAME)
  };
}

export interface IndexedData {
  authors: Set<string>;
  quotes: Set<string>;
  categories: Set<string>;
}
