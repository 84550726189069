import makeStyles from "@material-ui/core/styles/makeStyles";
import pageContainer from "../../../styles/pageContainer";
import {
  scrollableContentContainer,
  scrollableContentWrapper
} from "../../../styles/scrollableContent";

const useStyles = makeStyles(theme => ({
  container: {
    ...pageContainer(theme)
  },
  contentContainer: {
    ...scrollableContentContainer(theme),
    width: "100%"
  },
  scrollableContent: {
    ...scrollableContentWrapper(theme)
  },
  grid: {
    maxHeight: "100%"
  },
  fixedGridItem: {
    width: "100%"
  },
  limitedGridItem: {
    overflow: "hidden",
    flex: 1,
    width: "100%"
  },
  statisticsItemContainer: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
    width: "100%"
  },
  emptyMessage: {
    position: "absolute",
    top: "50vh",
    left: "50vw",
    transform: "translate(-50%, -50%)"
  }
}));

export default useStyles;
