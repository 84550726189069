import * as React from "react";
import { LCEDefaultLoader } from "./LCEDefaultLoader";
import { LCEDefaultError } from "./LCEDefaultError";
import { LCE } from "../../architecture/lce/lce";

export const LCEWrapper = <T extends any>({
  lce,
  renderLoader = LCEDefaultLoader,
  renderContent,
  renderError = LCEDefaultError
}: {
  lce: LCE<T, Error>;
  renderLoader?: (() => JSX.Element) | null;
  renderContent: (content: T) => JSX.Element;
  renderError?: ((error: Error) => JSX.Element) | null;
}) => {
  if (lce.isLoading && renderLoader) {
    return renderLoader?.();
  }

  if (lce.error && renderError) {
    return renderError(lce.error);
  }

  return renderContent(lce.content!!);
};
