import { useCallback } from "react";
import { isNavigationEffect } from "./NavigationEffect";
import { fromPairs } from "lodash";
import { RecursivePartial } from "../../../utils/utilityTypes";
import { useInPageNavigation } from "../../../hooks/useInPageNavigation";
import { useHistory } from "react-router-dom";

export function useNavigationEffectCallback<PageParams extends object>() {
  const [pageParams, setPageParams, updateParams, goBack] = useInPageNavigation<
    PageParams
  >();
  const history = useHistory();
  const effectCallback = useCallback(
    effect => {
      if (isNavigationEffect<PageParams>(effect)) {
        if (effect.navigation.setLocationParams) {
          setPageParams(effect.navigation.setLocationParams);
        } else if (effect.navigation.updateLocationParams) {
          updateParams(effect.navigation.updateLocationParams);
        } else if (effect.navigation.clearParams) {
          updateParams(
            fromPairs(
              effect.navigation.clearParams.map(param => [param, undefined])
            ) as RecursivePartial<PageParams>
          );
        } else if (effect.navigation.goBack) {
          goBack();
        } else if (effect.navigation.setPath) {
          history.push(effect.navigation.setPath);
        }
      }
    },
    [goBack, history, setPageParams, updateParams]
  );
  return [history.location.pathname, pageParams, effectCallback] as const;
}
