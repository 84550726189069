import { DescriptiveIconButton } from "../../../../components/buttons/DescriptiveIconButton";
import { Cancel, DeleteForever, Edit, Save } from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import React from "react";
import { useViewModel } from "../../../viewmodel/useViewModel";
import { ViewModelKeys } from "../../../viewmodel/ViewModelFactoryMap";
import useTheme from "@material-ui/core/styles/useTheme";

export const AnnouncementDetailsActions = ({
  announcementId,
  isEditing
}: {
  announcementId: string;
  isEditing: boolean;
}) => {
  const [viewState, viewModel] = useViewModel(
    ViewModelKeys.ANNOUNCEMENTS_VIEW_MODEL
  );
  const theme = useTheme();

  if (isEditing) {
    return (
      <Box>
        <DescriptiveIconButton
          icon={<Save />}
          enabled={!!viewState.actionsState?.saveEnabled}
          description={"Save changes"}
          color={theme.contentManagement?.create.color}
          onClick={viewModel.onClickSaveEdits}
        />
        <DescriptiveIconButton
          icon={<Cancel />}
          description={"Cancel changes made"}
          onClick={viewModel.onClickCancelEdit}
        />
      </Box>
    );
  }

  return (
    <Box>
      <DescriptiveIconButton
        icon={<Edit />}
        description={"Edit this announcement"}
        onClick={viewModel.onClickEdit}
      />
      <DescriptiveIconButton
        icon={<DeleteForever />}
        description={"Delete this announcement"}
        color={theme.contentManagement?.remove.color}
        onClick={viewModel.onClickDelete}
      />
    </Box>
  );
};
