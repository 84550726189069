import * as React from "react";
import { PropsWithChildren } from "react";
import { Grid, GridSize } from "@material-ui/core";

const EqualSpacedHorizontalGrid = (
  props: PropsWithChildren<any> & { item?: boolean; flatten?: boolean }
) => {
  const { children, item, flatten } = props;
  const flatChildren = !flatten
    ? children
    : children.reduce(
        (
          children: React.ComponentType[],
          directChild: React.ComponentType | React.ComponentType[]
        ) => {
          (Array.isArray(directChild) ? directChild : [directChild]).forEach(
            child => {
              children.push(child);
            }
          );
          return children;
        },
        [] as React.ComponentType[]
      );
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      item={!!item}
    >
      {flatChildren.map((child: React.ComponentType, index: number) => (
        <Grid
          item
          xs={Math.round(12 / flatChildren.length) as GridSize}
          key={index}
        >
          {child}
        </Grid>
      ))}
    </Grid>
  );
};

export default EqualSpacedHorizontalGrid;
