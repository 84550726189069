import _ from "lodash";

export default class LazyArray<T> {
  private readonly backingArray: Array<T>;
  constructor(
    private readonly size: number,
    private readonly factory: (index: number) => T
  ) {
    this.backingArray = new Array<T>(size);
  }

  get: (index: number) => T = index => {
    let prev = this.backingArray[index];
    if (_.isUndefined(prev)) {
      prev = this.factory(index);
      this.backingArray[index] = prev;
    }
    return prev;
  };
}
