import * as React from "react";
import Typography from "@material-ui/core/Typography";
import EqualSpacedHorizontalGrid from "../../../components/grid/EqualSpacedHorizontalGrid";
import { LocalizedProp } from "../../../types/domainTypes";
import WarningWrapper from "../../../components/warning/WarningWrapper";
import { Grid } from "@material-ui/core";

interface QuoteContentProps {
  text: LocalizedProp<string>;
  author: LocalizedProp<string>;
  categories: LocalizedProp<string>[];
}

const QuoteContent = (props: QuoteContentProps) => {
  return (
    <EqualSpacedHorizontalGrid>
      {Object.values(props)
        .map(quoteProp => (!Array.isArray(quoteProp) ? [quoteProp] : quoteProp))
        .reduce((allQuoteProps, quotePropValues, index) => {
          allQuoteProps.push(
            quotePropValues.map(quoteProp => (
              <Grid container justify="center" alignItems="center">
                <Grid item key={index}>
                  <WarningWrapper
                    show={!quoteProp.isLocalized}
                    message={"Missing Translation"}
                  >
                    <Typography align="center">{quoteProp.value}</Typography>
                  </WarningWrapper>
                </Grid>
              </Grid>
            ))
          );
          return allQuoteProps;
        }, [] as JSX.Element[])}
    </EqualSpacedHorizontalGrid>
  );
};

export default QuoteContent;
