import { useViewModel } from "../../../viewmodel/useViewModel";
import { ViewModelKeys } from "../../../viewmodel/ViewModelFactoryMap";
import React from "react";
import { Dialog, Grid, Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Announcement } from "../../../../domain/announcements/entities/announcement";
import { ArrowRight } from "@material-ui/icons";
import { announcementRenderByProperty } from "../details/announcement-render-by-property";

export const SaveAnnouncementChangesConfirmationDialog = () => {
  const [viewState, viewModel] = useViewModel(
    ViewModelKeys.ANNOUNCEMENTS_VIEW_MODEL
  );
  return (
    <Dialog
      open={viewState.pendingAction?.actionConfirmation?.type === "saveEdit"}
      onClose={viewModel.onCancelAction}
    >
      {viewState.pendingAction?.isLoading && <LinearProgress variant="query" />}
      <DialogTitle>{"Save changes"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to save the changes you've made? This action
          cannot be undone.
        </DialogContentText>
        {viewState.changeDifference ? (
          <>
            <DialogContentText>Preview of changes made:</DialogContentText>
            {Object.keys(viewState.changeDifference).map(key => {
              const property = key as keyof Announcement;
              const previousValue =
                viewState.changeDifference?.[property]?.previousValue;
              const currentValue =
                viewState.changeDifference?.[property]?.currentValue;
              const propertyRenderer = announcementRenderByProperty[property]
                ?.renderStatic as (property: any) => JSX.Element;
              return (
                <Grid container alignItems={"center"} spacing={1}>
                  <Grid item xs={2}>{`${announcementRenderByProperty[property]
                    ?.label ?? property}:`}</Grid>
                  <Grid item>
                    {propertyRenderer?.(previousValue) ?? (
                      <Typography>{previousValue}</Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <ArrowRight />
                  </Grid>
                  <Grid item>
                    {propertyRenderer?.(currentValue) ?? (
                      <Typography>{currentValue}</Typography>
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={viewModel.onCancelAction}>Cancel</Button>
        <Button onClick={viewModel.onConfirmAction} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
