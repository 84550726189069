import Typography from "@material-ui/core/Typography";
import React from "react";
import Grid from "@material-ui/core/Grid";
import { CalendarNavigation } from "./CalendarNavigation";
import { Moment } from "moment-timezone";

export const CalendarToolbar = ({
  monthFormat = "MMMM yyyy",
  onPreviousClicked,
  onTodayClicked,
  onNextClicked,
  momentToShow
}: {
  monthFormat?: string;
  onPreviousClicked?: () => void;
  onTodayClicked?: () => void;
  onNextClicked?: () => void;
  momentToShow: Moment;
}) => {
  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h4">{momentToShow.format(monthFormat)}</Typography>
      </Grid>
      <Grid item>
        <CalendarNavigation
          onPreviousClicked={onPreviousClicked}
          onTodayClicked={onTodayClicked}
          onNextClicked={onNextClicked}
        />
      </Grid>
    </Grid>
  );
};
