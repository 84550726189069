import { TaskApi } from "../TaskApi";
import { Observable } from "rxjs";
import { Task } from "../Task";
import { FirebaseData } from "../../firebase/data/FirebaseData";
import { FirebaseFunctions } from "../../firebase/functions/FirebaseFunctions";

export class FirebaseTaskApi implements TaskApi {
  constructor(
    private readonly firebaseData: FirebaseData,
    private readonly firebaseFunctions: FirebaseFunctions
  ) {}

  getTasksObservable(): Observable<Task[]> {
    return this.firebaseData.getTasksObservable();
  }

  cancelTask(taskId: string): Promise<void> {
    return this.firebaseFunctions.call("cancelTask", { taskId });
  }

  deleteTask(taskId: string): Promise<void> {
    return this.firebaseFunctions.call("deleteTask", { taskId });
  }

  retryTask(taskId: string): Promise<void> {
    return this.firebaseFunctions.call("retryTask", { taskId });
  }

  createPublishTask({
    individualCommits
  }: {
    individualCommits?: {
      resourceName: string;
      commitId: string;
      locale: string;
    }[];
  }): Promise<void> {
    return this.firebaseFunctions.call("createPublishTask", {
      individualCommits
    });
  }
}
