import User from "../../auth/User";
import * as firebase from "firebase";

export function mapFirebaseUserToAuthUser(
  firebaseUser: firebase.User | null
): User | null {
  return (
    firebaseUser && {
      id: firebaseUser.uid,
      name: firebaseUser.displayName,
      imageUrl: firebaseUser.photoURL,
      email: firebaseUser.email
    }
  );
}
