import React, { useCallback, useMemo } from "react";
import { useRepositories } from "../../hooks/useRepositories";
import { LCEWrapper } from "../../components/lce/LCEWrapper";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { useStyles } from "./styles";
import useLCE from "../../hooks/useLCE";
import { useInPageNavigation } from "../../hooks/useInPageNavigation";
import { ScrapeSourceResults } from "../../components/quotesplorer/ScrapeSourceResults";

export const QuotesplorerPage = () => {
  const { scrapeV2 } = useRepositories();

  const classes = useStyles();

  const [pageParams, setPageParams, , goBack] = useInPageNavigation<{
    sourceId: string | undefined;
  }>();

  const scrapeSourcesLCE = useLCE(() => scrapeV2.listScrapeSources());

  const onQuotesSourceClicked = useCallback(
    (source: { id: string }) => {
      setPageParams({
        sourceId: source.id
      });
    },
    [setPageParams]
  );

  const onSourceResultsBackClicked = useCallback(goBack, []);

  const scrapePromise = useMemo(() => {
    return scrapeV2
      .getScrapeContent({
        sourceId: pageParams.sourceId!!
      })
      .then(response => response.scrapedContent)
      .then(scrapedContent => ({
        quotes: scrapedContent.quotes.map(q => ({
          textParts: [q.text],
          author: q.author.name,
          metadata: q.metadata
        }))
      }));
  }, [pageParams.sourceId, scrapeV2]);

  return (
    <>
      {pageParams.sourceId ? (
        <ScrapeSourceResults
          onBackClicked={onSourceResultsBackClicked}
          title={pageParams.sourceId ?? ""}
          scrapeSource={() => scrapePromise}
        />
      ) : (
        <>
          <Box className={classes.container}>
            <LCEWrapper
              lce={scrapeSourcesLCE}
              renderContent={({ sources }) => (
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="h4">Select Source:</Typography>
                  </Grid>
                  <Grid item container direction="column" spacing={1}>
                    {sources.map(source => (
                      <Grid item>
                        <Button
                          onClick={() => onQuotesSourceClicked(source)}
                          variant="contained"
                          color="primary"
                        >
                          <Typography>{source.name}</Typography>
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
            />
          </Box>
        </>
      )}
    </>
  );
};
