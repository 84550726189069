import * as React from "react";
import { Grid } from "@material-ui/core";
import { Locale } from "../../../locale";
import { toArray } from "../../../utils/maps";
import ContentDiffData from "./ContentDiffData";
import ContentRow from "../../content/row/ContentRow";
import { ContentItemViewModel } from "../../content/ContentItemViewModel";
import ContentDiffRow from "./ContentDiffRow";

const ContentDiff = ({
  headers,
  localeToDiffData,
  item = true,
  defaultLocale,
  commitSelection,
  resourceName
}: {
  localeToDiffData: Map<Locale, ContentDiffData>;
  headers: { key: string; isImage?: boolean; isUrl?: boolean }[];
  item?: boolean;
  defaultLocale: Locale | undefined;
  commitSelection: {
    enabled: boolean;
    selectedCommits: Map<string, { resourceName: string; locale: string }>;
    onChange: (
      resourceName: string,
      locale: string,
      commitId: string,
      isSelected: boolean
    ) => void;
  };
  resourceName: string;
}) => (
  <Grid container item={item}>
    {toArray(localeToDiffData, (fieldToDiff, locale) => (
      <ContentRow
        selectable={commitSelection.enabled}
        isSelected={commitSelection.selectedCommits.has(
          fieldToDiff.commitId ?? ""
        )}
        onChange={
          fieldToDiff.commitId
            ? isSelected =>
                commitSelection.onChange(
                  resourceName,
                  locale,
                  fieldToDiff.commitId!,
                  isSelected
                )
            : undefined
        }
        viewModel={
          new ContentItemViewModel(
            (
              <ContentDiffRow
                locale={locale}
                defaultLocale={defaultLocale}
                fieldToDiff={fieldToDiff}
                headers={headers}
              />
            ),
            fieldToDiff.contentActions,
            fieldToDiff.modificationType,
            fieldToDiff.ownerTimeline,
            fieldToDiff.updatedAt
          )
        }
      />
    ))}
  </Grid>
);

export default ContentDiff;
