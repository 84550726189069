import LocalizedResource from "../../data/LocalizedResource";

export enum QuoteFields {
  ID = "id",
  TEXT = "text",
  CONTEXT = "context",
  AUTHOR_ID = "authorId",
  CATEGORY_ID = "categoryId"
}

export default interface Quote extends LocalizedResource {
  [QuoteFields.ID]: string;
  [QuoteFields.TEXT]: string[] | undefined;
  [QuoteFields.AUTHOR_ID]: string[] | undefined;
  [QuoteFields.CATEGORY_ID]: string[] | undefined;
  [QuoteFields.CONTEXT]: string[] | undefined;
}
