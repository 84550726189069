import React, { useCallback } from "react";
import { ContextMenu } from "./ContextMenu";
import { Popover } from "@material-ui/core";

export interface ContextMenuPopoverProps {
  render: () => JSX.Element;
}

export const ContextMenuPopover = (
  props: React.PropsWithChildren<ContextMenuPopoverProps>
) => {
  const render = useCallback(
    (x: number, y: number) => {
      return (
        <Popover
          anchorReference={"anchorPosition"}
          open={true}
          anchorPosition={{ left: x, top: y }}
        >
          {props.render()}
        </Popover>
      );
    },
    [props]
  );
  return <ContextMenu render={render}>{props.children}</ContextMenu>;
};
