import React, { useCallback } from "react";
import { ScrappedContent } from "../../pages/quotesplorer/ScrappedContent";
import { Resources } from "../../data/Resources";
import BaseContentManagementPage from "../pages/BaseContentManagementPage";
import { CommitResource } from "../../api/commits/Commit";
import { Locale } from "../../locale";
import ManagedResource from "../../data/ManagedResource";
import { ScrapedSourceResultsHeader } from "./ScrapedSourceResultsHeader";
import { ScrapedSourceResultsContent } from "./ScrapedSrouceResultsContent";
import { useFilters } from "./useFilters";
import { useCreateManagedContent } from "./useCreateManagedContent";

export const ScrapeSourceResults = ({
  onBackClicked,
  title,
  scrapeSource
}: {
  title: string;
  onBackClicked: () => void;
  scrapeSource: () => Promise<ScrappedContent>;
}) => {
  const {
    setCreateCallback,
    onAddQuoteClicked,
    onAddAuthorClicked,
    onAddCategoryClicked
  } = useCreateManagedContent();
  const addResourceCallback = React.useCallback(
    callback => {
      setCreateCallback({ callback });
    },
    [setCreateCallback]
  );

  const { filters, onFilterValueChanged } = useFilters(scrapeSource);

  const renderHeader = useCallback(
    () => (
      <ScrapedSourceResultsHeader
        title={title}
        onBackClicked={onBackClicked}
        filters={filters}
        onFilterValueChanged={onFilterValueChanged}
      />
    ),
    [filters, onBackClicked, onFilterValueChanged, title]
  );

  const renderContent = useCallback(
    (
      _,
      managedContentByLocale: Map<
        CommitResource,
        Map<Locale, ManagedResource<any>[]>
      >
    ) => (
      <ScrapedSourceResultsContent
        scrapeSource={scrapeSource}
        onAddQuoteClicked={onAddQuoteClicked}
        onAddAuthorClicked={onAddAuthorClicked}
        onAddCategoryClicked={onAddCategoryClicked}
        managedContentByLocale={managedContentByLocale}
        filters={filters}
      />
    ),
    [
      filters,
      onAddAuthorClicked,
      onAddCategoryClicked,
      onAddQuoteClicked,
      scrapeSource
    ]
  );

  return (
    <BaseContentManagementPage
      resource={Resources.QUOTES}
      addResourceCallback={addResourceCallback}
      renderHeader={renderHeader}
      renderFooter={() => null}
      resources={[Resources.QUOTES, Resources.AUTHORS]}
      renderContent={renderContent}
    />
  );
};
