import React, { useEffect, useRef } from "react";

export interface GeometryReportingProps {
  element: JSX.Element;
  onMount: (getBoundingClientRect: () => DOMRect | undefined) => void;
  onUnmount: () => void;
}

export const GeometryReporting = (props: GeometryReportingProps) => {
  const ref = useRef<Element>();
  useEffect(() => {
    props.onMount(() => ref.current?.getBoundingClientRect());
    return () => {
      props.onUnmount();
    };
  }, [props]);
  return React.cloneElement(props.element, {
    ref
  });
};
