import * as React from "react";
import compose from "../../utils/compose";
import ManagedResource from "../../data/ManagedResource";
import { CommitsRepository } from "../../repository/use-case/commits/CommitsRepository";
import User from "../../api/auth/User";
import { Locale } from "../../locale";
import { Commit, CommitResource } from "../../api/commits/Commit";
import { withRepositories } from "./withRepositories";
import ContentRepository from "../../repository/use-case/content/ContentRepository";
import UserRepository from "../../repository/use-case/user/UserRepository";
import withLCE, { LCEContentConsumer } from "./withLCE";
import { useObservableRepositoryLCE } from "../../hooks/useObservableRepositoryLCE";
import { ContentData } from "../../repository/use-case/content/ContentData";
import withResourceManagementActions, {
  ResourceManagementProps
} from "./withResourceManagementActions";
import withLocale, { LocalePropsConsumer } from "./withLocale";
import withSystemConfiguration, {
  SystemConfigurationConsumerProps
} from "./withSystemConfiguration";
import { LocalizedObservableData } from "../../repository/LocalizedObservableData";
import {
  formFieldFactory,
  FormFieldFactory
} from "../dialogs/FormDialog/fields/factory/formFieldFactory";
import { mapOf, merge, transformValues } from "../../utils/maps";
import { LocaleData } from "../../repository/LocaleData";

export interface ManageResourcesPageProps
  extends ResourceManagementProps,
    LocalePropsConsumer,
    SystemConfigurationConsumerProps {
  managedContent: Map<CommitResource, ManagedResource<any>[]>;
  managedContentByLocale: Map<
    CommitResource,
    Map<Locale, ManagedResource<any>[]>
  >;
  formFieldFactory: FormFieldFactory;
}

const withResourceManagement = function<T extends ManagedResource<any>>(
  managedResourceMappers: ({
    contentData,
    commits,
    users
  }: {
    contentData: LocaleData<ContentData>;
    commits: Map<CommitResource, Map<string, Commit>>;
    users: Map<string, User>;
  }) => Map<CommitResource, ManagedResource<any>[]>
) {
  return (
    WrappedComponent: React.ComponentType<
      ManageResourcesPageProps & React.Attributes
    >
  ) => {
    const ResourcePageHOC = (
      props: React.Attributes &
        LCEContentConsumer<{
          content: LocalizedObservableData<ContentData>;
          commits: LocalizedObservableData<
            Map<CommitResource, Map<string, Commit>>
          >;
          users: Map<string, User>;
        }> &
        ResourceManagementProps &
        SystemConfigurationConsumerProps &
        LocalePropsConsumer
    ) => {
      const managedResources: {
        current: Map<CommitResource, ManagedResource<any>[]>;
        indexedByLocale: Map<
          CommitResource,
          Map<Locale, ManagedResource<any>[]>
        >;
      } = React.useMemo(() => {
        if (props.content) {
          const { commits, content, users } = props.content;
          const current = managedResourceMappers({
            contentData: content.localizedData,
            commits: commits.localizedData.data,
            users
          });
          const defaultData = managedResourceMappers({
            contentData: content.defaultData,
            commits: commits.defaultData.data,
            users
          });
          return {
            current,
            indexedByLocale: merge(
              transformValues(defaultData, managedResources =>
                mapOf(commits.defaultData.locale, managedResources)
              ),
              transformValues(current, managedResources =>
                mapOf(commits.localizedData.locale, managedResources)
              ),
              (defaultIndexedData, currentIndexedData) =>
                merge(defaultIndexedData || new Map(), currentIndexedData)
            )
          };
        }
        return {
          current: new Map(),
          indexedByLocale: new Map()
        };
      }, [props.content]);

      return (
        <WrappedComponent
          {...props}
          managedContent={managedResources.current}
          managedContentByLocale={managedResources.indexedByLocale}
          formFieldFactory={formFieldFactory(
            props.defaultLocale,
            props.locale,
            managedResources.indexedByLocale
          )}
        />
      );
    };

    return compose(
      withSystemConfiguration,
      withLocale,
      withRepositories(repositories => ({
        contentRepository: repositories.content,
        commitsRepository: repositories.commits,
        userRepository: repositories.user
      })),
      withLCE(
        (props: {
          contentRepository: ContentRepository;
          commitsRepository: CommitsRepository;
          userRepository: UserRepository;
          locale: Locale;
        }) =>
          useObservableRepositoryLCE(
            ([content, commits, users]) =>
              Promise.resolve({ content, commits, users }),
            [props.locale],
            props.contentRepository,
            props.commitsRepository,
            props.userRepository
          )
      ),
      withResourceManagementActions,
      React.memo
    )(ResourcePageHOC) as React.ComponentType<
      ManageResourcesPageProps & React.Attributes
    >;
  };
};

export default withResourceManagement;
