import { FileManagerFileListItem } from "../FileManagerViewModelBase";
import React, { CSSProperties } from "react";
import { Folder, Image, InsertDriveFile, PermMedia } from "@material-ui/icons";
import { FileState } from "../../../domain/file-management/entities/File";
import { CircularProgress, Tooltip } from "@material-ui/core";

export interface FileManagerDisplayPropsBase {
  fileList: FileManagerFileListItem[];
  onFileClick: (file: FileManagerFileListItem) => void;
  renderContextMenu: (fileItem: FileManagerFileListItem) => JSX.Element;
}

export function defaultThumbnailForItem(
  fileItem: FileManagerFileListItem,
  style: CSSProperties
): JSX.Element {
  if (fileItem.type === "directory") {
    return <Folder style={style} />;
  }

  if (fileItem.thumbnail_small) {
    return <img src={fileItem.thumbnail_small} style={style} />;
  }

  if (fileItem.type.startsWith("image/")) {
    return <Image style={style} />;
  }
  if (fileItem.type.startsWith("application/zip")) {
    return <PermMedia style={style} />;
  }
  return <InsertDriveFile style={style} />;
}

export function getFullName(fileItem: FileManagerFileListItem) {
  if (fileItem.type === "file" && fileItem.extension) {
    return fileItem.name + "." + fileItem.extension;
  }
  return fileItem.name;
}

export function stateIndicatorForItem(
  fileItem: FileManagerFileListItem
): JSX.Element {
  if (fileItem.type === "directory") {
    return <></>;
  }

  if (!fileItem.state || fileItem.state === FileState.NONE) {
    return <></>;
  }

  const stateTitleMap = {
    [FileState.GENERATING_THUMBNAILS]: "Generating thumbnails",
    [FileState.GENERATING_LINK]: "Generating link",
    [FileState.EXPANDING]: "Expanding zip"
  };
  return (
    <Tooltip title={stateTitleMap[fileItem.state]}>
      <CircularProgress size={24} color={"primary"} />
    </Tooltip>
  );
}
