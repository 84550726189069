import { Moment } from "moment-timezone";
import React, { useCallback, useState } from "react";
import { CalendarMonthView } from "./month-view/CalendarMonthView";
import { CalendarActionBar } from "./action-bar/CalendarActionBar";
import { CalendarToolbar } from "./toolbar/CalendarToolbar";
import Box from "@material-ui/core/Box";
import { useStyles } from "./styles";
import { CalendarEvent } from "../../repository/use-case/calendar/CalendarEvent";
import LinearProgress from "@material-ui/core/LinearProgress";

interface MaterialCalendarProps {
  currentEvents: CalendarEvent[];
  loadEvents: (startTime: Moment, endTime: Moment) => Promise<void>;
  onEventSelected?: (
    element: React.MouseEvent<HTMLButtonElement>,
    event: CalendarEvent
  ) => void;
  selectedDates: Moment[];
  onDatesSelected: (dates: Moment[]) => void;
  onNewEventClicked: () => void;
  momentProvider: () => Moment;
}
function calculateMoment(
  current: Moment | undefined,
  momentProvider: () => Moment,
  forward: boolean
) {
  return (current ?? momentProvider())
    .clone()
    .startOf("month")
    .add(forward ? 1 : -1, "month");
}

export const MaterialCalendar = ({
  currentEvents,
  loadEvents,
  onEventSelected,
  selectedDates,
  onDatesSelected,
  onNewEventClicked,
  momentProvider
}: MaterialCalendarProps) => {
  const [momentToShow, setMomentToShow] = useState<Moment>(momentProvider());

  const onPreviousClicked = useCallback(() => {
    setMomentToShow(currentShowingMoment =>
      calculateMoment(currentShowingMoment, momentProvider, false)
    );
  }, [momentProvider]);
  const onTodayClicked = useCallback(() => {
    setMomentToShow(momentProvider());
    onDatesSelected([momentProvider()]);
  }, [momentProvider, onDatesSelected]);
  const onNextClicked = useCallback(() => {
    setMomentToShow(currentShowingMoment =>
      calculateMoment(currentShowingMoment, momentProvider, true)
    );
  }, [momentProvider]);

  const [isLoading, setLoading] = useState(false);
  const onDateRangeDisplayed = useCallback(
    async (dateRange: Moment[]) => {
      setLoading(true);
      await loadEvents(dateRange[0], dateRange[dateRange.length - 1]);
      setLoading(false);
    },
    [loadEvents]
  );

  const classes = useStyles();
  return (
    <>
      {isLoading ? <LinearProgress /> : <div />}
      <Box className={classes.actionBarContainer}>
        <CalendarActionBar onCreateActionClicked={onNewEventClicked} />
      </Box>
      <Box className={classes.toolbarContainer}>
        <CalendarToolbar
          onPreviousClicked={onPreviousClicked}
          onTodayClicked={onTodayClicked}
          onNextClicked={onNextClicked}
          momentToShow={momentToShow}
        />
      </Box>
      <CalendarMonthView
        momentToShow={momentToShow}
        selectedDates={selectedDates}
        onDatesSelected={onDatesSelected}
        onDateRangeDisplayed={onDateRangeDisplayed}
        events={currentEvents}
        onEventSelected={onEventSelected}
        momentProvider={momentProvider}
      />
    </>
  );
};
