export type Locale = string;

const localeToCountryCodes: { [locale: string]: string } = {
  EN: "US",
  HE: "IL",
  RU: "RU"
};

export function getCountryCodeForLocale(locale: Locale): string {
  return localeToCountryCodes[locale] || "";
}

export function getSupportedLocales() {
  return Object.keys(localeToCountryCodes);
}
