import React from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";

export const AnnouncementState = ({ active }: { active: boolean }) => {
  const theme = useTheme();
  return (
    <Typography
      style={{ color: theme.state?.[active ? "active" : "inactive"] }}
    >
      {active ? "Active" : "Inactive"}
    </Typography>
  );
};
