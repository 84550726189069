import { LoginViewModel } from "../../components/login/LoginViewModel";
import * as React from "react";
import LoginPage from "./LoginPage";
import { useHistory, useLocation } from "react-router";
import AuthApi from "../../api/auth/AuthApi";

interface LoginPageHOCProps {
  authApi: AuthApi;
}

const LoginPageHOC = (props: LoginPageHOCProps) => {
  const history = useHistory();
  const location = useLocation();

  // @ts-ignore
  const { from } = location.state || { from: { pathname: "/" } };

  const onUserLoggedIn = () => {
    history.replace(from);
  };

  return (
    <LoginPage
      viewModel={new LoginViewModel(props.authApi)}
      onUserLoggedIn={onUserLoggedIn}
    />
  );
};

export default LoginPageHOC;
