import { useContext } from "react";
import FirebaseApiContext from "../components/context/firebase/FirebaseApiContext";
import useLCE from "./useLCE";
import { LCE } from "../architecture/lce/lce";

export function useDownloadURLFromPath(
  downloadPath: string
): LCE<string, Error> {
  const { storage } = useContext(FirebaseApiContext)!;
  return useLCE(() => storage.getDownloadUrl(downloadPath));
}
