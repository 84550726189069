import firebase from "firebase";
import { Observable } from "rxjs";

export enum TaskState {
  CANCELED = "CANCELED",
  ERROR = "ERROR",
  PAUSED = "PAUSED",
  RUNNING = "RUNNING",
  SUCCESS = "SUCCESS"
}

export interface UploadTaskProgress {
  name: string;
  state: TaskState;
  percentageCompleted: number;
  bytesTransferred: number;
  totalBytes: number;
}

export function toObservable(
  storageTask: firebase.storage.UploadTask
): Observable<UploadTaskProgress> {
  return new Observable(subscriber => {
    storageTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      taskSnapshot => {
        subscriber.next({
          name: taskSnapshot.ref.name,
          state: taskSnapshot.state as TaskState,
          percentageCompleted:
            (100 * taskSnapshot.bytesTransferred) / taskSnapshot.totalBytes,
          bytesTransferred: taskSnapshot.bytesTransferred,
          totalBytes: taskSnapshot.totalBytes
        });
      },
      subscriber.error
    );
  });
}
