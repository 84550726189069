import {
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Popover,
  Radio,
  RadioGroup
} from "@material-ui/core";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import useCookies from "react-cookie/lib/useCookies";
import { Cookies } from "../../cookies";
import { CommitResource } from "../../api/commits/Commit";
import { ContentManagementSortTypes } from "./content-management-sort-types";

export interface SortPopoverProps {
  anchorElement: HTMLElement | null;
  onClose: () => void;
  defaultSortField: string;
  sortableFields: string[];
  resource: CommitResource;
}

export const SortPopover = (props: SortPopoverProps) => {
  const [cookies, setCookie] = useCookies([Cookies.CONTENT_SORT]);
  const [selectedSortField, setSelectedSortField] = useState(
    cookies[Cookies.CONTENT_SORT]?.[props.resource]?.field ??
      props.defaultSortField
  );
  const onSelectSortField = useCallback(changeEvent => {
    setSelectedSortField(changeEvent.target.value as string);
  }, []);
  const onSelectSortType = useCallback(
    (sort: ContentManagementSortTypes) => {
      setCookie(Cookies.CONTENT_SORT, {
        ...cookies[Cookies.CONTENT_SORT],
        [props.resource]: {
          ...cookies[Cookies.CONTENT_SORT]?.[props.resource],
          type: sort,
          field: selectedSortField
        }
      });
      props.onClose();
    },
    [cookies, props, selectedSortField, setCookie]
  );
  const onSelectSortNone = useCallback(
    () => onSelectSortType(ContentManagementSortTypes.NONE),
    [onSelectSortType]
  );
  const onSelectSortAZ = useCallback(
    () => onSelectSortType(ContentManagementSortTypes.INCREASING),
    [onSelectSortType]
  );
  const onSelectSortZA = useCallback(
    () => onSelectSortType(ContentManagementSortTypes.DECREASING),
    [onSelectSortType]
  );
  const sortableProperties = useMemo(
    () => ({
      none: "None",
      // createdAt: "Create time",
      updatedAt: "Update time"
    }),
    []
  );
  const [sortProperty, setSortProperty] = useState(
    cookies[Cookies.CONTENT_SORT]?.[props.resource]?.property
  );
  const onSelectSortableProperty = useCallback(
    event => {
      const property = event.target.value as string;
      setSortProperty(property);
      setCookie(Cookies.CONTENT_SORT, {
        ...cookies[Cookies.CONTENT_SORT],
        [props.resource]: {
          ...cookies[Cookies.CONTENT_SORT]?.[props.resource],
          property
        }
      });
    },
    [cookies, props.resource, setCookie]
  );
  return (
    <Popover
      open={!!props.anchorElement}
      anchorEl={props.anchorElement}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
    >
      <List>
        <ListItem button onClick={onSelectSortNone}>
          <ListItemText>None</ListItemText>
        </ListItem>
        <ListItem button onClick={onSelectSortAZ}>
          <ListItemText>Increasing (A-Z or 0-9)</ListItemText>
        </ListItem>
        <ListItem button onClick={onSelectSortZA}>
          <ListItemText>Decreasing (Z-A or 9-0)</ListItemText>
        </ListItem>
        <Divider />
        <RadioGroup onChange={onSelectSortableProperty} value={sortProperty}>
          {Object.keys(sortableProperties).map(key => (
            <FormControlLabel
              value={key}
              control={<Radio />}
              label={sortableProperties[key as keyof typeof sortableProperties]}
            />
          ))}
        </RadioGroup>
        <Divider />
        <RadioGroup onChange={onSelectSortField} value={selectedSortField}>
          {props.sortableFields.map(field => (
            <FormControlLabel value={field} control={<Radio />} label={field} />
          ))}
        </RadioGroup>
      </List>
    </Popover>
  );
};
