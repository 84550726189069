import AuthApi from "../../api/auth/AuthApi";

export class LoginViewModel {
  constructor(private authApi: AuthApi) {}

  getSignInWithPopup(): () => Promise<any> {
    return this.authApi.signInWithPopup;
  }

  registerAuthUserChanges(callback: (user: any) => any) {
    return this.authApi.authUserChanges(callback);
  }
}
