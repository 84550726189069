import makeStyles from "@material-ui/core/styles/makeStyles";
import { centerFlex } from "../../styles/centerFlex";

const DATE_CELL_SIZE = "36px";

export const FORM_PROGRESS_SIZE_PX = 24;

export const useStyles = makeStyles(theme => ({
  calendarPaperWrapper: {
    margin: "2vw"
  },
  actionBarContainer: {
    padding: "0.5vw"
  },
  toolbarContainer: {
    margin: "0.5vw"
  },
  daysOfWeekContainer: {
    margin: "0 0.5vw"
  },
  calendarDatesContainer: {
    padding: "0 0.5vw"
  },
  toolbarTodayButton: {
    margin: "0 0.25vw"
  },
  calendarContainer: {
    width: "100%"
  },
  todayDateCellBackground: {
    backgroundColor: theme?.palette?.primary.main,
    borderRadius: "50%",
    width: DATE_CELL_SIZE,
    height: DATE_CELL_SIZE,
    ...centerFlex(theme)
  },
  dateCellBackground: {
    width: DATE_CELL_SIZE,
    height: DATE_CELL_SIZE,
    ...centerFlex(theme)
  },
  flexDivider: {
    height: "auto",
    alignSelf: "stretch"
  },
  monthDateCellButton: {
    width: "100%",
    height: "calc(100% - 1px)",
    padding: "0.25vw",
    borderStyle: "solid",
    borderColor: theme?.palette?.primary.main,
    borderWidth: "1px",
    borderRadius: 0
  },
  monthDateCellContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  monthDateCellDateContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  },
  monthDateCellEventWrapper: {
    marginTop: "0.25vw"
  },
  monthCellWithDividerContainer: {
    display: "flex",
    justifyItems: "flex-end",
    alignItems: "start",
    flexDirection: "row",
    height: "100%"
  },
  monthCellGridItem: {
    alignSelf: "stretch",
    height: "auto"
  },
  monthRowContainer: {
    alignSelf: "stretch",
    height: "auto"
  },
  monthGridContainer: {
    display: "grid",
    overflow: "hidden",
    gridAutoRows: "1fr",
    padding: 0,
    margin: 0,
    listStyleType: "none"
  },
  formSectionSpacing: {
    marginTop: "1vh",
    marginBottom: "0.5vh"
  },
  eventItemSpacing: {
    marginBottom: "0.5vh"
  },
  formItemSpacing: {
    marginBottom: "0.25vh"
  },
  formProgressPlaceholderSize: {
    width: `${FORM_PROGRESS_SIZE_PX}px`,
    height: `${FORM_PROGRESS_SIZE_PX}px`
  },
  matchParentHeight: {
    height: "100%"
  }
}));
