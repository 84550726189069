import { Permission } from "../../../api/permissions/Permission";
import { PermissionsApi } from "../../../api/permissions/PermissionsApi";

export class PermissionsRepository {
  constructor(private readonly permissionApi: PermissionsApi) {}

  getScreenPermissions = async (): Promise<Permission[]> =>
    (await this.permissionApi.getScreenPermissions()).permissions;

  listPermissions = this.permissionApi.listPermissions;
  listUserPermissions = this.permissionApi.listUserPermissions;
  setUserPermissions = this.permissionApi.setUserPermissions;
}
