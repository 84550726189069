import { TextField } from "@material-ui/core";
import React from "react";

export interface ExportFileNameSelectionProps {
  placeholder: string;
  onFileNameChange: (fileName: string) => void;
}

export const ExportFileNameSelection = (
  props: ExportFileNameSelectionProps
) => {
  return (
    <TextField
      placeholder={props.placeholder}
      variant={"outlined"}
      title={"File Name"}
      onChange={event => props.onFileNameChange(event.target.value.toString())}
    />
  );
};
