import { useViewModel } from "../../../viewmodel/useViewModel";
import { ViewModelKeys } from "../../../viewmodel/ViewModelFactoryMap";
import { Divider, List, Paper } from "@material-ui/core";
import React from "react";
import { AnnouncementListItem } from "./AnnouncementListItem";
import { CreateNewAnnouncement } from "./CreateNewAnnouncement";

export const AnnouncementsList = () => {
  const [viewState] = useViewModel(ViewModelKeys.ANNOUNCEMENTS_VIEW_MODEL);
  return (
    <>
      <Paper style={{ height: "fit-content", marginBottom: 8 }}>
        {viewState.announcements.length ? (
          <List>
            {viewState.announcements.map((announcement, index) => {
              return (
                <>
                  <AnnouncementListItem announcement={announcement} />
                  {index !== viewState.announcements.length - 1 ? (
                    <Divider />
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </List>
        ) : (
          <></>
        )}
      </Paper>
      {viewState.showCreateAnnouncement ? <CreateNewAnnouncement /> : <></>}
    </>
  );
};
