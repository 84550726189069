import * as React from "react";
import ManagedQuote, { QuoteContentFields } from "../../../data/ManagedQuote";
import compose from "../../../utils/compose";
import { Resources } from "../../../data/Resources";
import commonActions from "../../../components/content/actions/common-actions";
import QuoteContent from "./QuoteContent";
import {
  extractFirstLocalizedProp,
  extractLocalizedProps
} from "../../../utils/data/resources/managed";
import ManagedContent from "../../../data/ManagedContent";
import { Locale } from "../../../locale";
import { mapOf, merge } from "../../../utils/maps";
import { CommitResource } from "../../../api/commits/Commit";
import { SearchFields } from "../../../types/domainTypes";
import { QuoteFields } from "../../../api/content/Quote";
import {
  CLIPBOARD_ACTION,
  clipboardAction
} from "../../../components/content/actions/clipboard-action";
import { copyToClipboard } from "../../../utils/copyToClipboard";
import BaseContentManagementPage from "../../../components/pages/BaseContentManagementPage";

const searchFields: SearchFields = {
  contentFields: [
    QuoteFields.TEXT,
    QuoteContentFields.AUTHOR,
    QuoteContentFields.CATEGORY
  ]
};
const quoteRowActions = merge(clipboardAction, commonActions);

const QuotesPageWrapper = () => {
  const renderContentItem = React.useCallback(
    (content: ManagedContent<ManagedQuote>, locale) => (
      <QuoteContent
        text={extractFirstLocalizedProp(
          locale,
          content.managedResource,
          "text"
        )}
        author={extractFirstLocalizedProp(
          locale,
          content.managedResource,
          "author"
        )}
        categories={extractLocalizedProps(
          locale,
          content.managedResource,
          "category"
        )}
      />
    ),
    []
  );
  const onCustomActionClicked = React.useCallback(
    (actionType: string, managedResource: ManagedQuote, locale: Locale) => {
      if (actionType === CLIPBOARD_ACTION) {
        copyToClipboard(
          extractFirstLocalizedProp(locale, managedResource, QuoteFields.TEXT)
            .value ?? ""
        );
        return true;
      }
      return false;
    },
    []
  );
  const formLinkInfo = React.useMemo(
    () =>
      mapOf<CommitResource, string>(
        Resources.AUTHORS,
        "author",
        Resources.CATEGORIES,
        "category"
      ),
    []
  );
  return (
    <BaseContentManagementPage
      resource={Resources.QUOTES}
      supportedActions={quoteRowActions}
      searchFields={searchFields}
      renderViewModelContent={renderContentItem}
      onCustomActionClicked={onCustomActionClicked}
      formLinkInfo={formLinkInfo}
    />
  );
};

const QuotesPage = compose(React.memo)(
  QuotesPageWrapper
) as React.ComponentType;

export default QuotesPage;
