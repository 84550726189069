import createTheme from "@material-ui/core/styles/createTheme";

interface ThemeInfo {
  header?: {
    textColor: string;
    backgroundColor: string;
    logo: {
      bo: {
        textColor: string;
        backgroundColor: string;
      };
    };
  };
  drawer?: {
    backgroundColor: string;
    selectedItemColor: string;
  };
  destructiveColor?: string;
  contentManagement?: {
    create: {
      color: string;
      hoverColor: string;
    };
    count: {
      color: string;
      backgroundColor: string;
    };
    update: {
      color: string;
    };
    remove: {
      color: string;
    };
    translated: {
      color: string;
    };
    restore: {
      color: string;
    };
    clipboard: {
      color: string;
    };
    archive: {
      color: string;
    };
    unarchive: {
      color: string;
    };
    locked: {
      color: string;
    };
  };
  communityManagement?: {
    usersVoice: {
      conversation: {
        status: {
          pending: string;
          resolved: string;
        };
      };
    };
  };
  warning?: {
    color: string;
  };
  info?: {
    flavors?: {
      info: string;
      success: string;
      error: string;
    };
  };
  publish?: {
    panel?: {
      item?: {
        color?: string;
      };
    };
  };
  messages?: {
    system?: {
      configuration?: {
        contentLock?: {
          backgroundColor?: string;
        };
      };
    };
    usersVoice: {
      incoming: string;
      outgoing: string;
    };
  };
  tasks?: {
    cancel: {
      color: string;
    };
    retry: {
      color: string;
    };
    delete: {
      color: string;
    };
  };
  state?: {
    disabled: string;
    active: string;
    inactive: string;
  };
  textColors?: {
    primary: string;
  };
  components?: {
    list?: {
      alternatingColor: string;
    };
  };
}

declare module "@material-ui/core/styles/createTheme" {
  interface Theme extends ThemeInfo {}

  // allow configuration using `createTheme`
  interface ThemeOptions extends ThemeInfo {}
}

const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#039be5"
    },
    secondary: {
      main: "#47ffbf"
    }
  },
  header: {
    textColor: "#fff",
    backgroundColor: "#1E2832",
    logo: {
      bo: {
        textColor: "#000",
        backgroundColor: "#fff"
      }
    }
  },
  drawer: {
    backgroundColor: "#424242",
    selectedItemColor: "#81d4fa"
  },
  destructiveColor: "#ff6f60",
  contentManagement: {
    create: {
      color: "#98ee99",
      hoverColor: "#2aee6f"
    },
    count: {
      color: "#fff",
      backgroundColor: "#98ee99"
    },
    update: {
      color: "#ffb04c"
    },
    remove: {
      color: "#ff6f60"
    },
    translated: {
      color: "#6746c3"
    },
    restore: {
      color: "#64c1ff"
    },
    clipboard: {
      color: "#c0ca33"
    },
    archive: {
      color: "#7d5635"
    },
    unarchive: {
      color: "#c2ff00"
    },
    locked: {
      color: "#ff6f60"
    }
  },
  communityManagement: {
    usersVoice: {
      conversation: {
        status: {
          pending: "#EF6C00",
          resolved: "#558B2F"
        }
      }
    }
  },
  warning: {
    color: "#ff6f60"
  },
  info: {
    flavors: {
      info: "#c79631",
      success: "#31c766",
      error: "#c73131"
    }
  },
  publish: {
    panel: {
      item: {
        color: "#538cce"
      }
    }
  },
  messages: {
    system: {
      configuration: {
        contentLock: {
          backgroundColor: "#A84F44"
        }
      }
    },
    usersVoice: {
      incoming: "#1565C0",
      outgoing: "#2E7D32"
    }
  },
  tasks: {
    cancel: {
      color: "#ee881e"
    },
    delete: {
      color: "#ff6f60"
    },
    retry: {
      color: "#64c1ff"
    }
  },
  state: {
    disabled: "#909090",
    active: "#31c766",
    inactive: "#c73131"
  },
  textColors: {
    primary: "#fff"
  },
  components: {
    list: {
      alternatingColor: "#3c3c3c"
    }
  }
});

const brightTheme = createTheme({
  palette: {
    type: "light"
  },
  header: {
    textColor: "#fff",
    backgroundColor: "#38495C",
    logo: {
      bo: {
        textColor: "#000",
        backgroundColor: "#fff"
      }
    }
  },
  drawer: {
    backgroundColor: "#bdbdbd",
    selectedItemColor: "#0288d1"
  },
  destructiveColor: "#fe1824",
  contentManagement: {
    create: {
      color: "#338a3e",
      hoverColor: "#108a2d"
    },
    count: {
      backgroundColor: "#338a3e",
      color: "#fff"
    },
    update: {
      color: "#bc5100"
    },
    remove: {
      color: "#ab000d"
    },
    translated: {
      color: "#000063"
    },
    restore: {
      color: "#0064b7"
    },
    clipboard: {
      color: "#9e9d24"
    },
    archive: {
      color: "#87561e"
    },
    unarchive: {
      color: "#5f6d31"
    },
    locked: {
      color: "#ff6f60"
    }
  },
  communityManagement: {
    usersVoice: {
      conversation: {
        status: {
          pending: "#FFA726",
          resolved: "#9CCC65"
        }
      }
    }
  },
  warning: {
    color: "#ab000d"
  },
  info: {
    flavors: {
      info: "#ff6200",
      success: "#006a25",
      error: "#c73131"
    }
  },
  publish: {
    panel: {
      item: {
        color: "#83b9ff"
      }
    }
  },
  messages: {
    system: {
      configuration: {
        contentLock: {
          backgroundColor: "#a8291c"
        }
      }
    },
    usersVoice: {
      incoming: "#42A5F5",
      outgoing: "#66BB6A"
    }
  },
  tasks: {
    cancel: {
      color: "#ff6b00"
    },
    delete: {
      color: "#ab000d"
    },
    retry: {
      color: "#0064b7"
    }
  },
  state: {
    disabled: "#5d5d5d",
    active: "#006a25",
    inactive: "#c73131"
  },
  textColors: {
    primary: "#000"
  }
});

const themes = {
  dark: darkTheme,
  bright: brightTheme
};

export default themes;
