import EqualSpacedHorizontalGrid from "../../../components/grid/EqualSpacedHorizontalGrid";
import ManagedContent from "../../../data/ManagedContent";
import { ManagedMediaPlatform } from "../../../data/ManagedMediaPlatform";
import { Avatar, Typography } from "@material-ui/core";
import React from "react";
import {
  extractFirstLocalizedProp,
  extractLocalizedProps
} from "../../../utils/data/resources/managed";
import { MediaPlatformFields } from "../../../api/content/MediaPlatform";
import { Locale } from "../../../locale";
import WarningWrapper from "../../../components/warning/WarningWrapper";

const avatarStyle = { margin: "auto" };

const MediaPlatformContent = ({
  content,
  locale
}: {
  content: ManagedContent<ManagedMediaPlatform>;
  locale: Locale;
}) => {
  const platformName = extractFirstLocalizedProp(
    locale,
    content.managedResource,
    MediaPlatformFields.NAME
  );
  return (
    <EqualSpacedHorizontalGrid>
      <Avatar
        src={
          extractFirstLocalizedProp(
            locale,
            content.managedResource,
            MediaPlatformFields.ICON
          )?.value
        }
        style={avatarStyle}
      />
      <WarningWrapper
        show={!platformName.isLocalized}
        message={"Missing Translation"}
      >
        <Typography>{platformName.value}</Typography>
      </WarningWrapper>
    </EqualSpacedHorizontalGrid>
  );
};

export default MediaPlatformContent;
