import { Grid } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import ManagedResource from "../../../data/ManagedResource";
import Box from "@material-ui/core/Box";
import ContentManagementPageProps from "./ContentManagementPageProps";
import useStyles from "./styles";
import mapResourcesToManagedContent from "../../../data/mappers/resources-to-managed-content";
import ContentManagementDialogs from "../../content/management/ContentManagementDialogs";
import { Resources } from "../../../data/Resources";
import LockIcon from "@material-ui/icons/Lock";
import { Locale } from "../../../locale";
import { reduce } from "../../../utils/maps";
import {
  extractId,
  extractLocalizedProps
} from "../../../utils/data/resources/managed";
import SystemBanner from "../../banner/SystemBanner";
import elasticlunr from "elasticlunr";
import ManagedContent from "../../../data/ManagedContent";
import { CommitResource } from "../../../api/commits/Commit";
import { ContentManagementSortTypes } from "../content-management-sort-types";
import { sortedCopy } from "../../../utils/arrays/sortedCopy";

const ContentManagementPage = (props: ContentManagementPageProps) => {
  const {
    renderHeader,
    renderContent,
    renderFooter,
    managedResourcesByLocale,
    supportedActions,
    isUpdating,
    updateSuccess,
    isContentLock,
    isPublishing,
    mainResource,
    addResourceCallback,
    locale,
    onCustomActionClicked
  } = props;

  const [dialogStateCallback, setDialogStateCallback] = useState<
    | {
        callback:
          | (({
              resourceType,
              actionType,
              managedResource,
              localeToResource,
              isOpen
            }: {
              resourceType: Resources;
              actionType?: string;
              isOpen: boolean;
              managedResource?: ManagedResource<any>;
              localeToResource?: Map<Locale, ManagedResource<any>>;
            }) => void)
          | undefined;
      }
    | undefined
  >();

  useEffect(() => {
    addResourceCallback(
      (
        resourceType: CommitResource,
        localeToResource?: Map<Locale, ManagedResource<any>>
      ) => {
        dialogStateCallback?.callback?.({
          resourceType: resourceType ?? mainResource,
          actionType: "add",
          isOpen: true,
          localeToResource
        });
      }
    );
  }, [dialogStateCallback]);

  useEffect(() => {
    (updateSuccess || new Map()).forEach((isSuccessful, resource) => {
      if (isSuccessful) {
        dialogStateCallback?.callback?.({
          resourceType: resource,
          isOpen: false
        });
      }
    });
  }, [updateSuccess]);
  const resourceToManagedContent = React.useMemo(() => {
    const currentResources = managedResourcesByLocale.get(locale) || [];
    const localeToResourceIndexedById = reduce(
      managedResourcesByLocale,
      (acc, managedResources, locale) => {
        managedResources.forEach(resource => {
          const id = extractId(resource);
          const localeToResource = acc.get(id) || new Map();
          localeToResource.set(locale, resource);
          acc.set(id, localeToResource);
        });
        return acc;
      },
      new Map<string, Map<Locale, ManagedResource<any>>>()
    );
    return mapResourcesToManagedContent(
      currentResources,
      {
        normal: supportedActions,
        locked: {
          description: "Content changes are locked.",
          icon: <LockIcon />
        }
      },
      (managedResource, actionType) => {
        if (onCustomActionClicked?.(actionType, managedResource, locale)) {
          return;
        }
        dialogStateCallback?.callback?.({
          managedResource,
          localeToResource: localeToResourceIndexedById.get(
            extractId(managedResource)
          ),
          actionType,
          resourceType: props.mainResource,
          isOpen: true
        });
      },
      isContentLock || isPublishing
    );
  }, [managedResourcesByLocale, locale]);

  const dialogStateModifierConsumer = React.useCallback(callback => {
    setDialogStateCallback({ callback });
  }, []);

  const indexedSearch = React.useMemo(() => {
    if (props.searchFields?.contentFields?.length) {
      const index = elasticlunr<any>(function() {
        props.searchFields!.contentFields!.forEach(field =>
          this.addField(field)
        );
        this.setRef("id");
      });
      const indexedContent = new Map<string, ManagedContent<any>>();
      resourceToManagedContent.forEach(managedContent => {
        const id = extractId(managedContent.managedResource);
        indexedContent.set(id, managedContent);
        const doc: any = { id };
        props.searchFields!.contentFields!.forEach(field => {
          doc[field] = extractLocalizedProps(
            props.locale,
            managedContent.managedResource,
            field
          ).reduce(
            (values, prop, index, arr) =>
              `${values}${prop.value}${index < arr.length - 1 ? " " : ""}`,
            ""
          );
        });
        index.addDoc(doc);
      });
      return {
        index,
        indexedContent
      };
    }
  }, [props.locale, props.searchFields, resourceToManagedContent]);
  const searchConfig = React.useMemo(
    () => ({
      fields: props.searchFields?.contentFields?.reduce(
        (configFields, field) => ({
          [field]: {
            expand: true
          },
          ...configFields
        }),
        {}
      )
    }),
    [props.searchFields]
  );
  const filteredContent = React.useMemo(() => {
    let filteredContent = resourceToManagedContent;

    if (props.filters?.searchTerm && indexedSearch) {
      filteredContent = indexedSearch.index
        .search(props.filters?.searchTerm, searchConfig)
        .map(result => indexedSearch.indexedContent.get(result.ref)!);
    }

    filteredContent = filteredContent.filter(managedContent => {
      if (managedContent.managedResource.archived) {
        return !!props.filters?.showArchivedContent;
      }
      return !props.filters?.showArchivedContent;
    });

    if (props.filters?.activeOptions?.length) {
      filteredContent = filteredContent.filter(content => {
        const anyNonPassingFilter = props.filters?.activeOptions?.some(
          filterOption => !filterOption.evaluate(content)
        );
        return !anyNonPassingFilter;
      });
    }

    return filteredContent;
  }, [indexedSearch, props.filters, resourceToManagedContent, searchConfig]);
  const sortedContent = React.useMemo(() => {
    const comparator = (a: ManagedContent<any>, b: ManagedContent<any>) => {
      if (props.sortInfo.property === "updatedAt") {
        if (!a.updatedAt) {
          if (b.updatedAt) {
            return 1;
          }
        } else if (!b.updatedAt) {
          return -1;
        } else {
          const updateTimeDifference = b.updatedAt - a.updatedAt;
          if (updateTimeDifference !== 0) {
            return updateTimeDifference;
          }
          // Proceed with comparing fields.
        }
      }

      if (!props.sortInfo?.field) {
        return 0;
      }

      const firstValue =
        a.managedResource.content[props.sortInfo.field]?.[0] ??
        a.managedResource.tentativeState.updatedContent[
          props.sortInfo.field
        ]?.[0]?.value;
      const secondValue =
        b.managedResource.content[props.sortInfo.field]?.[0] ??
        b.managedResource.tentativeState.updatedContent[
          props.sortInfo.field
        ]?.[0]?.value;
      if (typeof firstValue !== typeof secondValue) {
        return 0; // Treat different types as equal as there is no good way to determine.
      }
      if (typeof firstValue === "number") {
        // Both are numbers, just subtract.
        return firstValue - secondValue;
      }
      if (typeof firstValue === "string") {
        // Both are strings, compare them.
        return firstValue.localeCompare(secondValue);
      }
      if (typeof firstValue === "boolean") {
        return +firstValue - secondValue.valueOf();
      }
      return 0; // How should non primitives compare?
    };

    switch (props.sortInfo.type ?? ContentManagementSortTypes.NONE) {
      case ContentManagementSortTypes.INCREASING:
        return sortedCopy(filteredContent, comparator);
      case ContentManagementSortTypes.DECREASING:
        return sortedCopy(filteredContent, (a, b) => -comparator(a, b));
      case ContentManagementSortTypes.NONE:
      default:
        return filteredContent;
    }
  }, [filteredContent, props.sortInfo]);

  const classes = useStyles();
  return (
    <Box className={classes.containerWithBanner}>
      <SystemBanner {...props} />
      <Box className={classes.container}>
        <Grid
          container
          alignItems="center"
          justify="flex-start"
          spacing={1}
          direction="column"
          className={classes.grid}
          wrap="nowrap"
        >
          {renderHeader && (
            <Grid item className={classes.fixedGridItem} key="renderHeader">
              {renderHeader()}
            </Grid>
          )}
          {renderContent && (
            <Grid item className={classes.limitedGridItem} key="renderContent">
              {renderContent(sortedContent, props.managedContentByLocale)}
            </Grid>
          )}
          {renderFooter && (
            <Grid item className={classes.fixedGridItem} key="renderFooter">
              {renderFooter()}
            </Grid>
          )}
        </Grid>
        <ContentManagementDialogs
          indexedFormInfoByResource={props.formInfo}
          dialogStateModifierConsumer={dialogStateModifierConsumer}
          isUpdating={isUpdating}
          fieldsUpdater={props.fieldsUpdater}
          changesReverter={props.changesReverter}
          entryRemove={props.entryRemove}
          entryArchive={props.entryArchive}
          entryUnarchive={props.entryUnarchive}
        />
      </Box>
    </Box>
  );
};

export default React.memo(ContentManagementPage);
