import makeStyles from "@material-ui/core/styles/makeStyles";
import pageContainer from "../../styles/pageContainer";
import { SLIDE_BORDER_RADIUS, SLIDE_SIZE } from "./weekly-tip-constants";

export const useStyles = makeStyles(theme => ({
  viewPortCenter: {
    position: "absolute",
    top: "50vh",
    left: "50vw"
  },
  pageContainer: {
    ...pageContainer(theme)
  },
  fieldsContainer: {
    padding: "0.5vw 0.5vw"
  },
  slideContainer: {
    height: `${SLIDE_SIZE}px`,
    width: `${SLIDE_SIZE}px`,
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  newSlideButton: {
    width: `${SLIDE_SIZE}px`,
    height: `${SLIDE_SIZE}px`
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    flexWrap: "nowrap",
    width: "100%",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  },
  weeklyTipNameTextField: {
    width: '500px'
  },
  slideControlItemWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  controlStyle: {
    position: "absolute",
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    height: "70%",
    width: `${SLIDE_SIZE}px`,
    borderTopLeftRadius: `${SLIDE_BORDER_RADIUS}px`,
    borderTopRightRadius: `${SLIDE_BORDER_RADIUS}px`
  },
  slideControlsPadding: {
    padding: "8px"
  }
}));
