import React from "react";
import { useRepositories } from "../../../hooks/useRepositories";
import { useObservableLCE2 } from "../../../hooks/useObservableLCE2";
import { LCEWrapper } from "../../lce/LCEWrapper";
import { List, ListItem } from "@material-ui/core";
import { ActivityTaskItem } from "./ActivityTaskItem";

export const ActivityIndicatorPopover = () => {
  const { globalUserTasks } = useRepositories();
  const userTasksSummaryLCE = useObservableLCE2(globalUserTasks.tasksList());
  return (
    <LCEWrapper
      lce={userTasksSummaryLCE}
      renderContent={taskObservables => (
        <List>
          {taskObservables.map(observable => (
            <ListItem>
              <ActivityTaskItem taskObservable={observable} />
            </ListItem>
          ))}
        </List>
      )}
    />
  );
};
