import React from "react";
import ListDialog from "../../dialogs/ListDialog/ListDialog";
import {
  Avatar,
  Grid,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography
} from "@material-ui/core";
import moment from "moment";
import { DATE_FORMAT, UTC_FORMAT } from "../../../constants/time";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import StagedDataDocument from "../../../api/configuration/StagedDataDocument";

const emptyListFunction = () => undefined;

const ReloadStagedDialog = ({
  isOpen,
  handleClose,
  handleConfirm,
  listStaged,
  isStaging,
  stageError,
  getOwnerDetails
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: (id: string) => void;
  listStaged: (() => Promise<StagedDataDocument[]>) | undefined;
  isStaging: boolean;
  stageError?: Error;
  getOwnerDetails: (
    uid?: string
  ) => { imageUrl?: string | null; name?: string | null };
}) => {
  const renderItem = React.useCallback(
    ({ createdAt, ownerId }) => (
      <>
        <ListItemAvatar>
          <Avatar>
            <HourglassFullIcon />
          </Avatar>
        </ListItemAvatar>
        <Grid container spacing={1}>
          <Grid item>
            <ListItemText>
              <Typography>
                {`Submitted at ${moment
                  .utc(moment(createdAt).format(UTC_FORMAT))
                  .local()
                  .format(DATE_FORMAT)} by owner: `}
              </Typography>
            </ListItemText>
          </Grid>
          <Grid item>
            <ListItemAvatar>
              <Tooltip title={getOwnerDetails(ownerId).name ?? ""}>
                <Avatar src={getOwnerDetails(ownerId).imageUrl ?? ""} />
              </Tooltip>
            </ListItemAvatar>
          </Grid>
        </Grid>
      </>
    ),
    [getOwnerDetails]
  );
  return (
    <ListDialog
      handleConfirm={handleConfirm}
      handleClose={handleClose}
      isOpen={isOpen}
      listData={listStaged ?? emptyListFunction}
      isProcessingAction={isStaging}
      actionError={stageError}
      title="Select a stage to load"
      description="Anything that was staged or publish but failed will show here, if you encounter issues while staging select the latest stage"
      renderItem={renderItem}
    />
  );
};

export default React.memo(ReloadStagedDialog);
