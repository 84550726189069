import { CommitResource } from "../../../../api/commits/Commit";
import { Resources } from "../../../../data/Resources";
import { QuoteFields } from "../../../../api/content/Quote";
import { AuthorFields } from "../../../../api/content/Author";
import { CategoryFields } from "../../../../api/content/Category";
import { WeeklyTipFields } from "../../../../api/content/WeeklyTip";
import { MediaFeedFields } from "../../../../api/content/MedaFeedContent";
import { MediaPlatformFields } from "../../../../api/content/MediaPlatform";
import {
  Checkbox,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

export interface ExportedFieldsSelectionProps {
  resource: CommitResource;
  onFieldsSelectionChange: (selectedFields: string[]) => void;
}

export const ExportedFieldsSelection = ({
  resource,
  onFieldsSelectionChange
}: ExportedFieldsSelectionProps) => {
  const [selectedContentFields, setSelectedContentFields] = useState(
    new Set<string>()
  );
  useEffect(() => {
    setSelectedContentFields(new Set(getContentFieldsForResource(resource)));
  }, [resource]);
  useEffect(() => {
    onFieldsSelectionChange(Array.from(selectedContentFields));
  }, [onFieldsSelectionChange, selectedContentFields]);
  return (
    <>
      <Typography>Select exported fields:</Typography>
      <List style={{ marginTop: 8 }}>
        {getContentFieldsForResource(resource).map(field => (
          <ListItem>
            <ListItemText>{field}</ListItemText>
            <ListItemSecondaryAction>
              <Checkbox
                color="primary"
                checked={selectedContentFields.has(field)}
                onClick={() => {
                  setSelectedContentFields(currentContentFields => {
                    const updatedSet = new Set(currentContentFields);
                    if (selectedContentFields.has(field)) {
                      updatedSet.delete(field);
                    } else {
                      updatedSet.add(field);
                    }
                    return updatedSet;
                  });
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </>
  );
};

function getContentFieldsForResource(resource: CommitResource): string[] {
  switch (resource) {
    case Resources.QUOTES:
      return Object.values(QuoteFields);
    case Resources.AUTHORS:
      return Object.values(AuthorFields);
    case Resources.CATEGORIES:
      return Object.values(CategoryFields);
    case Resources.WEEKLY_TIP:
      return Object.values(WeeklyTipFields);
    case Resources.PODCASTS:
    // FALLTHROUGH
    case Resources.VIDEOS:
    // FALLTHROUGH
    case Resources.BOOKS:
      return Object.values(MediaFeedFields);
    case Resources.MEDIA_PLATFORM:
      return Object.values(MediaPlatformFields);
  }
}
